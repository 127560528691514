import { DeviationRelation } from './deviation-relation'
import { DeviationModuleLevel } from './deviation-module-level'
import { IntegratorBusinessModule } from '../../module'

class DeviationsCriteria {
  newDeviation
  open
  closed
  archived
  discoveryDateRange
  deadlineDateRange
  internalCaseManagerIds
  externalCaseManagerIds
  managedBy
  internalResponsibleIds
  externalResponsibleIds
  severityNames
  modules
  moduleLevels
  offset
  limit
  withFullReport
  withContext
  ordered
  optionalRelations
  relationsOnly
  clientFilterText
  costEstimateRange
  constructor(props) {
    if (props) {
      this.newDeviation = props.newDeviation
      this.open = props.open
      this.closed = props.closed
      this.archived = props.archived
      this.discoveryDateRange = new Range(props.discoveryDateRange)
      this.deadlineDateRange = new Range(props.deadlineDateRange)
      this.internalCaseManagerIds = props.internalCaseManagerIds
      this.externalCaseManagerIds = props.externalCaseManagerIds
      this.managedBy = props.managedBy
      this.internalResponsibleIds = props.internalResponsibleIds
      this.externalResponsibleIds = props.externalResponsibleIds
      this.severityNames = props.severityNames
      this.offset = props.offset
      this.limit = props.limit
      this.withFullReport = props.withFullReport
      this.withContext = props.withContext
      this.ordered = props.ordered
      this.relationsOnly = props.relationsOnly
      this.clientFilterText = props.clientFilterText
      this.costEstimateRange = new Range(props.costEstimateRange)
      this.optionalRelations = props.optionalRelations?.map(item => new DeviationRelation(item)) || []
      this.modules = props.modules?.map(item => new IntegratorBusinessModule(item)) || []
      this.moduleLevels = props.moduleLevels?.map(moduleLevelName => new DeviationModuleLevel(moduleLevelName))
    }
  }

  initDefault() {
    this.newDeviation = true
    this.open = true
    this.closed = false
    this.ordered = true
    this.relationsOnly = true
    this.limit = 1000
    this.managedBy = 'ALL'
    this.internalResponsibleIds = []
    this.externalResponsibleIds = []
    this.internalCaseManagerIds = []
    this.externalCaseManagerIds = []
    this.optionalRelations = []
    this.severityNames = []
    this.clientFilterText = ''
    const now = new Date()
    const previousYearDate = new Date(now.getFullYear() - 1, 0, 1)
    const dateFormatter = Intl.DateTimeFormat('sv-SE')
    this.discoveryDateRange = {
      from: dateFormatter.format(previousYearDate),
      to: ''
    }
    this.deadlineDateRange = { from: '', to: '' }
    this.costEstimateRange = { from: '', to: '' }
    return this
  }

  changeStatus(key) {
    this[key] = !this[key]
  }

  resetStatus() {
    this.newDeviation = false
    this.open = false
    this.closed = false
  }

  internalResponsible(id) {
    this.internalResponsibleIds = this.#singleElementOnArray(id)
  }

  externalResponsible(id) {
    this.externalResponsibleIds = this.#singleElementOnArray(id)
  }

  clearResponsible() {
    this.externalResponsibleIds = []
    this.internalResponsibleIds = []
  }

  internalCaseManager(id) {
    this.internalCaseManagerIds = this.#singleElementOnArray(id)
  }

  externalCaseManager(id) {
    this.externalCaseManagerIds = this.#singleElementOnArray(id)
  }

  clearCaseManager() {
    this.externalCaseManagerIds = []
    this.internalCaseManagerIds = []
  }

  #singleElementOnArray(element) {
    const array = []
    if (element) {
      array.push(element)
    }
    return array
  }

  resetSeverity() {
    this.severityNames.splice(0, this.severityNames.length)
  }

  toggleSeverity(s) {
    if (this.severityNames.includes(s)) {
      this.severityNames.splice(this.severityNames.indexOf(s), 1)
    } else {
      this.severityNames.push(s)
    }
  }

  isSeveritySelected() {
    return this.severityNames?.length > 0
  }

  changeClientFilterText(newClientFilterText) {
    let changed = false
    if (newClientFilterText !== this.clientFilterText) {
      changed = true
    }
    if (newClientFilterText && newClientFilterText.length >= 3 && newClientFilterText !== this.clientFilterText) {
      this.clientFilterText = newClientFilterText
    } else {
      this.clientFilterText = ''
    }
    return changed
  }

  newDiscoveryDate(range) {
    const from = range.from ? range.from : new Date(0)
    const to = range.to ? range.to : new Date()
    this.discoveryDateRange = {
      from: from,
      to: to
    }
  }
}

class Range {
  from
  to
  constructor(props) {
    this.from = props?.from || ''
    this.to = props?.to || ''
  }
}

export { DeviationsCriteria }
