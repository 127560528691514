import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/structure/departments`
}

function departmentUrl(businessUnitId, departmentId) {
  return url(businessUnitId) + `/${departmentId}`
}

function departmentEmployeeUrl(businessUnitId, departmentId, employeeId) {
  return departmentUrl(businessUnitId, departmentId + `/employees/${employeeId}`)
}

function departmentMangerUrl(businessUnitId, departmentId, employeeId) {
  return departmentUrl(businessUnitId, departmentId + `/manager/${employeeId}`)
}

function create(unitId, newDepartment, parentDepartmentId) {
  const finalUrl = url(unitId) + '/add-child' + (parentDepartmentId ? `/${parentDepartmentId}` : '')
  return ifetch(finalUrl, {
    method: 'POST',
    body: JSON.stringify(newDepartment)
  })
}

function read(unitId) {
  return ifetch(url(unitId), {})
}
function readTree(unitId) {
  return ifetch(url(unitId) + '/tree', {})
}

function update(unitId, departmentId, department) {
  return ifetch(departmentUrl(unitId, departmentId), {
    method: 'POST',
    body: JSON.stringify(department)
  })
}

function establishRootDepartment(unitId) {
  return ifetch(url(unitId) + '/establishRootDepartment', {
    method: 'POST'
  })
}

function remove(unitId, departmentId) {
  return ifetch(departmentUrl(unitId, departmentId), {
    method: 'DELETE'
  })
}

function setManager(unitId, departmentId, employeeId) {
  return ifetch(departmentMangerUrl(unitId, departmentId, employeeId), {
    method: 'POST'
  })
}

function removeManager(unitId, departmentId, employeeId) {
  return ifetch(departmentMangerUrl(unitId, departmentId, employeeId), {
    method: 'DELETE'
  })
}

function addEmployee(unitId, departmentId, employeeId) {
  return ifetch(departmentEmployeeUrl(unitId, departmentId, employeeId), {
    method: 'POST'
  })
}

function removeEmployee(unitId, departmentId, employeeId) {
  return ifetch(departmentEmployeeUrl(unitId, departmentId, employeeId), {
    method: 'DELETE'
  })
}

export {
  create,
  read,
  readTree,
  update,
  remove,
  establishRootDepartment,
  setManager,
  removeManager,
  addEmployee,
  removeEmployee
}
