'use strict'

import ApiConstants from '../../../config/config.constants.js'
import angular from 'angular'

/**
 * Data service for /procedures.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
class ProceduresDataService {
  constructor($http, $log, $q, $cacheFactory, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.cache = $cacheFactory.get('$http')
    this.endpoint = new ProceduresEndpoint(apiConfig)
  }

  /**
   * Get project procedures
   * @param projectId
   */
  projectProcedures(projectId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.projectProcedures(projectId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.projectProcedures(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Get procedure documents.
   * @param id procedure id
   */
  getDocuments(id) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.documents(id), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for ' + this.endpoint.documents(id) + ' with error: ' + error.status + ' ' + error.statusText
        )
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }

  /**
   * Get procedure categories for project.
   * @param id project id
   */
  getProcedureCategories(id) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.projectProcedureCategories(id), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for ' +
            this.endpoint.projectProcedureCategories(id) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }

  getProcedureSubCategories(projectId, mainCategoryId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.projectProcedureSubCategories(projectId, mainCategoryId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for ' +
            this.endpoint.projectProcedureCategories(id) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }

  getProcedure(procedureId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.procedure(procedureId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for ' +
            this.endpoint.projectProcedureCategories(procedureId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }

  getCheckLists(unitId, projectId, procedureId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.procedureChecklists(unitId, projectId, procedureId), { cache: true })
      .then(response => {
        asyncResult.resolve(response.data)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for ' +
            this.endpoint.procedureChecklists(procedureId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  getTables(procedureId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.procedureTables(procedureId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for ' +
            this.endpoint.procedureTables(procedureId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }
}

ProceduresDataService.$inject = ['$http', '$log', '$q', '$cacheFactory', 'apiConfig']

class ProceduresEndpoint {
  constructor(apiConfig) {
    this.url = apiConfig.url()
    this.path = apiConfig.completeUrl() + '/procedures'
    this.base = apiConfig.completeUrl()
    this.proceduresFsPath = apiConfig.completeFsUrl() + '/procedure'
  }

  // Path for project procedures
  projectProcedures(projectId) {
    return this.path + '/project/' + projectId
  }

  // Path for procedure documents
  documents(procedureId) {
    return this.proceduresFsPath + '/' + procedureId + '/structure'
  }

  projectProcedureCategories(projectId) {
    return this.path + '/project/' + projectId + '/categories'
  }

  projectProcedureSubCategories(projectId, mainCategoryId) {
    return this.path + '/project/' + projectId + '/categories/' + mainCategoryId
  }

  procedure(procedureId) {
    return this.path + '/' + procedureId
  }

  procedureChecklists(unitId, projectId, procedureId) {
    return this.url + '/business/' + unitId + '/project/' + projectId + '/procedure/' + procedureId + '/checklists'
  }

  procedureTables(procedureId) {
    return this.base + '/tables/procedures/' + procedureId
  }
}

/**
 * Export projects data service.
 */
export default angular
  .module('service.data.procedures', [ApiConstants])
  .service('psProcedureService', ProceduresDataService).name
