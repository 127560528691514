'use strict'

/**
 * Controller for detail view of a single component.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */

import _ from 'lodash'

class ComponentDetailCtrl {
  constructor($stateParams, components, psComponentsService, $log, psPdfReportService, $state) {
    this.service = psComponentsService
    this.$log = $log
    this.fabIsOpen = false
    this.pdfService = psPdfReportService

    this.unitId = $stateParams.unitId
    this.projectId = $stateParams.project
    this.controlId = $stateParams.control
    this.componentId = $stateParams.component

    // Unpack component tree.
    this.components = []
    this.findComponents(components.data.data)

    // Set issues to empty list for now
    this.issues = []

    // Get the current component from the list of components.
    this.component = _.find(this.components, component => {
      return component.id === parseInt($stateParams.component)
    })

    this.loadChecklists()
    this.goToState =
      'controls.detail({ unitId: ' +
      this.unitId +
      ', project : ' +
      $stateParams.project +
      ', control : ' +
      $stateParams.control +
      '})'
  }

  /**
   * Used to recursively unpack component tree.
   * @param array
   */
  findComponents(array) {
    _.forEach(array, element => {
      this.components.push(element)
      if (element.childComponents.length > 0) {
        this.findComponents(element.childComponents)
      }
    })
  }

  loadChecklists() {
    this.service
      .getChecklists(this.unitId, this.projectId, this.controlId, this.component.id)
      .then(response => {
        this.checklists = response.data.list || []
        this.$log.debug(this.checklists)
      })
      .catch(error => {
        this.$log.error(error)
      })
  }
}

ComponentDetailCtrl.$inject = [
  '$stateParams',
  'components',
  'psComponentsService',
  '$log',
  'psPdfReportService',
  '$state'
]

export default ComponentDetailCtrl
