import { Actions, Person } from '../../universal'
import { NotificationReason } from './notification-reason'

class DeviationNotification {
  recipient
  actions
  reason
  constructor(props) {
    if (props) {
      this.recipient = new Person(props.recipient)
      this.actions = new Actions(props.actions)
      this.reason = new NotificationReason(props.reason)
    }
  }
}

export { DeviationNotification }
