import { ifetch } from '@integrator/api'

function baseUrl(unitId, procedureId) {
  return `/api/business/${unitId}/procedure/${procedureId}/checklists`
}

function list(unitId, procedureId) {
  return ifetch(baseUrl(unitId, procedureId), { method: 'GET' })
}

// CHECK THIS REQUEST CORRECTNESS
// BusinessProcedureChecklistsEndpoint.java, assignedList() method
function assignedList(unitId, procedureId, checkpoints = false) {
  return ifetch(baseUrl(unitId, procedureId) + `/assigned?checkpoints=${checkpoints}`, { method: 'GET' })
}

function activeList(unitId, procedureId) {
  return ifetch(baseUrl(unitId, procedureId) + `/active`, { method: 'GET' })
}

function createNewTemplate(unitId, procedureId, definition) {
  return ifetch(baseUrl(unitId, procedureId) + `/add-template`, { method: 'POST', body: JSON.stringify(definition) })
}

function addChecklistTemplate(unitId, procedureId, templateId) {
  return ifetch(baseUrl(unitId, procedureId) + `/copy-global-template/${templateId}`, { method: 'POST' })
}

// In java files this method is called addChecklist() with value = "/add/{templatId}"
function addChecklistFromTemplate(unitId, procedureId, templateId) {
  return ifetch(baseUrl(unitId, procedureId) + `/add/${templateId}`, { method: 'POST' })
}

function addChecklist(unitId, procedureId, definition) {
  return ifetch(baseUrl(unitId, procedureId) + `/add`, { method: 'POST', body: JSON.stringify(definition) })
}

function checklists(unitId, procedureId) {
  return ifetch(baseUrl(unitId, procedureId), { method: 'GET' })
}

function assignedChecklists(unitId, procedureId, checkpoints = false) {
  return ifetch(url(unitId, procedureId) + `checklists/assigned?checkpoints=` + checkpoints + `&`)
}

export {
  list,
  assignedList,
  activeList,
  createNewTemplate,
  addChecklistTemplate,
  addChecklistFromTemplate,
  addChecklist,

  //deprecated
  checklists,
  assignedChecklists
}
