import { ifetch } from '@integrator/api'

function url(unitId, projectId, contextName) {
  return `/api/${unitId}/projects/${projectId}/calendars/${contextName}/events`
}

function events(unitId, projectId, contextName, relationId) {
  return ifetch(url(unitId, projectId, contextName) + `/${relationId}`)
}

function allEventsGrouped(unitId, projectId, contextName) {
  return ifetch(url(unitId, projectId, contextName) + '/grouped')
}

function eventsWithIds(unitId, projectId, contextName, relationIds) {
  return ifetch(
    url(unitId, projectId, contextName, { method: 'GET', body: JSON.stringify(relationIds) }) + '/with-relation-ids'
  )
}

function grouppedEventsWithIds(unitId, projectId, contextName, relationIds) {
  return ifetch(
    url(unitId, projectId, contextName, { method: 'GET', body: JSON.stringify(relationIds) }) +
      '/grouped/with-relation-ids'
  )
}

function allEvents(unitId, projectId, contextName) {
  return ifetch(url(unitId, projectId, contextName))
}

export { allEvents, allEventsGrouped, events, eventsWithIds, grouppedEventsWithIds }
