import { ifetch } from '@integrator/api'

function url(unitId, folderId) {
  return `/api/business/${unitId}/projects/folders/${folderId}`
}


function remove(unitId, folderID) {
  return ifetch(url(unitId, folderID), { method: 'DELETE' })
}

// folderDefinition = { id, name }
function update(unitId, folderId, folderDefinition) {
  return ifetch(url(unitId, folderId), { method: 'PUT', body: JSON.stringify(folderDefinition) })
}

function updateWithMove(unitId, folderId, newParentFolderId, folderDefinition) {
  return ifetch(url(unitId, folderId) + `/${newParentFolderId}`, { method: 'PUT', body: JSON.stringify(folderDefinition) })
}

export { remove, update, updateWithMove }
