class UiFeatures {
  oldMom
  hasPeriodicControlContract
  constructor(props) {
    this.oldMom = props?.oldMom
    this.hasPeriodicControlContract = props?.hasPeriodicControlContract
  }
}

export { UiFeatures }
