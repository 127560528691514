'use strict'

import ApiConstants from '../../../config/config.constants.js'
import angular from 'angular'

/**
 * Data service for /components  - structure components.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
class ComponentsDataService {
  constructor($http, $log, $q, $cacheFactory, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.cache = $cacheFactory.get('$http')
    this.endpoint = new ComponentsEndpoint(apiConfig)
  }

  /**
   * Get checklists for component.
   * @param unitId
   * @param projectId
   * @param controlId
   * @param componentId
   */
  getChecklists(unitId, projectId, controlId, componentId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.getChecklists(unitId, projectId, controlId, componentId))
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.getChecklists(componentId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Get issues for component with id
   * @param componentId
   */
  getIssues(componentId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.getIssues(componentId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for ' +
            this.endpoint.getIssues(componentId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }
}

ComponentsDataService.$inject = ['$http', '$log', '$q', '$cacheFactory', 'apiConfig']

class ComponentsEndpoint {
  constructor(apiConfig) {
    this.url = apiConfig.url()
    this.path = apiConfig.completeUrl() + '/components'
  }

  // Path for getting component checklists
  getChecklists(unitId, projectId, controlId, componentId) {
    let url =
      this.url +
      '/business/{unitId}/project/{projectId}/periodic-control/controls/{controlId}/components/{componentId}/checklists/assigned'
    url = url.replace('{unitId}', unitId)
    url = url.replace('{projectId}', projectId)
    url = url.replace('{controlId}', controlId)
    url = url.replace('{componentId}', componentId)
    return url
  }

  // Path for getting component issues
  getIssues(componentId) {
    return this.path + '/' + componentId + '/issues'
  }
}

/**
 * Export projects data service.
 */
export default angular
  .module('service.data.components', [ApiConstants])
  .service('psComponentsService', ComponentsDataService).name
