import { Enum } from '@integrator/api'

class TaskGroup extends Enum {
  static WEEK = new TaskGroup('WEEK')
  static MONTH = new TaskGroup('MONTH')

  constructor(name = TaskGroup.MONTH) {
    super(name)
  }
}

export { TaskGroup }
