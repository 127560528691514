'use strict'

import ApiConstants from '../../../config/config.constants.js'
import angular from 'angular'

/**
 * Data service for /controls  - Periodic Controls.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
class ControlsDataService {
  constructor($http, $log, $q, $cacheFactory, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.cache = $cacheFactory.get('$http')
    this.endpoint = new ControlsEndpoint(apiConfig)
  }

  /**
   * Get project periodic controls.
   * @param projectId
   */
  projectControls(projectId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.getProjectControls(projectId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.getProjectControls(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Get components for a periodic control.
   * @param id control id
   */
  getControlComponents(id) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.getComponents(id), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for ' + this.endpoint.getComponents(id) + ' with error: ' + error.status + ' ' + error.statusText
        )
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }
}

ControlsDataService.$inject = ['$http', '$log', '$q', '$cacheFactory', 'apiConfig']

class ControlsEndpoint {
  constructor(apiConfig) {
    this.path = apiConfig.completeUrl() + '/controls'
  }

  // Path for getting periodic control components
  getComponents(controlId) {
    return this.path + '/' + controlId + '/components'
  }

  // Path for getting periodic controls for project
  getProjectControls(projectId) {
    return this.path + '/project/' + projectId
  }
}

/**
 * Export projects data service.
 */
export default angular.module('service.data.controls', [ApiConstants]).service('psControlsService', ControlsDataService)
  .name
