import { ContainerHit } from './container-hit'
import { ContainerContent } from './container-content'

/**
 * @typedef { object } ContainerStructure
 * @property { Array<ContainerHit> } coordinates
 * @property { ContainerHit } container
 * @property { Number } fileSystemId
 */
class ContainerStructure extends ContainerContent {
  coordinates
  container
  fileSystemId

  constructor(props) {
    super(props)
    if (props) {
      this.coordinates = props.coordinates?.map(containerHit => new ContainerHit(containerHit)) || []
      this.container = new ContainerHit(props.container ? props.container : {})
      this.fileSystemId = props.fileSystemId
    }
  }
}

export { ContainerStructure }
