import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, elementId, pieceId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/building-documentation/${elementId}/pieces/${pieceId}`
}

// no response value
function definition(unitId, projectId, elementId, pieceId, text) {
  return ifetch(baseUrl(unitId, projectId, elementId, pieceId) + `/description`, {
    method: 'POST',
    body: JSON.stringify(text)
  })
}

function links(unitId, projectId, elementId, pieceId) {
  return ifetch(baseUrl(unitId, projectId, elementId, pieceId) + `/links`, { method: 'GET' })
}

function addLink(unitId, projectId, elementId, pieceId, linkJson) {
  return ifetch(baseUrl(unitId, projectId, elementId, pieceId) + `/links`, {
    method: 'POST',
    body: JSON.stringify(linkJson)
  })
}

function getProducts(unitId, projectId, elementId, pieceId) {
  return ifetch(baseUrl(unitId, projectId, elementId, pieceId) + `/products`, { method: 'GET' })
}

// no response value
function addProduct(unitId, projectId, elementId, pieceId, id) {
  return ifetch(baseUrl(unitId, projectId, elementId, pieceId) + `/products/${id}`, { method: 'POST' })
}

function connectEfoBaseProduct(unitId, projectId, elementId, pieceId, externalProductId) {
  return ifetch(baseUrl(unitId, projectId, elementId, pieceId) + `/products/connect/efobase/${externalProductId}`, {
    method: 'POST'
  })
}

function connectNobbVavvsProduct(unitId, projectId, elementId, pieceId, externalProductId) {
  return ifetch(baseUrl(unitId, projectId, elementId, pieceId) + `/products/connect/nobbvavvs/${externalProductId}`, {
    method: 'POST'
  })
}

export { definition, links, addLink, getProducts, addProduct, connectEfoBaseProduct, connectNobbVavvsProduct }
