'use strict'

import angular from 'angular'
import router from 'angular-ui-router'
import dataService from './security.dataservice'
import jwtHelper from 'angular-jwt'
import Notification from '../service.notifications.js'
import _ from 'lodash'
import Cookies from 'js-cookie'

/**
 * Authentication Service for Profsys webclients.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
const logTag = 'AuthService'
class AuthService {
  constructor($log, apiService, $state, jwtHelper, $q, Notification, EVENTS, $cacheFactory) {
    this.$log = $log
    this.jwtHelper = jwtHelper
    this.api = apiService
    this.$state = $state
    this.$q = $q
    this.Notification = Notification
    this.EVENTS = EVENTS
    this.cache = $cacheFactory.get('$http')
    this.decodeToken()
  }

  /**
   * Try decoding JWT token if it exists in localStorage.
   */
  decodeToken() {
    let token = this.getTokenFromStorage()
    if (_.isNull(token) || _.isUndefined(token)) {
      return
    }

    try {
      this.token = token
      Cookies.set(globalThis.JWT_COOKIE_NAME, this.token)
      this.user = this.jwtHelper.decodeToken(this.token)
    } catch (jwtError) {
      this.$log.error(logTag + ' : Error parsing JwtToken. ', jwtError)
    }
  }

  getTokenFromStorage() {
    return Cookies.get(globalThis.JWT_COOKIE_NAME, this.token)
  }

  saveTokenToStorage(token) {
    if (typeof localStorage === 'object') {
      try {
        Cookies.set(globalThis.JWT_COOKIE_NAME, this.token)
        window.jwtToken = token
        return token
      } catch (error) {
        console.log(error)
        Storage.prototype._setItem = Storage.prototype.setItem
        Storage.prototype.setItem = function () {}
      }
    } else {
      return null
    }
  }

  /**
   * Remove JWT token and go to login.
   */
  removeToken() {
    Cookies.remove(globalThis.JWT_COOKIE_NAME)
  }

  isAuthenticated() {
    return this.getTokenFromStorage() != null
  }

  /**
   * Login user.
   * @param email - user email
   * @param password  - user password
   * @param unitId - optional .. if user has access to more then one unit.
   */
  login(email, password, unitId) {
    let asyncResult = this.$q.defer()
    this.api
      .login({ username: email, password: password, unit: unitId })
      .then(
        // Success callback (resolves promise)
        response => {
          if (response.data.token) {
            this.token = response.data.token
            let savedToken = this.saveTokenToStorage(this.token)
            if (!_.isNull(savedToken) && !_.isUndefined(savedToken)) {
              this.Notification.broadcast(this.EVENTS.LOGIN_EVENT)
              asyncResult.resolve(response.data)
            } else {
              asyncResult.reject({ data: { error: 'LOCAL_STORAGE_NOT_SUPPORTED' } })
            }
          } else if (response.data.businessUnits) {
            asyncResult.resolve(response.data)
          }
          asyncResult.reject({ data: { error: 'NO_DATA_IN_RESPONSE' } })
        }
      )
      // Catch error (reject promise)
      .catch(error => {
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Forgot password.
   */
  forgot(email, callbackUrl) {
    let asyncResult = this.$q.defer()
    this.api
      .forgot({ email: email, handleRequestUrl: callbackUrl })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Reset password (with server token).
   *
   * @param payload - email, url
   * @param token - server token
   * @returns {*}
   */
  resetPassword(payload, token) {
    let asyncResult = this.$q.defer()
    this.api
      .resetPassword(payload, token)
      .then(response => asyncResult.resolve(response))
      .catch(error => {
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Activate user with password and server token).
   *
   * @param payload - email, url
   * @param token - server token
   * @returns {*}
   */
  activateAndSetPassword(payload, token) {
    let asyncResult = this.$q.defer()
    this.api
      .activateAndSetPassword(payload, token)
      .then(response => asyncResult.resolve(response))
      .catch(error => {
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Logout user.
   */
  logout() {
    Cookies.remove(globalThis.JWT_COOKIE_NAME)
    this.cache.removeAll()
    this.user = null
    this.token = null
    this.Notification.broadcast(this.EVENTS.LOGOUT_EVENT)
    this.$state.go('auth.login')
  }

  /** Helper methods **/

  static isTokenNull(token) {
    return _.isNull(token) || _.isUndefined(token)
  }
}

AuthService.$inject = [
  '$log',
  'securityDataService',
  '$state',
  'jwtHelper',
  '$q',
  'Notification',
  'EVENTS',
  '$cacheFactory'
]

/**
 * Http interceptor that add authorization header.
 */
class AuthInterceptor {
  request(config) {
    let token = Cookies.get(globalThis.JWT_COOKIE_NAME)
    if (config.method === 'OPTIONS') {
      return
    } else if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  }
}

let interceptConfig = function ($httpProvider) {
  $httpProvider.interceptors.push('authInterceptor')
}

interceptConfig.$inject = ['$httpProvider']

export default angular
  .module('service.auth', [router, jwtHelper, dataService, Notification])
  .service('authService', AuthService)
  .service('authInterceptor', AuthInterceptor)
  .config(interceptConfig).name
