'use strict'
/**
 *
 * Controller for listing documents
 *
 * @author Audun Follegg (audunfo@gmail.com)
 *
 */

class DocumentListCtrl {
  constructor(psDocumentsService, $stateParams, $log) {
    // Fs context
    this.contextName = $stateParams.contextName
    this.relationId = $stateParams.id
    this.psDocumentsService = psDocumentsService
    this.$log = $log
    this.documents = null
    this.folders = null
    this.searchBarOpen = false
    this.getDocuments(this.contextName, this.relationId, null)
    this.positions = []
    this.root = null
  }

  // Get documents and temeplate documents for project
  getDocuments(contextName, relationId, containerId) {
    this.psDocumentsService
      .getDocuments(contextName, relationId, containerId)
      .then(response => {
        this.containerStructure = response.data
      })
      .catch(error => {
        this.$log.error(error)
      })
  }

  openContainer(containerId) {
    this.getDocuments(this.contextName, this.relationId, containerId)
  }

  // Method to get css class for document type.
  documentIconClass(type) {
    switch (type) {
      case 'PDF':
        return 'fa-file-pdf-o'
      case 'IMAGE':
        return 'fa-file-image-o'
      case 'MS_WORD':
        return 'fa-file-word-o'
      case 'MS_EXCEL':
        return 'fa-file-excel-o'
      case 'MS_POWERPOINT':
        return 'fa-file-powerpoint-o'
      case 'GENERIC':
        return 'fa-file'
      default:
        return 'fa-file'
    }
  }

  showSubfolder(folder) {
    this.documents = folder.documents != null ? folder.documents : []
    this.folders = folder.folders != null ? folder.folders : []
    this.positions.push({ folder })
  }

  showParentFolder(index) {
    this.documents = this.positions[index].folder.documents != null ? this.positions[index].folder.documents : []
    this.folders = this.positions[index].folder.folders != null ? this.positions[index].folder.folders : []
    this.positions.splice(index + 1)
  }

  showRoot() {
    this.documents = this.root.documents != null ? this.root.documents : []
    this.folders = this.root.folders != null ? this.root.folders : []
    this.positions = []
  }
}

DocumentListCtrl.$inject = ['psDocumentsService', '$stateParams', '$log']

export default DocumentListCtrl
