/**
 * Directive for listing components.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import router from 'angular-ui-router'
import ProjectsDataService from '../../services/data/projects.dataservice.js'
import ControlsDataService from '../../services/data/controls.dataservice.js'
/*import ComponentsDataService from '../../services/data/'*/

// Style
import './sidenav.components.list.directive.scss'

class ComponentsListCtrl {
  constructor($stateParams, psProjectService, $state, $log) {
    this.currentProjectId = $stateParams.id
    this.service = psProjectService
    this.$state = $state
    this.projects = null
    this.nameFilter = ''
    this.error = false
    this.$log = $log

    this.loadProjects()
  }

  loadProjects() {
    this.service
      .list()
      .then(response => {
        this.projects = response.data
      })
      .catch(error => {
        this.showError()
      })
  }

  showError() {
    this.error = true
  }
}
ComponentsListCtrl.$inject = ['$stateParams', 'psProjectService', '$state', '$log', 'project', 'control']

export default angular
  .module('ps.directives.sidenav.components', [router, ProjectsDataService])
  .controller('ComponentsListCtrl', ComponentsListCtrl)
  .directive('componentsList', function () {
    return {
      restrict: 'A',
      template: require('./sidenav.components.list.directive.tpl.html'),
      scope: {
        project: '=',
        control: '='
      },
      controller: 'ComponentsListCtrl',
      controllerAs: 'vc'
    }
  }).name
