/**
 * Object which gathers all types into separate maps.
 * Key of the map is name of the field (from field template)
 *
 * @typedef { object } FieldValues
 * @property { Map<String, String> } dateValues
 * @property { Map<String, String> } stringValues
 */
class FieldValues {
  dateValues
  stringValues

  constructor(props) {
    this.dateValues = props.dateValues ? props.dateValues : new Map()
    this.stringValues = props.stringValues ? props.stringValues : new Map()
  }

  toJson() {
    return {
      dateValues: Object.fromEntries(this.dateValues.entries()),
      stringValues: Object.fromEntries(this.stringValues.entries())
    }
  }
}

export { FieldValues }
