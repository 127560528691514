import { ProjectMomSettings } from './project-mom-settings'

class ProjectSettings {
  imageUrl
  mom
  constructor(props) {
    this.imageUrl = props?.imageUrl
    this.mom = new ProjectMomSettings(props?.mom)
  }
}

export { ProjectSettings }
