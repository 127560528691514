'use strict'
/**
 * Dialog for showing browser error.
 */

class Controller {
  constructor($mdDialog) {
    this.dialog = $mdDialog
  }

  cancel() {
    this.dialog.hide()
  }
}
Controller.$inject = ['data', '$mdDialog']

export default function () {
  return {
    controller: Controller,
    controllerAs: 'vc',
    template: require('./dialogs.browser.error.dialog.tpl.html'),
    autoWrap: true,
    fullscreen: true
  }
}
