import { WopiAccessToken } from './wopi-access-token'

/**
 * @typedef { object } WopiHost
 * @property { WopiAccessToken } accessToken
 * @property { String } action
 * @property { String } faviconUrl
 * @property { String } fileName
 */
class WopiHost {
  accessToken
  action
  faviconUrl
  fileName

  constructor(props) {
    this.accessToken = props.accessToken ? new WopiAccessToken(props.accessToken) : new WopiAccessToken({})
    this.action = props.action
    this.faviconUrl = props.faviconUrl
    this.fileName = props.fileName
  }

  static create(props) {
    return new WopiHost(props)
  }
}

export { WopiHost }
