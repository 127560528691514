import { InstallationTypes } from './installation-types'
import { InstallationDivision } from './installation-division'
import { Regulations } from './regulations'

class ControlInstallation {
  boligmappaId
  installationTypes
  installationDescription
  division
  regulations
  yearBuild
  yearUpgraded
  constructor(props) {
    if (props) {
      this.boligmappaId = props.boligmappaId
      this.installationTypes = new InstallationTypes(props.installationTypes)
      this.installationDescription = props.installationDescription
      this.division = props?.division ? new InstallationDivision(props.division) : undefined
      this.regulations = new Regulations(props.regulations)
      this.yearBuild = props.yearBuild
      this.yearUpgraded = props.yearUpgraded
    }
  }
}

export { ControlInstallation }
