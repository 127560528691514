import { ifetch } from '@integrator/api'

function baseUrl(unitId, module) {
  return `/api/business/${unitId}/module/${module}/checklists`
}

function add(unitId, module, definition) {
  return ifetch(baseUrl(unitId, module), { method: 'POST', body: JSON.stringify(definition) })
}

function copyFrom(unitId, module, checklistId) {
  return ifetch(`${baseUrl(unitId, module)}/copy-from-template/${checklistId}`, { method: 'POST' })
}

function copyFromRuntime(unitId, module, checklistId) {
  return ifetch(`${baseUrl(unitId, module)}/copy-from-runtime/${checklistId}`, { method: 'POST' })
}

function list(unitId, module) {
  return ifetch(baseUrl(unitId, module), { method: 'GET' })
}

function activeList(unitId, module) {
  return ifetch(baseUrl(unitId, module) + '/active', { method: 'GET' })
}

export { add, copyFrom, copyFromRuntime, list, activeList }
