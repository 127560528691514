import { Enum } from '@integrator/api'

class ChecklistStatus extends Enum {
  static IN_PROGRESS = new ChecklistStatus('IN_PROGRESS')
  static DONE = new ChecklistStatus('DONE')

  constructor(name = ChecklistStatus.IN_PROGRESS.name) {
    super(name)
  }
}

export { ChecklistStatus }
