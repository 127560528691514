import { DeviationRelationName } from './deviation-relation-name'
import { NameIdentity } from '../../universal'
import { IntegratorBusinessModule } from '../../module'

class DeviationRelation {
  id
  name
  module
  identity
  constructor(props) {
    this.id = props?.id
    if (props) {
      this.module = props.module ? new IntegratorBusinessModule(props.module) : undefined
      this.name = new DeviationRelationName(props.name)
      this.identity = new NameIdentity(props.identity)
    }
  }
}

export { DeviationRelation }
