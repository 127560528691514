import { ifetch } from '@integrator/api'

function url(unitId, employeeId) {
  return `/api/business/${unitId}/employees/${employeeId}/competences`
}

// in java file: addCompetency
function addExperience(unitId, employeeId, competencyId, level) {
  return ifetch(url(unitId, employeeId) + `/${competencyId}`, { method: 'POST', body: JSON.stringify(level) })
}

// in java file: deleteCompetency
function deleteExperience(unitId, employeeId, competencyId) {
  return ifetch(url(unitId, employeeId) + `/${competencyId}`, { method: 'DELETE' })
}

// change experience level
function changeLevel(unitId, employeeId, competencyId, level) {
  return ifetch(url(unitId, employeeId) + `/${competencyId}`, { method: 'PATCH', body: JSON.stringify(level) })
}

// in java file: singleCompetency
function employeeCompetencyDetails(unitId, employeeId, competencyId) {
  return ifetch(url(unitId, employeeId) + `/${competencyId}`)
}

export { addExperience, deleteExperience, changeLevel, employeeCompetencyDetails }
