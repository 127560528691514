import { ifetch } from '@integrator/api'

function url(contextName, relationId, containerId) {
  return `/api/filesystems/${contextName}/${relationId}/container-structure/${containerId}`
}

/**
 * Fetch inner structure for file system.
 *
 * @param contextName name of filesystem context (ie: project)
 * @param relationId id of filesystem relation (ie: project id)
 * @param containerId id of container which may contain sub containers and records.
 * @param externalClient optional. If provided elements are filtered for specified external client.
 *
 * @returns {(function(*): Promise<string|any>)|*} when resolved we get inner structure of filesystem.
 */
function containerStructure(contextName, relationId, containerId, externalClient) {
  let externalClientPath = ''
  if (externalClient) {
    externalClientPath += '/' + externalClient
  }
  return ifetch(url(contextName, relationId, containerId) + `/structure${externalClientPath}`)
}

const flatContext = ['checkpoint']

function addRecordUrl(contextName, relationId, containerId) {
  const flat = flatContext.includes(contextName)
  if (flat) {
    return url(contextName, relationId, containerId) + '/add-record'
  } else {
    return url(contextName, relationId, containerId) + '/add-record-with-container'
  }
}

export { containerStructure, addRecordUrl }
