import './list.drawings.directive.scss'

class ListDrawingsDirCtrl {
  constructor($stateParams, psFdvService, psDownloadService) {
    this.projectId = $stateParams.id
    this.service = psFdvService
    this.downloadService = psDownloadService
    this.getDrawings(this.projectId)
  }

  getDrawings(projectId) {
    this.service
      .fdvProjectDrawings(projectId)
      .then(response => {
        this.response = true
        this.drawings = response.data.documents
      })
      .catch(error => {
        this.response = true
      })
  }

  download(id, fileName) {
    this.downloadService.getDocument(id, fileName).catch(error => {
      console.log(error)
    })
  }

  getDownloadUrl(id, fileName) {
    return this.downloadService.getOpenDownloadUrl(id, fileName)
  }
}

ListDrawingsDirCtrl.$inject = ['$stateParams', 'psFdvService', 'psDownloadService']

export default ListDrawingsDirCtrl
