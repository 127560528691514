'use strict'
/**
 * Controller for project detail view.
 *
 * @author Rannveig A.Skjerve (rannveig.a.skjerve@gmail.com)
 */

import _ from 'lodash'

class ProjectDeviationCtrl {
  constructor($scope, $log, $state) {
    this.$log = $log
    this.$state = $state
    $scope.projectId = $state.params.id
  }
}

ProjectDeviationCtrl.$inject = ['$scope', '$log', '$state']

export default ProjectDeviationCtrl
