import { Enum } from '../../universal'

class NotificationReason extends Enum {
  static OPEN = new NotificationReason('OPEN')
  static STATUS = new NotificationReason('STATUS')
  static CLOSE = new NotificationReason('CLOSE')
  static DEADLINE = new NotificationReason('DEADLINE')
}

export { NotificationReason }
