import { ifetch } from '@integrator/api'

function businessUserAccountUrl(businessUnitId, businessUserId) {
  return `/business/${businessUnitId}/users/${businessUserId}/account`
}

function change(businessUnitId, businessUserId, userId, newAccountStatusName) {
  return ifetch(`${businessUserAccountUrl(businessUnitId, businessUserId)}/${userId}`, {
    method: 'POST',
    body: newAccountStatusName
  })
}

export { change }
