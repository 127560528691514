import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/employees/leave/pdf`
}

function getConfig(unitId) {
  return ifetch(url(unitId) + `/config`)
}

function addConfig(unitId, body) {
  return ifetch(url(unitId) + `/config`, { method: 'POST', body: JSON.stringify(body) })
}

function download(unitId) {
  window.open(`/ny` + url(unitId) + `/pdfLink` + '?token=' + window.jwtToken)
}

export { url, addConfig, getConfig, download }
