import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/settings/contact-titles`
}

function getTitles(businessUnitId) {
  return ifetch(url(businessUnitId), {
    method: 'GET'
  })
}

function addTitle(businessUnitId, titleName) {
  return ifetch(url(businessUnitId), {
    method: 'POST',
    body: JSON.stringify({ id: 0, name: titleName })
  })
}

function updateTitle(contactTitleId, titleName, businessUnitId) {
  return ifetch(url(businessUnitId) + `/${contactTitleId}`, {
    method: 'PUT',
    body: JSON.stringify({ id: contactTitleId, name: titleName })
  })
}

export { getTitles, addTitle, updateTitle }
