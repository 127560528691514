import { ProcedureManualName } from './procedure-manual-name'

/**
 * Represents the manual status of a procedure.
 */
export class ProcedureManualStatus {
  /**
   * The version of the procedure manual status.
   * @type {number}
   */
  version

  /**
   * The name of the procedure manual status.
   * @type {ProcedureManualName}
   */
  name

  /**
   * The count of times the manual status has been read.
   * @type {number}
   */
  countOfRead

  constructor(props) {
    if (props) {
      this.version = props.version
      this.name = new ProcedureManualName(props?.name)
      this.countOfRead = props.countOfRead
    }
  }
}
