import { css, html, LitElement } from 'lit'
import session from '@iwc/common/src/session'
import { globalUsersSearchApi } from '@integrator/api'
import '@shoelace-style/shoelace/dist/components/skeleton/skeleton.js'

class SessionUser extends LitElement {
  static styles = [
    css`
      a {
        color: var(--sl-color-primary-700);
        text-decoration: none;
      }
    `
  ]

  static properties = {
    userId: { type: Number },
    showEmail: { type: Boolean },
    _user: { type: Object, state: true }
  }

  firstUpdated(_changedProperties) {
    this._fetchUser()
  }

  updated(_changedProperties) {
    super.updated(_changedProperties)
    if (_changedProperties.has('userId')) {
      this._fetchUser()
    }
  }

  async _fetchUser() {
    const user = session.unitUsers?.[this.userId]
    this._user = user

    if (!user) {
      try {
        const person = await globalUsersSearchApi.personById(this.userId)()
        session.unitUsers = session.unitUsers || {}
        session.unitUsers[this.userId] = this._user = { person }
      } catch {
        this._user = { person: { fullName: this.userId.toString() } }
      }
    }
  }

  render() {
    return this._user
      ? html`
          <span part="name">${this._user.person.fullName}</span>
          ${this.renderEmail()}
        `
      : html`
          <sl-skeleton effect="pulse"></sl-skeleton>
        `
  }

  renderEmail() {
    const email = this._user.person.email
    if (this.showEmail && email) {
      return html`
        <span part="email">
          <a href="mailto:${email}" @click=${e => e.stopPropagation()} target="_top">${email}</a></p>
        </span>
      `
    }
  }
}

customElements.define('i-session-user', SessionUser)
