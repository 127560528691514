import { ifetch, integratorUrl, jwtToken } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedures/pdf`
}

function getConfig(unitId) {
  return ifetch(url(unitId) + `/config`)
}

function addConfig(unitId, body) {
  return ifetch(url(unitId) + `/config`, { method: 'POST', body: JSON.stringify(body) })
}

function download(unitId) {
  window.open(`${integratorUrl()}/ny${url(unitId)}/link?token=${jwtToken()}`)
}

function data(unitId) {
  return ifetch(url(unitId) + `/data`)
}

export { url, addConfig, getConfig, download, data }
