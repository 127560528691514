import { ifetch } from '@integrator/api'

function url(unitId, mainCategoryId) {
  return `/api/business/${unitId}/procedures/main-categories/${mainCategoryId}/categories`
}

function add(unitId, mainCategoryId, category) {
  return ifetch(url(unitId, mainCategoryId), {
    method: 'POST',
    body: JSON.stringify(category)
  })
}

export { add }
