import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `api/integration/geonorge/address`
}

function search(query) {
  return ifetch(url() + `/search?query=${query}`, { method: 'GET' })
}

export { search }
