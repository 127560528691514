import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedures/work-titles`
}

function procedureUrl(unitId, procedureId) {
  return `/api/business/${unitId}/procedures/${procedureId}/work-titles`
}

function settingsUrl(unitId) {
  return `/api/business/${unitId}/settings/work-titles`
}

function get(unitId) {
  return ifetch(settingsUrl(unitId))
}

function getMatrix(unitId) {
  return ifetch(url(unitId))
}

function set(unitId, procedureId, workTitleId) {
  return ifetch(procedureUrl(unitId, procedureId) + '/' + workTitleId, {
    method: 'POST',
    body: JSON.stringify({})
  })
}

function del(unitId, procedureId, workTitleId) {
  return ifetch(procedureUrl(unitId, procedureId) + '/' + workTitleId, {
    method: 'DELETE'
  })
}

export { get, getMatrix, set, del }
