import { ifetch } from '@integrator/api'

function url(unitId, projectId) {
  return `/api/${unitId}/projects/${projectId}/settings`
}

function updateMomMarkingSystem(unitId, projectId, markingSystemEnabled) {
  return ifetch(url(unitId, projectId) + '/mom/marking-system', {
    method: 'PATCH',
    body: markingSystemEnabled
  })
}

function momSettings(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/mom')
}

export { updateMomMarkingSystem, momSettings }
