/**
 * @typedef { object } FileSystemContext
 * @property { string } name
 * @property { number } relationId
 */
class FileSystemContext {
  name
  relationId

  constructor(props) {
    Object.assign(this, props)
  }
}

export { FileSystemContext }
