class EditorModel {
  constructor(model, supportedBlocks) {
    if (model) {
      this.blocks = model.blocks
    } else {
      this.blocks = []
    }
    if (this.blocks?.length <= 0) {
      this.blocks.push(this.emptyText())
    }
    this.dataChanged = false
    this.supportedBlocks = supportedBlocks
  }

  emptyModel() {
    return {
      blocks: [this.emptyText()]
    }
  }

  block(index) {
    return this.blocks[index]
  }

  /**
   * Gather content from all blocks
   * Please note that some blocks hold reference to content editable element.
   */
  content() {
    const blocks = this.blocks.map(block => {
      switch (block.type) {
        case 'text':
        case 'word':
          return this.copyText(block)
        case 'header':
          return this.copyHeader(block)
        case 'image':
          return this.copyImage(block)
        case 'link':
          return this.copyLink(block)
      }
    })
    return {
      blocks: blocks
    }
  }

  add(block, index) {
    this.blocks.splice(index, 0, block)
    if (block.text && block.text.length > 0) {
      this.setFocus(index, 'START')
    } else {
      this.setFocus(index, 'END')
    }
    this.dataChanged = true
  }

  remove(index) {
    if (index > -1) {
      this.blocks.splice(index, 1)
    }
    if (this.blocks.length <= 0) {
      this.blocks.push(this.emptyText())
    }
    this.setFocus(this.blocks.length - 1, 'END')
    this.dataChanged = true
  }

  setFocus(index, mode) {
    if (index > -1 && index < this.blocks.length) {
      this.blocks.forEach(this.unsetFocus)
      this.blocks[index].focus = mode
    }
  }

  unsetFocus(block) {
    delete block.focus
  }

  /**
   * Please do not use.
   * @param index
   */
  addList(index) {
    this.add(this.emptyList(), index)
  }

  /**
   * Please do not use
   * @param index
   */
  addHeading(index) {
    this.add(this.emptyHeading(), index)
  }

  convertToHeader(index) {
    if (index > -1 && index < this.blocks.length) {
      let block = this.blocks[index]
      if (block) {
        let headerBlock = this.emptyHeading()
        headerBlock.header.text = block.ce.text()
        this.blocks[index] = headerBlock
      }
    }
  }

  convertToWord(index) {
    if (index > -1 && index < this.blocks.length) {
      let block = this.blocks[index]
      if (block) {
        this.blocks[index] = this.emptyPasteFromWord()
      }
    }
  }

  convertToText(index) {
    if (index > -1 && index < this.blocks.length) {
      let block = this.blocks[index]
      if (block) {
        let textBlock = this.newText(block.ce.text())
        this.blocks[index] = textBlock
      }
    }
  }

  convertToHtmlText(index) {
    if (index > -1 && index < this.blocks.length) {
      let block = this.blocks[index]
      if (block) {
        let textBlock = this.newText(block.ce.html())
        this.blocks[index] = textBlock
      }
    }
  }

  addText(index) {
    this.add(this.emptyText(), index)
  }

  emptyText() {
    return this.newText('')
  }

  newText(text) {
    return {
      id: this.newId(),
      type: 'text',
      text: {
        value: text
      }
    }
  }

  checkBlockForRemoval(block) {
    if (block && block.text) {
      const text = block.text.replace(/\s/g, '')
      return text.length <= 0
    } else {
      return true
    }
  }
  blockToHtml(block) {
    switch (block.type) {
    }
  }

  moveUp(index) {
    this._reorderBlocks(index, index - 1)
  }

  moveDown(index) {
    this._reorderBlocks(index, index + 1)
  }

  _reorderBlocks(oldIndex, newIndex) {
    if (newIndex >= this.blocks.length) {
      let k = newIndex - this.blocks.length + 1
      while (k--) {
        this.blocks.push(undefined)
      }
    }
    this.blocks.splice(newIndex, 0, this.blocks.splice(oldIndex, 1)[0])
  }

  //
  // New blocks
  //

  emptyHeading() {
    return {
      id: this.newId(),
      type: 'header',
      header: {
        level: 2,
        text: ' '
      }
    }
  }

  emptyList() {
    return {
      id: this.newId(),
      type: 'list',
      items: [''],
      style: 'ordered'
    }
  }

  emptyImage() {
    return {
      id: this.newId(),
      type: 'image',
      image: {
        url: ''
      }
    }
  }

  emptyLink() {
    return {
      id: this.newId(),
      type: 'link',
      link: {
        url: 'https://',
        exists: false,
        title: null
      }
    }
  }

  emptyPasteFromWord() {
    return {
      id: this.newId(),
      type: 'word',
      text: {
        value: ''
      }
    }
  }

  newId() {
    return Date.now()
  }

  //
  // Establish clean model
  //

  copyText(block) {
    return {
      id: block.id,
      type: 'text',
      text: {
        value: block.ce.cleanHtml()
      }
    }
  }

  copyHeader(block) {
    return {
      id: block.id,
      type: 'header',
      header: {
        level: block.header.level,
        text: block.ce.text()
      }
    }
  }

  copyImage(block) {
    return {
      id: block.id,
      type: 'image',
      image: {
        url: block.image.url
      }
    }
  }

  copyLink(block) {
    return {
      id: block.id,
      type: 'link',
      link: block.link
    }
  }
}

export default EditorModel
