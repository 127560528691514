import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/security`
}

function impersonateForCustomerPortal(unitId, projectId, contactPersonId) {
  return ifetch(url(unitId) + `/impersonate/customer-portal/${projectId}/${contactPersonId}`)
}

export { impersonateForCustomerPortal }
