import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/contacts`
}

function addContactPrivatePerson(contact, businessUnitId) {
  return ifetch(url(businessUnitId) + `/add-private-person`, {
    method: 'POST',
    body: JSON.stringify(contact)
  })
}

function addContactCompany(contact, businessUnitId) {
  return ifetch(url(businessUnitId) + `/add-company`, {
    method: 'POST',
    body: JSON.stringify(contact)
  })
}

// search = {type:'', query:''}
function search(businessUnitId, search) {
  return ifetch(url(businessUnitId) + `/search`, {
    method: 'POST',
    body: JSON.stringify(search)
  })
}

function list(businessUnitId, isShowArchived) {
  return ifetch(url(businessUnitId) + `?archived=${isShowArchived}`)
}

function getContact(businessUnitId, contactId) {
  return ifetch(url(businessUnitId) + `/${contactId}`)
}

export { addContactPrivatePerson, addContactCompany, search, list, getContact }
