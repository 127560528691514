import { ifetch } from '@integrator/api'

function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/integrations/boligmappa`
}

function saveSelectedProperties(unitId, projectId, boligmappaNumber) {
  return ifetch(url(unitId, projectId) + `/${boligmappaNumber}`, {
    method: 'POST'
  })
}

function displayIntegratedPlants(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/integratedProjectToPlant', {
    method: 'GET'
  })
}

function plantByBoligmappaNumber(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/plant', {
    method: 'GET'
  })
}

function deletePlantFile(unitId, projectId, fileId) {
  return ifetch(url(unitId, projectId) + `/deletePlantFile/${fileId}`, {
    method: 'POST'
  })
}

function sendFileToBoligmappaPlant(unitId, projectId, contextName, recordId, plantFileRequest) {
  return ifetch(url(unitId, projectId) + `/uploadFileToPlant/${contextName}/${recordId}`, {
    method: 'POST',
    body: JSON.stringify(plantFileRequest)
  })
}

function plantFilesByBoligmappaNumber(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/plantFiles', {
    method: 'GET'
  })
}

function getDocumentTypes(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/documentTypes', {
    method: 'GET'
  })
}

function getProfessionTypes(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/professionTypes', {
    method: 'GET'
  })
}

function getToken(userId) {
  return ifetch('api/integration/boligmappa/token', {
    method: 'POST',
    body: JSON.stringify({ userId: userId })
  })
}

function disconnectIntegration(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/${boligmappaNumber}', {
    method: 'DELETE'
  })
}

function editPlantFile(unitId, projectId, plantFileRequest) {
  return ifetch(url(unitId, projectId) + '/editPlantFile', {
    method: 'POST',
    body: JSON.stringify(plantFileRequest)
  })
}

export {
  saveSelectedProperties,
  displayIntegratedPlants,
  plantByBoligmappaNumber,
  deletePlantFile,
  plantFilesByBoligmappaNumber,
  getProfessionTypes,
  getDocumentTypes,
  sendFileToBoligmappaPlant,
  getToken,
  disconnectIntegration,
  editPlantFile
}
