'use strict'

/**
 * Routes for Periodic Controls module.
 * @author Rannveig A. Skjerve (rannveig.a.skjerve@gmail.com)
 */

let routes = function ($stateProvider) {
  $stateProvider
    .state('periodic', {
      url: '/periodic',
      parent: 'unit',
      abstract: true,
      template: '<ui-view class="full-height"></ui-view>'
    })
    .state('periodic.parent', {
      url: '',
      parent: 'periodic',
      template: require('./periodic.parent.tpl.html'),
      authenticate: true,
      controller: 'PeriodicControlsCtrl',
      controllerAs: 'vc',
      resolve: {
        projects: [
          'psProjectService',
          function (service) {
            return service.list()
          }
        ]
      },
      ncyBreadcrumb: {
        parent: 'periodic',
        label: "{{'PERIODIC_CONTROLS' | translate}}"
      }
    })
    .state('periodic.detail', {
      url: '/:id',
      parent: 'periodic.parent',
      template: require('./detail/periodic.detail.tpl.html'),
      controller: 'PeriodicDetailCtrl',
      controllerAs: 'vc',
      resolve: {
        project: [
          'psProjectService',
          '$stateParams',
          function (service, $stateParams) {
            return service.getProject($stateParams.id)
          }
        ]
      },
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('periodic.detail.controls', {
      parent: 'periodic.detail',
      template: require('../controls/list/controls.list.tpl.html'),
      controller: 'ControlsListCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('periodic.detail.documents', {
      url: '/:contextName',
      parent: 'periodic.detail',
      template: require('../documents/documents.list.tpl.html'),
      controller: 'DocumentListCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('periodic.detail.issues', {
      parent: 'periodic.detail',
      template: require('../issues/issues.list.tpl.html'),
      controller: 'IssuesListCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
}

routes.$inject = ['$stateProvider']

export default routes
