import { ifetch } from '@integrator/api'

function url(unitId, projectId, controlId) {
  return `/api/business/${unitId}/projects/${projectId}/periodic-control/controls/${controlId}/components`
}

function list(unitId, projectId, controlId) {
  return ifetch(url(unitId, projectId, controlId))
}

function tree(unitId, projectId, controlId) {
  return ifetch(url(unitId, projectId, controlId) + '/tree')
}

export { list, tree }
