'use strict'

import './login.scss'

const logTag = 'LoginCtrl'

import browserErrorDialog from '../../../common/services/dialogs/error/dialogs.browser.error.dialog.js'
class LoginCtrl {
  constructor($http, $log, authService, $state, $mdToast, $translate) {
    this.$http = $http
    this.$state = $state
    this.user = new User()
    this.loading = false
    this.authService = authService
    this.$log = $log
    this.auth = false
    this.$mdToast = $mdToast
    this.$translate = $translate
    this.unitId = $state.params.unitId
  }

  /**
   * Login user.
   */
  login() {
    this.loading = true
    this.authService
      .login(this.user.email, this.user.password)
      .then(loginResponse => {
        this.loading = false
        if (loginResponse.token) {
          this.$state.go('projects.list', { unitId: loginResponse.unit.id })
        }
        if (loginResponse.businessUnits) {
          this.units = loginResponse.businessUnits
        }
      })
      .catch(error => {
        this.loading = false
        this.handleApiError(error)
      })
  }

  loginWithUnit(unitId) {
    this.loading = true
    this.authService
      .login(this.user.email, this.user.password, unitId)
      .then(loginResponse => {
        this.loading = false
        this.$state.go('projects.list', { unitId: unitId })
      })
      .catch(error => {
        this.loading = false
        this.handleApiError(error)
      })
  }

  handleApiError(errorResponse) {
    // Get localized error message
    this.$translate(errorResponse.data.error)
      .then(localizedError => {
        this.showLoginError(localizedError)
      })
      .catch(defaultValue => {
        this.showLoginError(defaultValue)
      })
  }

  /**
   * Show error message.
   * @param error
   */
  showLoginError(errorText) {
    let toast = this.$mdToast
      .simple()
      .content(errorText)
      .theme('ps-default')
      .action('OK')
      .position('top right')
      .hideDelay(10000)

    this.$mdToast.show(toast)
  }
}

LoginCtrl.$inject = ['$http', '$log', 'authService', '$state', '$mdToast', '$translate']

export default LoginCtrl

/**
 * User model for login.
 */
class User {
  constructor(email, password) {
    this.email = email
    this.password = password
  }

  setPassword(password) {
    this.password = password
  }

  setEmail(email) {
    this.email = email
  }

  toString() {
    return 'User: {Email: ' + this.email + ', Password: ' + this.password + '} '
  }

  isValid() {
    return !!this.email && !!this.password
  }
}
