import { ifetch } from '@integrator/api'

function baseUrl(unitId) {
  return `/api/business/${unitId}/processes`
}

function check(unitId) {
  return ifetch(baseUrl(unitId) + `/check`, { method: 'GET' })
}

function subscribeURL(unitId) {
  return `/ny` + baseUrl(unitId) + `/subscribe`
}

// TODO: Slawek! get ride of this temporary function
function generateDummy(unitId, count) {
  return ifetch(baseUrl(unitId) + `/generate/${count}`, { method: 'GET' })
}

export { check, subscribeURL, generateDummy }
