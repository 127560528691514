import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/projects/periodic-control/controls`
}

function controlsDutyUsers(unitId, controlIds) {
  return ifetch(url(unitId) + '/duty-users', {
    method: 'POST',
    body: JSON.stringify(controlIds)
  })
}

export { controlsDutyUsers }
