import { FsElement } from './fs-element'
/**
 * @typedef { Object } RecordHit
 * @property { Number } id
 * @property { String } name
 * @property { RecordHitOrigin } origin
 */
class RecordHit extends FsElement {
  id
  name
  url
  origin
  constructor(props) {
    super(props)
    this.id = props.id
    this.name = props.name
    this.url = props.url
  }

  get isPdf() {
    if (this.name) {
      const pdfRegex = /(pdf|PDF)$/
      return pdfRegex.test(this.name)
    } else {
      return false
    }
  }

  get isImage() {
    if (this.name) {
      const imageRegex = /(png|jpg|jpeg|gif|bmp|ico|svg|PNG|JPG|JPEG|GIF|BMP|ICO|SVG|webp)$/
      return imageRegex.test(this.name)
    } else {
      return false
    }
  }

  get isWopiFile() {
    if (this.name) {
      const wopiFileRegex =
        /(xlsx|xlsb|xlsm|XLSX|XLSB|XLSM|docm|docx|doc|odt|dot|dotm|dotx|rtf|DOCM|DOCX|DOC|ODT|DOT|DOTM|DOTX|RTF|odp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx|ODP|POT|POTM|POTX|PPS|PPSM|PPSX|PPT|PPTM|PPTX)$/
      return wopiFileRegex.test(this.name)
    } else {
      return false
    }
  }
}

export { RecordHit }
