'use strict'

import ApiConstants from '../../../config/config.constants.js'
import angular from 'angular'
import router from 'angular-ui-router'

/**
 * Data service for FDV
 *
 * @author Rannveig A. Skjerve (rannveig.a.skjerve@gmail.com)
 */

class FdvDataService {
  constructor($http, $log, $q, $cacheFactory, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.cache = $cacheFactory.get('$http')
    this.endpoint = new FdvEndpoint(apiConfig)
  }

  fdvForUser() {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvForUser(), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' + this.endpoint.fdvForUser() + ' with error: ' + error.status + ' ' + error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  fdvAdministration(projectId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvAdministration(projectId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.fdvAdministration(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  fdvAdministrationDocument(projectId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvAdministrationDocument(projectId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.fdvAdministrationDocument(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  fdvComponents(projectId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvComponents(projectId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.fdvComponents(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  fdvComponent(componentId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvComponent(componentId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.fdvComponent(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  fdvComponentDrawings(componentId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvComponentDrawings(componentId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.fdvComponentDrawings(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  fdvComponentDocuments(componentId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvComponentDocuments(componentId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.fdvComponentDocuments(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  fdvProjectImages(projectId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvProjectImages(projectId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.fdvProjectImages(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  fdvProjectDocuments(projectId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvProjectDocuments(projectId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.fdvProjectDocuments(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  fdvProjectDrawings(projectId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.fdvProjectDrawings(projectId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.fdvProjectDrawings(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }
}

FdvDataService.$inject = ['$http', '$log', '$q', '$cacheFactory', 'apiConfig']

class FdvEndpoint {
  constructor(apiConfig) {
    this.path = apiConfig.completeUrl() + '/fdv/'
    this.base = apiConfig.completeUrl()
  }

  fdvForUser() {
    return this.path + '/user'
  }

  fdvAdministration(projectId) {
    return this.path + 'administration/' + projectId
  }

  fdvAdministrationDocument(projectId) {
    return this.path + 'administration/documents/' + projectId
  }

  fdvComponents(projectId) {
    return this.path + 'components/' + projectId
  }

  fdvComponent(componentId) {
    return this.path + 'component' + componentId
  }

  fdvComponentDrawings(componentId) {
    return this.path + 'component/drawings/' + componentId
  }

  fdvComponentDocuments(componentId) {
    return this.path + 'component/documents/' + componentId
  }

  fdvProjectImages(projectId) {
    return this.path + 'images/' + projectId
  }

  fdvProjectDocuments(projectId) {
    return this.path + 'documents/' + projectId
  }

  fdvProjectDrawings(projectId) {
    return this.path + 'drawings/' + projectId
  }
}

/**
 * Export projects data service.
 */
export default angular.module('ps.service.data.fdv', [router, ApiConstants]).service('psFdvService', FdvDataService)
  .name
