import { ifetch } from '@integrator/api'

function url(businessContent, procedureId) {
  return `/api/template-procedures/${businessContent}/${procedureId}`
}

function manualPreview(businessContent, procedureId, unitId) {
  return ifetch(url(businessContent, procedureId) + '/manual/preview/' + unitId)
}

export { manualPreview }
