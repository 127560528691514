import { DeviationRelations } from './deviation-relations'

class DeviationContext {
  relations
  constructor(props) {
    this.relations = new DeviationRelations(props?.relations)
  }
}

export { DeviationContext }
