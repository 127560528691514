import { ifetch } from '@integrator/api'

function url(unitId, regulationId) {
  return `/api/business/${unitId}/settings/periodic-control/regulations/${regulationId}`
}

function update(unitId, regulationId, definitionJson) {
  return ifetch(url(unitId, regulationId), {
    method: 'PATCH',
    body: JSON.stringify(definitionJson)
  })
}

function activate(unitId, regulationId) {
  return ifetch(url(unitId, regulationId) + '/activate', {
    method: 'PATCH'
  })
}

function deactivate(unitId, regulationId) {
  return ifetch(url(unitId, regulationId) + '/deactivate', {
    method: 'PATCH'
  })
}

function deleteRegulation(unitId, regulationId) {
  return ifetch(url(unitId, regulationId), {
    method: 'delete'
  })
}

function changeDescription(unitId, regulationId, description) {
  return ifetch(url(unitId, regulationId) + '/description', {
    method: 'PATCH',
    body: description
  })
}

function details(unitId, regulationId) {
  return ifetch(url(unitId, regulationId) + '/details')
}

export { activate, deactivate, update, deleteRegulation, changeDescription, details }
