/**
 * Self contained module that lists projects.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import router from 'angular-ui-router'

import './list.documents.directive.scss'
import _ from 'lodash'

class ListDocumentsDirCtrl {
  constructor($state, $log, $scope, psDownloadService) {
    this.$scope = $scope
    this.$log = $log
    this.$state = $state
    this.downloadService = psDownloadService
  }

  getDownloadUrl(id, fileName) {
    return this.downloadService.getOpenDownloadUrl(id, fileName)
  }

  getDownloadUrl(document) {
    if (document.link.includes('google')) {
      return document.link
    } else {
      return this.downloadService.getOpenDownloadUrl(document.id, document.fileName)
    }
  }

  // Method to get css class for document type.
  documentIconClass(type) {
    switch (type) {
      case 'PDF':
        return 'fa-file-pdf-o'
      case 'IMAGE':
        return 'fa-file-image-o'
      case 'MS_WORD':
        return 'fa-file-word-o'
      case 'MS_EXCEL':
        return 'fa-file-excel-o'
      case 'MS_POWERPOINT':
        return 'fa-file-powerpoint-o'
      case 'GENERIC':
        return 'fa-file'
      default:
        return 'fa-file'
    }
  }
}
ListDocumentsDirCtrl.$inject = ['$state', '$log', '$scope', 'psDownloadService']

export default angular
  .module('ps.directives.list.documents', [router])
  .controller('ListDocumentsDirCtrl', ListDocumentsDirCtrl)
  .directive('listDocuments', function () {
    return {
      restrict: 'E',
      template: require('./list.documents.directive.tpl.html'),
      scope: {
        documents: '='
      },
      controller: 'ListDocumentsDirCtrl',
      controllerAs: 'vc'
    }
  }).name
