import { ifetch, integratorUrl, jwtToken } from '@integrator/api'
import Cookies from 'js-cookie'

function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/procedures/pdf`
}

function getConfig(unitId, projectId) {
  return ifetch(url(unitId, projectId) + `/config`)
}

function addConfig(unitId, projectId, body) {
  return ifetch(url(unitId, projectId) + `/config`, { method: 'POST', body: JSON.stringify(body) })
}

function download(unitId, projectId) {
  window.open(`${integratorUrl()}/ny${url(unitId, projectId)}/link?token=${jwtToken()}`)
}

function data(unitId, projectId) {
  return ifetch(url(unitId, projectId) + `/data`)
}

export { url, addConfig, getConfig, download, data }
