/** @typedef {object} ProcessesOverallStatus */
export class ProcessesOverallStatus {
  /** @type {number} */
  completed

  /** @type {number} */
  fail

  /** @type {number} */
  inProgress

  /** @type {number} */
  pending

  constructor(props) {
    if (props) {
      this.completed = props.COMPLETED
      this.fail = props.FAIL
      this.inProgress = props.IN_PROGRESS
      this.pending = props.PENDING
    }
  }

  get total() {
    return this.completed + this.fail + this.inProgress + this.pending
  }

  get runningTotal() {
    return this.inProgress + this.pending
  }

  hasRunningProcesses() {
    return this.inProgress > 0 || this.pending > 0
  }
}
