import { ifetch } from '@integrator/api'

function url() {
  return '/api/session'
}

function current() {
  return ifetch(url())
}
function currentLight() {
  return ifetch(url() + '/light')
}

export { current, currentLight }
