'use strict'

/**
 * Routes for Projects module.
 * @author Audun Follegg (audunfo@gmail.com)
 */

let routes = function ($stateProvider) {
  $stateProvider
    .state('projects', {
      parent: 'unit',
      url: '/projects',
      abstract: true,
      template: '<ui-view class="full-height"></ui-view>'
    })
    .state('projects.list', {
      url: '',
      parent: 'projects',
      template: require('./projects.tpl.html'),
      controller: 'ProjectsCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        parent: 'projects',
        /*label: 'Projects'*/
        label: "{{'PROJECTS' | translate}}"
      }
    })
    .state('projects.detail', {
      url: '/:id',
      parent: 'projects.list',
      template: require('./projects.detail.tpl.html'),
      controller: 'ProjectDetailCtrl',
      controllerAs: 'vc',
      resolve: {
        projects: [
          'psProjectService',
          function (service) {
            return service.list()
          }
        ]
      },
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('projects.detail.alternatives', {
      parent: 'projects.detail',
      template: require('./projects.alternatives.tpl.html'),
      controller: 'ProjectAlternativesCtrl',
      controllerAs: 'vc',
      authenticate: true,
      resolve: {
        modules: [
          '$stateParams',
          'authService',
          'apiConfig',
          ($stateParams, authService, apiConfig) => {
            var modules = {
              FDV: {
                id: 'FDV',
                name: 'FDV',
                icon: 'book',
                location: function () {
                  return (
                    apiConfig.portalUrl +
                    'reader/' +
                    $stateParams.unitId +
                    '/projects/' +
                    $stateParams.id +
                    '/mom/dashboard?token=' +
                    authService.getTokenFromStorage()
                  )
                }
              },
              OLD_FDV: {
                id: 'OLD_FDV',
                name: 'FDV',
                icon: 'book',
                state: 'fdv.detail({unitId: ' + $stateParams.unitId + ', id:' + $stateParams.id + '})'
              },
              IKS: {
                id: 'IKS',
                name: 'PROCEDURES',
                icon: 'assignment',
                state: 'procedures.project({unitId: ' + $stateParams.unitId + ', id:' + $stateParams.id + '})'
              },
              IKK: {
                id: 'IKK',
                name: 'PERIODIC_CONTROLS',
                icon: 'assignment_turned_in',
                state: 'periodic.detail({unitId: ' + $stateParams.unitId + ', id :' + $stateParams.id + '})'
              }
            }
            return modules
          }
        ]
      },
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('projects.detail.images', {
      parent: 'projects.detail',
      template:
        '<i-container-tree ng-prop-relation="$state.params.id" ng-prop-context="\'project-image-gallery\'"></i-container-tree>',
      controller: 'WebComponentController',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('projects.detail.fs', {
      parent: 'projects.detail',
      template:
        '<i-container-tree ng-prop-relation="$state.params.id" ng-prop-context="\'project\'"></i-container-tree>',
      controller: 'WebComponentController',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('projects.detail.drawings', {
      parent: 'projects.detail',
      template:
        '<i-container-tree ng-prop-relation="$state.params.id" ng-prop-context="\'project-drawing\'"></i-container-tree>',
      controller: 'WebComponentController',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('projects.detail.deviation', {
      parent: 'projects.detail',
      template: require('./project.deviation.tpl.html'),
      controller: 'ProjectDeviationCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('projects.detail.documents', {
      parent: 'projects.detail',
      template: require('../documents/documents.list.tpl.html'),
      controller: 'DocumentListCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('projects.detail.controls', {
      parent: 'projects.detail',
      template: require('../controls/list/controls.list.tpl.html'),
      controller: 'ControlsListCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('projects.detail.issues', {
      parent: 'projects.detail',
      template: require('../issues/issues.list.tpl.html'),
      controller: 'IssuesListCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
}

routes.$inject = ['$stateProvider']

export default routes
