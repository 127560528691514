'use strict'

/**
 * Module for components
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import router from 'angular-ui-router'
import ComponentsParentCtrl from './components.parent.controller.js'
import ComponentsListCtrl from './list/components.list.controller.js'
import ComponentDetailCtrl from './detail/components.detail.controller.js'
import SidenavComponentsDirective from '../../common/directives/sidenav/sidenav.components.list.directive.js'
import routes from './components.routes.js'

// Data services
import ProjectsService from '../../common/services/data/projects.dataservice.js'
import ControlsService from '../../common/services/data/controls.dataservice.js'
import ComponentService from '../../common/services/data/components.dataservice.js'
import PdfReportService from '../../common/services/data/pdf.report.dataservice.js'

// Directives
import ChecklistsDirective from '../../common/directives/lists/checklists/list.checklists.directive.js'
import IssuesDirective from '../../common/directives/lists/issues/list.issues.directive.js'
import ListDocumentsDirective from '../../common/directives/lists/documents/list.documents.directive.js'

// Styles
import './components.parent.scss'

export default angular
  .module('ps.module.components', [
    router,
    SidenavComponentsDirective,
    ProjectsService,
    ControlsService,
    ComponentService,
    ChecklistsDirective,
    IssuesDirective,
    ListDocumentsDirective,
    PdfReportService
  ])
  .controller('ComponentsParentCtrl', ComponentsParentCtrl)
  .controller('ComponentsListCtrl', ComponentsListCtrl)
  .controller('ComponentDetailCtrl', ComponentDetailCtrl)
  .config(routes).name
