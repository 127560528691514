class FdvComponentCtrl {
  constructor($stateParams, psFdvService, psDocumentsService, psPdfGenerateService) {
    this.projectId = $stateParams.id
    this.service = psFdvService
    this.documentsService = psDocumentsService
    this.pdfService = psPdfGenerateService
    this.getComponents(this.projectId)
  }

  getComponents(projectId) {
    this.service
      .fdvComponents(projectId)
      .then(response => {
        this.categories = response.data.fdvcategories
      })
      .catch(error => {
        //TODO
      })
  }

  showCategoryComponents(category) {
    if (!category.open) {
      category.open = true
    } else {
      category.open = !category.open
    }
  }

  showComponentDocuments(component) {
    if (!component.open) {
      component.open = true
      this.getComponentDocuments(component)
    } else {
      component.open = false
    }
  }

  getComponentDocuments(component) {
    this.documentsService.getDocuments('FDV', component.id).then(response => {
      component.fsStructure = response.data
    })
  }

  download(id, fileName) {
    this.downloadService.getDocument(id, fileName).catch(error => {
      console.log(error)
    })
  }

  getPdfURL(component) {
    var pdfUrl = this.pdfService.getFdvComopnentPdf(component.id)
    return pdfUrl
  }

  documentIconClass(extension) {
    var type = extension.toUpperCase()
    switch (type) {
      case 'PDF':
        return 'fa-file-pdf-o'
      case 'IMAGE':
        return 'fa-file-image-o'
      case 'MS_WORD':
        return 'fa-file-word-o'
      case 'MS_EXCEL':
        return 'fa-file-excel-o'
      case 'MS_POWERPOINT':
        return 'fa-file-powerpoint-o'
      case 'GENERIC':
        return 'fa-file-o'
      default:
        return 'fa-file-o'
    }
  }
}

FdvComponentCtrl.$inject = ['$stateParams', 'psFdvService', 'psDocumentsService', 'psPdfGenerateService']

export default FdvComponentCtrl
