import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/competences/matrix`
}

function matrix(businessUnitId) {
  return ifetch(url(businessUnitId))
}

function matrixInExcel(businessUnitId) {
  window.open('/ny' + url(businessUnitId) + '/excel' + '?token=' + window.jwtToken)
}

export { matrix, matrixInExcel }
