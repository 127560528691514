import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/projects`
}

/**
 *
 * @param unitId id of business unit
 * @param project data for new project
 * @param contract flag which determine if project was created from contract flow.
 * @returns {(function(*): Promise<string|any>)|*}
 */
function newProjectWizard(unitId, project, contract) {
  const queryParams = new URLSearchParams({ periodicControl: contract })
  const newProjectUrl = url(unitId) + `/wizard/new?` + queryParams
  const options = {
    method: 'POST',
    body: JSON.stringify(project)
  }
  return ifetch(newProjectUrl, options)
}

function structure(unitId) {
  return ifetch(url(unitId) + `/structure`)
}

function projectsInFolder(unitId, folderId) {
  return ifetch(url(unitId) + `/by-folder/${folderId}`)
}

function list(unitId) {
  return ifetch(url(unitId) + `/active-list`)
}

function readAccessList(unitId) {
  return ifetch(url(unitId) + `/read-access`)
}

export { newProjectWizard, structure, projectsInFolder, list, readAccessList }
