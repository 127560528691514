import _ from 'lodash'

class ProjectDetailCtrl {
  constructor($state, $stateParams, projects, Notification, EVENTS, $rootScope, $log, psPdfReportService) {
    this.$state = $state
    this.Notification = Notification
    this.EVENTS = EVENTS
    this.$rootScope = $rootScope
    this.$log = $log
    this.navBarSize = 20
    this.projects = projects
    this.unitId = $stateParams.unitId
    this.Notification.subscribe(this.$rootScope, this.EVENTS.TOGGLE_SIDE_NAV, this.toggleSideNav())
    this.pdfService = psPdfReportService

    // Get the current project from the list of projects
    this.project = _.find(projects.data, project => {
      return project.id === parseInt($stateParams.id)
    })

    this.features = this.project.enabledFeatures.features
    this.selectDefaultTab()
  }

  // Handle logic for default open tab here.
  selectDefaultTab() {
    this.$state.go('projects.detail.alternatives', { unitId: this.unitId })
  }

  toggleSideNav() {
    return function () {
      this.$log.info('Received TOGGLE_SIDE_NAV event')
      if (this.navBarSize === 20) {
        this.navBarSize = 0
      } else {
        this.navBarSize = 20
      }
    }
  }

  /**
   * Generate readable address.
   * @returns {string}
   */
  completeAddress() {
    let address = this.project.address
    let result = ''
    if (address.street) {
      result += address.street
    }
    if (address.streetNumber) {
      result += ' ' + address.streetNumber
    }
    if (address.postalCode) {
      result += ', ' + address.postalCode
    }
    if (address.city) {
      result += ' ' + address.city
    }

    return result
  }

  isFdv() {
    var features = this.project.enabledFeatures.features
    var fdv = _.some(features, function (f) {
      return f === 'FDV'
    })
    return fdv
  }
}

ProjectDetailCtrl.$inject = [
  '$state',
  '$stateParams',
  'projects',
  'Notification',
  'EVENTS',
  '$rootScope',
  '$log',
  'psPdfReportService'
]

export default ProjectDetailCtrl
