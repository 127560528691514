import { Address, Cadastre } from '../../universal'

class ProjectAttributes {
  address
  cadastre
  buildingOwner
  constructor(props) {
    if (props) {
      this.address = new Address(props.address)
      this.cadastre = new Cadastre(props.cadastre)
      this.buildingOwner = props.buildingOwner
    }
  }
}

export { ProjectAttributes }
