/**
 * Controller for FDV sidenav.
 */

class FdvParentCtrl {
  constructor($log, $stateParams, $state, projects, psFdvService) {
    this.$log = $log
    this.$state = $state
    this.$stateParams = $stateParams
    this.unitId = $stateParams.unitId
    this.projects = []
    this.projects.data = projects.data
  }

  openProjectFdv(project) {
    this.project = project
    this.$state.go('fdv.detail', { unitId: this.unitId, id: this.project.id })
  }

  isDetailState() {
    return this.$state.current.name !== 'fdv.parent'
  }
}

FdvParentCtrl.$inject = ['$log', '$stateParams', '$state', 'projects', 'psFdvService']

export default FdvParentCtrl
