'use strict'
/**
 * Module for issues (lists, details and modals)
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import router from 'angular-ui-router'

import IssuesListCtrl from './issues.list.controller.js'

export default angular.module('ps.module.issues', [router]).controller('IssuesListCtrl', IssuesListCtrl).name
