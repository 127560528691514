import { ControlOverview } from './control-overview'
import { Actions, Uploads } from '../../universal'
import { ControlStatus } from './control-status'
import { ControlSetup } from './control-setup'
import { ControlInstallation } from './control-installation'

class Control {
  id
  status
  overview
  uploads
  actions
  setup
  installation
  plan
  constructor(props) {
    if (props) {
      this.id = props.id
      this.status = new ControlStatus(props.status)
      this.overview = new ControlOverview(props.overview)
      this.uploads = new Uploads(props.uploads)
      this.actions = new Actions(props.actions)
      this.setup = new ControlSetup(props.setup)
      this.installation = new ControlInstallation(props.installation)
    }
  }
}

export { Control }
