import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/projects/folders`
}

function projectsByFolderName(unitId, folderName) {
  return ifetch(url(unitId) + `/by-name/${folderName}`)
}

function listFolders(unitId) {
  return ifetch(url(unitId) + '/list')
}

function addFolder(unitId, parentId, name) {
  return ifetch(url(unitId) + '/add', { method: 'POST', body: JSON.stringify({ parentId, name }) })
}

function deleteFolder(unitId, folderID) {
  return ifetch(url(unitId) + `/${folderID}`, { method: 'DELETE' })
}

// folderDefinition = { id, name }
function updateFolder(unitId, folderId, folderDefinition) {
  return ifetch(url(unitId) + `/${folderId}`, { method: 'PUT', body: JSON.stringify(folderDefinition) })
}

export { addFolder, deleteFolder, listFolders, updateFolder, projectsByFolderName }
