import { Actions, Address } from '../../universal'
import { Customer } from '../../customer'
import { Integrations } from '../../universal'
import { PeriodicControlDefinition } from '../../periodic-control'
import { ProjectFolder } from './project-folder'
import { ProjectAttributes } from './project-attributes'
import { ProjectIntegration } from '../../integration'
import { ProjectContractLabelType } from './project-contract-label-type'
import { ProjectSectorName } from './project-sector-name'
import { ProjectSettings } from './project-settings'
import { UiFeatures } from './ui-features'

class Project {
  id
  address
  name
  number
  folder
  attributes
  chatParticipantsCount
  settings
  uiFeatures
  integration
  integrations
  sector
  contract
  customer
  periodicControlDefinition
  actions
  constructor(props) {
    if (props) {
      this.id = props.id
      this.address = new Address(props.address)
      this.name = props.name
      this.number = props.number
      this.folder = new ProjectFolder(props.folder)
      this.attributes = new ProjectAttributes(props.attributes)
      this.chatParticipantsCount = props.chatParticipantsCount
      this.settings = new ProjectSettings(props.settings)
      this.uiFeatures = new UiFeatures(props.uiFeatures)
      this.integration = new ProjectIntegration(props.integration)
      this.integrations = new Integrations(props.integrations)
      this.sector = props.sector ? new ProjectSectorName(props.sector) : undefined
      this.contract = props.contract ? new ProjectContractLabelType(props.contract) : undefined
      this.customer = new Customer(props.customer)
      this.periodicControlDefinition = new PeriodicControlDefinition(props.periodicControlDefinition)
      this.actions = new Actions(props.actions)
    }
  }
}

export { Project }
