import { CheckpointTemplate } from './checkpoint-template'
import { ChecklistTemplate } from './checklist-template'

class CheckpointTemplates {
  /** @type {Array<CheckpointTemplate>} */
  list
  /** @type {ChecklistTemplate} */
  checklist

  constructor(props) {
    this.list = []
    this.checklist = new ChecklistTemplate(props.checklist)
    if (props.list) {
      this.list = props.list.map(ct => new CheckpointTemplate(ct))
    }
  }
}

export { CheckpointTemplates }
