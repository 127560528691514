import { Actions, ExternalAccess, ProcedureLevel, ProcedureManualStatus } from '@integrator/api'

/**
 * Represents a procedure.
 */
export class Procedure {
  /**
   * The template procedure ID.
   * @type {number}
   */
  templateProcedureId

  /**
   * The business procedure ID.
   * @type {number}
   */
  businessProcedureId

  /**
   * The parent procedure ID.
   * @type {number}
   */
  parentProcedureId

  /**
   * The ID of the procedure.
   * @type {number}
   */
  id

  /**
   * The name of the procedure.
   * @type {string}
   */
  name

  /**
   * The procedure category ID.
   * @type {number}
   */
  procedureCategoryId

  /**
   * The sort order of the procedure.
   * @type {number}
   */
  sortOrder

  /**
   * The assign level of the procedure.
   * @type {ProcedureLevel}
   */
  assignLevel

  /**
   * The design level of the procedure.
   * @type {ProcedureLevel}
   */
  designLevel

  /**
   * The external access of the procedure.
   * @type {ExternalAccess}
   */
  externalAccess

  /**
   * The manual status of the procedure.
   * @type {ProcedureManualStatus}
   */
  manualStatus

  /**
   * The actions associated with the procedure.
   * @type {Actions}
   */
  actions

  constructor(props) {
    if (props) {
      this.templateProcedureId = props?.templateProcedureId
      this.businessProcedureId = props?.businessProcedureId
      this.parentProcedureId = props?.parentProcedureId
      this.id = props?.id
      this.name = props?.name
      this.procedureCategoryId = props?.procedureCategoryId
      this.sortOrder = props?.sortOrder
      this.assignLevel = new ProcedureLevel(props?.assignLevel)
      this.designLevel = new ProcedureLevel(props?.designLevel)
      this.externalAccess = new ExternalAccess(props?.externalAccess)
      this.manualStatus = new ProcedureManualStatus(props?.manualStatus)
      this.actions = new Actions(props?.actions)
    }
  }

  get version() {
    return this.manualStatus?.version
  }

  /**
   * Procedure status name
   * @returns {string}
   */
  get statusName() {
    return this.manualStatus?.name?.name
  }

  /**
   * Is procedure has status APPROVED
   * @returns {boolean}
   */
  get isApproved() {
    return this.manualStatus?.name?.name === 'APPROVED'
  }

  /**
   * Is procedure published on customer portal
   * @returns {boolean}
   */
  get isPublished() {
    return this.externalAccess?.clientPermissions?.get('READ_ACCESS') === 'ALLOWED'
  }
}
