import { html, css, LitElement } from 'lit'
import { recordLineStyles } from './record-line-styles'
import translate from '@iwc/common/src/translate'

import '@shoelace-style/shoelace/dist/components/format-date/format-date.js'

/**
 */
export class RecordLineHeader extends LitElement {
  static styles = [recordLineStyles]

  firstUpdated(_changeProperties) {}

  render() {
    return html`
      <span class="header-name name">${translate('NAME')}</span>
      <span class="header-createdBy createdBy">${translate('OWNER')}</span>
      <span class="header-createdAt createdAt">${translate('PUBLISHED_AT')}</span>
      <span class="header-size size">${translate('SIZE')}</span>
    `
  }
}

customElements.define('i-record-line-header', RecordLineHeader)
