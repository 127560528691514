'use strict'
/**
 *
 * Directive for read-access navigation bar.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 *
 */
require('./navbar.scss')

import angular from 'angular'
import router from 'angular-ui-router'
import material from 'angular-material'
import authService from '../services/auth/auth.service.js'
import NotificationService from '../services/service.notifications.js'
import UserService from '../services/auth/user.dataservice.js'
import Breadcrumbs from './navbar.breadcrumb.js'

const moduleName = 'NavigationBar'
class NavBarCtrl {
  constructor($log, $rootScope, authService, Notification, EVENTS, userDataService, $state, $mdSidenav) {
    this.$log = $log
    this.$rootScope = $rootScope
    this.authenticated = false
    this.authService = authService
    this.Notification = Notification
    this.userService = userDataService
    this.$state = $state
    this.EVENTS = EVENTS
    this.$mdSidenav = $mdSidenav
    this.showSpinner = false
    this.user = undefined
    this.unit = undefined

    // Subscribe to relevant events
    this.Notification.subscribe(this.$rootScope, this.EVENTS.LOGIN_EVENT, this.loginEvent())
    this.Notification.subscribe(this.$rootScope, this.EVENTS.LOGOUT_EVENT, this.logoutEvent())
    this.Notification.subscribe(this.$rootScope, this.EVENTS.START_HTTP_LOADING, this.startSpinnerEvent())
    this.Notification.subscribe(this.$rootScope, this.EVENTS.STOP_HTTP_LOADING, this.stopSpinnerEvent())

    // Check if we are authenticated
    this.checkAuthentication()
  }

  /**
   * Check authentication.
   */
  checkAuthentication() {
    this.authenticated = this.authService.isAuthenticated()
    if (this.authenticated) {
      this.getCurrentUser()
      this.changeState(true)
    } else {
      this.changeState(false)
      this.user = null
    }
  }

  /**
   * For now, we only show menu button in project details state.
   * @returns {boolean}
   */
  shouldShowMenu() {
    return this.$state.current.name.indexOf('projects.detail') >= 0
  }

  toggleSideNav() {
    this.$mdSidenav('menu').toggle()

    /*this.Notification.broadcast(this.EVENTS.TOGGLE_SIDE_NAV);*/
  }

  /**
   * Change authentication state.
   * @param state (true or false)
   */
  changeState(state) {
    this.authenticated = state
  }

  /**
   * Get the authenticated user and set authenticated = true
   */
  getCurrentUser() {
    this.userService.get().then(item => {
      this.user = item.person
      this.unit = item.unit
    })
  }

  /**
   * Log out current user.
   */
  logOut() {
    this.authService.logout()
    this.changeState(false)
    this.user = null
    this.unit = null
  }

  /**
   * Event callback for LOGIN_EVENT
   */
  loginEvent() {
    return () => {
      this.$log.debug(moduleName + ': LOGIN EVENT RECEIVED')
      this.checkAuthentication()
    }
  }

  /**
   * Event callback for LOGOUT_EVENT
   */
  logoutEvent() {
    return () => {
      this.$log.debug(moduleName + ': LOGOUT_EVENT RECEIVED')
      this.checkAuthentication()
    }
  }

  startSpinnerEvent() {
    return () => {
      this.$log.debug(moduleName + ': SHOW_SPINNER_EVENT RECIEVED')
      this.showSpinner = true
    }
  }

  stopSpinnerEvent() {
    return () => {
      this.$log.debug(moduleName + ': STOP_SPINNER_EVENT RECIEVED')
      this.showSpinner = false
    }
  }
}

// Inject dependencies for controller
NavBarCtrl.$inject = [
  '$log',
  '$rootScope',
  'authService',
  'Notification',
  'EVENTS',
  'userDataService',
  '$state',
  '$mdSidenav'
]

// Export module
export default angular
  .module('ps.directives.navbar', [router, NotificationService, UserService, Breadcrumbs, material])
  .controller('navBarCtrl', NavBarCtrl)
  .directive('navbar', function () {
    return {
      restrict: 'E',
      template: require('./navbar.tpl.html'),
      scope: {},
      controller: 'navBarCtrl',
      controllerAs: 'nc'
    }
  }).name
