class BusinessAccountRoleName {
  static GlobalAdmin = new BusinessAccountRoleName('GLOBAL_ADMIN')
  static GlobalContentAdmin = new BusinessAccountRoleName('GLOBAL_CONTENT_ADMIN')
  static BusinessUnionContentAdmin = new BusinessAccountRoleName('BUSINESS_UNION_CONTENT_ADMIN')
  static BusinessUnionReader = new BusinessAccountRoleName('BUSINESS_UNION_READER')
  static BusinessUnitAdmin = new BusinessAccountRoleName('BUSINESS_UNIT_ADMIN')
  static BusinessUnitGlobalAdmin = new BusinessAccountRoleName('BUSINESS_UNIT_USER_ADMIN')
  static BusinessUnitProcedureReader = new BusinessAccountRoleName('BUSINESS_UNIT_PROCEDURE_READER')
  static BusinessUnitProcedureWriter = new BusinessAccountRoleName('BUSINESS_UNIT_PROCEDURE_WRITER')
  static BusinessUnitProcedureApprover = new BusinessAccountRoleName('BUSINESS_UNIT_PROCEDURE_APPROVER')
  static BusinessUnitEmployeeReader = new BusinessAccountRoleName('BUSINESS_UNIT_EMPLOYEE_READER')
  static BusinessUnitEmployeeAdmin = new BusinessAccountRoleName('BUSINESS_UNIT_EMPLOYEE_ADMIN')
  static BusinessUnitEmployeeLeader = new BusinessAccountRoleName('BUSINESS_UNIT_EMPLOYEE_LEADER')
  static BusinessIntegratorApp = new BusinessAccountRoleName('BUSINESS_INTEGRATOR_APP')
  static BusinessUnitDeviationsAdmin = new BusinessAccountRoleName('BUSINESS_UNIT_DEVIATIONS_ADMIN')
  static BusinessUnitDeviationsCaseManager = new BusinessAccountRoleName('BUSINESS_UNIT_DEVIATIONS_CASE_MANAGER')
  static PortalControlsRead = new BusinessAccountRoleName('PORTAL_CONTROLS_READ')
  static PortalControlsDocumentsRead = new BusinessAccountRoleName('PORTAL_CONTROLS_DOCUMENTS_READ')
  static PortalDeviationsRead = new BusinessAccountRoleName('PORTAL_DEVIATIONS_READ')
  static PortalDeviationsCaseManager = new BusinessAccountRoleName('PORTAL_DEVIATIONS_CASE_MANAGER')
  static PortalControlsProceduresRead = new BusinessAccountRoleName('PORTAL_CONTROLS_PROCEDURES_READ')
  static PortalControlsProceduresDocumentsRead = new BusinessAccountRoleName(
    'PORTAL_CONTROLS_PROCEDURES_DOCUMENTS_READ'
  )
  static PortalTaskRead = new BusinessAccountRoleName('PORTAL_TASK_READ')
  static PortalTaskCaseManager = new BusinessAccountRoleName('PORTAL_TASK_CASE_MANAGER')

  static _allPortal = [
    this.PortalControlsRead,
    this.PortalControlsDocumentsRead,
    this.PortalDeviationsRead,
    this.PortalDeviationsCaseManager,
    this.PortalControlsProceduresRead,
    this.PortalControlsProceduresDocumentsRead,
    this.PortalTaskRead,
    this.PortalTaskCaseManager
  ]

  name
  names

  constructor(name) {
    this.name = name
    this.names = [this.name, this.name.replaceAll('_', '').toLowerCase()]
  }

  matchName(name) {
    return this.names.find(n => n === name)
  }

  /**
   * @property { Array<String> } names
   * @return { Array<BusinessAccountRoleName> }
   */
  static find(names) {
    const rNames = new Set()
    if (names) {
      names.forEach(name => {
        this._allPortal.forEach(portalAccountRoleName => {
          if (portalAccountRoleName.matchName(name)) {
            rNames.add(portalAccountRoleName)
          }
        })
      })
    }
    return Array.from(rNames)
  }
}

export { BusinessAccountRoleName }
