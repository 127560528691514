import { ifetch } from '@integrator/api'
function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/periodic-control/controls`
}

function controls(unitId, projectId) {
  return ifetch(url(unitId, projectId))
}

function upcomingControls(unitId, projectId, count) {
  return ifetch(url(unitId, projectId) + `/upcoming/${count}`)
}

function configurationForNew(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/new-config')
}

function addNew(unitId, projectId, controlData) {
  return ifetch(url(unitId, projectId), {
    method: 'POST',
    body: JSON.stringify(controlData)
  })
}

export { controls, upcomingControls, configurationForNew, addNew }
