import { ifetch } from '@integrator/api'
//import { ifetch } from '@integrator/api'

function baseUrl(businessContent, procedureId, checklistId) {
  return `/api/${businessContent}/procedure/${procedureId}/checklists/${checklistId}`
}

// no response value
function remove() {
  return ifetch(baseUrl(businessContent, procedureId, checklistId), { method: 'DELETE' })
}

export { remove }
