import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/settings/periodic-control/installation-types`
}

function installationTypesByControlTypes(unitId, controlTypeIds) {
  return ifetch(url(unitId) + '/by-control-types?' + new URLSearchParams({ controlTypeIds: controlTypeIds }), {
    method: 'GET'
  })
}

export { installationTypesByControlTypes }
