'use strict'

class ProcedureContainerCtrl {
  constructor($state, $stateParams, project) {
    this.project = project.data.project
    this.unitId = $stateParams.unitId
    this.$state = $state
    this.setDefaultTab()
  }

  setDefaultTab() {
    if (this.$state.current.name === 'procedures.project') {
      this.$state.go('procedures.project.list', { unitId: this.unitId, id: this.project.id })
    }
  }
}

ProcedureContainerCtrl.$inject = ['$state', '$stateParams', 'project']

export default ProcedureContainerCtrl
