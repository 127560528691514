import { CountedData } from '@integrator/api'

class ChecklistOverview {
  /** @type {CountedData} */
  deviations
  /** @type {CountedData} */
  checkpoints
  /** @type {number} */
  filesCount

  /**
   * @param {CountedData} deviations
   * @param {CountedData} checkpoints
   * @param {number} filesCount
   */
  constructor(props) {
    if (props) {
      const { deviations, checkpoints, filesCount } = props
      this.deviations = new CountedData(deviations)
      this.checkpoints = new CountedData(checkpoints)
      this.filesCount = filesCount
    }
  }
}

export { ChecklistOverview }
