'use strict'

import _ from 'lodash'

class ControlsDetailCtrl {
  constructor(components, controls, $stateParams, psPdfReportService) {
    this.components = components
    this.controls = controls
    this.$stateParams = $stateParams
    this.unitId = $stateParams.unitId
    this.projectId = $stateParams.project
    this.pdfService = psPdfReportService
    this.fabIsOpen = false
    this.control = this.flattenAndFindControl()
  }

  /**
   * Find project.
   * REMOVE this when api supports getting one control by ID.
   *
   */
  flattenAndFindControl() {
    let controlArray = []
    if (this.isDefined(this.controls.data.active)) {
      controlArray = _.concat(controlArray, this.controls.data.active)
    }

    if (this.isDefined(this.controls.data.done)) {
      controlArray = _.concat(controlArray, this.controls.data.done)
    }

    return _.find(controlArray, control => {
      return control.id === parseInt(this.$stateParams.control)
    })
  }

  isDefined(object) {
    return !(_.isNull(object) || _.isUndefined(object))
  }
}

ControlsDetailCtrl.$inject = ['components', 'controls', '$stateParams', 'psPdfReportService']

export default ControlsDetailCtrl
