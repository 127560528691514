import { ifetch } from '@integrator/api'

function baseUrl(applicationName, deviationId) {
  return `/api/${applicationName}/deviations/${deviationId}/reports`
}

function addReport(applicationName, deviationId, step, newReport) {
  return ifetch(baseUrl(applicationName, deviationId) + `/add/${step}`, {
    method: 'POST',
    body: JSON.stringify(newReport)
  })
}

export { addReport }
