import { Actions, Definition, Person, TaskOverview } from '@integrator/api'
import { TaskSetup } from './task-setup'
import { TaskPlan } from './task-plan'
import { TaskStatus } from './task-status'

class Task {
  id
  definition
  actions
  setup
  plan
  status
  responsiblePerson
  overview

  constructor(props) {
    this.id = props?.id
    this.definition = new Definition(props?.definition)
    this.actions = new Actions(props?.actions)
    this.setup = new TaskSetup(props?.setup)
    this.plan = new TaskPlan(props?.plan)
    this.status = new TaskStatus(props?.status)
    this.responsiblePerson = new Person(props?.responsiblePerson)
    this.overview = new TaskOverview(props?.overview)
  }

  isUserResponsibleFor(userId) {
    return this.responsiblePerson.id === userId
  }

  clone() {
    return new Task(this)
  }
}

export { Task }
