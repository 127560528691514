import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/contacts/persons`
}

function allPersons(businessUnitId) {
  return ifetch(url(businessUnitId), {
    method: 'GET'
  })
}

function search(businessUnitId, query) {
  return ifetch(url(businessUnitId) + `/search`, {
    method: 'POST',
    body: query
  })
}

export { allPersons, search }
