import { ifetch } from '@integrator/api'

function url(contextName, relationId, containerId) {
  return `/api/filesystems/${contextName}/${relationId}/containers/${containerId}/search`
}

function search(contextName, relationId, containerId, query) {
  return ifetch(url(contextName, relationId, containerId) + '/by-files-full-text-search', {
    body: query,
    method: 'POST'
  })
}

export { search }
