import { ifetch } from '@integrator/api'

function url(unitId, projectId, userId) {
  return `/api/business/${unitId}/projects/${projectId}/customer-portal/users/${userId}/roles`
}

function list(unitId, projectId, userId) {
  return ifetch(url(unitId, projectId, userId), { method: 'GET' })
}

function add(unitId, projectId, userId, roleId) {
  return ifetch(url(unitId, projectId, userId) + '/' + roleId, { method: 'POST' })
}

function remove(unitId, projectId, userId, roleId) {
  return ifetch(url(unitId, projectId, userId) + '/' + roleId, { method: 'DELETE' })
}

export { list, add, remove }
