import { ifetch } from '@integrator/api'

function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/contact-persons`
}

/**
 * Fetch list of contact persons in a project
 *
 * @param unitId
 * @param projectId
 * @returns {(function(*): Promise<*>)|*}
 */
function list(unitId, projectId) {
  return ifetch(url(unitId, projectId), { method: 'GET' })
}

export { list }
