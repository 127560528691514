import { Address } from './address'
import { GpsCoordinates } from './gps-coordinates'

class MapAddress {
  address
  coordinates
  constructor(props) {
    this.address = new Address(props?.address)
    this.coordinates = new GpsCoordinates(props?.coordinates)
  }
}

export { MapAddress }
