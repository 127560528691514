/**
 * Simple notification service.
 * Should be used to communicate events between components.
 * @author Audun Follegg (audunfo@gmail.com).
 *
 * @type {NotificationService}
 */
import angular from 'angular'

class NotificationService {
  constructor($rootScope) {
    this.$scope = $rootScope
  }

  /**
   * Subscribe to event.
   * @param scope - listener scope
   * @param callback - callback for event.
   */
  subscribe(scope, channel, callback) {
    let eventHandler = this.$scope.$on(channel, callback)

    if (scope) {
      scope.$on('$destroy', eventHandler)
    }

    return eventHandler
  }

  /**
   * Post event to listeners.
   */
  post(channel, action, payload) {
    this.$scope.$emit(channel, action, angular.copy(payload))
  }

  /**
   * Broadcast message
   * @param name - event
   * @param payload - data
   */
  broadcast(name, payload) {
    this.$scope.$broadcast(name, payload)
  }
}

NotificationService.$inject = ['$rootScope']

/**
 * Supported notification events.
 */
const SUPPORTED_EVENTS = {
  LOGIN_EVENT: 'LOGIN_EVENT',
  LOGOUT_EVENT: 'LOGOUT_EVENT',
  NO_CONNECTION_EVENT: 'NO_CONNECTION_EVENT',
  CONNECTION_ESTABLISHED_EVENT: 'CONNECTION_ESTABLISHED_EVENT',
  TOGGLE_SIDE_NAV: 'TOGGLE_SIDE_NAV',
  START_HTTP_LOADING: 'START_HTTP_LOADING',
  STOP_HTTP_LOADING: 'STOP_HTTP_LOADING'
}

export default angular
  .module('ps.service.notifications', [])
  .service('Notification', NotificationService)
  .constant('EVENTS', SUPPORTED_EVENTS).name
