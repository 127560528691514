/**
 * @typedef { object } LocalDateTime
 * @proerty { Array } array
 */
class LocalDateTime {
  array

  constructor(props) {
    this.array = props || []
  }

  toDate() {
    let onlyDate = this.array
      .slice(0, 3)
      .map(num => num + '')
      .join('-')
    return new Date(onlyDate)
  }

  toDateTime() {
    return Date.fromArray(this.array)
  }
}

export { LocalDateTime }
