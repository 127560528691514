import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/settings/periodic-control/regulations`
}

function regulationsByControlTypes(unitId, controlTypeIds) {
  return ifetch(url(unitId) + '/by-control-types' + '?' + new URLSearchParams({ controlTypeIds: controlTypeIds }), {
    method: 'GET'
  })
}

function get(unitId) {
  return ifetch(url(unitId))
}

function newRegulation(unitId, definition) {
  return ifetch(url(unitId), {
    method: 'PUT',
    body: JSON.stringify(definition)
  })
}

function getAvailable(unitId) {
  return ifetch(url(unitId) + '/available')
}

function addFromTemplate(unitId, templateId) {
  return ifetch(url(unitId) + `/from-template/${templateId}`, {
    method: 'PUT'
  })
}

export { get, newRegulation, regulationsByControlTypes, getAvailable, addFromTemplate }
