'use strict'

import router from 'angular-ui-router'

/**
 * Routes for FDV module.
 * @author Rannveig A. Skjerve (rannveig.a.skjerve@gmail.com)
 */

let routes = function ($stateProvider) {
  $stateProvider
    .state('procedures', {
      url: '/procedures',
      parent: 'unit',
      abstract: true,
      template: '<ui-view class="full-height"></ui-view>'
    })
    .state('procedures.parent', {
      url: '',
      authenticate: true,
      template: require('./procedures.parent.tpl.html'),
      controller: 'ProcedureParentCtrl',
      controllerAs: 'vc',
      resolve: {
        projects: [
          'psProjectService',
          function (service) {
            return service.list()
          }
        ]
      },
      ncyBreadcrumb: {
        parent: 'procedures',
        label: "{{'PROCEDURES' | translate}}"
      }
    })
    .state('procedures.project', {
      parent: 'procedures.parent',
      //prosjektid
      url: '/:id',
      template: require('./procedure.project.container.tpl.html'),
      controller: 'ProcedureContainerCtrl',
      controllerAs: 'vc',
      resolve: {
        project: [
          'psProjectService',
          '$stateParams',
          function (service, $stateParams) {
            return service.getProject($stateParams.id)
          }
        ]
      },
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('procedures.project.list', {
      parent: 'procedures.project',
      //prosjektid
      url: '/:id',
      template: require('./list/procedure.list.tpl.html'),
      controller: 'ProcedureListCtrl',
      controllerAs: 'vc',
      resolve: {
        project: [
          'psProjectService',
          '$stateParams',
          function (service, $stateParams) {
            return service.getProject($stateParams.id)
          }
        ]
      },
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('procedures.project.detail', {
      parent: 'procedures.project',
      url: '/:project_id/details/:procedure_id',
      template: require('./detail/procedure.detail.tpl.html'),
      controller: 'ProcedureDetailCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      },
      params: {
        procedure_id: '0'
      }
    })
}

routes.$inject = ['$stateProvider']

export default routes
