import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedures/main-categories`
}

function add(unitId, category) {
  return ifetch(url(unitId), {
    method: 'POST',
    body: JSON.stringify(category)
  })
}

function index(unitId) {
  return ifetch(url(unitId) + '/index')
}

export { add, index }
