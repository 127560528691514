import { Deviation, Procedure } from '@integrator/api'

class CheckpointOverview {
  /** @type {Map<String, String>} */
  deviations
  /** @type {Map<String, Map<String, String>>} */
  deviationsWithSeverity
  /** @type {Array<Number>} */
  deviationIds
  /** @type {number} */
  filesCount
  /** @type {number} */
  deviationsCount

  /**
   * @param {Object} props
   */
  constructor(props) {
    if (props) {
      if (props.deviations instanceof Map) {
        this.deviations = new Map(props.deviations)
      } else {
        this.deviations = new Map(
          Object.entries(props.deviations).map(([primaryId, businessId]) => {
            return [Number(primaryId), businessId]
          })
        )
      }

      if (props.deviationsWithSeverity instanceof Map) {
        this.deviationsWithSeverity = new Map(props.deviationsWithSeverity)
      } else {
        this.deviationsWithSeverity = new Map(
          Object.entries(props.deviationsWithSeverity).map(([primaryId, obj]) => {
            const entries = Object.entries(obj)
            const innerMap = new Map(entries)
            return [Number(primaryId), innerMap]
          })
        )
      }
      this.deviationIds = [...props.deviationIds]
      this.filesCount = props.filesCount || 0
      this.deviationsCount = props?.deviationsCount || 0
    }
  }

  addDeviation(deviation) {
    this.deviations.set(deviation.id.primary, deviation.id.business)
    this.deviationIds.push(deviation.id.primary)
    const innerMap = new Map()
    innerMap.set(deviation.id.business, deviation.severity)
    this.deviationsWithSeverity.set(deviation.id.primary, innerMap)
  }

  updateDeviation(deviation) {
    const innerMap = this.deviationsWithSeverity.get(deviation.id.primary)
    if (innerMap) {
      innerMap.set(deviation.id.business, deviation.severity)
    }
  }

  removeDeviation(deviation) {
    const innerMap = this.deviationsWithSeverity.get(deviation.id.primary)
    if (innerMap) {
      innerMap.delete(deviation.id.business)
    }
    this.deviationIds.remove(deviation.id.primary)
    this.deviations.delete(deviation.id.primary)
  }

  toJSON() {
    // There is no point of sending this object to the server!
    return {}
  }
}

export { CheckpointOverview }
