import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/settings/work-titles`
}

function titleUrl(businessUnitId, titleId) {
  return url(businessUnitId) + `/${titleId}`
}

function create(unitId, newTitle) {
  return ifetch(url(unitId), {
    method: 'POST',
    body: JSON.stringify(newTitle)
  })
}

function read(unitId) {
  return ifetch(url(unitId), {})
}

function update(unitId, titleId, existingTitle) {
  return ifetch(titleUrl(unitId, titleId), {
    method: 'POST',
    body: JSON.stringify(existingTitle)
  })
}

function remove(unitId, titleId) {
  return ifetch(titleUrl(unitId, titleId), {
    method: 'DELETE'
  })
}

export { create, read, update, remove }
