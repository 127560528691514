import { Enum } from '../enum'

class Frequency extends Enum {
  static NONE = new Frequency('NONE')
  static DAY = new Frequency('DAY')
  static WEEK = new Frequency('WEEK')
  static MONTH = new Frequency('MONTH')
  static YEAR = new Frequency('YEAR')

  constructor(name = Frequency.NONE.name) {
    super(name)
  }
}

export { Frequency }
