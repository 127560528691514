class Session {
  install(unit, user, translations) {
    this.unit = unit
    this.user = user
    this.translations = translations
    this.ready = true
    return this.isReady()
  }

  create(session, translations) {
    this.unit = session.unit
    this.licensedUsers = session.licensedUsers
    this.unitUsers = session.unitUsers
    if (session.person) {
      this.user = { person: session.person }
    }
    if (session.user) {
      this.user = session.user
    }
    this.accountRoles = session.accountRoles
    this.translations = translations
    this.hasSubsidiaries = session.hasSubsidiaries
    this.ready = true
    this.initDefault()
    return this.isReady()
  }

  initDefault() {
    if (!this.unitUsers) {
      this.unitUsers = {}
    }
  }

  isReady() {
    return this.ready
  }

  unitId() {
    return this.unit.id
  }

  primaryContent() {
    let content = this.unit.union.contents[0]
    if (content) {
      content = content.toLowerCase()
      return content[0].toUpperCase() + content.substring(1)
    } else {
      return content
    }
  }

  hasRole(businessAccountRole) {
    return this.accountRoles.includes(businessAccountRole.name)
  }

  hasAnyRole(businessAccountRoles) {
    const filteredRoles = businessAccountRoles.filter(bar => this.hasRole(bar))
    return filteredRoles.length > 0
  }

  doTranslation(key, locale) {
    if (this.translations) {
      return this.translateFrom(this.translations, key, locale)
    } else {
      return this.translateFrom(window.translations, key, locale)
    }
  }

  translateFrom(translations, key, locale) {
    locale = locale || this.user?.settings?.language
    locale = locale || 'no'
    const localTranslations = translations ? translations[locale] : {}
    const value = localTranslations ? localTranslations[key] : key
    return value || key
  }
}

const session = new Session()

export default session
