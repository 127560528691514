import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/general-documentation`
}

function getAllElementLinks(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/links`, { method: 'GET' })
}

export { getAllElementLinks }
