import { FsPaths } from './fs-paths'
import { Actions } from '@integrator/api/src/universal/model/actions'
/**
 * @typedef { object } FsElement
 * @property { number } id
 * @property { number } containerId
 * @property { string } name
 * @property { string } description
 * @property { FsPaths } paths
 * @property { boolean } restricted
 * @property { Actions } actions
 */
class FsElement {
  id
  containerId
  name
  description
  paths
  restricted
  actions

  constructor(props) {
    this.id = props.id
    this.containerId = props.containerId
    this.name = props.name
    this.description = props.description
    this.paths = new FsPaths(props.paths ? props.paths : {})
    this.restricted = props.restricted
    this.actions = new Actions(props.actions ? props.actions : {})
  }
}

export { FsElement }
