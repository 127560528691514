import { Enum } from '@integrator/api'

class TaskPriority extends Enum {
  static HIGH = new TaskPriority('HIGH')
  static MEDIUM = new TaskPriority('MEDIUM')
  static LOW = new TaskPriority('LOW')

  constructor(name = TaskPriority.LOW.name) {
    super(name)
  }
}

export { TaskPriority }
