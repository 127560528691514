import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/excel`
}

// no response value
function tfmReportInExcel(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/tfm`, { method: 'GET' })
}

export { tfmReportInExcel }
