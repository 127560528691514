import './reset.scss'

/**
 * Controller for reset password.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */

const logTag = 'ResetCtrl'
class ResetCtrl {
  constructor($log, authService, callbackUrls, $translate, $mdToast) {
    this.$log = $log
    this.authService = authService
    this.callbackUrls = callbackUrls
    this.email = null
    this.loading = false
    this.$translate = $translate
    this.$mdToast = $mdToast
    this.resetForm = {}
    this.success = false
  }

  // Reset password onClick.
  reset() {
    this.$log.info(logTag + ': resetPassword: ' + this.email)
    this.authService
      .forgot(this.email, this.callbackUrls.changePassword)
      .then(() => {
        this.success = true
      })
      .catch(error => {
        this.handleApiError(error)
      })
  }

  handleError(error) {
    if (error.data.error) {
      switch (error.data.error) {
        case 'FORGOT_PASSWORD_USER_NOT_FOUND':
          this.$log.debug('User with email ' + this.email + ' not found')
          this.resetForm.email.$error.notFound = true
          break
      }
    }
  }

  handleApiError(errorResponse) {
    // Get localized error message
    this.$translate(errorResponse.data.error)
      .then(localizedError => {
        this.showLoginError(localizedError)
      })
      .catch(defaultValue => {
        this.showLoginError(defaultValue)
      })
  }

  /**
   * Show error message.
   * @param error
   */
  showLoginError(errorText) {
    let toast = this.$mdToast
      .simple()
      .content(errorText)
      .action('OK')
      .position('top right')
      .highlightAction(false)
      .theme('default')
      .hideDelay(10000)

    this.$mdToast.show(toast)
  }

  handleSuccess(response) {}

  // Check if form is valid.
  isValid() {
    return !!this.email
  }
}

ResetCtrl.$inject = ['$log', 'authService', 'callbackUrls', '$translate', '$mdToast']

export default ResetCtrl
