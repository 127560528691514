import { Enum } from '../../universal'

class ProjectSectorName extends Enum {
  static PRIVATE = new ProjectSectorName('PRIVATE')
  static COMMERCIAL = new ProjectSectorName('COMMERCIAL')
  static PUBLIC = new ProjectSectorName('PUBLIC')
  static AGRICULTURE = new ProjectSectorName('AGRICULTURE')
  static OTHER = new ProjectSectorName('OTHER')
}

export { ProjectSectorName }
