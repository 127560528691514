import { Enum } from '@integrator/api'

class ChecklistsOverviewCategory extends Enum {
  static PROJECT = new ChecklistsOverviewCategory('PROJECT')
  static BUSINESS = new ChecklistsOverviewCategory('BUSINESS')
  static CORPORATE = new ChecklistsOverviewCategory('CORPORATE')
  static TEMPLATE = new ChecklistsOverviewCategory('TEMPLATE')

  constructor(name) {
    super(name)
  }
}

export { ChecklistsOverviewCategory }
