import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/account-roles`
}

/**
 * Get available roles in the system on specified level.
 *
 * @param businessUnitId
 * @param roleLevel .. can be one of the following: CONTACT_PROJECT, UNIT, USER, GLOBAL
 * @returns {(function(*): Promise<string|any>)|*}
 */
function availableOnLevel(businessUnitId, roleLevel) {
  return ifetch(url(businessUnitId) + `/available/${roleLevel}`)
}

/**
 * Convenient method for contact project level roles.
 *
 * @param businessUnitId
 * @returns {(function(*): Promise<string|*>)|*}
 */
function contactProjectRoles(businessUnitId) {
  return availableOnLevel(businessUnitId, 'CONTACT_PROJECT')
}

export { availableOnLevel, contactProjectRoles }
