import { TaskPriority } from './task-priority'
import { EventRecurrence } from '@integrator/api'

class TaskSetup {
  taskCopyFromId
  priorityName
  start
  deadline
  recurrence

  constructor(props) {
    if (props) {
      this.taskCopyFromId = props?.taskCopyFromId
      this.start = props?.start
      this.deadline = props?.deadline
    }
    this.priorityName = new TaskPriority(props?.priorityName)
    this.recurrence = new EventRecurrence(props?.recurrence)
  }
}

export { TaskSetup }
