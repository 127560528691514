import { ControlType } from './control-type'

class ControlTypes {
  list
  constructor(props) {
    this.list = props?.list?.map(type => new ControlType(type))
  }
}

export { ControlTypes }
