import { ifetch } from '@integrator/api'

function url(unitId, userId) {
  return `/api/business/${unitId}/employees/${userId}/sick-leave`
}

function sickLeaveDetails(unitId, userId, absenceId) {
  return ifetch(url(unitId, userId) + `/${absenceId}`, { method: 'GET' })
}

function messages(unitId, userId, absenceId) {
  return ifetch(url(unitId, userId) + `/absences/${absenceId}/messages`, { method: 'GET' })
}

function addMessage(unitId, userId, absenceId, message) {
  return ifetch(url(unitId, userId) + `/absences/${absenceId}/messages`, { method: 'POST', body: message })
}

function approve(unitId, userId, absenceId) {
  return ifetch(url(unitId, userId) + `/absences/${absenceId}/approve`, { method: 'POST' })
}

function reject(unitId, userId, absenceId) {
  return ifetch(url(unitId, userId) + `/absences/${absenceId}/reject`, { method: 'POST' })
}

function deleteSickLeave(unitId, userId, absenceId) {
  return ifetch(url(unitId, userId) + `/absences/${absenceId}`, { method: 'DELETE' })
}

function addSickLeave(unitId, userId, body) {
  return ifetch(url(unitId, userId), { method: 'POST', body: body })
}

function validateSickLeave(unitId, userId, body) {
  return ifetch(url(unitId, userId) + `/validate`, { method: 'POST', body: body })
}

function preUpdateSickLeave(unitId, userId, sickLeaveId, range) {
  return ifetch(url(unitId, userId) + `/absences/${sickLeaveId}/validate`, { method: 'POST', body: range })
}

function updateSickLeave(unitId, userId, sickLeaveId, sickLeaveAbsence) {
  return ifetch(url(unitId, userId) + `/absences/${sickLeaveId}`, { method: 'POST', body: sickLeaveAbsence })
}

export {
  sickLeaveDetails,
  messages,
  addMessage,
  approve,
  reject,
  deleteSickLeave,
  addSickLeave,
  validateSickLeave,
  updateSickLeave,
  preUpdateSickLeave
}
