class BoligmappaProjectIntegration {
  plantId
  boligmappaNumber
  constructor(props) {
    this.plantId = props?.plantId
    this.boligmappaNumber = props?.boligmappaNumber
  }
}

export { BoligmappaProjectIntegration }
