import { Definition, Definitions, Actions, ChecklistStatus } from '@integrator/api'
import { ChecklistTemplateStatus } from './checklists-template-status'

class ChecklistTemplate {
  /** @type {Number} */
  id
  /** @type {Definition} */
  definition
  /** @type {ChecklistTemplateStatus | string} */
  statusName
  /** @type {Actions} */
  actions
  /** @type {Definitions} */
  subjectAreas

  /**
   * @param {Definition} definition
   * @param {ChecklistTemplateStatus | string} statusName
   * @param {Actions} actions
   * @param {Definitions} subjectAreas
   */
  constructor(props) {
    this.id = props.id
    this.definition = new Definition(props.definition)
    this.statusName = new ChecklistTemplateStatus(props.statusName?.name || props.statusName)
    this.actions = new Actions(props.actions)
    this.subjectAreas = props.subjectAreas ? new Definitions(props.subjectAreas) : null
  }
}

export { ChecklistTemplate }
