'use strict'

/**
 * Controller for changing password (after email with link)
 * One should never enter this state without a valid token.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
class ResetChangeController {
  constructor(authService, $state, $log, $stateParams, $translate, $mdToast, $mdDialog) {
    this.service = authService
    this.$state = $state
    this.password = null
    this.repeat = null
    this.$log = $log
    this.$stateParams = $stateParams
    this.$translate = $translate
    this.$mdToast = $mdToast
    this.$mdDialog = $mdDialog
    this.token = $stateParams.token
    this.activation = $stateParams.activation
  }

  save() {
    if (this.token) {
      this.resetPassword()
    } else if (this.activation) {
      this.activateAndSave()
    }
  }

  activateAndSave() {
    this.service
      .activateAndSetPassword(this.password, this.activation)
      .then(response => {
        this.$log.info('Success activating user with password')
        this.handleSuccess(response)
      })
      .catch(error => {
        this.$log.error('Error activating user', error)
        this.handleApiError(error)
      })
  }
  resetPassword() {
    this.service
      .resetPassword(this.password, this.token)
      .then(response => {
        this.$log.info('Success change password')
        this.handleSuccess(response)
      })
      .catch(error => {
        this.$log.error('Error change password', error)
        this.handleApiError(error)
      })
  }

  isValid() {
    return this.password === this.repeat && this.password !== null && this.repeat !== null
  }

  handleSuccess(response) {
    alert('Success')
    this.$state.go('auth.login')
  }

  handleApiError(errorResponse) {
    // Get localized error message
    this.$translate(errorResponse.data.error)
      .then(localizedError => {
        this.showLoginError(localizedError)
      })
      .catch(defaultValue => {
        this.showLoginError(defaultValue)
      })
  }

  /**
   * Show error message.
   * @param error
   */
  showLoginError(errorText) {
    let toast = this.$mdToast
      .simple()
      .content(errorText)
      .action('OK')
      .position('top right')
      .highlightAction(false)
      .theme('default')
      .hideDelay(10000)

    this.$mdToast.show(toast)
  }

  /**
   * Go to error page
   * @param error from server
   */
  transitionToError(error) {
    this.$state.go('auth.reset.error', { error: error })
  }

  /**
   * Go to success page
   */
  transitionToSuccess() {
    this.$state.go('auth.reset.success')
  }
}

ResetChangeController.$inject = ['authService', '$state', '$log', '$stateParams', '$translate', '$mdToast', '$mdDialog']

export default ResetChangeController
