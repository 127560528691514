'use strict'

class PeriodicDetailCtrl {
  constructor($stateParams, $state, project) {
    this.project = project.data.project
    this.$state = $state
    this.unitId = $stateParams.unitId
    this.selectDefaultTab()
  }

  selectDefaultTab() {
    this.$state.go('periodic.detail.controls', { unitId: this.unitId })
  }
}

PeriodicDetailCtrl.$inject = ['$stateParams', '$state', 'project']

export default PeriodicDetailCtrl
