class Enumeration {
  id
  name
  icon
  constructor(props) {
    if (props) {
      this.id = props.id
      this.name = props.name
      this.icon = props.icon
    }
  }
}

export { Enumeration }
