import { MapAddress, Organization } from '../../universal'
import { ContactType } from './contact-type'
import { CustomerSettings } from './customer-settings'

class Contact {
  id
  ownerId
  organization
  address
  type
  customerNumber
  archived
  settings
  constructor(props) {
    this.id = props.id
    this.ownerId = props.ownerId
    this.organization = new Organization(props.organization)
    this.address = new MapAddress(props.address)
    this.type = props.type ? new ContactType(props.type) : undefined
    this.customerNumber = props.customerNumber
    this.archived = props.archived
    this.settings = new CustomerSettings(props.settings)
  }
}

export { Contact }
