import { ifetch } from '@integrator/api'

function url() {
  return '/api/translate'
}

function map(language) {
  return ifetch(url() + `/${language}`, {})
}

function get(language, key) {
  return ifetch(url() + `/${language}/${key}`, {})
}

export { map, get }
