class OrganizationRegister {
  electricityEnabled
  electricity
  constructor(props) {
    this.electricityEnabled = props?.electricityEnabled
    this.electricity = props?.electricity
  }
}

export { OrganizationRegister }
