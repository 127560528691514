class Credentials {
  username
  password
  token
  constructor(props) {
    if (props) {
      this.username = props.username
      this.password = props.password
      this.token = props.token
    }
  }
}

export { Credentials }
