import { ifetch } from '@integrator/api'

function url(unitId, person) {
  return `/api/business/${unitId}/projects/contacts/${person.contactId}/persons/${person.id}`
}

// contact projects
function collaboration(unitId, person) {
  return ifetch(url(unitId, person))
}

function withContactPerson(unitId, person) {
  return ifetch(url(unitId, person) + `/contact-person`)
}

export { collaboration, withContactPerson }
