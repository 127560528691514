import { ifetch } from '@integrator/api'

function url(unitId, userId) {
  return `/api/business/${unitId}/users/${userId}/settings/notification-profiles`
}

function notificationProfiles(unitId, userId) {
  return ifetch(url(unitId, userId))
}

function add(unitId, userId, profileJson) {
  return ifetch(url(unitId, userId), {
    method: 'PUT',
    body: JSON.stringify(profileJson)
  })
}

export { notificationProfiles, add }
