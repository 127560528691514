'use strict'

import authService from '../../common/services/auth/auth.service.js'

/**
 * Routes for Authentication module.
 * @author Audun Follegg (audunfo@gmail.com)
 */

// Routes for authentication.
let authRoutes = function ($stateProvider) {
  $stateProvider
    .state('unit', {
      url: '/:unitId',
      abstract: true,
      template: '<ui-view/>'
    })
    .state('auth', {
      url: '/auth',
      abstract: true,
      template: '<ui-view/>'
    })
    .state('auth.login', {
      url: '/login',
      parent: 'auth',
      template: require('./login/login.tpl.html'),
      controller: 'LoginCtrl',
      controllerAs: 'vc',
      authenticate: false,
      ncyBreadcrumb: {
        skip: true
      }
    })
    // Reset password states.
    .state('auth.reset', {
      url: '/reset',
      parent: 'auth',
      abstract: true,
      authenticate: false,
      template: '<ui-view/>',
      ncyBreadcrumb: {
        skip: true
      }
    })
    // State to enter email to reset password from
    .state('auth.reset.email', {
      url: '',
      parent: 'auth.reset',
      template: require('./reset/reset.tpl.html'),
      controller: 'ResetCtrl',
      controllerAs: 'reset',
      authenticate: false,
      ncyBreadcrumb: {
        skip: true
      }
    })
    // State for changing password with token from server
    .state('auth.reset.change', {
      url: '/change?token',
      parent: 'auth.reset',
      template: require('./reset/reset.change.tpl.html'),
      authenticate: false,
      controller: 'ResetChangeCtrl',
      controllerAs: 'vc',
      ncyBreadcrumb: {
        skip: true
      }
    })
    // State for activating user
    .state('auth.reset.activate', {
      url: '/activate?activation',
      parent: 'auth.reset',
      template: require('./reset/reset.change.tpl.html'),
      authenticate: false,
      controller: 'ResetChangeCtrl',
      controllerAs: 'vc',
      ncyBreadcrumb: {
        skip: true
      }
    })
    // Reset success
    .state('auth.reset.success', {
      url: '#success',
      template: require('./reset/reset.error.tpl.html'),
      authenticate: false,
      ncyBreadcrumb: {
        skip: true
      }
    })
    // Reset error
    .state('auth.reset.error', {
      url: '#error',
      template: require('./reset/reset.success.tpl.html'),
      authenticate: false,
      ncyBreadcrumb: {
        skip: true
      }
    })
}

authRoutes.$inject = ['$stateProvider']

export default authRoutes
