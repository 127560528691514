import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, locationId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/building-locations/${locationId}`
}

// no response value
function update(unitId, projectId, locationId, code) {
  return ifetch(baseUrl(unitId, projectId, locationId), { method: 'PATCH', body: code })
}

// no response value
function remove(unitId, projectId, locationId) {
  return ifetch(baseUrl(unitId, projectId, locationId), { method: 'DELETE' })
}

export { update, remove }
