import { Enum } from '../../universal'

class ChecklistTemplateStatus extends Enum {
  static ACTIVE = new ChecklistTemplateStatus('ACTIVE')
  static IN_ACTIVE = new ChecklistTemplateStatus('IN_ACTIVE')

  constructor(name) {
    super(name)
  }
}

export { ChecklistTemplateStatus }
