/**
 * @typedef {Object} LinkPreview
 */
export class LinkPreview {
  /** @type {string} */
  name

  /** @type {string} */
  title

  /** @type {string} */
  description

  /** @type {string} */
  image

  /** @type {string} */
  icon

  /** @type {boolean} */
  exists

  constructor(props) {
    if (props) {
      this.title = props.title
      this.description = props.description
      this.image = props.image
      this.icon = props.icon
      this.exists = props.exists
    }
  }
}
