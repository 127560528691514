/**
 * General configuration for application.
 * @author Audun Follegg (audunfo@gmail.com)
 */
'use strict'

let appRouting = function ($urlRouterProvider, $locationProvider, $translateProvider, $mdThemingProvider) {
  // Enable HTML5 mode
  $locationProvider.html5Mode({
    enabled: false,
    requireBase: false
  })

  // Setup translations
  $translateProvider.translations('no', {
    //Component namespace

    COMPONENT: {
      SINGLE: 'Komponent',
      TYPE: {
        MAIN_BOARD: 'Hovedfordeling',
        BOARD: 'Fordeling',
        CIRCUIT: 'Kurs',
        EMERGENCY_GENERATOR: 'Aggregat',
        UPS: 'UPS',
        OTHER: 'Annet',
        SYSTEM: 'System'
      },
      STATUS: {
        UNKNOWN: 'Ukjent/Ikke angitt',
        OK: 'Alt OK',
        WARNING: 'Liten feil / advarsel',
        UNDER_CONSTRUCTION: 'Under oppføring',
        NOTIFICATION: 'Varsel',
        COMPLETE: 'Ferdigstilt'
      }
    },

    //Projects namespace

    PROJECTS_NS: {
      READ: 'LES',
      NO_ENABLED_FEATURES: 'Ingen tilgjengelige moduler. Kontakt eier av prosjektet for å få lesetilgang'
    },

    CONTROLS_NS: {
      GO_BACK: 'Tilbake til oversikt'
    },
    COMPONENT_NS: {
      GO_BACK: 'Tilbake til kontroll'
    },

    LOGIN: 'Logg inn',
    LOGOUT: 'Logg ut',
    SIGNUP: 'Registrer konto',
    APP: 'Lesetilgang',
    FORGOT_PASSWORD: 'Glemt passord',
    EMAIL: 'Epost',
    FORGOT_PASSWORD_ACTION: 'Send epost for å opprette nytt passord',
    PROJECTS: 'Prosjekter',
    PROCEDURES: 'Prosedyrer',
    DOCUMENTS: 'Dokumenter',
    CONTROLS: 'Kontroller',
    PERIODIC_CONTROLS: 'Periodiske kontroller',
    ISSUES: 'Avvik',
    LOADING: 'Laster data',
    DETAILS: 'Detaljer',
    DOWNLOAD: 'Last ned',
    ACTIVE_CONTROLS: 'Aktive kontroller',
    DONE_CONTROLS: 'Ferdige kontroller',
    NO_CONTROLS: 'Ingen kontroller',
    NO_DOCUMENTS: 'Ingen tilgjengelige dokumenter',
    PROJECT_DOCUMENTS: 'Prosjektdokumenter',
    PROJECT: 'Prosjekt',
    OWNER: 'Eier',
    ADDRESS: 'Adresse',
    TEMPLATE_DOCUMENTS: 'Dokumentmaler',
    NO_PROCEDURES: 'Ingen prosedyrer tilgjengelig',
    FILTER_PROJECTS: 'Filtrer prosjekter',
    CONTROL: 'Kontroll',
    MENU: 'Meny',
    COMPONENTS: 'Komponenter',
    CLOSE: 'Lukk',
    CHOOSE_PROJECT: 'Velg prosjekt',
    CHOOSE_CONTROL: 'Velg kontroll',
    CHOOSE_PROJECT_AND_CONTROL_FROM_MENU: 'Velg prosjekt og kontroll fra meny',
    NAME: 'Navn',
    PLACEMENT: 'Plassering',
    STATUS: 'Status',
    OPEN: 'Åpne',
    COMMENT: 'Kommentar',
    CHECKLIST: 'Sjekkliste',
    YES: 'Ja',
    NO: 'Nei',
    CREATED: 'Opprettet',
    DONE: 'Ferdig',
    DESCRIPTION: 'Beskrivelse',
    NO_CHECKLISTS: 'Ingen tilgjengelige sjekklister sjekklister',
    NO_ISSUES: 'Ingen registrerte avvik',
    SYSTEM: 'System',
    SAFETY_TYPE: 'Vern type',
    MAIN_SWITCH_VALUE: 'Hovedbryter In',
    CP_POWER_SET_VALUE: 'Innstilt verdi',
    SELECT_CONTROL: 'Velg kontroll',
    SELECT_COMPONENT: 'Velg komponent',
    UNKNOWN: 'Ukjent',
    OK: 'Ok',
    TN_S: 'TN-S',
    CHECKLISTS: 'Sjekklister',
    SET_NEW_PASSWORD: 'Sett nytt passord',
    SAVE: 'Lagre',
    NO_COMPONENTS: 'Ingen tilgjengelige komponenter',
    ISSUE: 'Avvik',
    TYPE: 'Type',
    ERROR_CATEGORY: 'Kategori',
    ERROR_DESCRIPTION: 'Beskrivelse av feil',
    ERROR_LAW_REFERENCE: 'Lovreferanse',
    GENERAL: 'Generell',
    CHECKLIST_ITEM: 'Sjekkpunkt',
    SUMMARY: 'Oppsummering',
    SERIOUSNESS: 'Alvorlighet',
    REPORT: 'Rapport',
    COST_ESTIMATE: 'Kostnadsestimat',
    ISSUE_RECOMMENDATION: 'Anbefalt utbedring',
    ATTACHMENTS: 'Vedlegg',
    BUILDING_PART: 'Komponent',
    PERFORMED: 'Utført',
    SHORT_DESCRIPTION: 'Kort beskrivelse',
    FREQUENCY: 'Frekvens',
    TABLE: 'Tabell',
    GOTO: 'Gå til',
    TIMES: 'ganger',
    NO_IMAGES: 'Ingen tilgjengelig bilder',

    /* Time units*/
    DATE: 'Dato',
    DAY: 'dag',
    WEEK: 'uke',
    MONTH: 'måned',
    YEAR: 'år',

    /* Navigation */
    TO_PROJECT: 'Til prosjekt',
    CARD_VIEW: 'Vis liste med rektangler',
    TRADITIONAL_LIST_VIEW: 'Vis som normal liste',

    /* Procedure detail tabs*/
    PROCEDURE_TEXT: 'Prosedyretekst',
    PROCEDURE_CHECKLISTS: 'Sjekklister',
    PROCEDURE_DOCS: 'Dokumenter og vedlegg',
    PROCEDURE_LINKS: 'Lenker',
    PROJECT_LINKS: 'Prosjektets lenker',
    COMPANY_LINKS: 'Bedriftens lenker',
    TEMPLATE_LINKS: 'Lenker fra mal',
    PROCEDURE_TASKS: 'Oppgaver',
    PROCEDURE_PURPOSE: 'Hensikt',
    PROCEDURE_SCOPE: 'Omfang',
    PROCEDURE_PROJECT_TEXT: 'Prosjektspesifikk informasjon',
    PROCEDURE_RESPONSIBILITY: 'Ansvar',
    PROCEDURE_DESCRIPTION: 'Beskrivelse',
    FREQUENCY_REPEAT: 'Hver',
    NO_REPEAT: 'Skal ikke gjentas',
    NEXT_TASK_PERFORM_DATE: 'Neste planlagte utførelse',
    NO_LINKS: 'Ingen tilgjengelige lenker',
    NO_TASKS: 'Ingen tilgjengelige oppgaver',
    NO_PROCEDURE_TEXT: 'Ingen tilgjengelig prosedyretekst',
    PROCEDURE_BACK: 'Tilbake til oversikt',
    TABLE_TEMPLATES: 'Tabeller tilgjengelig i mal',
    NO_ACTIVE_TABLES: 'Ingen aktive tabeller',
    CHECKLIST_TEMPLATES: 'Sjekklister tilgjengelig i mal',
    COMPANY_CHECKLIST_TEMPLATES: 'Bedriftsmal',
    NO_ACTIVE_CHECKLISTS: 'Ingen aktive sjekklister',
    EMPTY_TABLE: 'Tabellen er tom',
    DOCUMENT_TEMPLATES: 'Tilgjengelig dokumenter i mal',
    CLOSED_ISSUE: 'Lukket avvik',
    CLOSED: 'Lukket',
    BY: 'av',

    /* FDV */
    FDV: 'FDV',
    FDV_ADMINISTRATION: 'Forvaltning',
    FDV_COMPONENTS: 'Anleggsdeler',
    FDV_IMG_GALLERY: 'Bildegalleri',
    FDV_DRAWINGS: 'Tegninger',
    SELECT_FDV: 'Velg FDV',
    SELECT_PROJECT_FROM_MENU: 'Velg prosjekt fra meny',
    NO_FDV_PROJECTS_FOUND: 'Ingen FDV tilgjengelig',
    NO_DRAWINGS: 'Ingen tilgjengelige tegninger',

    /* ERROR MESSAGES */
    LOGIN_INVALID_PASSWORD: 'Ugyldig passord.',
    LOGIN_USER_NOT_FOUND: 'Bruker med oppgitt epost finnes ikke.',
    FORGOT_PASSWORD_USER_NOT_FOUND: 'Oppgitt e-post finnes ikke',
    NO_CONNECTION_HEADLINE: 'Mistet forbindelsen til server.',
    TRYING_RECONNECT: 'Forsøker å opprette forbindelse...',
    NO_CONTROLS_FOUND: 'Ingen kontroller funnet for prosjekt',
    RESET_PASSWORD_INVALID_TOKEN: 'Ugyldig engangskode. Forsøk å gjenopprette passord på nytt',

    DEVIATION_OVERVIEW: 'Avvikoversikt',

    DEVIATION_SEVERITY_HIGH: '3 - Alvorlig avvik, omgående utbedring nødvendig',
    DEVIATION_SEVERITY_LOW: '1 - Mindre avvik, overvåkes, ny kontroll avtales',
    DEVIATION_SEVERITY_MEDIUM: '2 - Viktig avvik registrert, utbedres snarest mulig',
    DEVIATION_SEVERITY_IMPROVEMENT: '0 - Forslag til forbedring',

    DEVIATION_SEVERITY_TG3: 'TG 3 - Alvorlige avvik (utbedres omgående)',
    DEVIATION_SEVERITY_TG2: 'TG 2 - Viktig avvik registrert (utbedres innen 1 måned)',
    DEVIATION_SEVERITY_TG1: 'TG 1 - Mindre avvik til orientering',
    DEVIATION_SEVERITY_TG0: 'TG 0 - Ingen kommentar',
    DEVIATION_SEVERITY_UNKNOWN: 'Ukjent',

    BACK: 'Tilbake'
  })

  $translateProvider.translations('en', {
    COMPONENT: {
      SINGLE: 'Component',
      TYPE: {
        MAIN_BOARD: 'Main Board',
        BOARD: 'Board',
        CIRCUIT: 'Circuit',
        EMERGENCY_GENERATOR: 'Emergency generator',
        UPS: 'UPS',
        OTHER: 'Other',
        SYSTEM: 'System'
      },
      STATUS: {
        UNKNOWN: 'Unknown',
        OK: 'OK',
        WARNING: 'Small issue/ warning',
        UNDER_CONSTRUCTION: 'Under construction',
        NOTIFICATION: 'Notification',
        COMPLETE: 'Complete'
      }
    },
    LOGIN: 'Login',
    LOGOUT: 'Log out',
    SIGNUP: 'Signup',
    APP: 'Read access',
    FORGOT_PASSWORD: 'Forgot password',
    EMAIL: 'Email',
    FORGOT_PASSWORD_ACTION: 'Send password reset information',
    PROJECTS: 'Projects',
    PROCEDURES: 'Procedures',
    DOCUMENTS: 'Documents',
    CONTROLS: 'Controls',
    ISSUES: 'Issues',
    LOADING: 'Loading',
    DETAILS: 'Details',
    DOWNLOAD: 'Download',
    ACTIVE_CONTROLS: 'Active controls',
    DONE_CONTROLS: 'Finished controls',
    NO_CONTROLS: 'No controls',
    NO_DOCUMENTS: 'No documents available',
    NO_LINKS: 'No links',
    PROJECT_DOCUMENTS: 'Project documents',
    PROJECT: 'Project',
    OWNER: 'Owner',
    ADDRESS: 'Address',
    TEMPLATE_DOCUMENTS: 'Document templates',
    NO_PROCEDURES: 'No procedures available',
    FILTER_PROJECTS: 'Filter projects',
    CONTROL: 'Control',
    MENU: 'Menu',
    COMPONENTS: 'Components',
    CLOSE: 'Close',
    CHOOSE_PROJECT: 'Choose project',
    CHOOSE_CONTROL: 'Choose control',
    CHOOSE_PROJECT_AND_CONTROL_FROM_MENU: 'Choose project and control from menu',
    NAME: 'Name',
    PLACEMENT: 'Placement',
    STATUS: 'Status',
    OPEN: 'Open',
    COMMENT: 'Comment',
    CHECKLIST: 'Checklist',
    YES: 'Yes',
    NO: 'No',
    CREATED: 'Created',
    DONE: 'Done',
    DESCRIPTION: 'Description',
    NO_CHECKLISTS: 'No checklists available',
    NO_ISSUES: 'No issues available',
    SYSTEM: 'System',
    SAFETY_TYPE: 'Protection type',
    MAIN_SWITCH_VALUE: 'Main switch In',
    CP_POWER_SET_VALUE: 'Set value',
    SELECT_CONTROL: 'Select control',
    SELECT_COMPONENT: 'Select component',
    UNKNOWN: 'Unknown',
    OK: 'Ok',
    TN_S: 'TN-S',
    CHECKLISTS: 'Checklists',
    SET_NEW_PASSWORD: 'Select new password',
    SAVE: 'Save',
    NO_COMPONENTS: 'No components available',
    ISSUE: 'Issue',
    TYPE: 'Type',
    ERROR_CATEGORY: 'Category',
    ERROR_DESCRIPTION: 'Description of error',
    ERROR_LAW_REFERENCE: 'Law reference',
    GENERAL: 'General',
    CHECKLIST_ITEM: 'Checklist item',
    SUMMARY: 'Summary',
    SERIOUSNESS: 'Severeness',
    REPORT: 'Report',
    COST_ESTIMATE: 'Cost estimate',
    ISSUE_RECOMMENDATION: 'Recommended fix',
    ATTACHMENTS: 'Attachments',
    BUILDING_PART: 'Component',
    PERFORMED: 'Performed',
    SHORT_DESCRIPTION: 'Short description',
    FREQUENCY: 'Frequency',
    TABLE: 'Table',
    GOTO: 'Go to',
    CLOSED: 'Lukket',
    TIMES: 'ganger',
    BY: 'by',

    /* Time units*/
    DATE: 'Date',
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year',

    /*Navigation*/
    TO_PROJECT: 'To project',
    CARD_VIEW: 'Show list with cards',
    TRADITIONAL_LIST_VIEW: 'Show as normal list',

    /* Procedure detail tabs*/
    PROCEDURE_TEXT: 'Procedure text',
    PROCEDURE_CHECKLISTS: 'Checklists',
    PROCEDURE_DOCS: 'Documents and attachments',
    PROCEDURE_LINKS: 'Links',
    PROJECT_LINKS: 'Links for project',
    COMPANY_LINKS: 'Links for company',
    TEMPLATE_LINKS: 'Links from template',
    PROCEDURE_TASKS: 'Tasks',
    PROCEDURE_PURPOSE: 'Purpose',
    PROCEDURE_SCOPE: 'Scope',
    PROCEDURE_PROJECT_TEXT: 'Prosjektspesific information',
    PROCEDURE_RESPONSIBILITY: 'Responsibility',
    PROCEDURE_DESCRIPTION: 'Description',
    FREQUENCY_REPEAT: 'Every',
    NO_REPEAT: 'Does not repeat',
    NEXT_TASK_PERFORM_DATE: 'Next task perform date',
    NO_TASKS: 'No tasks available',
    NO_PROCEDURE_TEXT: 'No procedure text avaiable',
    PROCEDURE_BACK: 'Tilbake til oversikt',
    EMPTY_TABLE: 'The table is empty',
    COMPANY_CHECKLIST_TEMPLATES: 'Company template',
    DOCUMENT_TEMPLATES: 'Available documents in template',

    /* ERROR MESSAGES */
    LOGIN_INVALID_PASSWORD: 'Invalid password.',
    LOGIN_USER_NOT_FOUND: 'User with the supplied email does not exist.',
    FORGOT_PASSWORD_USER_NOT_FOUND: 'Email does not exist',
    NO_CONNECTION_HEADLINE: 'Lost connection to server.',
    TRYING_RECONNECT: 'Trying to re-connect...',
    NO_CONTROLS_FOUND: 'No controls found for project',
    RESET_PASSWORD_INVALID_TOKEN: 'Invalid token, try resetting password again.',

    DEVIATION_SEVERITY_HIGH: 'High',
    DEVIATION_SEVERITY_LOW: 'Low',
    DEVIATION_SEVERITY_MEDIUM: 'Medium',
    DEVIATION_SEVERITY_IMPROVEMENT: 'Improvement',

    DEVIATION_SEVERITY_TG3: 'TG3',
    DEVIATION_SEVERITY_TG2: 'TG2',
    DEVIATION_SEVERITY_T1: 'TG1',
    DEVIATION_SEVERITY_TG0: 'TG0',
    DEVIATION_SEVERITY_UNKNOWN: 'Unknown'
  })

  $translateProvider.preferredLanguage('no')
  // TODO: Add sanitize option.
  /*$translateProvider.useSanitizeValueStrategy('sanitize');*/

  // Material theme config
  console.log($mdThemingProvider)
  //noinspection JSUnresolvedFunction
  $mdThemingProvider
    .theme('ps-default')
    .primaryPalette('teal')
    .accentPalette('orange')
    .warnPalette('amber')
    .backgroundPalette('grey')

  $mdThemingProvider.setDefaultTheme('ps-default')
}

appRouting.$inject = ['$urlRouterProvider', '$locationProvider', '$translateProvider', '$mdThemingProvider']

export default appRouting
