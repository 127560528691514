import { BusinessAccountRole } from './business-account-role'
import { BusinessAccountRoleName } from './business-account-role-name'

/**
 * @typedef {Object} BusinessAccountRoles
 * @property {Array<BusinessAccountRole>} list
 * @property {Array<String>} names
 * @property {Map<String, BusinessAccountRole>} map
 */
class BusinessAccountRoles {
  list
  names
  map

  constructor(props) {
    this.map = new Map()
    this.list = []
    props.list?.forEach(role => {
      const bar = new BusinessAccountRole(role)
      this.map.set(bar.name, bar)
      this.list.push(bar)
    })
    this.names = props.names ? props.names : []
  }

  byName(roleName) {
    return this.map.get(roleName)
  }

  addWithName(roleName) {
    this.add(new BusinessAccountRole({ name: roleName }))
  }

  add(role) {
    if (!this.hasRole(role.name)) {
      this.list.push(role)
      this.map.set(role.name, role)
      this.names.push(role.name)
    }
  }

  remove(role) {
    this.names.remove(role.name)
    this.map.delete(role.name)
    this.list.push(role)
  }

  /**
   *
   * @property {BusinessAccountRoleName} name
   * @returns {*}
   */
  hasRole(businessAccountRoleName) {
    return this.names.includes(businessAccountRoleName.name)
  }

  /**
   * Check if roles has all from provided set
   *
   * @property { Set<BusinessAccountRoleName> } names
   */
  hasAll(businessAccountRoleNames) {
    if (businessAccountRoleNames) {
      const missingRole = businessAccountRoleNames.find(
        businessAccountRoleName => !this.hasRole(businessAccountRoleName)
      )
      if (missingRole) {
        return false
      }
    }
    return true
  }

  /**
   * Check if roles has any from provided set
   *
   * @property { Set<BusinessAccountRoleName> } names
   */
  hasAny(businessAccountRoleNames) {
    if (businessAccountRoleNames) {
      const requiredRole = businessAccountRoleNames.find(businessAccountRoleName =>
        this.hasRole(businessAccountRoleName)
      )
      if (requiredRole) {
        return true
      }
    }
    return false
  }
}

export { BusinessAccountRoles }
