import { Enum } from '../enum'

class Day extends Enum {
  static SUNDAY = new Day('SUNDAY')
  static MONDAY = new Day('MONDAY')
  static TUESDAY = new Day('TUESDAY')
  static WEDNESDAY = new Day('WEDNESDAY')
  static THURSDAY = new Day('THURSDAY')
  static FRIDAY = new Day('FRIDAY')
  static SATURDAY = new Day('SATURDAY')

  constructor(name = Day.MONDAY.name) {
    super(name)
  }
}

export { Day }
