import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/pdf`
}

function html(unitId, projectId, pdfSetup) {
  return ifetch(baseUrl(unitId, projectId) + `/as-html`, { method: 'POST', body: JSON.stringify(pdfSetup) })
}

// no response value
function addConfig(unitId, projectId, pdfSetup) {
  return ifetch(baseUrl(unitId, projectId) + `/config`, { method: 'POST', body: JSON.stringify(pdfSetup) })
}

function getConfig(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/config`, { method: 'GET' })
}

function pdf(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/`, { method: 'GET' })
}

function pdfFromExternalSystemWithConfig(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/external-system/with-config`, { method: 'GET' })
}

function pdfFromExternalSystem(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/external-system/default-config`, { method: 'GET' })
}

function saveToFs(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/save`, { method: 'POST' })
}

function download(unitId, projectId) {
  window.open(`/ny` + baseUrl(unitId, projectId) + '/?token=' + window.jwtToken)
}

export { html, addConfig, getConfig, pdf, pdfFromExternalSystemWithConfig, pdfFromExternalSystem, saveToFs, download }
