import { ifetch } from '@integrator/api'

function url(unitId, contactId) {
  return `/api/business/${unitId}/projects/contacts/${contactId}`
}

// contact projects
function collaboration(unitId, contactId) {
  return ifetch(url(unitId, contactId) + '/participate')
}

function ownedByContact(unitId, contactId) {
  return ifetch(url(unitId, contactId) + '/customer')
}

export { collaboration, ownedByContact }
