import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, id) {
  return `/api/business/${unitId}/projects/${projectId}/mom/general-documentation/${id}`
}

// no response value
function setDescriptionText(unitId, projectId, id, text) {
  return ifetch(baseUrl(unitId, projectId, id) + `/description`, { method: 'POST', body: JSON.stringify(text) })
}

function addLink(unitId, projectId, id, newLink) {
  return ifetch(baseUrl(unitId, projectId, id) + `/links`, { method: 'POST', body: JSON.stringify(newLink) })
}

function getLinks(unitId, projectId, id) {
  return ifetch(baseUrl(unitId, projectId, id) + `/links`, { method: 'GET' })
}

export { setDescriptionText, addLink, getLinks }
