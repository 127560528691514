import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, elementId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/building-documentation/${elementId}`
}

// no response value
function scopeDefinition(unitId, projectId, elementId, definition) {
  return ifetch(baseUrl(unitId, projectId, elementId) + `/scope-definition`, {
    method: 'POST',
    body: JSON.stringify(definition)
  })
}

function links(unitId, projectId, elementId) {
  return ifetch(baseUrl(unitId, projectId, elementId) + `/links`, { method: 'GET' })
}

function products(unitId, projectId, elementId) {
  return ifetch(baseUrl(unitId, projectId, elementId) + `/products`, { method: 'GET' })
}

export { scopeDefinition, links, products }
