import { ifetch } from '@integrator/api'

function url(businessUnitId, competencyId, iterationId) {
  // /api/business/{businessUnitId}/competences/{competencyId}/iterations/{iterationId}"
  return `api/business/${businessUnitId}/competences/${competencyId}/iterations/${iterationId}`
}

function deleteIteration(businessUnitId, competencyId, iterationId) {
  return ifetch(url(businessUnitId, competencyId, iterationId), { method: 'DELETE' })
}

function updateIteration(businessUnitId, competencyId, iteration) {
  return ifetch(url(businessUnitId, competencyId, iteration.id), {
    method: 'PUT',
    body: JSON.stringify(iteration?.attributes)
  })
}

export { updateIteration, deleteIteration }
