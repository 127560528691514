'use strict'
/**
 * Dialog for showing checklist.
 */

import './dialogs.checklist.directive.scss'

class Controller {
  constructor($stateParams, data, psPdfReportService, $mdDialog) {
    this.checklist = data
    this.dialog = $mdDialog
    //
    // Seems like project id is set in two ways
    //
    if ($stateParams.project) {
      this.projectId = $stateParams.project
    } else {
      if ($stateParams.id) {
        this.projectId = $stateParams.id
      }
    }
    this.procedure_id = $stateParams.procedure_id || 0
    this.psPdfReportService = psPdfReportService
  }

  cancel() {
    this.dialog.hide()
  }
}
Controller.$inject = ['$stateParams', 'data', 'psPdfReportService', '$mdDialog']

export default checklist => {
  return {
    controller: Controller,
    controllerAs: 'vc',
    template: require('./dialogs.checklist.tpl.html'),
    autoWrap: true,
    fullscreen: true,

    locals: {
      data: checklist
    }
  }
}
