import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, procedureId) {
  return `/api/business/${unitId}/project/${projectId}/procedures/links`
}

function links(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId), { method: 'GET' })
}

export { links }
