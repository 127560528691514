/**
 * @typedef { object } RecordHitOrigin
 * @property { string } name
 */
class RecordHitOrigin {
  static TEMPLATE_RECORDS = new RecordHitOrigin('TEMPLATE_RECORDS')
  static BUSINESS_RECORDS = new RecordHitOrigin('BUSINESS_RECORDS')
  static BUSINESS_TEMPLATE_RECORDS = new RecordHitOrigin('BUSINESS_TEMPLATE_RECORDS')
  static CORPORATE_RECORDS = new RecordHitOrigin('CORPORATE_RECORDS')
  static PROJECT_RECORDS = new RecordHitOrigin('PROJECT_RECORDS')

  constructor(props) {
    if (props instanceof RecordHitOrigin) {
      this.name = props.name
    } else {
      this.name = props
    }
  }

  toJSON() {
    return this.name
  }

  toString() {
    return this.name
  }
}

export { RecordHitOrigin }
