import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/settings/periodic-control/component-templates`
}

function get(unitId) {
  return ifetch(url(unitId))
}

function newComponentTemplate(unitId, componentTemplateJson) {
  return ifetch(url(unitId), {
    method: 'PUT',
    body: JSON.stringify(componentTemplateJson)
  })
}

function getAvailable(unitId) {
  return ifetch(url(unitId) + '/available')
}

function addFromTemplate(unitId, templateId) {
  return ifetch(url(unitId) + `/from-template/${templateId}`, {
    method: 'PUT'
  })
}

export { get, newComponentTemplate, getAvailable, addFromTemplate }
