import { ifetch } from '@integrator/api'

function url(unitId, person) {
  return `/api/business/${unitId}/contacts/${person.contactId}/persons/${person.id}/license`
}

function getLicenses(businessUnitId, person) {
  return ifetch(url(businessUnitId, person), {
    method: 'GET'
  })
}

function dropLicense(businessUnitId, person, businessModuleName) {
  return ifetch(url(businessUnitId, person) + `/${businessModuleName}`, {
    method: 'DELETE'
  })
}

function addLicense(businessUnitId, person, businessModuleName) {
  return ifetch(url(businessUnitId, person) + `/${businessModuleName}`, {
    method: 'POST'
  })
}

export { getLicenses, addLicense, dropLicense }
