'use strict'

import router from 'angular-ui-router'

/**
 * Routes for Components module.
 * @author Audun Follegg (audunfo@gmail.com)
 */

let routes = function ($stateProvider) {
  $stateProvider
    .state('components', {
      url: '/components',
      parent: 'unit',
      abstract: true,
      template: '<ui-view class="full-height"></ui-view>'
    })
    .state('components.parent', {
      url: '',
      parent: 'components',
      template: require('./components.parent.tpl.html'),
      authenticate: true,
      controller: 'ComponentsParentCtrl',
      resolve: {
        projects: [
          'psProjectService',
          function (service) {
            return service.list()
          }
        ]
      },
      controllerAs: 'vc',
      ncyBreadcrumb: {
        parent: 'components',
        /*label: 'Projects'*/
        label: "{{'COMPONENTS' | translate}}"
      }
    })
    .state('components.list', {
      url: '/project/:project/control/:control',
      parent: 'components.parent',
      template: require('./list/components.list.tpl.html'),
      controller: 'ComponentsListCtrl',
      controllerAs: 'vc',
      resolve: {
        components: [
          'psControlsService',
          '$stateParams',
          function (service, $stateParams) {
            return service.getControlComponents($stateParams.control)
          }
        ]
      },
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('components.detail', {
      url: '/project/:project/control/:control/component/:component',
      parent: 'components.parent',
      template: require('./detail/components.detail.tpl.html'),
      controller: 'ComponentDetailCtrl',
      controllerAs: 'vc',
      resolve: {
        components: [
          'psControlsService',
          '$stateParams',
          function (service, $stateParams) {
            return service.getControlComponents($stateParams.control)
          }
        ]
      },
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('components.detail.issues', {
      url: '/issues',
      parent: 'components.detail',
      template: require('../issues/issues.list.tpl.html'),
      scope: {
        type: 'COMPONENT_ISSUES'
      },
      controller: 'IssuesListCtrl',
      controllerAs: 'vc',
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
}

routes.$inject = ['$stateProvider']

export default routes
