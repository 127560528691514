'use strict'
/**
 * Angular entry point for 'ps-read-access-frontend'.
 * @author Audun Follegg (audunfo@gmail.com)
 */

// Import styles
import styles from './common/styles/index.scss'
import materialStyles from 'angular-material/angular-material.css'
import materialIcons from 'material-icons/css/material-icons.css'
import fontAwesome from 'font-awesome/css/font-awesome.css'

// Application dependencies
import angular from 'angular'
import material from 'angular-material'
import uirouter from 'angular-ui-router'
import angularTranslate from 'angular-translate'
import ngMessages from 'angular-messages'

// Services
import spinnerService from './common/services/service.spinner'
import authService from './common/services/auth/auth.service.js'

// Config
import config from './config/config'
import run from './config/run'
import constants from './config/config.constants.js'

// Modules
import authModule from './modules/auth/auth.module'
import projectsModule from './modules/projects/projects.module'
import navBar from './common/navbar/navbar.directive.js'
import ConnectionDirective from './common/directives/connection/connection.directive.js'
import MenuDirective from './common/directives/sidenav/sidenav.menu.directive.js'
import ComponentsModule from './modules/components/components.module.js'
import FdvModule from './modules/fdv/fdv.module.js'
import ProceduresModule from './modules/procedures/procedures.module.js'
import PeriodicControlModule from './modules/periodic/periodic.module.js'

import './web-components/procedure/view/procedure-manual'
import '@iwc/filestore/src/tree-strucutre/tree-structure'

import './app.scss'
import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js'

globalThis.JWT_COOKIE_NAME = 'jwt'

registerIconLibrary('fa', {
  resolver: name => {
    const filename = name.replace(/^fa[rbsld]-/, '')
    let folder = 'regular'
    if (name.substring(0, 4) === 'fas-') folder = 'solid'
    if (name.substring(0, 4) === 'fab-') folder = 'brands'
    if (name.substring(0, 4) === 'fad-') folder = 'duotone'
    if (name.substring(0, 4) === 'fal-') folder = 'light'
    if (name.substring(0, 4) === 'far-') folder = 'regular'
    return `/fontawesome-pro-5.15.4-web/svgs/${folder}/${filename}.svg`
  },
  mutator: svg => svg.setAttribute('fill', 'currentColor')
})

export default angular
  .module('psReadAccess', [
    material,
    uirouter,
    ngMessages,
    angularTranslate,
    spinnerService,
    authService,
    authModule,
    projectsModule,
    constants,
    navBar,
    ConnectionDirective,
    MenuDirective,
    ComponentsModule,
    FdvModule,
    ProceduresModule,
    PeriodicControlModule
  ])
  .config(config)
  .run(run)
