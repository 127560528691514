import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/contracts`
}

function newContractMetaData(unitId) {
  return ifetch(url(unitId) + '/new-contract-metadata', {
    method: 'GET'
  })
}

export { newContractMetaData }
