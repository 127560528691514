import { ifetch } from '@integrator/api'

function baseUrl(checklistId, checkpointId) {
  return `/api/checklists/${checklistId}/checkpoints/${checkpointId}`
}

function status(checklistId, checkpointId) {
  return ifetch(baseUrl(checklistId, checkpointId) + `/status`, { method: 'POST' })
}

function definition(checklistId, checkpointId, definition) {
  return ifetch(baseUrl(checklistId, checkpointId) + `/definition`, {
    method: 'POST',
    body: JSON.stringify(definition)
  })
}

// no response value
function remove(checklistId, checkpointId) {
  return ifetch(baseUrl(checklistId, checkpointId), { method: 'DELETE' })
}

// no response value
function removeWithDeviations(checklistId, checkpointId) {
  return ifetch(baseUrl(checklistId, checkpointId) + '/with-deviations', { method: 'DELETE' })
}

export { status, definition, remove, removeWithDeviations }
