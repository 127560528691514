'use strict'

// Imports
import angular from 'angular'
import router from 'angular-ui-router'
import ApiConstants from '../../../config/config.constants.js'

/**
 * Service for generating appropriate PDF download URLs.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
class PdfReportDataService {
  constructor($log, apiConfig, authService) {
    this.$log = $log
    this.apiConfig = apiConfig
    this.completeUrl = apiConfig.completeUrl()
    this.path = apiConfig.completeUrl() + '/download/open/reports/pdf'
    this.token = authService.getTokenFromStorage()
  }

  getDeviationPdfForControl(control) {
    return this.apiConfig.url() + 'deviations/pdf/CONTROL/' + control + '/book/default-config?token=' + this.token
  }

  getDeviationPdfForProject(projectId) {
    return this.apiConfig.url() + 'deviations/pdf/PROJECT/' + projectId + '/book/default-config?token=' + this.token
  }

  getOpenDeviationPdfForProject(projectId) {
    let url = this.apiConfig.url() + 'deviations/pdf/PROJECT/' + projectId + '/book/with-config?token=' + this.token
    url += '&criteria.withContext=true'
    url += '&criteria.withFullReport=true'
    url += '&criteria.archived=false'
    url += '&criteria.closed=false'
    url += '&criteria.open=true'
    url += '&config.withContext=true'
    url += '&config.withImages=true'
    url += '&config.compressImages=true'
    url += '&config.withEstimation=true'
    url += '&config.withRecommendation=true'
    url += '&config.sortOrderKeys=deviation.severityOrder'
    return url
  }

  getDeviationPdfForComponent(componentId) {
    return this.apiConfig.url() + 'deviations/pdf/COMPONENT/' + componentId + '/book/default-config?token=' + this.token
  }

  getChecklistReportForAllComponentsInControl(unitId, projectId, controlId) {
    var url = `business/${unitId}/project/${projectId}/periodic-control/controls/${controlId}/pdf`
    return `${this.apiConfig.url()}/${url}?token=${this.token}`
  }

  getChecklistReport(projectId, checklistId, procedure_id) {
    var url = 'checklists/pdf/' + projectId + '/' + procedure_id + '/' + checklistId + '/reprint'
    return this.apiConfig.url() + url + '?token=' + this.token
  }

  getComponentInfoPdfReportUrl(unitId, projectId, controlId, componentId) {
    const url =
      'business/' +
      unitId +
      '/project/' +
      projectId +
      '/periodic-control/controls/' +
      controlId +
      '/components/' +
      componentId +
      '/pdf/component-info'
    return this.apiConfig.url() + url + '?token=' + this.token
  }

  getComponentMeasurementReport(unitId, projectId, controlId, componentId) {
    var url =
      'business/' +
      unitId +
      '/project/' +
      projectId +
      '/periodic-control/controls/' +
      controlId +
      '/components/' +
      componentId +
      '/pdf/measurement-protocol'
    return this.apiConfig.url() + url + '?token=' + this.token
  }

  getIssueReportForComponentStructure(unitId, projectId, controlId, componentId) {
    const url =
      'business/' +
      unitId +
      '/prosjekt/' +
      projectId +
      '/ikk/kontroll/' +
      controlId +
      '/komponenter/' +
      componentId +
      '/table/avvikForKomponentOgUnderkomponenter.pdf'
    return this.apiConfig.url() + url + '?token=' + this.token
  }
}

PdfReportDataService.$inject = ['$log', 'apiConfig', 'authService']

export default angular
  .module('ps.service.data.report.pdf', [router, 'ngFileSaver', ApiConstants])
  .service('psPdfReportService', PdfReportDataService).name
