'use strict'

// Imports
import angular from 'angular'
import router from 'angular-ui-router'

class GeneratePdfService {
  constructor() {
    //for testing
    //this.base = "http://documentservice-test.apps.profsys-integrator.no/PDF/";
    this.base = 'http://documentservice.apps.profsys-integrator.no/PDF/'
  }

  getFdvAdministrationPdf(projectId) {
    return this.base + 'fdv_administration/' + this.getIdString(projectId) + this.getJwtString()
  }

  getFdvComopnentPdf(componentId) {
    return this.base + 'fdv_components/' + this.getIdString(componentId) + this.getJwtString()
  }

  getIdString(id) {
    return '{"id":"' + id + '"}'
  }

  getJwtString() {
    //local test
    // var jwt = ''; //get jwt from test-integrator
    // return '?jwt=' +jwt;
    return '?jwt=' + localStorage.getItem('jwt')
  }
}

export default angular.module('ps.service.pdf.generate', [router]).service('psPdfGenerateService', GeneratePdfService)
  .name
