'use strict'

/**
 * Run function for ReadAccess app.
 * @author Audun Follegg (audunfo@gmail.com)
 */
let Run = function ($rootScope, $state, AuthService, $location, $window) {
  $rootScope.$on('$stateChangeStart', function (event, toState, toParam, fromState, fromParams) {
    if (toState.authenticate) {
      if (AuthService.isAuthenticated()) {
        if (!toParam.unitId) {
          toParam.unitId = AuthService.user['unit_id']
          $state.go(toState.name, toParam, { location: 'replace' })
        }
      } else {
        $state.go('auth.login')
        event.preventDefault()
      }
    }
  })
}

Run.$inject = ['$rootScope', '$state', 'authService', '$location', '$window']

export default Run
