import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, procedureId) {
  return `/api/business/${unitId}/project/${projectId}/procedure/${procedureId}/checklists`
}

// CHECK THIS REQUEST CORRECTNESS
// ProjectProcedureChecklistsEndpoint.java, assignedList() method
function assignedList(unitId, projectId, procedureId, checkpoints = false) {
  return ifetch(baseUrl(unitId, projectId, procedureId) + `/assigned?checkpoints=${checkpoints}`, { method: 'GET' })
}

function list(unitId, projectId, procedureId) {
  return ifetch(baseUrl(unitId, projectId, procedureId), { method: 'GET' })
}

function addChecklist(unitId, projectId, procedureId, definition) {
  return ifetch(baseUrl(unitId, projectId, procedureId) + `/add`, { method: 'POST', body: JSON.stringify(definition) })
}

function addChecklistFromTemplate(unitId, projectId, procedureId, templateId) {
  return ifetch(baseUrl(unitId, projectId, procedureId) + `/add/${templateId}`, { method: 'POST' })
}

export { assignedList, list, addChecklist, addChecklistFromTemplate }
