import { ifetch } from '@integrator/api'

function url(businessUnitId, competencyId) {
  // /api/business/{businessUnitId}/competences/{competencyId}/iterations
  return `api/business/${businessUnitId}/competences/${competencyId}/iterations`
}

function getIterations(businessUnitId, competencyId, isAll) {
  return ifetch(url(businessUnitId, competencyId) + (isAll ? '?all=true' : ''), { method: 'GET' })
}

function addIteration(businessUnitId, competencyId, iteration) {
  return ifetch(url(businessUnitId, competencyId), { method: 'POST', body: JSON.stringify(iteration) })
}

function getParticipants(businessUnitId, competencyId) {
  return ifetch(url(businessUnitId, competencyId) + '/employees', { method: 'GET' })
}

export { getIterations, addIteration, getParticipants }
