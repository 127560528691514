import { Actions, Address, Person } from '../../universal'
import { Account } from './account'
import { BusinessUserSettings } from './business-user-settings'
import { EmployeeAttributes } from '../../employee'

class BusinessUser {
  id
  businessUnitId
  person
  account
  address
  settings
  actions
  employeeAttributes
  workTitle
  department
  constructor(props) {
    if (props) {
      this.id = props.id
      this.businessUnitId = props.businessUnitId
      this.person = new Person(props.person)
      this.account = new Account(props.account)
      this.address = new Address(props.address)
      this.settings = new BusinessUserSettings(props.settings)
      this.actions = new Actions(props.actions)
      this.employeeAttributes = new EmployeeAttributes(props.employeeAttributes)
      this.workTitle = props.workTitle
      this.department = props.department
    }
  }
}

export { BusinessUser }
