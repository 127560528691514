'use strict'
/**
 * Module definition for documents module.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import router from 'angular-ui-router'
import DocumentListCtrl from './documents.list.controller.js'
import DocumentsDataService from '../../common/services/data/documents.dataservice.js'
import DownloadDataService from '../../common/services/data/download.dataservice.js'
import TemplateDocumentDataService from '../../common/services/data/templates.dataservice.js'

import './documents.list.scss'

export default angular
  .module('ps.module.documents', [router, DocumentsDataService, DownloadDataService, TemplateDocumentDataService])
  .controller('DocumentListCtrl', DocumentListCtrl).name
