import { ifetch } from '@integrator/api'

function url(contextName, relationId) {
  return `/api/filesystems/${contextName}/${relationId}/records`
}

function latestRecords(contextName, relationId, count) {
  return ifetch(url(contextName, relationId) + '/latest/' + count)
}

export { latestRecords }
