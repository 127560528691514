import { Enum } from '@integrator/api'

class ChecklistType extends Enum {
  static CHECKLISTS = new ChecklistType('checklists')
  static UNIT_TEMPLATES = new ChecklistType('unitTemplates')
  static CORPORATE_TEMPLATES = new ChecklistType('corporateTemplates')
  static GLOBAL_TEMPLATES = new ChecklistType('globalTemplates')

  constructor(name) {
    super(name)
  }
}

export { ChecklistType }
