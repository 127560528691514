import { Person } from '@integrator/api'

/**
 * @typedef {Object} PortalAuthenticateResult
 * @property {string} token
 * @property {PortalAuthenticateResultKey} resultKey
 * @property {Person} person
 */
class PortalAuthenticationResult {
  token
  resultKey
  person
  unit
  localizedMessage
  businessUnits

  constructor(props) {
    this.token = props.token
    this.resultKey = PortalAuthenticateResultKey.value(props.resultKey)
    this.person = props.person ? new Person(props.person) : null
    this.unit = props.unit
    this.localizedMessage = props.localizedMessage
    this.businessUnits = props.businessUnits
  }

  selectUnit() {
    return this.resultKey === PortalAuthenticateResultKey.SelectBusinessUnit
  }

  success() {
    return this.resultKey === PortalAuthenticateResultKey.Success
  }
}

/**
 * Enumeration over possible result key values
 */
class PortalAuthenticateResultKey {
  static Success = new PortalAuthenticateResultKey('SUCCESS')
  static SelectBusinessUnit = new PortalAuthenticateResultKey('SELECT_BUSINESS_UNIT')

  constructor(name) {
    this.name = name
  }
  toString() {
    return `ResultKey.${this.name}`
  }

  static value(name) {
    const all = [this.SelectBusinessUnit, this.Success]
    return all.find(resultKey => resultKey.name === name)
  }
}

export { PortalAuthenticationResult, PortalAuthenticateResultKey }
