import { ifetch } from '@integrator/api'

function baseUrl(checklistId, checkpointId) {
  return `/api/checklists/template/${checklistId}/checkpoints/${checkpointId}`
}

// returns no json
function saveDefinition(checklistId, checkpointId, checkpointTemplate) {
  return ifetch(baseUrl(checklistId, checkpointId) + `/definition`, {
    method: 'POST',
    body: JSON.stringify(checkpointTemplate)
  })
}

// returns no json
function remove(checklistId, checkpointId) {
  return ifetch(baseUrl(checklistId, checkpointId), { method: 'DELETE' })
}

export { saveDefinition, remove }
