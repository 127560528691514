import { Person } from '../../universal'

class ControlDutyUser {
  person
  certificateNumber
  constructor(props) {
    this.person = new Person(props.person)
    this.certificateNumber = props.certificateNumber
  }
}

export { ControlDutyUser }
