import { ContainerStructure, RecordHitOrigin } from '@integrator/api'
import { BusinessProcedureFiles } from './business-procedure-files'

/**
 * Represents a files available in project procedure
 */
export class ProjectProcedureFiles extends BusinessProcedureFiles {
  /**
   * Container structure for project files.
   *
   * @type {ContainerStructure}
   */
  projectContainerStructure

  /**
   * The ID of the project procedure filesystem
   * @type {number}
   */
  projectProcedureFileSystemId

  constructor(props) {
    super(props)
    if (props) {
      this.projectContainerStructure = new ContainerStructure(props.projectContainerStructure)
      this.projectProcedureFileSystemId = props.projectProcedureFileSystemId
    }
  }

  /**
   * Map of records
   * @returns {Map<Number, RecordHit>}
   */
  recordMap() {
    const projectOrderedDocument = new Map(this.projectContainerStructure.records)
    projectOrderedDocument.forEach((value, key) => {
      value.origin = RecordHitOrigin.PROJECT_RECORDS
    })
    const companyOrderedDocuments = super.recordMap()
    companyOrderedDocuments.forEach((value, key) => {
      projectOrderedDocument.set(key, value)
    })
    return projectOrderedDocument
  }
}
