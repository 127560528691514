import angular from 'angular'
import router from 'angular-ui-router'

import './list.images.directive.scss'

import ListDrawingsDirCtrl from './list.drawings.directive.js'
import psFdvService from '../../../services/data/fdv.dataservice.js'
import psDownloadService from '../../../services/data/download.dataservice.js'

class ListImagesDirCtrl {
  constructor($stateParams, psFdvService) {
    this.projectId = $stateParams.id
    this.service = psFdvService
    this.getImages(this.projectId)
  }

  getImages(projectId) {
    this.service
      .fdvProjectImages(projectId)
      .then(response => {
        this.images = response.data.projectimages
      })
      .catch(error => {})
  }

  getComment(metadata) {
    return JSON.parse(metadata).comment
  }
}

ListImagesDirCtrl.$inject = ['$stateParams', 'psFdvService']

export default angular
  .module('ps.directives.list.images', [router, psFdvService])
  .controller('ListImagesDirCtrl', ListImagesDirCtrl)
  .controller('ListDrawingsDirCtrl', ListDrawingsDirCtrl)
  .directive('imagegallery', function () {
    return {
      restrict: 'E',
      template: require('./list.images.directive.tpl.html'),
      controller: 'ListImagesDirCtrl',
      controllerAs: 'vc'
    }
  })
  .directive('drawings', function () {
    return {
      restrict: 'E',
      template: require('./list.drawings.directive.tpl.html'),
      controller: 'ListDrawingsDirCtrl',
      controllerAs: 'vc'
    }
  }).name
