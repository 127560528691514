import { Project } from './project'

class Projects {
  list
  constructor(props) {
    this.list = props?.list?.map(item => new Project(item))
  }
}

export { Projects }
