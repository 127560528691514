import { FieldType } from './field-type'

/**
 * @typedef { object } Field
 * @property { object } value
 * @property { FieldType } type
 * @property { String } name
 * @property { number } sortOrder
 */
class Field {
  value
  type
  name
  sortOrder

  constructor(props) {
    this.value = props.value ? props.value : null
    this.type = new FieldType(props.type)
    this.name = props.name
    this.sortOrder = props.sortOrder
  }
}

export { Field }
