import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedures/manual`
}

function availableParagraphs(unitId) {
  return ifetch(url(unitId))
}

export { availableParagraphs }
