import { ifetch } from '@integrator/api'

function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/procedures/search`
}

function freeTextSearchInName(unitId, projectId, search) {
  return ifetch(url(unitId, projectId) + '/free-text-search-in-name', {
    method: 'POST',
    body: search
  })
}

function freeTextSearchInManual(unitId, projectId, search) {
  return ifetch(url(unitId, projectId) + '/free-text-search-in-manual', {
    method: 'POST',
    body: search
  })
}

function freeTextSearchInChecklist(unitId, projectId, search) {
  return ifetch(url(unitId, projectId) + '/free-text-search-in-checklist', {
    method: 'POST',
    body: search
  })
}

function freeTextSearchInFiles(unitId, projectId, search) {
  return ifetch(url(unitId, projectId) + '/free-text-search-in-files', {
    method: 'POST',
    body: search
  })
}

export {
  freeTextSearchInName,
  freeTextSearchInManual,
  freeTextSearchInChecklist,
  freeTextSearchInFiles
}
