import { ifetch, jwtToken } from '@integrator/api'
import { WopiHost } from './model'

function url(contextName, relationId) {
  return `/api/filesystem/${contextName}/${relationId}/wopi/host`
}

/**
 * @param { FileSystemContext } context
 * @param { Number } documentId
 *
 * @returns { WopiHost } wopi host
 */
function preparePreview(context, documentId) {
  return prepare(context, 'embedview', documentId)
}

/**
 *
 * @param { FileSystemContext } context
 * @param action
 * @param documentId
 *
 * @returns {*} WopiHost
 */
function prepare(context, action, documentId) {
  const token = jwtToken()
  const prepareUrl = url(context.name, context.relationId) + `/${action}/${documentId}/${token}`
  return ifetch(prepareUrl, { method: 'GET' })
}

export { preparePreview, prepare }
