import { Actions, ChecklistStatus, Definition, Persons, ExternalAccess } from '@integrator/api'
import { ChecklistOverview } from './checklist-overview'
import { ChecklistTemplate } from './checklist-template'
import { Checkpoint } from './checkpoint'

class Checklist {
  /** @type {Definition} */
  definition
  /** @type {ChecklistOverview} */
  overview
  /** @type {ChecklistStatus} */
  status
  /** @type {Actions} */
  actions
  /** @type {ExternalAccess} */
  externalAccess
  /** @type {Checkpoint[]} */
  checkpoints
  /** @type {ChecklistTemplate} */
  template
  /** @type {Persons} */
  doneBy
  /** @type {number} */
  id

  /**
   * @param {Definition} definition
   * @param {ChecklistOverview} overview
   * @param {ChecklistStatus} status
   * @param {Actions} actions
   * @param {ExternalAccess} externalAccess
   * @param {Checkpoint[]} checkpoints
   * @param {ChecklistTemplate} template
   * @param {Persons} doneBy
   * @param {number} id
   */

  constructor(props) {
    this.definition = new Definition(props.definition)
    this.overview = new ChecklistOverview(props.overview)
    this.status = new ChecklistStatus(props.status)
    this.actions = new Actions(props.actions)
    this.externalAccess = props.externalAccess
    this.checkpoints = props.checkpoints?.map(checkpoint => new Checkpoint(checkpoint))
    this.template = props.template ? new ChecklistTemplate(props.template) : undefined
    this.doneBy = props.doneBy ? new Persons(props.doneBy) : undefined
    this.id = props.id
  }

  clone() {
    return new Checklist(this)
    // alternative option
    // return new Checklist(JSON.parse(JSON.stringify(this)))
  }
}

export { Checklist }
