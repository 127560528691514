import { CheckpointOverview } from './checkpoint-overview'
import { Actions, Definition, Comment, CheckpointStatusName, CheckpointModeName } from '@integrator/api'

class Checkpoint {
  /** @type {number} */
  id
  /** @type {number} */
  checklistId
  /** @type {Definition} */
  definition
  /** @type {CheckpointModeName} */
  modeName
  /** @type {CheckpointStatusName} */
  statusName
  /** @type {Comment} */
  comment
  /** @type {number} */
  sortOrder
  /** @type {CheckpointOverview} */
  overview
  /** @type {string} */
  guidance
  /** @type {Actions} */
  actions

  /**
   * @param {Object|*} props
   */
  constructor(props = {}) {
    this.id = props.id
    this.checklistId = props.checklistId
    this.definition = new Definition(props.definition)
    this.modeName = new CheckpointModeName(props.modeName)
    this.statusName = new CheckpointStatusName(props.statusName)
    this.comment = new Comment(props.comment?.value || '')
    this.sortOrder = props.sortOrder
    this.overview = new CheckpointOverview(props.overview)
    this.guidance = props.guidance
    this.actions = new Actions(props.actions)
  }
}

export { Checkpoint }
