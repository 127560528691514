/**
 * @typedef { object } OperationTransaction
 */
import { Operation } from './operation'
import { LocalDateTime } from './local-date-time'

class OperationTransaction {
  /** @type {Array<Operation>} */
  events
  /** @type {Number} */
  userId
  /** @type {LocalDateTime} */
  startedAt
  /** @type {LocalDateTime} */
  endAt
  /** @type {String} */
  device
  /** @type {String} */
  transaction

  constructor(props) {
    this.events = props.events.map(event => new Operation(event))
    this.userId = props.userId
    this.startedAt = new LocalDateTime(props.startedAt)
    this.endAt = new LocalDateTime(props.endAt)
    this.device = props.device
    this.transaction = props.transaction
  }
}

export { OperationTransaction }
