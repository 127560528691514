import { FieldType } from './field-type'

/**
 * @typedef { Object } FieldTemplate
 * @property { string } name
 * @property { FieldType } type
 */
class FieldTemplate {
  name
  type
  constructor(props) {
    this.name = props.name
    this.type = new FieldType(props.type)
  }
}

export { FieldTemplate }
