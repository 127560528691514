import { Actions, LinkPreview, Definition } from '@integrator/api'

/**
 * @typedef {object} Link
 */
export class Link {
  /** @type {number} */
  id

  /** @type {boolean} */
  valid

  /** @type {Definition} */
  definition

  /** @type {string} */
  value

  /** @type {LinkPreview} */
  preview

  /** @type {Actions} */
  actions

  constructor(props) {
    if (props) {
      this.id = props.id
      this.valid = props.valid
      this.definition = new Definition(props.definition)
      this.value = props.value
      this.preview = new LinkPreview(props.preview)
      this.actions = new Actions(props.actions)
    }
  }
}
