/**
 * @typedef { object } WopiAccessToken
 * @property { String } value
 * @property { Number } createdAt
 * @property { Number } timeToLive
 */
class WopiAccessToken {
  value
  createdAt
  timeToLive

  constructor(props) {
    this.value = props.value
    this.createdAt = props.createdAt
    this.timeToLive = props.timeToLive
  }
}

export { WopiAccessToken }
