import { ifetch } from '@integrator/api'

function baseUrl(unitId) {
  return `/api/business/${unitId}/mom/standard`
}

function tfmComponents(unitId) {
  return ifetch(baseUrl(unitId) + `/tfm/components`, { method: 'GET' })
}

export { tfmComponents }
