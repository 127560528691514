import { ifetch } from '@integrator/api'

function baseUrl(elementId) {
  return `/api/mom/general-elements/${elementId}`
}

function element(elementId) {
  return ifetch(baseUrl(elementId), { method: 'GET' })
}

function getStandardText(elementId) {
  return ifetch(baseUrl(elementId) + `/standard-text`, { method: 'GET' })
}

// no response value
function updateStandardText(elementId, text) {
  return ifetch(baseUrl(elementId) + `/standard-text`, { method: 'POST', body: JSON.stringify(text) })
}

export { element, getStandardText, updateStandardText }
