import { CountedData } from '@integrator/api'

/**
 * @typedef {Object} TaskOverview
 * @property {CountedData} deviationProgress
 * @property {CountedData} checklistProgress
 */
class TaskOverview {
  deviationProgress
  checklistProgress

  constructor(props) {
    this.deviationProgress = new CountedData(props?.deviationProgress)
    this.checklistProgress = new CountedData(props?.checklistProgress)
  }
}

export { TaskOverview }
