import { css } from 'lit'

export const recordLineStyles = css`
  :host {
    width: 100%;
    display: flex;
    font-size: 16px;
  }
  .header-name {
    font-weight: bold;
  }
  .header-createdAt {
    font-weight: bold;
  }
  .header-createdBy {
    font-weight: bold;
  }
  .header-size {
    font-weight: bold;
  }
  .name {
    margin-right: 32em;
    padding-bottom: 0.4em;
  }
  .createdBy {
    right: 16em;
    position: absolute;
    width: 15em;
    padding: 0 1em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .createdAt {
    right: 7em;
    position: absolute;
  }
  .size {
    right: 1em;
    position: absolute;
  }
`
