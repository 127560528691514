import { ifetch } from '@integrator/api'

function baseUrl(unitId, collectionId) {
  return `/api/business/${unitId}/procedures/collections/${collectionId}`
}

function procedureIds(unitId, collectionId) {
  return ifetch(baseUrl(unitId, collectionId) + `/ids`, { method: 'GET' })
}

function structure(unitId, collectionId) {
  return ifetch(baseUrl(unitId, collectionId) + `/structure`, { method: 'GET' })
}
function assignedProcedureIds(unitId, collectionId) {
  return ifetch(baseUrl(unitId, collectionId) + `/assigned-procedure-ids`, { method: 'GET' })
}

function assignBusinessStructure(unitId, collectionId) {
  return ifetch(baseUrl(unitId, collectionId) + `/assign-structure`, { method: 'GET' })
}

function procedureCollectionSelection(unitId, collectionId, projectId) {
  return ifetch(baseUrl(unitId, collectionId) + `/assign-structure/${projectId}`, { method: 'GET' })
}

// no response value
function addProcedureId(unitId, procedureId, collectionId) {
  return ifetch(baseUrl(unitId, collectionId) + `/add/${procedureId}`, { method: 'POST' })
}

// no response value
function removeProcedureId(unitId, procedureId, collectionId) {
  return ifetch(baseUrl(unitId, collectionId) + `/remove/${procedureId}`, { method: 'POST' })
}

// no response value
function updateProceduresIds(unitId, collectionId, procedureIds) {
  return ifetch(baseUrl(unitId, collectionId) + `/update`, { method: 'POST', body: JSON.stringify(procedureIds) })
}

// no response value
function update(unitId, collectionId, definition) {
  return ifetch(baseUrl(unitId, collectionId), { method: 'POST', body: JSON.stringify(definition) })
}

// no response value
function remove(unitId, collectionId) {
  return ifetch(baseUrl(unitId, collectionId), { method: 'DELETE' })
}

export {
  procedureIds,
  structure,
  assignedProcedureIds,
  assignBusinessStructure,
  procedureCollectionSelection,
  addProcedureId,
  removeProcedureId,
  updateProceduresIds,
  update,
  remove
}
