import { Enum } from '../../universal'

export class DeviationManagementBy extends Enum {
  static INTERNAL = new DeviationManagementBy('INTERNAL')
  static EXTERNAL = new DeviationManagementBy('EXTERNAL')

  constructor(enumOrName = DeviationManagementBy.INTERNAL) {
    super(enumOrName.name ? enumOrName.name : enumOrName)
  }
}
