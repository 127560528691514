import { Contact } from '../../contact'
import { CustomerResponsiblePerson } from './customer-responsible-person'

class Customer {
  contact
  contactPersons
  constructor(props) {
    this.contact = new Contact(props?.contact)
    this.contactPersons = props?.contactPersons?.map(person => new CustomerResponsiblePerson(person))
  }
}

export { Customer }
