import { ifetch } from '@integrator/api'

function url(unitId, mainCategoryId, categoryId) {
  return `/api/business/${unitId}/procedures/main-categories/${mainCategoryId}/categories/${categoryId}`
}

function remove(unitId, mainCategoryId, categoryId) {
  return ifetch(url(unitId, mainCategoryId, categoryId), {
    method: 'DELETE'
  })
}

function update(unitId, mainCategoryId, categoryId, category) {
  return ifetch(url(unitId, mainCategoryId, categoryId), {
    method: 'POST',
    body: JSON.stringify(category)
  })
}

function activate(unitId, mainCategoryId, categoryId) {
  return ifetch(url(unitId, mainCategoryId, categoryId) + `/activate`, {
    method: 'POST'
  })
}

function deactivate(unitId, mainCategoryId, categoryId) {
  return ifetch(url(unitId, mainCategoryId, categoryId) + `/deactivate`, {
    method: 'POST'
  })
}

export { remove, update, activate, deactivate }
