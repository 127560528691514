import angular from 'angular'
import angularMaterial from 'angular-material'
import HeaderIcon from '../icons/module/icon.module.header.js'
import dialog from '../../services/dialogs/controls/dialogs.checklist'

export default angular
  .module('ps.directives.header.project', [angularMaterial, HeaderIcon])
  .directive('projectheader', function () {
    return {
      restrict: 'E',
      template: require('./project.info.header.tpl.html'),
      scope: {
        project: '=',
        unitId: '=',
        icon: '@',
        modulename: '@'
      }
    }
  }).name
