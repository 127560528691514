import { html, css, LitElement } from 'lit'

import { projectProcedureApi } from '@integrator/api'
import '@iwc/common/src/editor/editor-view'

export class ProcedureManual extends LitElement {
  static properties = {
    input: {
      type: Object
    },
    manual: {
      type: Object,
      state: true
    }
  }

  static styles = css`
    .manual-paragraph-header {
      font-size: 18px;
      font-weight: bold;
    }
  `

  firstUpdated(changedProperties) {
    if (this.input) {
      this.getManual()
    }
  }

  getManual() {
    projectProcedureApi
      .manual(this.input.unitId, this.input.project_id, this.input.procedure_id)()
      .then(resp => {
        this.manual = resp
      })
  }

  render() {
    if (this.manual) {
      return html`
        <div>
          ${this.manual.definition.paragraphs.map(
            paragraphDefinition => html`
              <div class="manual-paragraph-header">${paragraphDefinition.name}</div>
              ${this.renderTemplate(paragraphDefinition)} ${this.renderBusiness(paragraphDefinition)}
              ${this.renderProject(paragraphDefinition)}
            `
          )}
        </div>
      `
    }
  }

  renderTemplate(paragraphDefinition) {
    return this.renderValue('TEMPLATE', paragraphDefinition.id)
  }

  renderBusiness(paragraphDefinition) {
    return this.renderValue('BUSINESS', paragraphDefinition.id)
  }

  renderProject(paragraphDefinition) {
    return this.renderValue('PROJECT', paragraphDefinition.id)
  }

  renderValue(level, paragraphDefinitionId) {
    const paragraph = this.manual.paragraphs.map[paragraphDefinitionId]
    if (paragraph) {
      const textValue = paragraph.text.values[level]
      if (textValue && textValue.approved.view) {
        return html`
          <i-editor-view .content=${textValue.approved.view}></i-editor-view>
        `
      }
    }
  }
}

customElements.define('ra-procedure-manual', ProcedureManual)
