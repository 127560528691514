import { ifetch } from '@integrator/api'

function url(unitId, projectId, controlId) {
  return `/api/business/${unitId}/projects/${projectId}/periodic-control/controls/${controlId}/pdf`
}

function getData(unitId, projectId, controlId) {
  return ifetch(url(unitId, projectId, controlId) + '/data')
}
function getConfig(unitId, projectId, controlId) {
  return ifetch(url(unitId, projectId, controlId) + '/config')
}

function addConfig(unitId, projectId, controlId, pdfSetup) {
  return ifetch(url(unitId, projectId, controlId) + '/config', {
    method: 'POST',
    body: JSON.stringify(pdfSetup)
  })
}

function download(unitId, projectId, controlId) {
  window.open(`/ny` + url(unitId, projectId, controlId) + '/download?token=' + window.jwtToken)
}

function savePdf(unitId, projectId, controlId) {
  return ifetch(url(unitId, projectId, controlId) + '/save', { method: 'POST' })
}

export { getData, addConfig, getConfig, download, savePdf }
