class GpsCoordinates {
  latitude
  longitude
  constructor(props) {
    this.latitude = props?.latitude
    this.longitude = props?.longitude
  }
}

export { GpsCoordinates }
