import { ifetch } from '@integrator/api'

function url(unitId, userId) {
  return `/api/business/${unitId}/users/${userId}`
}

/**
 * Get user with id.
 *
 * @param unitId
 * @param userId
 */
function get(unitId, userId) {
  return ifetch(url(unitId, userId))
}

export { get }
