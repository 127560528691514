import { ifetch } from '@integrator/api'

function baseUrl(contextName, relationId, recordId) {
  return `/api/filesystems/${contextName}/${relationId}/records/${recordId}/network`
}

function linkTo(contextName, relationId, recordId, destinationFs) {
  return ifetch(baseUrl(contextName, relationId, recordId) + `/connect`, {
    method: 'POST',
    body: JSON.stringify(destinationFs)
  })
}

// no response value
function linkRemoveFrom(contextName, relationId, recordId, sourceFs) {
  return ifetch(baseUrl(contextName, relationId, recordId) + `/disconnect`, {
    method: 'POST',
    body: JSON.stringify(sourceFs)
  })
}

export { linkRemoveFrom, linkTo }
