if (!Date.fromArray) {
  var timeUnitIndexes = [
    function (date, value) {
      date.setFullYear(value)
    },
    function (date, value) {
      date.setMonth(value - 1)
    },
    function (date, value) {
      date.setDate(value)
    },
    function (date, value) {
      date.setHours(value)
    },
    function (date, value) {
      date.setMinutes(value)
    },
    function (date, value) {
      date.setSeconds(value)
    },
    function (date, value) {}
  ]
  Date.fromArray = function (array) {
    var date = new Date()
    for (var i = 0; i < array.length; i++) {
      var f = timeUnitIndexes[i]
      if (f) {
        f(date, array[i])
      }
    }
    return date
  }
}

if (!Date.prototype.toArray) {
  Date.prototype.toArray = function () {
    return [this.getFullYear(), this.getMonth() + 1, this.getDate()]
  }
}
