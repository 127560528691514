import { CountedData } from '@integrator/api'

/**
 * @typedef {Object} ControlOverview
 * @property {number} componentsCount
 * @property {number} measurementProtocolsCount
 * @property {number} checklistsCount
 * @property {number} progressPercentage
 * @property {CountedData} deviationSeverityStatistic
 * @property {CountedData} deviationProgress
 * @property {CountedData} checklistProgress
 * @property {CountedData} measurementsProgress
 */
class ControlOverview {
  componentsCount
  measurementProtocolsCount
  checklistsCount
  progressPercentage
  deviationSeverityStatistic
  deviationProgress
  checklistProgress
  measurementsProgress

  constructor(props) {
    if (props) {
      this.componentsCount = props?.componentsCount
      this.measurementProtocolsCount = props?.measurementProtocolsCount
      this.checklistsCount = props?.checklistsCount
      this.progressPercentage = props?.progressPercentage
    }
    this.deviationSeverityStatistic = new CountedData(props?.deviationSeverityStatistic)
    this.deviationProgress = new CountedData(props?.deviationProgress)
    this.checklistProgress = new CountedData(props?.checklistProgress)
    this.measurementsProgress = new CountedData(props?.measurementsProgress)
  }
}

export { ControlOverview }
