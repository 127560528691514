import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/employees/leave`
}

function overview(businessUnitId, body) {
  return ifetch(url(businessUnitId) + `/overview`, {
    method: 'POST',
    body: JSON.stringify(body)
  })
}

function absences(businessUnitId, year) {
  return ifetch(url(businessUnitId) + `/overview/${year}`, { method: 'GET' })
}

//@Deprecated
function randomAbsence(businessUnitId, year) {
  return ifetch(url(businessUnitId) + `/random-absence/${year}`, { method: 'GET' })
}

export { overview, absences, randomAbsence }
