import { ifetch } from '@integrator/api'

function url(unitId, installationTypeId) {
  return `/api/business/${unitId}/settings/periodic-control/installation-types/${installationTypeId}`
}

function update(unitId, installationTypeId, definitionJson) {
  return ifetch(url(unitId, installationTypeId), {
    method: 'PATCH',
    body: JSON.stringify(definitionJson)
  })
}

function changeDescription(unitId, installationTypeId, description) {
  return ifetch(url(unitId, installationTypeId) + '/description', {
    method: 'PATCH',
    body: description
  })
}

function activate(unitId, installationTypeId) {
  return ifetch(url(unitId, installationTypeId) + '/activate', {
    method: 'PATCH'
  })
}

function deactivate(unitId, installationTypeId) {
  return ifetch(url(unitId, installationTypeId) + '/deactivate', {
    method: 'PATCH'
  })
}

function deleteInstallationType(unitId, installationTypeId) {
  return ifetch(url(unitId, installationTypeId), {
    method: 'delete'
  })
}

function details(unitId, installationTypeId) {
  return ifetch(url(unitId, installationTypeId) + '/details')
}

export { activate, changeDescription, deleteInstallationType, deactivate, update, details }
