/**
 * @typedef {Object} ProcedureLevel
 * @property {string} name
 */
export class ProcedureLevel {
  static TEMPLATE = new ProcedureLevel('TEMPLATE')
  static BUSINESS = new ProcedureLevel('BUSINESS')
  static PROJECT = new ProcedureLevel('PROJECT')
  static INVALID = new ProcedureLevel('INVALID')

  constructor(props) {
    if (props instanceof ProcedureLevel) {
      this.name = props.name
    } else {
      this.name = props
    }
  }

  toJSON() {
    return this.name
  }

  toString() {
    return this.name
  }
}
