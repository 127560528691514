import { Enum } from '../../universal'

class DeviationStatusName extends Enum {
  static NEW = new DeviationStatusName('NEW')
  static OPEN = new DeviationStatusName('OPEN')
  static CLOSED = new DeviationStatusName('CLOSED')

  constructor(name) {
    super(name)
  }
}

export { DeviationStatusName }
