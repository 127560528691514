import { Enum } from '@integrator/api'

class TaskStatusName extends Enum {
  static PLANNED = new TaskStatusName('PLANNED')
  static IN_PROGRESS = new TaskStatusName('IN_PROGRESS')
  static DONE = new TaskStatusName('DONE')

  constructor(name = TaskStatusName.PLANNED.name) {
    super(name)
  }

  static getNameByValue(value) {
    return value === 0 ? TaskStatusName.PLANNED : value === 100 ? TaskStatusName.DONE : TaskStatusName.IN_PROGRESS
  }
}

export { TaskStatusName }
