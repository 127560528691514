'use strict'

/**
 * Controller for startview for Periodic Controls
 *
 * @author Rannveig A.Skjerve (rannveig.a.skjerve@gmail.com)
 */

class PeriodicControlsCtrl {
  constructor($stateParams, $state, projects) {
    this.$state = $state
    this.$stateParams = $stateParams
    this.projects = projects
  }

  isDetailState() {
    return this.$state.current.name !== 'periodic.parent'
  }
}

PeriodicControlsCtrl.$inject = ['$stateParams', '$state', 'projects']

export default PeriodicControlsCtrl
