import { Deviation } from './deviation'
import { NameIdentity } from '../../universal'
import { DeviationReports } from './deviation-reports'
import { DeviationNotifications } from './deviation-notifications'
import { BusinessUser } from '../../user'

class DeviationDetails {
  deviation
  reports
  notifications
  involvedUsers
  relationObjects
  constructor(props) {
    this.involvedUsers = new Map()
    this.relationObjects = new Map()
    if (props) {
      this.deviation = new Deviation(props.deviation)
      this.reports = new DeviationReports(props.reports)
      this.notifications = new DeviationNotifications(props.notifications)
      Object.entries(props.involvedUsers)?.forEach(([key, val]) => this.involvedUsers.set(key, new BusinessUser(val)))
      Object.entries(props.relationObjects)?.forEach(([key, val]) =>
        this.relationObjects.set(key, new NameIdentity(val))
      )
    }
  }
}

export { DeviationDetails }
