import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/users`
}

function getAllUsers(unitId) {
  return ifetch(url(unitId))
}

function getAccountUsers(unitId, accounts) {
  const query = new URLSearchParams({ 'accountStatusNames[]': accounts })
  return ifetch(url(unitId) + `/by-account-status-name?${query}`)
}

function getByAccountRoleNames(unitId, accountRoleNames) {
  const query = new URLSearchParams({ 'accountRoleNames[]': accountRoleNames })
  return ifetch(url(unitId) + `/by-account-role-name?${query}`)
}

function getWebUsers(unitId) {
  return ifetch(url(unitId) + '/web')
}

export { getAllUsers, getWebUsers, getAccountUsers, getByAccountRoleNames }
