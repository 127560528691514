'use strict'
/**
 * Data service for read access User.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import constants from '../../../config/config.constants.js'
const moduleName = 'userDataService'

// Data service
class UserDataService {
  constructor($http, $log, apiConfig, $rootScope, $q) {
    this.$http = $http
    this.$log = $log
    this.user = undefined
    this.unit = undefined
    this.endpoint = new UserEndpoint(apiConfig)
    this.$scope = $rootScope
    this.$q = $q
  }

  /**
   * XHR GET for current user.
   * @returns {*}
   */
  get() {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.get(), { cache: true })
      .then(response => {
        this.user = response.data.person
        this.unit = response.data.unit

        asyncResult.resolve(response.data)
      })
      .catch(error => {
        this.$log.error(moduleName + ' XHR failed for GET /user with error: ' + error.status + ' ' + error.statusText)
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }
}
UserDataService.$inject = ['$http', '$log', 'apiConfig', '$rootScope', '$q']

// Endpoint definition
class UserEndpoint {
  constructor(apiConfig) {
    this.path = apiConfig.url() + 'session/light'
  }

  get() {
    return this.path
  }
}
UserEndpoint.$inject = ['apiConfig']

/**
 * Export this service as a separate module.
 */
export default angular.module('service.data.user', [constants]).service(moduleName, UserDataService).name
