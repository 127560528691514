import { Integration } from './integration'

class Integrations {
  integrations
  constructor(props) {
    this.integrations = new Map()
    if (props?.integrations) {
      Object.entries(props.integrations)?.forEach(([key, val]) => this.integrations.set(key, new Integration(val)))
    }
  }
}

export { Integrations }
