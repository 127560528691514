class TaskCompletion {
  active
  doneDate
  nextDate

  constructor(props) {
    this.doneDate = props?.doneDate
    this.active = props?.active
    this.nextDate = props?.nextDate
  }
}

export { TaskCompletion }
