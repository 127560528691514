import { NameIdentity, Person } from '../../universal'
import { IndependenceLevel } from './independence-level'

class PeriodicControlDefinition {
  priority
  timeEstimate
  independenceLevel
  comment
  responsiblePerson
  controlTypes
  constructor(props) {
    if (props) {
      this.priority = props.priority
      this.timeEstimate = props.timeEstimate
      this.independenceLevel = props.independenceLevel ? new IndependenceLevel(props.independenceLevel) : undefined
      this.comment = props.comment
      this.responsiblePerson = new Person(props.responsiblePerson)
      this.controlTypes = props.controlTypes?.map(type => new NameIdentity(type))
    }
  }
}

export { PeriodicControlDefinition }
