import { Procedure, ProcedureCategoryTree, ProcedureCollectionDefinition } from '@integrator/api'

/**
 * Represents a structure for selecting procedure collections.
 */
export class ProcedureCollectionSelectionStructure {
  /**
   * The definition of the procedure collection.
   * @type {ProcedureCollectionDefinition}
   */
  definition

  /**
   * The category tree of procedures.
   * @type {ProcedureCategoryTree}
   */
  categoryTree

  /**
   * The procedures within the collection.
   * @type {Map<number, Procedure>}
   */
  procedures

  /**
   * Indicates whether procedures are assigned.
   * @type {Array<number>}
   */
  assigned

  /**
   * The selection status of the collection.
   * @type {Map<number, boolean>}
   */
  selection

  constructor(props) {
    if (props) {
      this.definition = new ProcedureCollectionDefinition(props?.definition)
      this.categoryTree = new ProcedureCategoryTree(props?.categoryTree)
      this.procedures = new Map()
      for (const [key, value] of Object.entries(props?.procedures)) {
        this.procedures.set(Number(key), new Procedure(value))
      }
      this.assigned = props?.assigned
      this.selection = new Map()
      if (props?.selection) {
        Object.entries(props?.selection?.map)?.forEach(([key, val]) => this.selection.set(Number(key), !!val))
      }
    }
  }
}
