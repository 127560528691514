class ProjectFolder {
  id
  name
  description
  children
  parentId

  constructor(props) {
    this.id = props?.id
    this.name = props?.name
    this.description = props?.description
    this.children = props?.children?.map(folder => new ProjectFolder(folder))
    this.parentId = props?.parentId
  }
}

export { ProjectFolder }
