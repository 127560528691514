import { ifetch } from '@integrator/api'

function url(unitId, contactId) {
  return `/api/business/${unitId}/contacts/${contactId}`
}

function getContact(contactId, businessUnitId) {
  return ifetch(url(businessUnitId, contactId), {
    method: 'GET'
  })
}

function saveContact(businessUnitId, contact) {
  return ifetch(url(businessUnitId, contact.organization.id), {
    method: 'PUT',
    body: JSON.stringify(contact)
  })
}

function archiveContact(businessUnitId, contact) {
  return ifetch(url(businessUnitId, contact.organization.id) + `/archive`, {
    method: 'PATCH'
  })
}

function activateContact(businessUnitId, contact) {
  return ifetch(url(businessUnitId, contact.organization.id) + `/activate`, {
    method: 'PATCH'
  })
}

function deleteContact(businessUnitId, contact) {
  return ifetch(url(businessUnitId, contact.organization.id), {
    method: 'DELETE'
  })
}

function getCoordinates(businessUnitId, contactId) {
  return ifetch(url(businessUnitId, contactId) + `/discover-coordinates`, {
    method: 'PATCH'
  })
}

export { getContact, getCoordinates, saveContact, archiveContact, activateContact, deleteContact }
