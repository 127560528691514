import { ifetch } from '@integrator/api'

function url(unitId, projectId, contextName, procedureId) {
  return `/api/business/${unitId}/projects/${projectId}/procedure/${contextName}/${procedureId}/files`
}

function files(unitId, projectId, contextName, procedureId) {
  return ifetch(url(unitId, projectId, contextName, procedureId))
}

export { files }
