import { ifetch } from '@integrator/api'

function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}`
}

function get(unitId, projectId) {
  return ifetch(url(unitId, projectId))
}

function update(unitId, projectId, project) {
  return ifetch(url(unitId, projectId), {
    method: 'PATCH',
    body: JSON.stringify(project)
  })
}

function deleteProject(unitId, projectId) {
  return ifetch(url(unitId, projectId), {
    method: 'DELETE'
  })
}

function complete(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/complete', {
    method: 'PATCH'
  })
}

function unComplete(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/unComplete', {
    method: 'PATCH'
  })
}

export { get, update, deleteProject, complete, unComplete }
