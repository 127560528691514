import { ifetch, integratorUrl } from '@integrator/api'

function url(contextName, relationId, recordId) {
  return `/api/filesystems/${contextName}/${relationId}/records/${recordId}`
}

function preview(contextName, relationId, recordId, size) {
  return ifetch('/ny/' + url(contextName, relationId, recordId) + '/preview/' + size)
}

function encrypt(contextName, relationId, recordId, password) {
  const options = { method: 'POST', body: password }
  return ifetch(url(contextName, relationId, recordId) + '/encrypt', options)
}

function deleteRecord(contextName, relationId, recordId) {
  const options = { method: 'DELETE' }
  return ifetch(url(contextName, relationId, recordId), options)
}

function changeName(contextName, relationId, recordId, newName) {
  const options = { method: 'PATCH', body: newName }
  return ifetch(`${url(contextName, relationId, recordId)}/name`, options)
}

function changeDescription(contextName, relationId, recordId, newDescription) {
  const options = { method: 'PATCH', body: newDescription }
  return ifetch(`${url(contextName, relationId, recordId)}/description`, options)
}

function changeAttributes(contextName, relationId, recordId, attributes) {
  const options = { method: 'PATCH', body: JSON.stringify(attributes) }
  return ifetch(`${url(contextName, relationId, recordId)}/attributes`, options)
}

function downloadLink(contextName, relationId, recordId) {
  return integratorUrl() + '/ny' + url(contextName, relationId, recordId) + '/data'
}

function detail(contextName, relationId, recordId) {
  return ifetch(url(contextName, relationId, recordId) + '/details', { method: 'GET' })
}

export { preview, encrypt, deleteRecord, changeName, changeDescription, changeAttributes, downloadLink, detail }
