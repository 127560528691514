import { OrganizationNumber } from './organization-number'
import { OrganizationRegister } from './organization-register'

class Organization {
  id
  name
  number
  register
  email
  phone
  constructor(props) {
    if (props) {
      this.id = props.id
      this.name = props.name
      this.number = new OrganizationNumber(props.number)
      this.register = new OrganizationRegister(props.register)
      this.email = props.email
      this.phone = props.phone
    }
  }
}

export { Organization }
