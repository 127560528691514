import { ifetch } from '@integrator/api'

function url(unitId, userId) {
  return `/api/business/${unitId}/employees/${userId}/sick-leave/settings`
}

function employeeSettings(unitId, userId) {
  return ifetch(url(unitId, userId), { method: 'GET' })
}

function updateEmployeeSettings(unitId, userId, employeeStatus) {
  let detail = {
    //TODO[check when specialAgreement should be 'false']
    specialAgreement: true,
    specialAgreementExtraDays: employeeStatus.agreementExtraDays,
    sideSelfDeclaration: true,
    sideSelfDeclarationCount: employeeStatus.sideSelfDeclarationCount,
    sideSelfDeclarationDate: employeeStatus.sideSelfDeclarationDate,
    transferDays: true,
    transferDaysCount: employeeStatus.transferExtraDays,
    sideDoctorDeclarationCount: employeeStatus.sideDoctorDeclarationCount,
    sideChildDeclarationCount: employeeStatus.sideChildDeclarationCount
  }

  return ifetch(url(unitId, userId), { method: 'POST', body: JSON.stringify(detail) })
}

function addChild(unitId, userId, body) {
  return ifetch(url(unitId, userId) + `/children`, { method: 'POST', body: JSON.stringify(body) })
}

function updateChild(unitId, userId, child) {
  return ifetch(url(unitId, userId) + `/children/${child.id}`, { method: 'POST', body: JSON.stringify(child) })
}

function deleteChild(unitId, userId, child) {
  return ifetch(url(unitId, userId) + `/children/${child.id}`, { method: 'DELETE' })
}

export { employeeSettings, updateEmployeeSettings, addChild, updateChild, deleteChild }
