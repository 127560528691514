import { integratorUrl, jwtToken } from '../ifetch'

function url(unitId, procedureId) {
  return `/api/business/${unitId}/procedures/${procedureId}/pdf`
}

function download(unitId, procedureId) {
  window.open(`${integratorUrl()}/ny${url(unitId, procedureId)}/link?token=${jwtToken()}`)
}

export { download }
