import { Regulation } from './regulation'

class Regulations {
  list
  constructor(props) {
    this.list = props?.list?.map(item => new Regulation(item))
  }
}

export { Regulations }
