import { ifetch } from '@integrator/api'

function url(businessUnitId, employeeId) {
  return `/api/business/${businessUnitId}/employees/${employeeId}/competences`
}

function employeeCourses(businessUnitId, employeeId) {
  return ifetch(url(businessUnitId, employeeId))
}

function periodicControlCertificates(businessUnitId, employeeId, controlTypeNames) {
  return ifetch(
    url(businessUnitId, employeeId) +
      '/certificates/periodic-control?' +
      new URLSearchParams({ controlTypeNames: controlTypeNames })
  )
}

export { employeeCourses, periodicControlCertificates }
