import { ifetch } from '@integrator/api'

function url() {
  return `/api/deviations/pdf`
}

function getConfig(relationName, relationId) {
  return ifetch(url() + `/get-config/${relationName}/${relationId}`)
}

export { getConfig }
