'use strict'

import router from 'angular-ui-router'

/**
 * Routes for FDV module.
 * @author Rannveig A. Skjerve (rannveig.a.skjerve@gmail.com)
 */

let routes = function ($stateProvider) {
  $stateProvider
    .state('fdv', {
      url: '/fdv',
      parent: 'unit',
      abstract: true,
      template: '<ui-view class="full-height"></ui-view>'
    })
    .state('fdv.parent', {
      url: '',
      parent: 'fdv',
      template: require('./fdv.parent.tpl.html'),
      authenticate: true,
      controller: 'FdvParentCtrl',
      controllerAs: 'vc',
      resolve: {
        projects: [
          'psProjectService',
          function (service) {
            return service.list()
          }
        ]
      },
      ncyBreadcrumb: {
        parent: 'fdv',
        label: "{{'FDV' | translate}}"
      }
    })
    .state('fdv.detail', {
      url: '/:id',
      parent: 'fdv.parent',
      template: require('./detail/fdv.detail.tpl.html'),
      controller: 'FdvDetailCtrl',
      controllerAs: 'vc',
      resolve: {
        project: [
          'psProjectService',
          '$stateParams',
          function (service, $stateParams) {
            return service.getProject($stateParams.id)
          }
        ]
      },
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('fdv.detail.administration', {
      parent: 'fdv.detail',
      template: require('./detail/fdv.detail.administration.html'),
      controller: 'FdvDetailCtrl',
      controllerAs: 'vc',
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('fdv.detail.components', {
      parent: 'fdv.detail',
      template: require('./detail/component/fdv.detail.component.tpl.html'),
      controller: 'FdvComponentCtrl',
      controllerAs: 'vc',
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('fdv.detail.documents', {
      parent: 'fdv.detail',
      template: require('../documents/documents.list.tpl.html'),
      controller: 'DocumentListCtrl',
      controllerAs: 'vc',
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('fdv.detail.images', {
      parent: 'fdv.detail',
      template: '<imagegallery></imagegallery>',
      ncyBreadcrumb: {
        skip: true
      }
    })
    .state('fdv.detail.drawings', {
      parent: 'fdv.detail',
      template: '<drawings></drawings>',
      ncyBreadcrumb: {
        skip: true
      }
    })
}

routes.$inject = ['$stateProvider']

export default routes
