import { DeviationField } from './deviation-field'

class DeviationFields {
  list
  constructor(props) {
    this.list = props?.list?.map(item => new DeviationField(item)) || []
  }
}

export { DeviationFields }
