class ControlStatus {
  name
  doneDate
  conclusion
  constructor(props) {
    if (props) {
      this.name = props.name
      this.doneDate = props.doneDate
      this.conclusion = props.conclusion
    }
  }
}

export { ControlStatus }
