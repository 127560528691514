class ControlType {
  id
  name
  active
  description
  constructor(props) {
    if (props) {
      this.id = props.id
      this.name = props.name
      this.active = props.active
      this.description = props.description
    }
  }
}

export { ControlType }
