import { ifetch } from '@integrator/api'

function url(unitId, groupId) {
  return `/api/business/${unitId}/competences/groups/${groupId}`
}

function deleteGroup(unitId, groupId) {
  return ifetch(url(unitId, groupId), { method: 'DELETE' })
}

function changeGroup(unitId, groupId, group) {
  return ifetch(url(unitId, groupId), { method: 'PUT', body: JSON.stringify(group) })
}

function changeGroupColor(unitId, groupId, color) {
  return ifetch(url(unitId, groupId) + '/color', { method: 'PATCH', body: color })
}

function newCompetency(unitId, groupId, competency) {
  return ifetch(url(unitId, groupId), { method: 'POST', body: JSON.stringify(competency) })
}

function moveCompetency(unitId, groupId, competencyId, order) {
  return ifetch(url(unitId, groupId) + `/move/${competencyId}`, { method: 'POST', body: JSON.stringify(order) })
}

export { deleteGroup, changeGroup, changeGroupColor, newCompetency, moveCompetency }
