import { html, LitElement } from 'lit'

/**
 */
export class ContainerLine extends LitElement {
  static properties = {
    container: { type: Object }
  }

  firstUpdated(_changeProperties) {}

  render() {
    return html`
      <span style="font-size: 16px">${this.container.name}</span>
    `
  }
}

customElements.define('i-container-line', ContainerLine)
