import * as businessUnitInstallationTypesApi from './business-unit-installation-types-api'
import * as regulationApi from './business-unit-regulation-api'
import * as regulationsApi from './business-unit-regulations-api'
import * as controlTypesApi from './control-types-api'
import * as controlTypeApi from './control-type-api'
import * as controlApi from './control-api'
import * as controlsApi from './controls-api'
import * as geonorgeSearchApi from './geonorge-search-api'
import * as componentTemplatesApi from './component-templates-api'
import * as componentTemplateApi from './component-template-api'
import * as installationTypesApi from './installation-types-api'
import * as installationTypeApi from './installation-type-api'
import * as periodicControlPdfApi from './periodic-control-pdf-api'
import * as projectControlsApi from './project-controls-api'
import * as projectPeriodicControlApi from './project-periodic-control-api'
import * as projectsPeriodicControlApi from './projects-periodic-control-api'
import * as componentsApi from './components-api'
import * as componentPdfApi from './component-pdf-api'
import * as componentChecklistApi from './component-checklist-api'
import * as controlGlobalApi from './controls-global-api'

export * from './model'
export {
  businessUnitInstallationTypesApi,
  regulationApi,
  regulationsApi,
  controlTypesApi,
  controlTypeApi,
  controlApi,
  controlsApi,
  geonorgeSearchApi,
  componentTemplatesApi,
  componentTemplateApi,
  installationTypesApi,
  installationTypeApi,
  periodicControlPdfApi,
  projectControlsApi,
  projectPeriodicControlApi,
  projectsPeriodicControlApi,
  componentsApi,
  componentPdfApi,
  componentChecklistApi,
  controlGlobalApi
}
