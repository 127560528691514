'use strict'

// Imports
import angular from 'angular'
import router from 'angular-ui-router'
import FileSaver from 'angular-file-saver'
import ApiConstants from '../../../config/config.constants.js'

/**
 * Data service for /downloads.
 * Downloading of files.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
class DownloadService {
  constructor($http, $log, $q, FileSaver, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.FileSaver = FileSaver
    this.endpoint = new DownloadEndpoint(apiConfig)
  }

  /**
   * Download document with Id.
   * We cache document downloads in read access.
   * @param id project id
   */
  getDocument(id, fileName) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.get(id), { responseType: 'arraybuffer', cache: true })
      .then(response => {
        asyncResult.resolve(response)
        var byteArray = new Uint8Array(response.data)
        var contentType = response.headers('Content-Type')
        var file = new Blob([byteArray], { type: contentType })
        this.FileSaver.saveAs(file, fileName)
      })
      .catch(error => {
        this.$log.error('XHR failed for ' + this.endpoint.get())
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }

  getOpenDownloadUrl(id, fileName) {
    let token = Cookies.get(globalThis.JWT_COOKIE_NAME)
    return this.endpoint.getOpenDownload(id, fileName, token)
  }
}

DownloadService.$inject = ['$http', '$log', '$q', 'FileSaver', 'apiConfig']

class DownloadEndpoint {
  constructor(apiConfig) {
    this.path = apiConfig.completeUrl() + '/download'
  }

  get(id) {
    return this.path + '/' + id
  }

  getOpenDownload(id, fileName, token) {
    return this.path + '/open/' + id + '/' + fileName + '?jwt=' + token
  }
}

/**
 * Export projects data service.
 */
export default angular
  .module('ps.service.data.download', [router, 'ngFileSaver', ApiConstants])
  .service('psDownloadService', DownloadService).name
