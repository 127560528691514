import { RegulationDefinition } from './regulation-definition'

class Regulation {
  id
  definition
  active
  description
  constructor(props) {
    if (props) {
      this.id = props.id
      this.definition = new RegulationDefinition(props.definition)
      this.active = props.active
      this.description = props.description
    }
  }
}

export { Regulation }
