import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/generate`
}

// no response value
function full(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/full-simple`, { method: 'GET' })
}

// no response value
function generate(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/tfm`, { method: 'GET' })
}

export { full, generate }
