import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/settings/vacation`
}

function getVacationCountingRules(unitId) {
  return ifetch(url(unitId), { method: 'GET' })
}

function updateVacationCountingRules(unitId, vacationCountingRules) {
  return ifetch(url(unitId), { method: 'POST', body: vacationCountingRules })
}

function createVacationPeriod(unitId, vacationPeriod) {
  return ifetch(url(unitId) + `/periods`, { method: 'POST', body: vacationPeriod })
}

function updateVacationPeriod(unitId, vacationPeriodId, vacationPeriod) {
  return ifetch(url(unitId) + `/periods/${vacationPeriodId}`, { method: 'POST', body: vacationPeriod })
}

function deleteVacationPeriod(unitId, vacationPeriodId) {
  return ifetch(url(unitId) + `/periods/${vacationPeriodId}`, { method: 'DELETE' })
}

function getVacationPeriods(unitId) {
  return ifetch(url(unitId) + '/periods', { method: 'GET' })
}

export {
  getVacationCountingRules,
  updateVacationCountingRules,
  createVacationPeriod,
  updateVacationPeriod,
  deleteVacationPeriod,
  getVacationPeriods
}
