import Cookies from 'js-cookie'

function ifetch(url, options, action) {
  const contextPath = '/ny'

  function prepareUrl(url, action) {
    url = url.startsWith('/') ? url : '/' + url
    action = action ? '' + action : ''
    action = action.startsWith('/') ? action : '/' + action
    action = action.endsWith('/') ? action.substring(0, action.length - 1) : action
    return integratorUrl() + contextPath + url + action
  }

  async function execute(resultOptions) {
    const requestUrl = prepareUrl(url, action)
    const authorizationToken = 'Bearer ' + jwtToken()

    let opts = {}
    opts.headers = {
      'Content-Type': 'application/json',
      Authorization: authorizationToken
    }
    Object.assign(opts, options)
    if (!opts.headers['Authorization']) {
      opts.headers['Authorization'] = authorizationToken
    }

    const _fetch = await fetch(requestUrl, opts)
    if (_fetch.status === 500) {
      const error = await _fetch.json()
      return Promise.reject(error)
    }
    if (_fetch.status === 404) {
      return Promise.reject('Resource is not available 404')
    }
    if (_fetch.status === 401) {
      return Promise.reject('Unauthorized 401')
    }

    if (resultOptions && resultOptions.noJson) {
      return _fetch.text()
    } else {
      return _fetch.json()
    }
  }

  return execute
}

function jwtToken() {
  const jwtToken = Cookies.get(globalThis.JWT_COOKIE_NAME)
  return jwtToken ? jwtToken : window.jwtToken
}

function integratorUrl() {
  return globalThis.INTEGRATOR_URL || ''
}

export { ifetch, jwtToken, integratorUrl }
