import { ChecklistTemplate } from './checklist-template'

const compareFn = (a, b) => a.definition.name.localeCompare(b.definition.name)

class BaseChecklist {
  /** @type {Array<ChecklistTemplate | Checklist>} */
  list

  /**
   * @param {Array<ChecklistTemplate | Checklist>} checklists
   */
  constructor() {
    this.list = []
  }

  sortByDefinitionName() {
    this.list.sort(compareFn)
  }

  /**
   * @param {Checklist} checklist
   */
  remove(checklist) {
    this.list = this.list?.filter(item => item.id !== checklist.id)
  }
}

export { BaseChecklist }
