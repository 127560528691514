import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/products`
}

function connectProduct(unitId, projectId, sourceName, productId) {
  return ifetch(baseUrl(unitId, projectId) + `/connect/${sourceName}/${productId}`, { method: 'POST' })
}

function uploadProducts(unitId, projectId, sourceName, recordId) {
  return ifetch(baseUrl(unitId, projectId) + `/process/${sourceName}/${recordId}`, { method: 'POST' })
}

function connectProductToProducts(unitId, projectId, sourceName, externalProducts) {
  return ifetch(baseUrl(unitId, projectId) + `/process/connect/${sourceName}`, {
    method: 'POST',
    body: JSON.stringify(externalProducts)
  })
}

function getConnectedProduct(unitId, projectId, sourceName, id) {
  return ifetch(baseUrl(unitId, projectId) + `/process/connect/status/${sourceName}/${id}`, { method: 'GET' })
}

function discoverProductsElement(unitId, projectId, productIds) {
  return ifetch(baseUrl(unitId, projectId) + `/process/discover`, { method: 'POST', body: JSON.stringify(productIds) })
}

function approve(unitId, projectId, sourceName, productsInElements) {
  return ifetch(baseUrl(unitId, projectId) + `/process/approve/${sourceName}`, {
    method: 'POST',
    body: JSON.stringify(productsInElements)
  })
}

function connectTo(unitId, projectId, buildingElementId, productIds) {
  return ifetch(baseUrl(unitId, projectId) + `/connect-to/${buildingElementId}`, {
    method: 'POST',
    body: JSON.stringify(productIds)
  })
}

function connectMultiTfm(unitId, projectId, productsTfmLocationJson) {
  return ifetch(baseUrl(unitId, projectId) + `/connect-tfm`, {
    method: 'POST',
    body: JSON.stringify(productsTfmLocationJson)
  })
}

function approveSingle(unitId, projectId, sourceName, productsInElements) {
  return ifetch(baseUrl(unitId, projectId) + `/process/approve-single/${sourceName}`, {
    method: 'POST',
    body: JSON.stringify(productsInElements)
  })
}

function productList(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId), { method: 'GET' })
}

function groupedByBuildingElementDocumentationId(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + '/grouped-by-building-element-documentation-id', { method: 'GET' })
}

function groupedByTfmLocation(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + '/grouped-by-tfm-location', { method: 'GET' })
}

function addOwnProduct(unitId, projectId, productJson) {
  return ifetch(baseUrl(unitId, projectId), { method: 'POST', body: JSON.stringify(productJson) })
}

function downloadFiles(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/download-missing-files`, { method: 'GET' })
}

function productsFiles(productIds) {
  return ifetch('/api/filesystems/product/multi-structure', { method: 'POST', body: JSON.stringify(productIds) })
}

function deleteList(unitId, projectId, productIds) {
  return ifetch(baseUrl(unitId, projectId), { method: 'DELETE', body: JSON.stringify(productIds) })
}

export {
  connectProduct,
  uploadProducts,
  connectProductToProducts,
  getConnectedProduct,
  discoverProductsElement,
  approve,
  connectTo,
  approveSingle,
  productList,
  addOwnProduct,
  downloadFiles,
  productsFiles,
  groupedByBuildingElementDocumentationId,
  groupedByTfmLocation,
  deleteList,
  connectMultiTfm
}
