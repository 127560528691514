export default class ApiController {
  /**
   * Host element.
   */
  host

  /**
   * Array [{key: apiPromise}]
   * For each element in array we are resolving promise
   * and set resolved value on the host under specified key
   */
  apiCalls

  /**
   * Constructor which takes host element and api calls object.
   *
   * @param host
   * @param apiCalls
   */
  constructor(host, apiCalls) {
    this.apiCalls = apiCalls
    this.host = host
    this.host.addController(this)
  }

  /**
   * Api are executed when host is connected to controller.
   */
  hostConnected() {
    const host = this.host
    const controller = this
    this.apiCalls.forEach(function (apiCall) {
      controller.callApi(host, apiCall)
    })
  }

  hostDisconnected() {
    this.apiCalls = null
  }

  callApi(host, apiKeyAndPromise) {
    for (const [property, promise] of Object.entries(apiKeyAndPromise)) {
      if (typeof promise === 'function') {
        promise().then(function (response) {
          host[property] = response
        })
      } else if (promise instanceof Object) {
        const customPromise = promise['promise']
        const customAction = promise['action']
        if (typeof customPromise === 'function' && typeof customAction === 'function') {
          customPromise()
            .then(customAction)
            .catch(function (error) {
              if (typeof promise['catch'] === 'function') {
                promise['catch'](error)
              }
            })
        }
      }
    }
  }
}
