import { Definition } from './definition'
import { TagGroup } from './tag-group'

class Tag {
  id
  group
  definition
  color
  sortOrder
  constructor(props) {
    this.id = props?.id
    this.group = new TagGroup(props?.group)
    this.definition = new Definition(props?.definition)
    this.color = props?.color
    this.sortOrder = props?.sortOrder
  }
}

export { Tag }
