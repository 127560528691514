import { Enum } from '@integrator/api/src/universal/model/enum'

class IntegratorExternalPermission extends Enum {
  static DENIED = new IntegratorExternalPermission('DENIED')
  static ALLOWED = new IntegratorExternalPermission('ALLOWED')

  constructor(name) {
    super(name)
  }
}

export { IntegratorExternalPermission }
