import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, procedureId) {
  return `/api/business/${unitId}/project/${projectId}/procedure/checklists`
}

function assignedList(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId), { method: 'GET' })
}

export { assignedList }
