import { ifetch, integratorUrl, jwtToken } from '@integrator/api'
import Cookies from 'js-cookie'

function url(unitId, projectId, procedureId) {
  return `/api/business/${unitId}/projects/${projectId}/procedures/${procedureId}/pdf`
}

function download(unitId, projectId, procedureId) {
  window.open(`${integratorUrl()}/ny${url(unitId, projectId, procedureId)}/link?token=${jwtToken()}`)
}

export { download }
