import { ifetch } from '@integrator/api'
function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/periodic-control`
}

function getPeriodicControlDefinition(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/definition')
}

function patchPeriodicControlDefinition(unitId, projectId, definition) {
  return ifetch(url(unitId, projectId) + '/definition', {
    method: 'PATCH',
    body: JSON.stringify(definition)
  })
}

export { getPeriodicControlDefinition, patchPeriodicControlDefinition }
