/**
 * Configuration for webclient API.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */

import angular from 'angular'

class ApiConstants {
  constructor() {}

  production() {
    return {
      baseUrl: 'https://profsys-integrator.no',
      portalUrl: 'https://portal.elektro-integrator.no/#/',
      port: '80',
      localPort: '80',
      apiPath: '/ny/hapi/v2/webclient',
      url: function () {
        return 'https://profsys-integrator.no/ny/api/'
      },
      completeUrl: function () {
        return 'https://profsys-integrator.no/ny/hapi/v2/webclient'
      },
      completeFsUrl: function () {
        return 'https://profsys-integrator.no/ny/api/filesystems/'
      }
    }
  }

  test() {
    return {
      baseUrl: 'http://test.profsys-integrator.no',
      portalUrl: 'https://portal.elektro-integrator.no/#/',
      port: '80',
      localPort: '80',
      apiPath: '/ny/hapi/v2/webclient',
      fsPath: '/ny/filesystems/',
      url: function () {
        return 'https://test.profsys-integrator.no/ny/api/'
      },
      completeUrl: function () {
        return 'https://test.profsys-integrator.no/ny/hapi/v2/webclient'
      },
      completeFsUrl: function () {
        return 'https://test.profsys-integrator.no/ny/api/filesystems/'
      }
    }
  }

  local() {
    return {
      baseUrl: 'http://localhost',
      portalUrl: 'http://localhost:8082/#/',
      port: '8081',
      localPort: '9000',
      apiPath: '/ny/hapi/v2/webclient',
      fsPath: '/ny/filesystems/',
      url: function () {
        return 'http://localhost:8081/ny/api/'
      },
      completeUrl: function () {
        return 'http://localhost:8081/ny/hapi/v2/webclient'
      },
      completeFsUrl: function () {
        return 'http://localhost:8081/ny/api/filesystems'
      }
    }
  }
}

const apiConfig = function () {
  let apiConstant = new ApiConstants()
  let currentConfig

  if (location.hostname === 'localhost') {
    currentConfig = apiConstant.local()
  } else if (location.hostname === 'ps-read-access.firebaseapp.com') {
    currentConfig = apiConstant.production()
  } else if (location.hostname === 'lesetilgang-test.firebaseapp.com') {
    currentConfig = apiConstant.test()
  } else if (location.hostname === 'ps-read-access-test.firebaseapp.com') {
    currentConfig = apiConstant.test()
  } else if (location.hostname === 'lesetilgang-test.apps.profsys-integrator.no') {
    currentConfig = apiConstant.test()
  } else if (location.hostname === 'lesetilgang-test.profsys-integrator.no') {
    currentConfig = apiConstant.test()
  } else if (location.hostname === 'lesetilgang.apps.profsys-integrator.no') {
    currentConfig = apiConstant.production()
  } else if (location.hostname === 'access.profsys-integrator.no') {
    currentConfig = apiConstant.production()
  } else if (location.hostname === 'lesetilgang.profsys.no') {
    currentConfig = apiConstant.production()
  } else {
    currentConfig = apiConstant.production()
  }
  let url = currentConfig.baseUrl
  if (currentConfig.port !== '80') {
    url += ':' + currentConfig.port
  }
  globalThis.INTEGRATOR_URL = url
  return currentConfig
}

let callBackUrls = function () {
  let config = apiConfig()
  let portString = ''
  if (config.baseUrl === 'http://localhost') {
    portString = ':' + config.localPort
  }
  return {
    changePassword: 'http://' + location.hostname + portString + '/#!/auth/reset/change'
  }
}

export default angular
  .module('ps.config.api', [])
  .constant('apiConfig', apiConfig())
  .constant('callbackUrls', callBackUrls())
  .filter('br', function ($sce) {
    return function (input) {
      if (!angular.isString(input)) {
        return input
      } else {
        return $sce.trustAsHtml(input.replace(/(?:\r\n|\r|\n)/g, '</br>'))
      }
    }
  }).name
