import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, productId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/products/${productId}`
}

// no response value
function remove(unitId, projectId, productId) {
  return ifetch(baseUrl(unitId, projectId, productId), { method: 'DELETE' })
}

// no response value
function edit(unitId, projectId, productId, product) {
  return ifetch(baseUrl(unitId, projectId, productId), { method: 'POST', body: JSON.stringify(product) })
}

function connectToBuildingElement(unitId, projectId, productId, buildingElementId) {
  return ifetch(baseUrl(unitId, projectId, productId) + `/connect-to/${buildingElementId}`, { method: 'POST' })
}

// no response value
function disconnectFromBuildingElement(unitId, projectId, productId, buildingElementId) {
  return ifetch(baseUrl(unitId, projectId, productId) + `/disconnect-from/${buildingElementId}`, { method: 'POST' })
}

// no response value
function addTfmLocation(unitId, projectId, productId, tfmProductLocationJson) {
  return ifetch(baseUrl(unitId, projectId, productId) + `/locations/tfm`, {
    method: 'POST',
    body: JSON.stringify(tfmProductLocationJson)
  })
}

// no response value
function changeTfmLocation(unitId, projectId, productId, tfmId, tfmProductLocationJson) {
  return ifetch(baseUrl(unitId, projectId, productId) + `/locations/tfm/${tfmId}`, {
    method: 'PATCH',
    body: JSON.stringify(tfmProductLocationJson)
  })
}

// no response value
function deleteTfmLocation(unitId, projectId, productId, tfmId) {
  return ifetch(baseUrl(unitId, projectId, productId) + `/locations/tfm/${tfmId}`, {
    method: 'DELETE'
  })
}

function product(unitId, projectId, productId) {
  return ifetch(baseUrl(unitId, projectId, productId))
}

export {
  remove,
  edit,
  product,
  connectToBuildingElement,
  disconnectFromBuildingElement,
  addTfmLocation,
  changeTfmLocation,
  deleteTfmLocation
}
