import { Enum } from '../../universal'

class ComponentStatusName extends Enum {
  static UNKNOWN = new ComponentStatusName('UNKNOWN')
  static OK = new ComponentStatusName('OK')
  static WARNING = new ComponentStatusName('WARNING')
  static ERROR = new ComponentStatusName('ERROR')
  static UNDER_CONSTRUCTION = new ComponentStatusName('UNDER_CONSTRUCTION')
  static COMPLETE = new ComponentStatusName('COMPLETE')
}

export { ComponentStatusName }
