import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, procedureId) {
  return `/api/business/${unitId}/project/${projectId}/procedure/${procedureId}/links`
}

function list(unitId, projectId, procedureId) {
  return ifetch(baseUrl(unitId, projectId, procedureId), { method: 'GET' })
}

function addLink(unitId, projectId, procedureId, linkJson) {
  return ifetch(baseUrl(unitId, projectId, procedureId), { method: 'POST', body: JSON.stringify(linkJson) })
}

export { list, addLink }
