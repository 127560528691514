/**
 * @typedef { Object } OperationLog
 */
import { OperationTransaction } from './operation-transaction'

class OperationLog {
  /** @type {Object} */
  rawData
  /** @type {Array<OperationTransaction>} */
  transactions

  constructor({ rawData, transactions }) {
    this.rawData = rawData
    this.transactions = transactions.map(transaction => new OperationTransaction(transaction))
  }
}

export { OperationLog }
