class EmployeeHmsCard {
  creationDate
  expiryDate
  number
  constructor(props) {
    if (props) {
      this.creationDate = props.creationDate
      this.expiryDate = props.expiryDate
      this.number = props.number
    }
  }
}

export { EmployeeHmsCard }
