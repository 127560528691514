import angular from 'angular'
import angularMaterial from 'angular-material'
import './icon.module.header.scss'

export default angular.module('ps.directives.icons.module', [angularMaterial]).directive('headericon', function () {
  return {
    restrict: 'E',
    template: require('./icon.module.header.tpl.html'),
    scope: {
      name: '@',
      icon: '@'
    }
  }
}).name
