'use strict'

import angular from 'angular'
import ApiConstants from '../../../config/config.constants.js'

class ChecklistDataService {
  constructor($http, $log, $q, $cacheFactory, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.cache = $cacheFactory.get('$http')
    this.endpoint = new ChecklistEndpoint(apiConfig)
  }

  checklist(checklistId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.checklist(checklistId))
      .then(response => {
        asyncResult.resolve(response.data)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.checklist(checklistId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }
}

ChecklistDataService.$inject = ['$http', '$log', '$q', '$cacheFactory', 'apiConfig']

class ChecklistEndpoint {
  constructor(apiConfig) {
    this.path = apiConfig.url() + 'checklists/'
  }

  checklist(checklistId) {
    return this.path + checklistId
  }
}

/**
 * Export the service as a separate angular module.
 */
export default angular
  .module('service.data.checklist', [ApiConstants])
  .service('psChecklistService', ChecklistDataService).name
