import { Checklists, ChecklistTemplates } from '@integrator/api'

const ProcedureChecklistType = {
  CHECKLISTS: 'checklists',
  UNIT_TEMPLATES: 'unitTemplates',
  CORPORATE_TEMPLATES: 'corporateTemplates',
  GLOBAL_TEMPLATES: 'globalTemplates'
}

class ProcedureChecklistsMap extends Map {
  constructor({ checklists, unitTemplates, corporateTemplates, globalTemplates }) {
    super()
    this.set(ProcedureChecklistType.CHECKLISTS, new Checklists(checklists))
    this.set(ProcedureChecklistType.UNIT_TEMPLATES, new ChecklistTemplates(unitTemplates))
    this.set(ProcedureChecklistType.CORPORATE_TEMPLATES, new ChecklistTemplates(corporateTemplates))
    this.set(ProcedureChecklistType.GLOBAL_TEMPLATES, new ChecklistTemplates(globalTemplates))
  }

  getChecklists() {
    return this.get(ProcedureChecklistType.CHECKLISTS)?.list
  }

  getUnitTemplates() {
    return this.get(ProcedureChecklistType.UNIT_TEMPLATES)?.list
  }

  getCorporateTemplates() {
    return this.get(ProcedureChecklistType.CORPORATE_TEMPLATES)?.list
  }

  getGlobalTemplates() {
    return this.get(ProcedureChecklistType.GLOBAL_TEMPLATES)?.list
  }
}

export { ProcedureChecklistsMap, ProcedureChecklistType }
