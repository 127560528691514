import { ifetch } from '@integrator/api'

function url() {
  return `/api/contacts`
}

function brregSearch(businessUnitId, query) {
  return ifetch(`/api/business/${businessUnitId}/kontaktsok?query=` + query, {
    method: 'GET'
  })
}

function searchByEmail(businessUnitId, query) {
  return ifetch(`/api/business/${businessUnitId}/users/search/email?query=` + query, {
    method: 'GET'
  })
}

function search(query, page, searchType, limit) {
  return ifetch(url() + `/search`, {
    method: 'POST',
    body: JSON.stringify({ query: query, page: page, type: searchType, limit: limit })
  })
}

function getById(id) {
  return ifetch(url() + `/${id}`, {
    method: 'GET'
  })
}

export { search, getById, searchByEmail, brregSearch }
