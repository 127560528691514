'use strict'

import ApiConstants from '../../../config/config.constants.js'
import angular from 'angular'

/**
 * Data service for /projects.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
class ProjectsDataService {
  constructor($http, $log, $q, $cacheFactory, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.cache = $cacheFactory.get('$http')
    this.endpoint = new ProjectsEndpoint(apiConfig)
  }

  /**
   * List projects.
   */
  list() {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.list(), { cache: true })
      .then(response => {
        asyncResult.resolve(response.data)
      })
      .catch(error => {
        console.log(error)
        this.$log.error('XHR failed for GET /projects with error: ' + error.status + ' ' + error.statusText)
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Get project with id
   * @param id project id
   */
  getProject(id) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.get(id), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error('XHR failed for ' + this.endpoint.get())
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }
}

ProjectsDataService.$inject = ['$http', '$log', '$q', '$cacheFactory', 'apiConfig']

class ProjectsEndpoint {
  constructor(apiConfig) {
    this.path = apiConfig.completeUrl()
  }

  list() {
    return this.path + '/project/'
  }

  get(id) {
    return this.path + '/project/' + id
  }

  deviations(id) {
    return this.path + '/issues/project/' + id
  }
}

/**
 * Export projects data service.
 */
export default angular.module('service.data.projects', [ApiConstants]).service('psProjectService', ProjectsDataService)
  .name
