import { ContactPerson } from '../../contact'

class CustomerResponsiblePerson {
  contactPersonId
  contactPerson
  relation
  constructor(props) {
    if (props) {
      this.contactPersonId = props.contactPersonId
      this.contactPersonId = new ContactPerson(props.contactPerson)
      this.relation = props.relation
    }
  }
}

export { CustomerResponsiblePerson }
