'use strict'

/**
 * TODO: Extract duplicated code for auto-complete into directive. (component parent and controls parent controller.)
 *
 * Parent controller for /components.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 *
 */

import _ from 'lodash'

class ControlsParentCtrl {
  constructor($state, projects, psControlsService, $stateParams) {
    this.$state = $state
    this.projects = projects.data
    this.service = psControlsService
    this.controls = []
    this.$stateParams = $stateParams
    this.unitId = $stateParams.unitId
    this.populateProjectState()
  }

  // Make sure auto complete is filled out on refresh
  // Populate for project
  populateProjectState() {
    this.sortProjects()
    if (!this.isDetailState()) {
      return
    }
    this.project = this.findElementByIdInList(this.projects, parseInt(this.$state.params.project))
    if (!this.isUndefined(this.project)) {
      this.projectSearchText = this.project.name

      this.service.projectControls(this.project.id).then(response => {
        this.controls = _.concat([], response.data.active, response.data.done)
        this.populateControlState()
      })
    }
  }

  sortProjects() {
    _.remove(this.projects, function (project) {
      var exists = _.findIndex(project.enabledFeatures.features, function (feature) {
        var exists = feature === 'IKK'
        return exists
      })
      return exists === -1
    })
  }

  // Make sure auto complete is filled out on refresh
  // Populate for controls
  populateControlState() {
    this.control = this.findElementByIdInList(this.controls, parseInt(this.$state.params.control))
    if (!this.isUndefined(this.control)) {
      this.controlSearchText = this.control.orderNumber
    }
  }

  /**
   * Find element with id in list.
   */
  findElementByIdInList(list, id) {
    return _.find(list, element => {
      return element.id === id
    })
  }

  // Callback when project is selected.
  projectSelected(project) {
    this.project = project
    this.controls = []
    this.components = null
    this.controlSearchText = ''
    this.$state.go('controls.parent', { unitId: this.unitId })
    if (!_.isNull(project) && !_.isUndefined(project)) {
      this.loadControlsForProject(project.id)
    } else {
      this.projectSearchText = ''
    }
  }

  controlSelected(control) {
    this.control = control
    this.components = null
    if (!this.isUndefined(control)) {
      this.$state.go('controls.detail', { unitId: this.unitId, project: this.project.id, control: this.control.id })
    } else {
      this.controlSearchText = ''
    }
  }

  loadControlsForProject(projectId) {
    this.service.projectControls(projectId).then(response => {
      let active = response.data.active || []
      let done = response.data.done || []
      this.controls = _.concat([], active, done)
    })
  }

  loadComponentsForControl(controlId) {
    this.service.getControlComponents(controlId).then(response => {
      this.components = response.data.data
    })
  }

  /**
   * Autocomplete functions.
   */

  projectSearch(query) {
    if (!_.isNull(query) && !_.isUndefined(query) && query.length > 1) {
      return _.filter(this.projects, project => {
        return project.name.indexOf(query) >= 0
      })
    } else {
      return this.projects
    }
  }

  controlSearch(query) {
    if (!_.isNull(query) && !_.isUndefined(query) && query.length > 1) {
      return _.filter(this.controls, control => {
        return control.orderNumber.indexOf(this.controlSearchText) >= 0
      })
    } else {
      return this.controls
    }
  }

  isUndefined(object) {
    return _.isNull(object) || _.isUndefined(object)
  }

  isDetailState() {
    return this.$state.current.name !== 'controls.parent'
  }

  dummy() {
    this.controlSearch('')
    this.projectSearch('')
    this.loadComponentsForControl(123)
  }
}
ControlsParentCtrl.$inject = ['$state', 'projects', 'psControlsService', '$stateParams']

export default ControlsParentCtrl
