import { Person, projectTaskApi, projectTasksApi, Task, TaskCriteria } from '@integrator/api'

export class ProjectTaskHandler {
  unitId
  projectId
  taskId

  constructor(unitId, projectId, taskId) {
    this.unitId = unitId
    this.projectId = projectId
    this.taskId = taskId
  }

  fetchTask(taskId = this.taskId) {
    return projectTaskApi
      .getTask(this.unitId, this.projectId, taskId)()
      .then(response => {
        return new Task(response)
      })
  }

  updateTask(task) {
    return projectTaskApi
      .update(this.unitId, this.projectId, task)()
      .then(response => {
        return new Task(response)
      })
  }

  completeTask(taskCompletion, taskId = this.taskId) {
    return projectTaskApi
      .statusDone(this.unitId, this.projectId, taskId, taskCompletion)()
      .then(({ doneTask }) => {
        return new Task(doneTask)
      })
  }

  reopenTask(taskId = this.taskId) {
    return projectTaskApi.statusOpen(this.unitId, this.projectId, taskId)({ noJson: true })
  }

  deleteTask(taskId = this.taskId) {
    return projectTaskApi.deleteTask(this.unitId, this.projectId, taskId)({ noJson: true })
  }

  deleteTaskWithDeviations(taskId = this.taskId) {
    return projectTaskApi.deleteTaskWithDeviations(this.unitId, this.projectId, taskId)({ noJson: true })
  }

  async canBeDeleted(taskId = this.taskId) {
    return await projectTaskApi.canBeDeleted(this.unitId, this.projectId, taskId)()
  }

  preTaskStatusDone(taskId = this.taskId) {
    return projectTaskApi
      .preTaskStatusDone(this.unitId, this.projectId, taskId)()
      .then(response => {
        return { completionDate: response.completionDate, task: new Task(response.task) }
      })
  }

  fetchTasks() {
    return projectTasksApi
      .tasks(this.unitId, this.projectId)()
      .then(response => {
        return response?.list?.map(task => new Task(task))
      })
  }

  fetchConfigurationForNewTask() {
    return projectTasksApi
      .configurationForNew(this.unitId, this.projectId)()
      .then(response => {
        return {
          tasks: response?.copyFromTasks?.list?.map(task => new Task(task)),
          persons: response?.responsiblePersons?.list?.map(person => new Person(person))
        }
      })
  }

  add(task) {
    return projectTasksApi
      .add(this.unitId, this.projectId, task)()
      .then(response => {
        return new Task(response)
      })
  }

  fetchTaskCriteria() {
    return projectTasksApi
      .taskCriteria(this.unitId, this.projectId)()
      .then(response => {
        return new TaskCriteria(response)
      })
  }
}
