import { html, nothing, LitElement } from 'lit'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'
import { repeat } from 'lit/directives/repeat.js'
import { editorStyles } from './editor-styles.js'
import EditorModel from './model/editor-model.js'
import './block/link-preview.js'

/**
 * View of the editor text.
 */
export class EditorView extends LitElement {
  static styles = [editorStyles]

  static properties = {
    content: { type: Object },
    searchText: { type: String }, // not required
    model: { type: Object, state: true }
  }

  constructor() {
    super()
  }

  firstUpdated() {
    this.model = new EditorModel(this.content)
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('content')) {
      this.model = new EditorModel(this.content)
    }
  }

  render() {
    if (!this.model) {
      return nothing
    }
    return html`
      <div part="base" class="editor-container">
        <div class="editor-header">
          <slot name="header"></slot>
        </div>
        <div class="editor-view">
          ${repeat(
            this.model.blocks,
            block => block.id,
            (block, index) => {
              switch (block.type) {
                case 'image':
                  return this.renderImage(block)
                case `header`:
                  return this.renderHeader(block)
                case 'text':
                  return this.renderText(block)
                case 'link':
                  return this.renderLink(block)
              }
            }
          )}
        </div>
      </div>
    `
  }

  renderImage(block) {
    return html`
      <img src="${block.image.url}" alt="img" />
    `
  }

  renderHeader(block) {
    switch (block.header.level) {
      case 1:
        return html`
          <h1 id=${block.id} class="textBox">${block.header.text}</h1>
        `
      case 2:
        return html`
          <h2 id=${block.id} class="textBox">${block.header.text}</h2>
        `
      case 3:
        return html`
          <h3 id=${block.id} class="textBox">${block.header.text}</h3>
        `
      case 4:
        return html`
          <h4 id=${block.id} class="textBox">${block.header.text}</h4>
        `
      case 5:
        return html`
          <h5 id=${block.id} class="textBox">${block.header.text}</h5>
        `
      case 6:
        return html`
          <h6 id=${block.id} class="textBox">${block.header.text}</h6>
        `
      default:
        return html`
          <h6 id=${block.id} class="textBox">${block.header.text}</h6>
        `
    }
  }

  renderText(block) {
    if (this.searchText) {
      const words = this.searchText.split(' ')
      words.forEach(word => {
        let wordRegex = new RegExp(word, 'ig')
        block.text.value = block.text.value.replace(
          wordRegex,
          `<span style="background: var(--sl-color-warning-200)">${word}</span>`
        )
      })
    }
    return html`
      <div id=${block.id}>${unsafeHTML(block.text.value)}</div>
    `
  }

  renderLink(block) {
    if (block.link.exists) {
      return html`
        <i-link-preview .link=${block.link}></i-link-preview>
      `
    } else {
      return html`
        <a href=${block.link.url} id=${block.id}>${block.link.url}</a>
      `
    }
  }
}

customElements.define('i-editor-view', EditorView)
