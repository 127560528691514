import { Checklists } from './checklists'
import { ChecklistsOverviewCategory } from './checklists-overview-category'

/**
 * @typedef {keyof typeof ChecklistsOverviewCategory} ChecklistsOverviewCategoryKey
 */

class ChecklistsOverview {
  /** @type {Map<ChecklistsOverviewCategory, Map<number, Checklists>>} */
  overview

  constructor(props) {
    this.overview = new Map()
    if (props) {
      for (const category in props) {
        const cat = new ChecklistsOverviewCategory(category)
        const subMap = new Map()
        for (const key in props[category]) {
          subMap.set(Number(key), new Checklists(props[category][key]))
        }
        this.overview.set(cat, subMap)
      }
    }
  }
}

export { ChecklistsOverview }
