import { ifetch } from '@integrator/api'

function url(unitId, contactId) {
  return `/api/business/${unitId}/contacts/${contactId}/persons`
}

function getPersons(businessUnitId, contactId) {
  return ifetch(url(businessUnitId, contactId), {
    method: 'GET'
  })
}

function searchPersons(businessUnitId, contactId, query) {
  return ifetch(url(businessUnitId, contactId) + `/search`, {
    method: 'POST',
    body: query
  })
}

function addNewPerson(businessUnitId, contactId, contactPerson) {
  return ifetch(url(businessUnitId, contactId), {
    method: 'PUT',
    body: JSON.stringify(contactPerson)
  })
}

export { getPersons, searchPersons, addNewPerson }
