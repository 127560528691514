import { ifetch } from '@integrator/api'

function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}`
}
function getIntegratedProject(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/info', {
    method: 'GET'
  })
}

export { getIntegratedProject }
