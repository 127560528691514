'use strict'

import router from 'angular-ui-router'

/**
 * Routes for Controls module.
 * @author Audun Follegg (audunfo@gmail.com)
 */

let routes = function ($stateProvider) {
  $stateProvider
    .state('controls', {
      url: '/controls',
      parent: 'unit',
      abstract: true,
      template: '<ui-view class="full-height"></ui-view>'
    })
    .state('controls.parent', {
      url: '',
      parent: 'controls',
      template: require('./controls.parent.tpl.html'),
      authenticate: true,
      controller: 'ControlsParentCtrl',
      resolve: {
        projects: [
          'psProjectService',
          function (service) {
            return service.list()
          }
        ]
      },
      controllerAs: 'vc',
      ncyBreadcrumb: {
        parent: 'controls',
        label: "{{'CONTROLS' | translate}}"
      }
    })

    .state('controls.detail', {
      url: '/project/:project/control/:control',
      parent: 'controls.parent',
      template: require('./detail/controls.detail.tpl.html'),
      controller: 'ControlsDetailCtrl',
      controllerAs: 'vc',
      resolve: {
        components: [
          'psControlsService',
          '$stateParams',
          function (service, $stateParams) {
            return service.getControlComponents($stateParams.control)
          }
        ],
        controls: [
          'psControlsService',
          '$stateParams',
          function (service, $stateParams) {
            return service.projectControls($stateParams.project)
          }
        ]
      },
      authenticate: true,
      ncyBreadcrumb: {
        skip: true
      }
    })
}

routes.$inject = ['$stateProvider']

export default routes
