import { Enum } from './enum'
/**
 * @typedef { FieldType } name
 * @property { string } name
 */

class FieldType extends Enum {
  static STRING = new FieldType('STRING')
  static NUMBER = new FieldType('NUMBER')
  static BOOLEAN = new FieldType('BOOLEAN')
  static DATE = new FieldType('DATE')

  constructor(name) {
    super(name)
  }
}

export { FieldType }
