import { Enum } from '../../universal'

class IndependenceLevel extends Enum {
  static A = new IndependenceLevel('A')
  static B = new IndependenceLevel('B')
  static C = new IndependenceLevel('C')
  static D = new IndependenceLevel('D')
}

export { IndependenceLevel }
