import { ifetch } from '@integrator/api'

function url(relationName, relationId) {
  return `/api/deviations/statistic/${relationName}/${relationId}`
}

function openCloseSeverity(relationName, relationId) {
  return ifetch(url(relationName, relationId) + '/open-close-severity', { method: 'GET' })
}

function statusWithCostEstimate(relationName, relationId) {
  return ifetch(url(relationName, relationId) + '/status-cost-estimate', { method: 'GET' })
}

function severityWithCostEstimate(relationName, relationId) {
  return ifetch(url(relationName, relationId) + '/severity-cost-estimate', { method: 'GET' })
}

export { openCloseSeverity, statusWithCostEstimate, severityWithCostEstimate }
