/**
 *   Periodic Control (IKK) module
 *   @author Rannveig A. Skjerve @gmail.com
 */

import angular from 'angular'
import router from 'angular-ui-router'
import routes from './periodic.routes.js'

//Modules
import IssuesModule from '../issues/issues.module.js'
import ControlsModule from '../controls/controls.module.js'
import DocumentsModule from '../documents/documents.module.js'

//Controllers
import PeriodicControlsCtrl from './periodic.parent.controller.js'
import PeriodicDetailCtrl from './detail/periodic.detail.controller.js'

//Services
import ProjectsService from './../../common/services/data/projects.dataservice.js'

//Directives
import ProjectsSidebarDirective from '../../common/directives/lists/list.projects.directive.js'

export default angular
  .module('ps.module.periodic', [
    router,
    routes,
    ProjectsService,
    ProjectsSidebarDirective,
    ControlsModule,
    DocumentsModule,
    IssuesModule
  ])
  .controller('PeriodicControlsCtrl', PeriodicControlsCtrl)
  .controller('PeriodicDetailCtrl', PeriodicDetailCtrl).name
