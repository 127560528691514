class Enum {
  name

  constructor(value) {
    if (typeof value === 'string') {
      this.name = value
    } else if (value instanceof Enum) {
      this.name = value.name
    } else {
      console.error('Enum.class. The value is neither a string nor an Enum.')
    }
  }

  toJSON() {
    return this.name
  }

  toCssName() {
    return this.name.toLowerCase().replace(/\s+/g, '-')
  }

  static fromString(name, enumType) {
    if (enumType.hasOwnProperty(name)) {
      return enumType[name]
    }
    throw new Error(`Invalid enum name: ${name}`)
  }

  equals(other) {
    return other instanceof Enum && this.name === other.name
  }

  toString() {
    return this.name
  }
}

export { Enum }
