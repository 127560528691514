import { BusinessContentName } from '@integrator/api'

/**
 * Represents a collection definition for procedures.
 */
export class ProcedureCollectionDefinition {
  /**
   * The ID of the procedure collection definition.
   * @type {number}
   */
  id

  /**
   * The name of the procedure collection definition.
   * @type {string}
   */
  name

  /**
   * The description of the procedure collection definition.
   * @type {string}
   */
  description

  /**
   * The unit ID of the procedure collection definition.
   * @type {number}
   */
  unitId

  /**
   * The content associated with the procedure collection definition.
   * @type {BusinessContentName}
   */
  content

  constructor(props) {
    if (props) {
      this.id = props.id
      this.name = props.name
      this.description = props.description
      this.unitId = props.unitId
      this.content = new BusinessContentName(props.content)
    }
  }
}
