/**
 * Self contained module that lists projects.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import router from 'angular-ui-router'
import ProjectsDataService from '../../services/data/projects.dataservice.js'
import './list.projects.scss'
import _ from 'lodash'

class ProjectsListCtrl {
  constructor($stateParams, psProjectService, $state, $log, $scope) {
    this.unitId = $stateParams.unitId
    this.currentProjectId = $stateParams.id
    this.service = psProjectService
    this.$state = $state
    this.$scope = $scope
    this.projects = null
    this.error = false
    this.$log = $log
    this.projects = $scope.projects.data
    this.gotoState = $scope.module
    this.icon = $scope.icon
    if ($scope.moduleType) {
      this.sortProjects($scope.moduleType)
    }
  }

  showError() {
    this.error = true
  }

  // Helper to transition to other state (md-list-item does not support ui-sref yet)
  goTo(id) {
    this.$state.go(this.gotoState, { unitId: this.unitId, id: id })
  }

  // Helper to style active list item.
  activeItem(projectId) {
    console.log(this.currentProjectId + ' === ' + parseInt(projectId))
    return this.currentProjectId === parseInt(projectId)
  }

  sortProjects(moduleType) {
    _.remove(this.projects, function (project) {
      var exists = _.findIndex(project.enabledFeatures.features, function (feature) {
        var exists = feature === moduleType
        return exists
      })
      return exists === -1
    })
  }
}
ProjectsListCtrl.$inject = ['$stateParams', 'psProjectService', '$state', '$log', '$scope']

export default angular
  .module('ps.directives.sidebar.projects', [router, ProjectsDataService])
  .controller('ProjectsListCtrl', ProjectsListCtrl)
  .directive('projectsList', function () {
    return {
      restrict: 'E',
      template: require('./list.projects.directive.tpl.html'),
      scope: {
        projects: '=',
        filter: '=',
        module: '=',
        icon: '=',
        moduleType: '@'
      },
      controller: 'ProjectsListCtrl',
      controllerAs: 'vc'
    }
  }).name
