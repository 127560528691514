import { Enum, TaskGroup } from '@integrator/api'

/**
 * @typedef {Object} TaskCriteria
 * @property {string} searchText
 * @property {boolean} overdue
 * @property {TaskGroup|string} group
 */
export class TaskCriteria {
  searchText
  overdue
  group

  constructor(props) {
    this.searchText = props?.searchText || ''
    this.overdue = !!props?.overdue
    if (props?.group instanceof Enum) {
      this.group = props.group.toJSON()
    } else {
      this.group = props?.group
    }
  }

  toJSON() {
    return {
      searchText: this.searchText,
      overdue: this.overdue,
      group: this.group
    }
  }
}
