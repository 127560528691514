import { ifetch } from '@integrator/api'

function baseUrl(id) {
  return `/api/mom/building-elements/${id}`
}

function updateInformationSpecification(id, specName) {
  return ifetch(baseUrl(id) + `/information-specification/${specName}`, { method: 'POST' })
}

function buildingElement(id) {
  return ifetch(baseUrl(id), { method: 'GET' })
}

function standardText(id, specName) {
  return ifetch(baseUrl(id) + `/standard-text/${specName}`, { method: 'GET' })
}

function standardTexts(id) {
  return ifetch(baseUrl(id) + `/standard-text`, { method: 'GET' })
}

function createStandardText(id, specName, text) {
  return ifetch(baseUrl(id) + `/standard-text/${specName}`, { method: 'POST', body: JSON.stringify(text) })
}

export { updateInformationSpecification, buildingElement, standardText, standardTexts, createStandardText }
