/**
 * @typedef {Object} ProcedureManualName
 * @property {string} name
 */
export class ProcedureManualName {
  static APPROVED = new ProcedureManualName('APPROVED')
  static NOT_APPROVED = new ProcedureManualName('NOT_APPROVED')
  static PROJECT = new ProcedureManualName('PROJECT')
  static TEMPLATE_UPDATED = new ProcedureManualName('TEMPLATE_UPDATED')

  constructor(props) {
    if (props instanceof ProcedureManualName) {
      this.name = props.name
    } else {
      this.name = props
    }
  }

  toJSON() {
    return this.name
  }

  toString() {
    return this.name
  }
}
