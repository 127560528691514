import { ifetch } from '@integrator/api'

function importContacts(businessUnitId, data) {
  return ifetch(`/api/business/${businessUnitId}/contacts/import`, {
    method: 'POST',
    body: data,
    headers: {}
  })
}

export { importContacts }
