'use strict'

/**
 * Controller for procedure list view.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
class ProcedureListCtrl {
  constructor(psProcedureService, $log, $stateParams, $state) {
    this.service = psProcedureService
    this.$log = $log
    this.projectId = $stateParams.id
    this.unitId = $stateParams.unitId
    this.$state = $state
    this.getProcedureCategories(this.projectId)
  }

  getProcedureCategories(projectId) {
    this.service
      .getProcedureCategories(projectId)
      .then(response => {
        this.categories = response.data.data
      })
      .catch(error => {
        this.$log.error(error)
      })
  }

  getProcedureSubCategories(mainCategory) {
    if (mainCategory.open) {
      mainCategory.open = false
      return
    }

    this.service
      .getProcedureSubCategories(this.projectId, mainCategory.id)
      .then(response => {
        mainCategory.categories = response.data.data
        mainCategory.open = true
      })
      .catch(error => {
        this.$log.error(error)
      })
  }

  openProcedure(procedureId) {
    this.$log.info('Open procedure with id: ' + procedureId)
    this.$state.go('procedures.project.detail', {
      unitId: this.unitId,
      procedure_id: procedureId,
      project_id: this.projectId
    })
  }
}

ProcedureListCtrl.$inject = ['psProcedureService', '$log', '$stateParams', '$state']

export default ProcedureListCtrl
