import { Link } from '@integrator/api'

export class ProcedureLinksStructure {
  /**
   * @type {Object} structure
   */
  structure

  constructor(props) {
    this.structure = props || {}
  }

  /**
   * @param {string} name - one of these: 'links', 'businessLinks', 'corporateLinks', 'templateLinks'
   * @returns {Link[]}
   */

  getLinks(name) {
    if (this.structure[name]?.empty) {
      return []
    }

    const links = []
    this.structure[name]?.links.forEach(link => {
      links.push(new Link(link))
    })

    return links
  }
}
