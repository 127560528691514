import { ProcedureDeletePermission } from './procedure-delete-permission'

/**
 * Represents a procedure delete permissions.
 */
export class ProcedureDeletePermissions {
  /**
   * The  procedure permissions list.
   * @type {array<ProcedureDeletePermission>}
   */
  list = []
  constructor(permissions) {
    if (permissions) {
      Object.entries(permissions).forEach(([key, value]) => {
        const permission = new ProcedureDeletePermission(key, value)
        this.list.push(permission)
      })
    }
  }
  get allowed() {
    return this.list.every(permission => permission.allowed)
  }
}
