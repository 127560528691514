import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/settings/periodic-control/installation-types`
}

function get(unitId) {
  return ifetch(url(unitId))
}

function getAvailable(unitId) {
  return ifetch(url(unitId) + '/available')
}

function newInstallationType(unitId, installationTypeJson) {
  return ifetch(url(unitId), {
    method: 'PUT',
    body: JSON.stringify(installationTypeJson)
  })
}

function addFromTemplate(unitId, templateId) {
  return ifetch(url(unitId) + `/from-template/${templateId}`, {
    method: 'PUT'
  })
}

function switchUselessTemplate(unitId, templateId) {
  return ifetch(url(unitId) + `/useless-template/${templateId}`, { method: 'POST' })
}

export { get, newInstallationType, getAvailable, addFromTemplate, switchUselessTemplate }
