import { ifetch } from '@integrator/api'

/**
 * Generate url for record fields.
 *
 * @param { FileSystemContext } context
 * @param { number } recordId
 * @returns {string} url
 */
function url(context, recordId) {
  return `/api/filesystems/${context.name}/${context.relationId}/records/${recordId}/fields`
}

/**
 * Update all fields for record.
 *
 * @param { FileSystemContext } context
 * @param { number } recordId
 * @param { FieldValues } fieldValues
 * @returns {(function(*): Promise<Fields>)|*}
 */
function update(context, recordId, fieldValues) {
  const fieldValuesJson = fieldValues.toJson()
  const options = { method: 'POST', body: JSON.stringify(fieldValuesJson) }
  return ifetch(url(context, recordId), options)
}

/**
 * Gets all fields for record.
 *
 * @param { FileSystemContext } context
 * @param recordId
 * @returns {(function(*): Promise<Fields>)|*}
 */
function get(context, recordId) {
  return ifetch(url(context, recordId))
}

export { get, update }
