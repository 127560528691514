'use strict'

/**
 * Common interceptor for starting an stopping
 * loading indicator based on HTTP requests.
 * NB: Cannot use class here, due to impl of interceptors.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */

import Notifications from '../services/service.notifications.js'

const SpinnerInterceptor = function ($q, $log, Notification, EVENTS) {
  let self = {}
  self.requestNumber = 0
  self.$log = $log
  self.$q = $q

  // Request interceptor.
  self.request = function (config) {
    if (self.requestNumber === undefined) {
      self.requestNumber = 0
    }
    self.requestNumber += 1
    self.startLoader()

    return config || self.$q.when(config)
  }

  // Response interceptor.
  self.response = response => {
    if (--self.requestNumber === 0) {
      self.stopLoader()
    }

    return response || self.$q.when(response)
  }

  // Response error interceptor
  self.responseError = response => {
    if (!--self.requestNumber) {
      self.stopLoader()
    }
    return self.$q.reject(response)
  }

  self.stopLoader = function () {
    Notification.broadcast(EVENTS.STOP_HTTP_LOADING)
  }
  self.startLoader = function () {
    Notification.broadcast(EVENTS.START_HTTP_LOADING)
  }

  return self
}

SpinnerInterceptor.$inject = ['$q', '$log', 'Notification', 'EVENTS']

const interceptConfig = function ($httpProvider) {
  $httpProvider.interceptors.push('spinnerInterceptor')
}

interceptConfig.$inject = ['$httpProvider']

export default angular
  .module('ps.service.spinner', [Notifications])
  .service('spinnerInterceptor', SpinnerInterceptor)
  .config(interceptConfig).name
