import { integratorUrl, jwtToken } from '../ifetch'

function url(deviationId) {
  return `/api/deviations/pdf/${deviationId}`
}

function download(deviationId) {
  const integratorHost = integratorUrl()
  const token = jwtToken()
  const address = `${integratorHost}/ny${url(deviationId)}?token=${token}`
  window.open(address)
}

export { download }
