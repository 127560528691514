import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedure-catalog`
}

/**
 * Catalog with templates.
 *
 * @param unitId
 */
function getCatalog(unitId) {
  return ifetch(url(unitId))
}

/**
 * Full catalog with active and inactive procedures.
 *
 * @param unitId
 * @returns {(function(*): Promise<string|any>)|*}
 */
function fullCatalog(unitId) {
  return ifetch(url(unitId) + '/full')
}

/**
 * Catalog only with unit procedures.
 *
 * @param unitId
 */
function getUnitCatalog(unitId) {
  return ifetch(url(unitId) + '/unit')
}

/**
 * Flat catalog with unit procedures.
 *
 * @param unitId
 */
function getUnitFlatCatalog(unitId) {
  return ifetch(url(unitId) + '/flat-unit')
}

/**
 * Make catalog similar to unit content.
 *
 * @param unitId
 */
function alignCatalogWithContent(unitId) {
  return ifetch(url(unitId) + '/align-with-content', {
    method: 'POST'
  })
}

export { getCatalog, fullCatalog, getUnitCatalog, getUnitFlatCatalog, alignCatalogWithContent }
