import { css } from 'lit'

export const editorStyles = css`
  .editor-container {
    border: 1px solid var(--sl-color-neutral-300);
    border-radius: 3px;
    margin-bottom: var(--sl-spacing-medium);
  }
  .editor-page {
    min-height: 40px;
  }
  .editor-view {
    padding-left: var(--sl-spacing-x-small);
    padding-right: var(--sl-spacing-x-small);
  }
  .editor-menu {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--sl-color-neutral-300);
  }
  .menu-items {
    width: 50%;
    margin: 0 0 0 75px;
    padding-top: 2px;
  }
  .editor-title-slot {
    padding: 2px 1em 0 0;
    margin-left: auto;
  }
  .editor-controls {
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--sl-color-neutral-300);
  }
  .controls-row {
    width: 100%;
    margin: 2px 2px 2px 75px;
    padding: 2px;
  }
  img {
    max-width: 100%;
  }
`
