import { ifetch } from '@integrator/api'

function url(unitId, procedureId) {
  return `/api/business/${unitId}/procedures/${procedureId}`
}

function get(unitId, procedureId) {
  return ifetch(url(unitId, procedureId))
}

function manual(unitId, procedureId) {
  return ifetch(url(unitId, procedureId) + '/manual')
}

function manualState(unitId, procedureId) {
  return ifetch(url(unitId, procedureId) + '/manual-state')
}

function generalInformation(unitId, procedureId) {
  return ifetch(url(unitId, procedureId) + '/specific/information')
}

function changeGeneralInformation(unitId, procedureId, info) {
  return ifetch(url(unitId, procedureId) + '/specific/information', {
    method: 'POST',
    body: JSON.stringify(info)
  })
}

function canBeDeleted(unitId, procedureId, complete) {
  return ifetch(url(unitId, procedureId) + '/can-be-deleted/' + complete)
}

function changeExternalAccess(unitId, procedureId, client, permission) {
  return ifetch(url(unitId, procedureId) + '/external-access/' + client, {
    method: 'POST',
    body: JSON.stringify(permission)
  })
}

function rename(unitId, procedureId, newName) {
  return ifetch(url(unitId, procedureId) + '/rename', {
    method: 'POST',
    body: newName
  })
}

function moveProcedureToCategory(unitId, procedureId, categoryId) {
  return ifetch(url(unitId, procedureId) + '/move-to-category/' + categoryId, {
    method: 'POST'
  })
}

function newParagraph(unitId, procedureId, paragraphDefinition) {
  return ifetch(url(unitId, procedureId) + '/new-paragraph', {
    method: 'POST',
    body: JSON.stringify(paragraphDefinition)
  })
}

function addParagraph(unitId, procedureId, paragraphDefinitionId) {
  return ifetch(url(unitId, procedureId) + '/new-paragraph/' + paragraphDefinitionId, {
    method: 'POST'
  })
}

function approve(unitId, procedureId, revision) {
  switch (revision) {
    case 'INCREMENT_REVISION':
    case 'KEEP_CURRENT_REVISION':
      return ifetch(url(unitId, procedureId) + `/approve/${revision}`, {
        method: 'POST'
      })
  }
}

function manualHistory(unitId, procedureId, version) {
  return ifetch(url(unitId, procedureId) + `/content/${version}`)
}

export {
  get,
  manual,
  manualState,
  generalInformation,
  changeGeneralInformation,
  canBeDeleted,
  changeExternalAccess,
  rename,
  moveProcedureToCategory,
  newParagraph,
  addParagraph,
  approve,
  manualHistory
}
