import { jwtToken, integratorUrl } from '@integrator/api'

function open(projectId, procedureId, checklistId) {
  const token = jwtToken()
  const integratorHost = integratorUrl()
  const url = `${integratorHost}/ny/api/checklists/pdf/${projectId}/${procedureId}/${checklistId}/reprint?token=${token}`
  window.open(url, '_blank')
}

export { open }
