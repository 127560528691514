import { ifetch } from '@integrator/api'

function url(unitId, componentTemplateId) {
  return `/api/business/${unitId}/settings/periodic-control/component-templates/${componentTemplateId}`
}

function update(unitId, componentTemplateId, definitionJson) {
  return ifetch(url(unitId, componentTemplateId), {
    method: 'PATCH',
    body: JSON.stringify(definitionJson)
  })
}

function changeData(unitId, componentTemplateId, data) {
  return ifetch(url(unitId, componentTemplateId) + '/data', {
    method: 'PATCH',
    body: data
  })
}

function activate(unitId, controlTypeId) {
  return ifetch(url(unitId, controlTypeId) + '/activate', {
    method: 'PATCH'
  })
}

function deactivate(unitId, controlTypeId) {
  return ifetch(url(unitId, controlTypeId) + '/deactivate', {
    method: 'PATCH'
  })
}

function deleteControlType(unitId, componentTemplateId) {
  return ifetch(url(unitId, componentTemplateId), {
    method: 'delete'
  })
}

function saveExtraData(unitId, componentTemplateId, template) {
  return ifetch(url(unitId, componentTemplateId) + `/data`, {
    method: 'PATCH',
    body: JSON.stringify(template)
  })
}

export { activate, changeData, deactivate, update, deleteControlType, saveExtraData }
