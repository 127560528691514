import { ifetch } from '@integrator/api'

function url() {
  return `/api/deviations`
}

function add(businessModule, newDeviation) {
  return ifetch(url() + `/${businessModule}`, { method: 'POST', body: JSON.stringify(newDeviation) })
}

function archive(deviationPrimaryIds) {
  return ifetch(url() + `/archive`, { method: 'POST', body: JSON.stringify(deviationPrimaryIds) })
}

function activate(deviationPrimaryIds) {
  return ifetch(url() + `/activate`, { method: 'POST', body: JSON.stringify(deviationPrimaryIds) })
}

export { add, archive, activate }
