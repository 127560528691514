import { ifetch } from '@integrator/api'

function baseUrl(deviationId, reportId) {
  return `/api/deviations/${deviationId}/reports/${reportId}`
}

function updateReport(deviationId, reportId, newReport) {
  return ifetch(baseUrl(deviationId, reportId), { method: 'POST', body: JSON.stringify(newReport) })
}

// no response value
function remove(deviationId, reportId) {
  return ifetch(baseUrl(deviationId, reportId), { method: 'DELETE' })
}

export { updateReport, remove }
