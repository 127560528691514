import { DeviationErrorGroup } from './deviation-error-group'

class DeviationErrorCode {
  group
  id
  code
  description
  reference
  constructor(props) {
    this.group = new DeviationErrorGroup(props?.group)
    if (props) {
      this.id = props?.id
      this.code = props?.code
      this.description = props?.description
      this.reference = props?.reference
    }
  }

  toString() {
    if (this.id) {
      return `${this.code} ${this.reference} ${this.description}`
    }
    return ''
  }
}

export { DeviationErrorCode }
