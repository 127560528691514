import { ifetch } from '@integrator/api'
import { ForgotPasswordRequest } from './model/forgot-password-request'

function url() {
  return '/api/portal/security'
}

function activateUser(token, newPassword) {
  return ifetch(url() + '/activate/' + token, {
    method: 'POST',
    body: newPassword
  })
}

/**
 * @property {ForgotPasswordRequest} forgotPasswordRequest
 * @returns {(function(*): Promise<*>)|*}
 */
function forgotPassword(forgotPasswordRequest) {
  return ifetch(url() + '/forgot/', {
    method: 'POST',
    body: JSON.stringify(forgotPasswordRequest)
  })
}

function resetPassword(token, newPassword) {
  return ifetch(url() + '/reset-password?token=' + token, {
    method: 'POST',
    body: newPassword
  })
}

export { activateUser, forgotPassword, resetPassword }
