import { ifetch } from '@integrator/api'

function url(projectId, procedureId) {
  return `/api/projects/${projectId}/procedures/${procedureId}/manual/paragraphs`
}

function addProjectText(projectId, procedureId, definitionId, editorText) {
  return ifetch(url(projectId, procedureId) + '/add-project-text/' + definitionId, {
    method: 'POST',
    body: JSON.stringify(editorText)
  })
}

function equalize(unitId, procedureId, definitionName) {
  return ifetch(url(unitId, procedureId) + '/equalize', {
    method: 'POST',
    body: definitionName
  })
}

function boostTextVersion(unitId, procedureId) {
  return ifetch(url(unitId, procedureId) + '/boost-text-version', {
    method: 'POST'
  })
}

function equalizeToTemplate(unitId, procedureId) {
  return ifetch(url(unitId, procedureId) + '/equalize-to-template', {
    method: 'POST'
  })
}

export { addProjectText, equalize, boostTextVersion, equalizeToTemplate }
