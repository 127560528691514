import { Persons } from '../../universal'
import { DeviationFields } from './deviation-fields'
import { DeviationErrorCodes } from './deviation-error-codes'
import { BusinessModule } from '../../business'
import { DeviationManagementBy } from '@integrator/api'

/**
 * @typedef {Object} NewDeviationConfig
 * @property {BusinessModule} module
 * @property {DeviationManagementBy} managedBy
 * @property {DeviationErrorCodes} errorCodes
 * @property {DeviationFields} fields
 * @property {String[]} severities
 * @property {Persons} internalCaseManagers
 * @property {Persons} externalCaseManagers
 * @property {Persons} internalResponsibleUsers
 * @property {Persons} externalResponsibleUsers
 */

class NewDeviationConfig {
  module
  managedBy
  errorCodes
  fields
  severities
  internalCaseManagers
  externalCaseManagers
  internalResponsibleUsers
  externalResponsibleUsers

  constructor(props) {
    this.module = new BusinessModule(props.module)
    this.managedBy = new DeviationManagementBy(props.managedBy)
    this.errorCodes = new DeviationErrorCodes(props.errorCodes)
    this.fields = new DeviationFields(props.fields)
    this.severities = props.severities || []
    this.internalCaseManagers = new Persons(props.internalCaseManagers)
    this.externalCaseManagers = new Persons(props.externalCaseManagers)
    this.internalResponsibleUsers = new Persons(props.internalResponsibleUsers)
    this.externalResponsibleUsers = new Persons(props.externalResponsibleUsers)
  }

  mapUsers() {
    function toList({ list }) {
      return list.map(item => ({ id: item.userId, label: item.fullName, selected: false }))
    }

    return {
      [DeviationManagementBy.INTERNAL.name]: {
        CASE_MANAGER: toList(this.internalCaseManagers),
        RESPONSIBLE: toList(this.internalResponsibleUsers)
      },
      [DeviationManagementBy.EXTERNAL.name]: {
        CASE_MANAGER: toList(this.externalCaseManagers),
        RESPONSIBLE: toList(this.externalResponsibleUsers)
      }
    }
  }
}

export { NewDeviationConfig }
