/**
 * Data service for Authentication.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
const logTag = 'UserDataService'
import constants from '../../../config/config.constants.js'
import Cookies from 'js-cookie'

class SecurityDataService {
  constructor($http, $log, apiConfig, $q) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.endpoint = new SecurityEndpoint(apiConfig)
  }

  /**
   * Login request.
   */
  login(payload) {
    let asyncResult = this.$q.defer()
    this.$http
      .post(this.endpoint.login(payload.unit), { username: payload.username, password: payload.password })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        asyncResult.reject(error)
        this.$log.error('XHR failed for POST /security/login with error: ' + error.status + ' ' + error.statusText)
      })
    return asyncResult.promise
  }

  /**
   * Forgot password.
   */
  forgot(payload) {
    let asyncResult = this.$q.defer()
    this.$http
      .post(this.endpoint.forgot(), payload)
      .then(response => asyncResult.resolve(response))
      .catch(error => {
        this.$log.error(
          'XHR failed for POST ' + this.endpoint.forgot() + ' with error: ' + error.status + ' ' + error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Reset password (with server token).
   *
   * @param payload - email, url
   * @param token - server token
   * @returns {*}
   */
  resetPassword(payload, token) {
    let asyncResult = this.$q.defer()
    this.$http
      .post(this.endpoint.resetPassword(token), payload)
      .then(response => asyncResult.resolve(response))
      .catch(error => {
        this.$log.error(
          'XHR failed for POST ' +
            this.endpoint.resetPassword(token) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Activate user and set password
   */

  activateAndSetPassword(payload, activation) {
    let asyncResult = this.$q.defer()
    this.$http
      .post(this.endpoint.activateAndSetPassword(activation), payload)
      .then(response => asyncResult.resolve(response))
      .catch(error => {
        this.$log.error(
          'XHR failed for POST ' +
            this.endpoint.activateAndSetPassword(activation) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }
}
SecurityDataService.$inject = ['$http', '$log', 'apiConfig', '$q']

class SecurityEndpoint {
  constructor(apiConfig) {
    this.apiUrl = apiConfig.url()
    this.path = apiConfig.url() + 'portal/security'
  }

  forgot() {
    return this.path + '/forgot'
  }

  resetPassword(token) {
    return this.path + '/reset-password?token=' + token
  }

  login(businessUnitId) {
    if (!!businessUnitId) {
      return this.apiUrl + 'portal/authenticate/' + businessUnitId
    }
    return this.apiUrl + 'portal/authenticate/'
  }

  valid() {
    return this.path + '/valid'
  }

  activateAndSetPassword(activation) {
    return this.path + '/activate-user?token=' + activation
  }
}
SecurityEndpoint.$inject = ['apiConfig']

/**
 * Export this service as a separate module.
 */
export default angular.module('service.data.security', [constants]).service('securityDataService', SecurityDataService)
  .name
