import { CheckpointModeName, Definition } from '@integrator/api'

class CheckpointTemplate {
  /** @type {Number} */
  id
  /** @type {Definition} */
  definition
  /** @type {CheckpointModeName} */
  modeName
  /** @type {number} */
  sortOrder
  /** @type {string} */
  guidance

  /**
   * @param {CheckpointTemplate} checkpointTemplate
   */
  constructor(props) {
    this.id = props.definition.id
    this.definition = new Definition(props.definition)
    this.modeName = new CheckpointModeName(props.modeName)
    this.sortOrder = props.sortOrder
    this.guidance = props.guidance
  }

  toJSON() {
    return {
      id: this.id,
      definition: this.definition,
      modeName: this.modeName.toJSON(),
      sortOrder: this.sortOrder,
      guidance: this.guidance
    }
  }
}

export { CheckpointTemplate }
