import { ifetch } from '@integrator/api'

function url() {
  return `api/deviations`
}

function detailsByRelationIds(relationName, relationIds) {
  return ifetch(url() + `/search/${relationName}/details`, { method: 'POST', body: JSON.stringify(relationIds) })
}

/**
 * Search for deviations.
 * Best use case is list presentation.
 *
 * @param id {Number} number of the relation object
 * @param name {String} name of the relation object
 * @param {DeviationsCriteria} criteria - The criteria for the search.
 * @returns {(function(*): Promise<*>)|*}
 */
function search(id, name, criteria) {
  return ifetch(url() + `/search/${name}/${id}`, { method: 'POST', body: JSON.stringify(criteria) })
}

function searchForDetailsWithStoredConfig(relationName, relationId) {
  return ifetch(url() + `/search-for-details/${relationName}/${relationId}`)
}

function setSearchCriteria(deviationRelationName, deviationsCriteria) {
  localStorage.setItem(`${deviationRelationName}.deviation-criteria`, JSON.stringify(deviationsCriteria))
}

function getSearchCriteria(deviationRelationName) {
  let deviationCriteriaText = localStorage.getItem(`${deviationRelationName}.deviation-criteria`)
  let deviationCriteria
  if (deviationCriteriaText) {
    try {
      deviationCriteria = JSON.parse(deviationCriteriaText)
    } catch (e) {}
  }
  return deviationCriteria
}

export { detailsByRelationIds, search, searchForDetailsWithStoredConfig, setSearchCriteria, getSearchCriteria }
