import { ifetch } from '@integrator/api'

function baseUrl() {
  return `/api/mom/general-elements`
}

function list() {
  return ifetch(baseUrl(), { method: 'GET' })
}

export { list }
