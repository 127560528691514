import { Persons } from '../../universal'
import { DeviationErrorCodes } from './deviation-error-codes'
import { DeviationFields } from './deviation-fields'
import { DeviationManagementBy } from './deviation-management-by'

class SearchDeviationConfig {
  firstDeviationDate
  modules
  moduleLevels
  managedBy
  errorCodes
  fields
  internalCaseManagers
  externalCaseManagers
  internalResponsibleUsers
  externalResponsibleUsers
  constructor(props) {
    this.firstDeviationDate = props?.firstDeviationDate
    this.modules = props?.modules
    this.moduleLevels = props?.moduleLevels
    this.managedBy = new DeviationManagementBy(props.managedBy)
    this.errorCodes = new DeviationErrorCodes(props?.errorCodes)
    this.fields = new DeviationFields(props?.fields)
    this.internalCaseManagers = new Persons(props?.internalCaseManagers)
    this.externalCaseManagers = new Persons(props?.externalCaseManagers)
    this.internalResponsibleUsers = new Persons(props?.internalResponsibleUsers)
    this.externalResponsibleUsers = new Persons(props?.externalResponsibleUsers)
  }
}
export { SearchDeviationConfig }
