import { DeviationStatusName } from './deviation-status-name'

class DeviationStatus {
  archived
  name
  sortOrder
  constructor(props) {
    this.archived = props?.archived
    if (props?.name) {
      this.name = new DeviationStatusName(props.name)
    }
    this.sortOrder = props?.sortOrder
  }
}

export { DeviationStatus }
