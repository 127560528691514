/**
 * FDV module
 *@author Rannveig A. Skjerve (rannveig.a.skjerve@gmail.com)
 */

//Dependencies
import router from 'angular-ui-router'
import routes from './fdv.routes.js'

//Styles
import './detail/component/fdv.detail.component.scss'
//Controllers
import FdvParentCtrl from './fdv.parent.controller.js'
import FdvDetailCtrl from './detail/fdv.detail.controller.js'
import FdvComponentCtrl from './detail/component/fdv.detail.component.controller.js'

//Services
import psFdvService from '../../common/services/data/fdv.dataservice.js'
import ProjectDataService from '../../common/services/data/projects.dataservice.js'
import DownloadDataService from '../../common/services/data/download.dataservice.js'
import GeneratePdfService from '../../common/services/data/pdf.generate.dataservice.js'

//Directives
import ImageGalleryDirective from '../../common/directives/lists/images/list.images.directive.js'
import DrawingListDirective from '../../common/directives/lists/images/list.images.directive.js'
import DocumentListDirective from '../../common/directives/lists/images/list.images.directive.js'
import ProjectHeader from '../../common/directives/headers/project.info.header.js'

export default angular
  .module('ps.module.fdv', [
    router,
    psFdvService,
    DownloadDataService,
    GeneratePdfService,
    ProjectDataService,
    ImageGalleryDirective,
    DrawingListDirective,
    ProjectHeader
  ])
  .controller('FdvParentCtrl', FdvParentCtrl)
  .controller('FdvDetailCtrl', FdvDetailCtrl)
  .controller('FdvComponentCtrl', FdvComponentCtrl)
  .config(routes).name
