import { ifetch } from '@integrator/api'

function url(unitId, userId, profileId) {
  return `/api/business/${unitId}/users/${userId}/settings/notification-profiles/${profileId}`
}

function deleteProfile(unitId, userId, profileId) {
  return ifetch(url(unitId, userId, profileId), {
    method: 'DELETE'
  })
}

function updateOccurrencesName(unitId, userId, profileId, name) {
  return ifetch(url(unitId, userId, profileId), {
    method: 'PUT',
    body: name
  })
}

function updateOccurrences(unitId, userId, profileId, occurrences) {
  return ifetch(url(unitId, userId, profileId) + '/occurrences', {
    method: 'PUT',
    body: JSON.stringify(occurrences)
  })
}

function activate(unitId, userId, profileId) {
  return ifetch(url(unitId, userId, profileId) + '/activate', {
    method: 'PATCH'
  })
}

function deactivate(unitId, userId, profileId) {
  return ifetch(url(unitId, userId, profileId) + '/deactivate', {
    method: 'PATCH'
  })
}

export { activate, deactivate, deleteProfile, updateOccurrences, updateOccurrencesName }
