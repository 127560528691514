'use strict'

class WebComponentController {
  constructor($scope, $state) {
    $scope.$state = $state
  }
}

WebComponentController.$inject = ['$scope', '$state']

export default WebComponentController
