/**
 * @typedef { object } FileSystemFeatures
 * @property { boolean } hasFields
 * @property { boolean } hasNetwork
 * @property { boolean } hasSecurity
 * @property { boolean } hasVersioning
 * @property { boolean } hasSignature
 */
class FileSystemFeatures {
  hasFields
  hasNetwork
  hasSecurity
  hasVersioning
  hasSignature

  constructor(props) {
    Object.assign(this, props)
  }
}

export { FileSystemFeatures }
