import { ifetch } from '@integrator/api'

function url(projectId) {
  return `/api/projects/${projectId}/procedures`
}

function assign(projectId, procedureId) {
  return ifetch(url(projectId) + `/${procedureId}/assign`, {
    method: 'POST'
  })
}

function batchAssign(projectId, procedureIds) {
  return ifetch(url(projectId) + `/batch-assign`, {
    method: 'POST',
    body: JSON.stringify(procedureIds)
  })
}

function add(projectId, categoryId, name) {
  return ifetch(url(projectId) + `/add/${categoryId}/`, {
    method: 'POST',
    body: name
  })
}

function structure(projectId) {
  return ifetch(url(projectId) + `/structure`, {
    method: 'GET'
  })
}

function assignedStructure(projectId) {
  return ifetch(url(projectId) + `/assigned-structure`, {
    method: 'GET'
  })
}

function assigned(projectId) {
  return ifetch(url(projectId) + `/assigned`, {
    method: 'GET'
  })
}

function batchChangeExternalAccess(projectId, procedureIds, client, permission) {
  return ifetch(url(projectId) + `/external-access/${client}/${permission}`, {
    method: 'POST',
    body: JSON.stringify(procedureIds)
  })
}

export { assign, batchAssign, add, structure, assignedStructure, assigned, batchChangeExternalAccess }
