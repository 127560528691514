import { ifetch } from '@integrator/api'

function baseUrl(contentId, checklistId) {
  if (contentId) {
    return `/api/${contentId}/checklists/template/${checklistId}`
  } else {
    return `/api/checklists/template/${checklistId}`
  }
}

/**
 * @param contentId
 * @param checklistId
 * @returns {(function(*): Promise<*>)|*}
 */
function checklist(contentId, checklistId) {
  return ifetch(baseUrl(contentId, checklistId), { method: 'GET' })
}

// no response value
function update(contentId, checklistId, definition) {
  return ifetch(baseUrl(contentId, checklistId) + `/definition`, {
    method: 'POST',
    body: JSON.stringify(definition)
  })
}

// no response value
function updateStatus(contentId, checklistId, statusName) {
  return ifetch(baseUrl(contentId, checklistId) + `/status`, {
    method: 'POST',
    body: statusName
  })
}

// no response value
function remove(contentId, checklistId) {
  return ifetch(baseUrl(contentId, checklistId), { method: 'DELETE' })
}

// no response value
function updateSubjectAreas(contentId, checklistId, subjectAreaIds) {
  return ifetch(baseUrl(contentId, checklistId) + `/subject-area`, {
    method: 'POST',
    body: JSON.stringify(subjectAreaIds)
  })
}

function subjectArea(contentId, checklistId) {
  return ifetch(baseUrl(contentId, checklistId) + `/subject-area`, { method: 'GET' })
}

export { checklist, remove, updateSubjectAreas, subjectArea, updateStatus, update }
