/**
 * @typedef {Object} BusinessContentName
 * @property {string} name
 */
class BusinessContentName {
  static DEVELOPMENT = new BusinessContentName('DEVELOPMENT')
  static NELFO = new BusinessContentName('NELFO')
  static BYGGMESTERFORBUNDET = new BusinessContentName('BYGGMESTERFORBUNDET')
  static NORSKE_TREVARER = new BusinessContentName('NORSKE_TREVARER')
  static GLASS_OG_FASADEFORENINGEN = new BusinessContentName('GLASS_OG_FASADEFORENINGEN')
  static VVSEKSPERTEN = new BusinessContentName('VVSEKSPERTEN')
  static HANDVERKSDATA = new BusinessContentName('HANDVERKSDATA')
  static NO_CONTENT = new BusinessContentName('NO_CONTENT')

  name
  constructor(content) {
    if (content instanceof BusinessContentName) {
      this.name = content.name
    } else {
      this.name = content
    }
  }
  toJSON() {
    return this.name
  }

  toString() {
    return this.name
  }
}

export { BusinessContentName }
