import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}`
}

/**
 * Get basic information about business unit
 *
 * @param unitId id of unit
 */
function information(unitId) {
  return ifetch(url(unitId) + '/information')
}

export { information }
