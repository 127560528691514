import { Person } from './person'

/**
 * @typedef {Object} Persons
 * @property {Person[]} list
 */
class Persons {
  /**
   * @type {Person[]}
   */
  list

  constructor(props) {
    this.list = props?.list?.map(item => new Person(item)) || []
  }
}

export { Persons }
