import { ifetch } from '@integrator/api'

function url(businessUnitId, competencyId, iterationId) {
  // /api/business/{businessUnitId}/competences/{competencyId}/iterations/{iterationId}/participants
  return `api/business/${businessUnitId}/competences/${competencyId}/iterations/${iterationId}/participants`
}

function addParticipant(businessUnitId, competencyId, iterationId, userId) {
  return ifetch(url(businessUnitId, competencyId, iterationId) + `/${userId}`, { method: 'POST' })
}

function getParticipants(businessUnitId, competencyId, iterationId) {
  return ifetch(url(businessUnitId, competencyId, iterationId), { method: 'GET' })
}

export { addParticipant, getParticipants }
