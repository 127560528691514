import { ifetch } from '@integrator/api'

function url(businessUnitId, contactId, contactPersonId) {
  return `/api/business/${businessUnitId}/contacts/${contactId}/persons/${contactPersonId}`
}

function getPerson(businessUnitId, contactId, contactPersonId) {
  return ifetch(url(businessUnitId, contactId, contactPersonId), {
    method: 'GET'
  })
}

function updatePerson(businessUnitId, contactId, contactPersonId, contactPersonStoreJson) {
  return ifetch(url(businessUnitId, contactId, contactPersonId), {
    method: 'PUT',
    body: JSON.stringify(contactPersonStoreJson)
  })
}

function deletePerson(businessUnitId, contactId, contactPersonId) {
  return ifetch(url(businessUnitId, contactId, contactPersonId), {
    method: 'DELETE'
  })
}

function updateMainContact(businessUnitId, contactId, contactPersonId, person) {
  return ifetch(url(businessUnitId, contactId, contactPersonId) + `/main-contact`, {
    method: 'PATCH',
    body: person?.mainContact
  })
}

function sendPassword(businessUnitId, contactId, contactPersonId, password) {
  return ifetch(url(businessUnitId, contactId, contactPersonId) + `/password`, {
    method: 'PATCH',
    body: password
  })
}

export { getPerson, updatePerson, deletePerson, updateMainContact, sendPassword }
