import { DeviationManagementBy } from './deviation-management-by'

/**
 * @typedef {Object} DeviationMetaData
 * @property {Date} discoveryDate
 * @property {string} severity
 * @property {number} errorCodeId
 * @property {number} fieldId
 * @property {string} location
 * @property {number} caseManagerId
 * @property {DeviationManagementBy} managedBy
 * @property {number} internalResponsibleForExecutionId
 * @property {number} externalResponsibleForExecutionId
 * @property {Date} deadline
 * @property {string} module
 */
class DeviationMetaData {
  discoveryDate
  severity
  errorCodeId
  fieldId
  location
  caseManagerId
  internalCaseManagerId
  externalCaseManagerId
  managedBy
  internalResponsibleForExecutionId
  externalResponsibleForExecutionId
  deadline
  module

  constructor(props) {
    if (props) {
      this.discoveryDate = props.discoveryDate
      this.severity = props.severity
      this.errorCodeId = props.errorCodeId
      this.fieldId = props.fieldId
      this.location = props.location
      this.caseManagerId = props.caseManagerId
      this.internalCaseManagerId = props.internalCaseManagerId
      this.externalCaseManagerId = props.externalCaseManagerId
      this.managedBy = props.managedBy ? new DeviationManagementBy(props.managedBy) : undefined
      this.internalResponsibleForExecutionId = props.internalResponsibleForExecutionId
      this.externalResponsibleForExecutionId = props.externalResponsibleForExecutionId
      this.deadline = props.deadline
      this.module = props.module
    }
  }

  /**
   * Construct deviation meta data from deviation.
   *
   * @param deviation
   * @returns {DeviationMetaData}
   */
  static fromDeviation(deviation) {
    return new DeviationMetaData({
      discoveryDate: deviation.discoveryDate,
      severity: deviation.severity,
      errorCodeId: deviation.errorCode?.id,
      fieldId: deviation.field?.id,
      location: deviation.location,
      caseManagerId: deviation.management?.caseManager?.id,
      managedBy: deviation.management?.managedBy,
      internalResponsibleForExecutionId: deviation.management?.internalResponsibleForExecution?.id,
      externalResponsibleForExecutionId: deviation.management?.externalResponsibleForExecution?.id,
      internalCaseManagerId: deviation.management?.internalCaseManager?.id,
      externalCaseManagerId: deviation.management?.externalCaseManager?.id,
      deadline: deviation.management?.deadline,
      module: deviation.module
    })
  }
}

export { DeviationMetaData }
