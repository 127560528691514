import { InstallationType } from './installation-type'

class InstallationTypes {
  list
  constructor(props) {
    this.list = props.list?.map(type => new InstallationType(type))
  }
}

export { InstallationTypes }
