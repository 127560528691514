import { ContainerStructure } from './container-structure'

/**
 * @typedef { object } ContainersStructure
 * @property { Map<Number, ContainerStructure> } map
 */
class ContainersStructure {
  map

  constructor(props) {
    this.map = new Map()
    if (props.map) {
      for (const [relationId, containerStructure] of Object.entries(props.map)) {
        this.map.set(Number(relationId), new ContainerStructure(containerStructure))
      }
    }
  }

  structureFor(relationId) {
    if (this.map.has(relationId)) {
      return this.map.get(relationId)
    } else {
      return new ContainerStructure({})
    }
  }

  addStructure(relationId, containerStructure) {
    this.map.set(relationId, containerStructure)
  }

  isEmpty() {
    return this.map.size <= 0
  }
}

export { ContainersStructure }
