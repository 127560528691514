/**
 * @typedef {Object} NameIdentity
 * @property {number} id
 * @property {string} name
 */
class NameIdentity {
  id
  name
  constructor(props) {
    Object.assign(this, props)
  }
}

export { NameIdentity }
