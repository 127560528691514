import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, productId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/products/${productId}`
}

// no response value
function setThumbnail(unitId, projectId, productId, imageUrl) {
  return ifetch(baseUrl(unitId, projectId, productId) + `/thumbnail`, {
    method: 'POST',
    body: imageUrl
  })
}

export { setThumbnail }
