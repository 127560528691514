import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, extension) {
  return `/api/business/${unitId}/projects/${projectId}/mom/pdf/${extension}`
}

function lastFile(unitId, projectId, extension) {
  return ifetch(baseUrl(unitId, projectId, extension) + `/lastFile`, { method: 'GET' })
}

function generate(unitId, projectId, extension, language) {
  return ifetch(baseUrl(unitId, projectId, extension) + `/${language}`, { method: 'POST' })
}

function status(unitId, projectId, extension) {
  return ifetch(baseUrl(unitId, projectId, extension) + `/status`, { method: 'GET' })
}

export { lastFile, generate, status }
