import { ifetch } from '@integrator/api'

function usersApiUrl(unitId, action) {
  return `/api/business/${unitId}/users/${action}`
}

function currentUser(unitId) {
  return ifetch(usersApiUrl(unitId, 'current'), {})
}

function mobileDevices(unitId) {
  return ifetch(usersApiUrl(unitId, 'mobile-devices'), {})
}

function mobileUsers(unitId) {
  return ifetch(usersApiUrl(unitId, 'mobile'), {})
}

function asEmployees(unitId) {
  return ifetch(usersApiUrl(unitId, 'as-employees'), {})
}

function searchById(unitId, userIds) {
  return ifetch(usersApiUrl(unitId, 'search' + `/byIds`), { method: 'POST', body: JSON.stringify(userIds) })
}

function usersCanManage(unitId, userId) {
  return ifetch(usersApiUrl(unitId, 'can-manage' + `/${userId}`), {})
}

export { currentUser, mobileDevices, mobileUsers, asEmployees, usersCanManage, searchById }
