import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/projects/periodic-control`
}

function numberOfContractsWithinEachSector(unitId) {
  return ifetch(url(unitId) + '/statistics/contracts-per-sector')
}

function numberOfControlsWithinEachControlType(unitId) {
  return ifetch(url(unitId) + '/statistics/controls-per-controlType')
}

function contractStatistics(unitId) {
  return ifetch(url(unitId) + '/statistics/contracts')
}

function contractsTrend(unitId) {
  return ifetch(url(unitId) + '/statistics/contracts-trend')
}

function executedControlsMonthlyTrend(unitId) {
  return ifetch(url(unitId) + '/statistics/controls/executed-monthly-trend')
}

function projectWorkload(unitId) {
  return ifetch(url(unitId) + '/project-workload', {
    method: 'POST'
  })
}

function controlWorkload(unitId) {
  return ifetch(url(unitId) + '/control-workload', {
    method: 'POST'
  })
}

function monthlyControlWorkload(unitId) {
  return ifetch(url(unitId) + '/statistics/control/monthly-workload', {
    method: 'GET'
  })
}

function statisticsDeviationSeverity(unitId) {
  return ifetch(url(unitId) + '/statistics/deviation/severity', {
    method: 'GET'
  })
}

export {
  monthlyControlWorkload,
  executedControlsMonthlyTrend,
  contractStatistics,
  contractsTrend,
  numberOfControlsWithinEachControlType,
  numberOfContractsWithinEachSector,
  projectWorkload,
  controlWorkload,
  statisticsDeviationSeverity
}
