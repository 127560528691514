'use strict'
/**
 * Directive for listing issues.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import angularMaterial from 'angular-material'
import router from 'angular-ui-router'
import dialog from '../../../services/dialogs/issues/dialogs.issues.js'
import DeviationsDataService from '../../../services/data/deviations.dataservice.js'
import FilesystemDataService from '../../../services/data/filesystem.dataservice.js'

import './list.issues.directive.scss'
import _ from 'lodash'

class ListIssuesCtrl {
  constructor($state, $log, $scope, $mdDialog, psDeviationsService, psFilesystemService) {
    this.$scope = $scope
    this.$log = $log
    this.$state = $state
    this.$mdDialog = $mdDialog
    this.psDeviationsService = psDeviationsService
    this.psFilesystemService = psFilesystemService

    this.deviations = null
    this.statistics = null

    this.propertyName = 'created'
    this.reverse = true
    this.sortBy = function (propertyName) {
      this.reverse = this.propertyName === propertyName ? !this.reverse : false
      this.propertyName = propertyName
    }
    this.getDeviations(psDeviationsService)
  }

  getDeviations(psDeviationsService) {
    psDeviationsService.fetch(this.$scope.relationName, this.$scope.relationId).then(response => {
      this.deviations = response.data.list
      this.statistics = response.data.openCloseStatistic
    })
  }

  openDeviation(deviation) {
    this.$mdDialog.show(dialog(deviation, this.psDeviationsService, this.psFilesystemService))
  }
}
ListIssuesCtrl.$inject = ['$state', '$log', '$scope', '$mdDialog', 'psDeviationsService', 'psFilesystemService']

export default angular
  .module('ps.directives.list.issues', [router, angularMaterial, DeviationsDataService, FilesystemDataService])
  .controller('ListIssuesCtrl', ListIssuesCtrl)
  .directive('listIssues', function () {
    return {
      restrict: 'E',
      template: require('./list.issues.directive.tpl.html'),
      scope: {
        relationName: '@',
        relationId: '='
      },
      controller: 'ListIssuesCtrl',
      controllerAs: 'vc'
    }
  }).name
