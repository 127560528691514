import { ifetch } from '@integrator/api'

function url(unitId, userId) {
  return `/api/business/${unitId}/users/${userId}/integrations`
}

function get(unitId, userId, integration) {
  return ifetch(url(unitId, userId) + `/${integration}`)
}

export { get }
