import { DeviationNotification } from './deviation-notification'

class DeviationNotifications {
  list
  constructor(props) {
    this.list = props?.list?.map(item => new DeviationNotification(item))
  }
}

export { DeviationNotifications }
