import { ifetch } from '@integrator/api'

function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/customer`
}

function getCustomer(unitId, projectId) {
  return ifetch(url(unitId, projectId))
}

function setCustomer(unitId, projectId, contactId) {
  return ifetch(url(unitId, projectId), {
    method: 'POST',
    body: contactId
  })
}

function deleteCustomer(unitId, projectId) {
  return ifetch(url(unitId, projectId), {
    method: 'DELETE'
  })
}

function setResponsible(unitId, projectId, customerResponsiblePersonJson) {
  return ifetch(url(unitId, projectId) + '/responsible', {
    method: 'POST',
    body: JSON.stringify(customerResponsiblePersonJson)
  })
}

function deleteAllResponsible(unitId, projectId) {
  return ifetch(url(unitId, projectId) + `/responsible/all`, {
    method: 'DELETE'
  })
}

function deleteResponsible(unitId, projectId, contactPersonId) {
  return ifetch(url(unitId, projectId) + `/responsible/${contactPersonId}`, {
    method: 'DELETE'
  })
}

export { getCustomer, setCustomer, deleteCustomer, setResponsible, deleteResponsible, deleteAllResponsible }
