import { Person } from '@integrator/api'
/**
 * Represents a deviation management instance.
 */
export class DeviationManagement {
  /**
   * Creates an instance of DeviationManagement.
   * @property {array} deadline - The deadline for the deviation.
   * @property {string} managedBy - The type for managing the deviation.
   * @property {object} internalCaseManager - The internal case manager responsible for the deviation.
   * @property {object} externalCaseManager - The external case manager responsible for the deviation.
   * @property {object} internalResponsibleForExecution - The internal responsible for executing the deviation.
   * @property {object} externalResponsibleForExecution - The external responsible for executing the deviation.
   */
  constructor(management) {
    this.deadline = management?.deadline
    this.managedBy = management?.managedBy

    this.internalCaseManager = management?.internalCaseManager ? new Person(management?.internalCaseManager) : null
    this.externalCaseManager = management?.externalCaseManager ? new Person(management?.externalCaseManager) : null

    this.internalResponsibleForExecution = management?.internalResponsibleForExecution
      ? new Person(management?.internalResponsibleForExecution)
      : null
    this.externalResponsibleForExecution = management?.externalResponsibleForExecution
      ? new Person(management?.externalResponsibleForExecution)
      : null
  }

  /**
   * Checks if the deviation is managed internally.
   * @returns {boolean} True if managed internally, otherwise false.
   */
  get isInternal() {
    return this.managedBy === 'INTERNAL'
  }

  /**
   * Checks if the deviation has a case manager based on the entity managing it.
   * @returns {boolean} True if there is a case manager, otherwise false.
   */
  get hasCaseManager() {
    return !!(this.isInternal ? this.internalCaseManager : this.externalCaseManager)
  }

  get hasResponsiblePersonForExecution() {
    return !!(this.internalResponsibleForExecution || this.externalResponsibleForExecution)
  }
}
