import { DeviationRelationName } from './deviation-relation-name'

class DeviationContextOptions {
  deleteOptions
  addOptions
  constructor(props) {
    this.deleteOptions = props?.deleteOptions?.map(option => new DeviationRelationName(option))
    this.addOptions = props?.addOptions?.map(option => new DeviationRelationName(option))
  }
}

export { DeviationContextOptions }
