class TaskPlan {
  active
  nextDate
  calculatedNextDate

  constructor(props) {
    if (props) {
      this.active = props?.active
      this.nextDate = props?.nextDate
      this.calculatedNextDate = props?.calculatedNextDate
    }
  }
}

export { TaskPlan }
