'use strict'
/*
* Controller for procedure details view
*
@author Rannveig A, Skjeve rannveig.a.skjerve@gmail.com
*/

class ProcedureDetailCtrl {
  constructor(psProcedureService, psDownloadService, $log, $stateParams, $state) {
    this.unitId = $stateParams.unitId
    this.projectId = $stateParams.project_id
    this.procedureId = $stateParams.procedure_id
    this.service = psProcedureService
    this.downloadService = psDownloadService
    this.$log = $log
    this.$stateParams = $stateParams
    this.$state = $state
    this.procedure = {}
    this.hasProcedureText = true
    this.getProcedure(this.procedureId)
  }

  getProcedure(procedureId) {
    this.service
      .getProcedure(procedureId)
      .then(response => {
        this.procedure = response.data
        this.getCheckLists()
        this.getDocuments()
      })
      .catch(error => {
        this.$log.error(error)
      })
  }

  getDocuments() {
    this.service.getDocuments(this.procedure.id).then(response => {
      this.documentContainer = response.data
    })
  }

  getCheckLists() {
    this.service
      .getCheckLists(this.unitId, this.projectId, this.procedure.id)
      .then(response => {
        this.checklists = response.checklists.list || []
        this.companyChecklistTemplates = response.unitTemplates ? response.unitTemplates.list || [] : []
        this.checklistTemplates = response.globalTemplates ? response.globalTemplates.list || [] : []
      })
      .catch(error => {
        this.$log.error(error)
      })
  }

  getTables() {
    this.service
      .getTables(this.procedure.id)
      .then(response => {
        this.tables = response.data.tables || []
        this.tableTemplates = response.data.templates || []
      })
      .catch(error => {
        this.$log.error(error)
      })
  }

  //formatting of frequencynumber in task tab
  getFrequencyNumberString(numberOf) {
    if (numberOf) {
      var numberString = numberOf > 1 ? numberOf + '.' : null
      return numberString
    }
  }

  setHasProcedureText() {
    this.hasProcedureText =
      this.procedure.procedureScope ||
      this.procedure.responsibility ||
      this.procedure.purpose ||
      this.procedure.description
  }

  download(id, fileName) {
    this.downloadService.getDocument(id, fileName).catch(error => {
      this.$log.error(error)
    })
  }

  goBack() {
    //ui-sref="procedures.project.list({ id : {{ vc.$stateParams.project_id}} })"
    console.log('goBack')
    this.$state.go('procedures.project.list', {
      unitId: this.unitId,
      id: this.$stateParams.project_id
    })
  }

  getDownloadUrl(id, fileName) {
    return this.downloadService.getOpenDownloadUrl(id, fileName)
  }
}

ProcedureDetailCtrl.$inject = ['psProcedureService', 'psDownloadService', '$log', '$stateParams', '$state']

export default ProcedureDetailCtrl
