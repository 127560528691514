import { ifetch } from '@integrator/api'

function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/customer-portal`
}

/**
 * Fetch all users which have access
 *
 * @param unitId
 * @param projectId
 * @returns {(function(*): Promise<*>)|*}
 */
function users(unitId, projectId) {
  return ifetch(url(unitId, projectId), { method: 'GET' })
}

/**
 * Fetch all contact persons which has access.
 *
 * @param unitId
 * @param projectId
 * @returns {(function(*): Promise<string|any>)|*}
 */
function contactPersons(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/contact-persons', { method: 'GET' })
}

function addContactPerson(unitId, projectId, contactPersonId) {
  return ifetch(url(unitId, projectId) + '/add-contact-person/' + contactPersonId, { method: 'POST' })
}

export { users, contactPersons, addContactPerson }
