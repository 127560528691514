import { DeviationErrorCode } from './deviation-error-code'

class DeviationErrorCodes {
  list
  constructor(props) {
    this.list = props?.list?.map(item => new DeviationErrorCode(item)) || []
  }
}

export { DeviationErrorCodes }
