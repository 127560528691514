import { ifetch } from '@integrator/api'

function url(unitId, projectId, procedureId) {
  return `/api/${unitId}/projects/${projectId}/procedures/${procedureId}`
}

function get(unitId, projectId, procedureId) {
  return ifetch(url(unitId, projectId, procedureId))
}

function manualState(unitId, projectId, procedureId) {
  return ifetch(url(unitId, projectId, procedureId) + '/manual-state')
}

function manual(unitId, projectId, procedureId) {
  return ifetch(url(unitId, projectId, procedureId) + '/manual')
}

function rename(unitId, projectId, procedureId, newName) {
  return ifetch(url(unitId, projectId, procedureId) + '/manual', {
    method: 'POST',
    body: newName
  })
}

function canBeDeleted(unitId, projectId, procedureId) {
  return ifetch(url(unitId, projectId, procedureId) + '/can-be-deleted', {
    method: 'POST'
  })
}

function makeTemplate(unitId, projectId, procedureId) {
  return ifetch(url(unitId, projectId, procedureId) + '/make-template', {
    method: 'POST'
  })
}

function updateSpecificInformation(unitId, projectId, procedureId, text) {
  return ifetch(url(unitId, projectId, procedureId) + '/specific/information', {
    method: 'POST',
    body: text
  })
}

function getSpecificInformation(unitId, projectId, procedureId) {
  return ifetch(url(unitId, projectId, procedureId) + '/specific/information')
}

function deleteProcedure(unitId, projectId, procedureId) {
  return ifetch(url(unitId, projectId, procedureId), {
    method: 'DELETE'
  })
}

function changeExternalAccess(unitId, projectId, procedureId, client, permission) {
  return ifetch(url(unitId, projectId, procedureId) + `/external-access/${client}/${permission}`, {
    method: 'POST'
  })
}

export {
  get,
  manualState,
  manual,
  rename,
  canBeDeleted,
  makeTemplate,
  updateSpecificInformation,
  getSpecificInformation,
  deleteProcedure,
  changeExternalAccess
}
