import { Upload } from './upload'

class Uploads {
  list
  constructor(props) {
    this.list = props?.list?.map(item => new Upload(item))
  }
}

export { Uploads }
