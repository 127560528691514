/**
 * @typedef {Object} Person
 * @property {number} id
 * @property {string} fullName
 * @property {string} nick
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} initials
 * @property {string} title
 * @property {string} profilePictureUrl
 */
class Person {
  id
  userId
  fullName
  nick
  firstName
  lastName
  email
  phoneNumber
  initials
  title
  profilePictureUrl
  constructor(props) {
    if (props) {
      this.id = props.id
      this.userId = props.userId
      this.fullName = props.fullName
      this.firstName = props.firstName
      this.nick = props.nick
      this.lastName = props.lastName
      this.email = props.email
      this.phoneNumber = props.phoneNumber
      this.initials = props.initials
      this.title = props.title
      this.profilePictureUrl = props.profilePictureUrl
    }
  }
}

export { Person }
