import { Control } from './control'

class Controls {
  controls
  constructor(props) {
    this.controls = props?.controls?.map(control => new Control(control))
  }
}

export { Controls }
