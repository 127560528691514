import { Action } from './action'

class Upload {
  id
  created
  constructor(props) {
    this.id = props?.id
    this.created = new Action(props?.created)
  }
}

export { Upload }
