import { ifetch } from '@integrator/api'

function baseUrl() {
  return `/api/deviations/meta`
}

function errorCodes() {
  return ifetch(baseUrl() + `/errors`, { method: 'GET' })
}

function addErrorCode(groupId, errorId, errorCodeJson) {
  return ifetch(baseUrl() + `/errors/${groupId}/${errorId}`, { method: 'POST', body: JSON.stringify(errorCodeJson) })
}

// no response value
function removeErrorCode(errorId) {
  return ifetch(baseUrl() + `/errors/${errorId}`, { method: 'DELETE' })
}

function fields() {
  return ifetch(baseUrl() + `/fields`, { method: 'GET' })
}

function fieldGroups() {
  return ifetch(baseUrl() + `/field-groups`, { method: 'GET' })
}

function preNewDeviation(businessModule, projectId) {
  return ifetch(baseUrl() + `/new-config/${businessModule}/${projectId}`, { method: 'GET' })
}

function searchConfig(businessModule, projectId) {
  if (businessModule) {
    const optionalProjectId = projectId ? projectId : 0
    return ifetch(baseUrl() + `/search-config/${businessModule}/${optionalProjectId}`, { method: 'GET' })
  } else {
    return ifetch(baseUrl() + `/search-config`, { method: 'GET' })
  }
}

export { errorCodes, removeErrorCode, addErrorCode, fields, fieldGroups, preNewDeviation, searchConfig }
