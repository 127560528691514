import { ChecklistTemplate, Definition, ifetch } from '@integrator/api'

function baseUrl(module) {
  return `/api/business/module/${module}/checklists`
}

/**
 * @param module {String}
 * @param definition {Definition} data for new definition
 * @returns {(function(*): Promise<ChecklistTemplate[]|any>)|*}
 */
function add(module, definition) {
  return ifetch(baseUrl(module), { method: 'POST', body: JSON.stringify(definition) })
}
function list(module) {
  return ifetch(baseUrl(module), { method: 'GET' })
}

function activeList(module) {
  return ifetch(baseUrl(module) + '/active', { method: 'GET' })
}

export { add, list, activeList }
