import { html, LitElement } from 'lit'
import '@shoelace-style/shoelace/dist/components/button/button.js'
import '@shoelace-style/shoelace/dist/components/icon/icon.js'
import '@shoelace-style/shoelace/dist/components/card/card.js'
import { linkPreviewStyles } from './link-preview-styles.js'

/**
 * Used by blocks which are editable.
 */
export class LinkPreview extends LitElement {
  static properties = {
    link: { type: Object }
  }

  static styles = [linkPreviewStyles]

  render() {
    if (this.link.name || this.link.title) {
      return html`
        <a class="link" href="${this.link.url}" target="_blank">
          <div class="link-card">
            <div class="link-title">${this.link.title}</div>
            <div class="link-name">${this.link.name}</div>
          </div>
        </a>
      `
    } else {
      return html`
        <a class="link" href="${this.link.url}" target="_blank">
          <div class="link-card">
            <div class="link-name">${this.link.url}</div>
          </div>
        </a>
      `
    }
  }
}
customElements.define('i-link-preview', LinkPreview)
