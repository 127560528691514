import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedures/search`
}

function freeTextSearchInName(unitId, search) {
  return ifetch(url(unitId) + '/free-text-search-in-name', {
    method: 'POST',
    body: search
  })
}

function freeTextSearchInTemplateName(unitId, search) {
  return ifetch(url(unitId) + '/free-text-search-in-template-name', {
    method: 'POST',
    body: search
  })
}

function freeTextSearchInManual(unitId, search) {
  return ifetch(url(unitId) + '/free-text-search-in-manual', {
    method: 'POST',
    body: search
  })
}

function freeTextSearchInTemplateManual(unitId, search) {
  return ifetch(url(unitId) + '/free-text-search-in-template-manual', {
    method: 'POST',
    body: search
  })
}

function freeTextSearchInChecklist(unitId, search) {
  return ifetch(url(unitId) + '/free-text-search-in-checklist', {
    method: 'POST',
    body: search
  })
}

function freeTextSearchInFiles(unitId, search) {
  return ifetch(url(unitId) + '/free-text-search-in-files', {
    method: 'POST',
    body: search
  })
}

export {
  freeTextSearchInName,
  freeTextSearchInTemplateName,
  freeTextSearchInManual,
  freeTextSearchInTemplateManual,
  freeTextSearchInChecklist,
  freeTextSearchInFiles
}
