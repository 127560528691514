import { ifetch } from '@integrator/api'

function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/user-time-registration`
}

function signIn(unitId, projectId) {
  return ifetch(url(unitId, projectId) + `/sign-in`, { method: 'POST' })
}

function signOut(unitId, projectId) {
  return ifetch(url(unitId, projectId) + `/sign-out`, { method: 'POST' })
}

export { signIn, signOut }
