class DeviationId {
  /** @type {number} */
  primary
  /** @type {string} */
  business
  constructor(props) {
    if (props?.primary) {
      this.primary = Number(props?.primary)
    }
    this.business = props?.business
  }
}

export { DeviationId }
