import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedures/`
}

function structure(unitId) {
  return ifetch(url(unitId) + `structure`)
}

function assigned(unitId) {
  return ifetch(url(unitId) + `assigned`)
}

function checklists(unitId) {
  return ifetch(url(unitId) + `checklists`)
}

function links(unitId) {
  return ifetch(url(unitId) + `links`)
}
export { structure, assigned, checklists, links }
