import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, taskId) {
  return `/api/business/${unitId}/projects/${projectId}/tasks/${taskId}/checklists`
}

function assignedList(unitId, projectId, taskId) {
  return ifetch(baseUrl(unitId, projectId, taskId) + `/assigned`)
}

function list(unitId, projectId, taskId) {
  return ifetch(baseUrl(unitId, projectId, taskId))
}

/**
 * @param {string} unitId
 * @param {string} projectId
 * @param {string} taskId
 * @param {definition} Definition
 * @returns {Promise<Task>}
 */
function addChecklist(unitId, projectId, taskId, definition) {
  return ifetch(baseUrl(unitId, projectId, taskId) + '/add', { method: 'POST', body: JSON.stringify(definition) })
}

/**
 * @param {string} unitId
 * @param {string} projectId
 * @param {string} taskId
 * @param {string} templateId
 * @returns {Promise<Task>}
 */
function addChecklistFromTemplate(unitId, projectId, taskId, templateId) {
  return ifetch(baseUrl(unitId, projectId, taskId) + `/add/${templateId}`, { method: 'POST' })
}

export { addChecklist, addChecklistFromTemplate, assignedList, list }
