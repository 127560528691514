'use strict'

import angular from 'angular'
import router from 'angular-ui-router'
import routes from './procedures.routes.js'

// Controllers
import ProcedureParentCtrl from './procedure.parent.controller.js'
import ProcedureListCtrl from './list/procedure.list.controller.js'
import ProcedureDetailCtrl from './detail/procedure.detail.controller.js'
import ProcedureContainerCtrl from './procedure.project.container.controller.js'

// Services
import ProcedureService from '../../common/services/data/procedures.dataservice.js'
import DownloadDataService from '../../common/services/data/download.dataservice.js'

// Styles
import './list/procedures.list.scss'
import './detail/procedures.detail.scss'
import './detail/tab/procedures.detail.tab.scss'

//// Directives
import TableListsDirective from '../../common/directives/lists/tables/list.tables.directive.js'

export default angular
  .module('ps.module.procedures', [ProcedureService, DownloadDataService, TableListsDirective, router, routes])
  .controller('ProcedureListCtrl', ProcedureListCtrl)
  .controller('ProcedureDetailCtrl', ProcedureDetailCtrl)
  .controller('ProcedureParentCtrl', ProcedureParentCtrl)
  .controller('ProcedureContainerCtrl', ProcedureContainerCtrl)
  .directive('procedurelinks', function () {
    return {
      restrict: 'E',
      template: require('./detail/tab/procedure.link.tab.html'),
      transclude: true
    }
  })
  .directive('proceduretasks', function () {
    return {
      restrict: 'E',
      template: require('./detail/tab/procedure.task.tab.html'),
      transclude: true
    }
  })
  .directive('proceduredocs', function () {
    return {
      restrict: 'E',
      template: require('./detail/tab/procedure.document.tab.html'),
      transclude: true
    }
  })
  .directive('procedurechecklist', function () {
    return {
      restrict: 'E',
      template: require('./detail/tab/procedure.checklists.tab.html'),
      transclude: true
    }
  }).name
