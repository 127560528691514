/**
 * @typedef {Object} BusinessAccountRole
 * @property {number} id
 * @property {string} name
 * @property {string} level
 * @property {string} module
 */
class BusinessAccountRole {
  id
  name
  level
  module

  constructor(props) {
    Object.assign(this, props)
  }
}

export { BusinessAccountRole }
