import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, controlId, componentId) {
  return `/api/business/${unitId}/project/${projectId}/periodic-control/controls/${controlId}/components/${componentId}/checklists`
}

function assigned(unitId, projectId, controlId, componentId) {
  return ifetch(baseUrl(unitId, projectId, controlId, componentId) + '/assigned', { method: 'GET' })
}

function list(unitId, projectId, controlId, componentId) {
  return ifetch(baseUrl(unitId, projectId, controlId, componentId), { method: 'GET' })
}

function addChecklist(unitId, projectId, controlId, componentId, definition) {
  return ifetch(baseUrl(unitId, projectId, controlId, componentId) + `/add`, {
    method: 'POST',
    body: JSON.stringify(definition)
  })
}

function addChecklistFromTemplate(unitId, projectId, controlId, componentId, templateId) {
  return ifetch(baseUrl(unitId, projectId, controlId, componentId) + `/add/${templateId}`, { method: 'POST' })
}

export { assigned, list, addChecklist, addChecklistFromTemplate }
