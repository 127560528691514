'use strict'

/**
 * Dialog for showing checklist.
 */

class Controller {
  constructor(data, psDeviationsService, psFilesystemService, $mdDialog) {
    this.data = data
    this.dialog = $mdDialog
    this.info = 'INFO'
    this.attachments = 'ATTACHMENTS'
    this.state = this.info
    this.deviationReportFs = {}
    this.getData(data.id.primary, psDeviationsService, psFilesystemService)
  }

  getData(deviationId, psDeviationsService, psFilesystemService) {
    psDeviationsService.details(deviationId).then(response => {
      this.deviationDetails = response.data
      this.data = response.data.deviation
      this.getReportFiles(psFilesystemService)
    })
  }

  getReportFiles(psFilesystemService) {
    let reports = this.deviationDetails.reports.list
    for (var i = 0; i < reports.length; i++) {
      var reportId = reports[i].id
      psFilesystemService.fetch('/deviation-report', reportId).then(response => {
        this.deviationReportFs[reportId] = response.data
      })
    }
  }

  cancel() {
    this.dialog.hide()
  }
}
Controller.$inject = ['data', 'psDeviationsService', 'psFilesystemService', '$mdDialog']

export default issue => {
  return {
    controller: Controller,
    controllerAs: 'vc',
    template: require('./dialogs.issues.tpl.html'),
    autoWrap: true,
    fullscreen: true,
    locals: {
      data: issue
    }
  }
}
