import { FileSystemContext } from './file-system-context'
import { FileSystemFeatures } from './file-system-features'

/**
 * @typedef { object } FileSystem
 * @property { number } id
 * @property { number } containerId
 * @property { string } name
 * @property { FileSystemContext } context
 * @property { Boolean } globalTemplateExists
 * @property { FileSystemFeatures } features
 */
class FileSystem {
  id
  containerId
  name
  context
  globalTemplateExists
  features

  constructor(props) {
    this.id = props.id
    this.containerId = props.containerId
    this.name = props.name
    this.context = new FileSystemContext(props.context)
    this.globalTemplateExists = props.globalTemplateExists
    this.features = props.features
  }
}

export { FileSystem }
