import { ChecklistTemplate } from './checklist-template'
import { BaseChecklist } from './base-checklist'
import { ChecklistTemplateStatus } from './checklists-template-status'

class ChecklistTemplates extends BaseChecklist {
  /**
   * @param {ChecklistTemplates|Array<ChecklistTemplate>} checklists
   */
  constructor(checklists) {
    super()
    if (checklists) {
      this.list = checklists.list.map(props => new ChecklistTemplate(props))
      this.sortByDefinitionName()
    }
  }

  get activeList() {
    return this.list.filter(({ statusName }) => ChecklistTemplateStatus.ACTIVE.equals(statusName))
  }
}

export { ChecklistTemplates }
