import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/structure`
}

function structure(unitId) {
  return ifetch(url(unitId))
}
export { structure }
