import { DeviationReport } from './deviation-report'
import { DeviationRelation } from './deviation-relation'
import { DeviationMetaData } from './deviation-meta-data'

class NewDeviation {
  report
  relation
  relations
  metaData
  constructor(props) {
    this.report = new DeviationReport(props?.report)
    this.relation = new DeviationRelation(props?.relation)
    this.relations = props?.relations?.map(relation => new DeviationRelation(relation))
    this.metaData = new DeviationMetaData(props?.metaData)
  }
}

export { NewDeviation }
