import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/mom`
}

function elementIds(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/element-ids`, { method: 'GET' })
}

function documentation(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/documentation`, { method: 'GET' })
}

function availableBuildingElements(unitId, projectId, informationType) {
  return ifetch(baseUrl(unitId, projectId) + `/building-elements/${informationType}`, { method: 'GET' })
}

function documentationIndex(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/documentation/index`, { method: 'GET' })
}

function addGeneralDocumentation(unitId, projectId, id) {
  return ifetch(baseUrl(unitId, projectId) + `/general-elements/${id}`, { method: 'POST' })
}

function removeGeneralDocumentation(unitId, projectId, id) {
  return ifetch(baseUrl(unitId, projectId) + `/general-elements/${id}`, { method: 'DELETE' })
}

function addBuildingDocumentation(unitId, projectId, id) {
  return ifetch(baseUrl(unitId, projectId) + `/building-element/${id}`, { method: 'POST' })
}

// no response value
function alignWithSpecification(unitId, projectId, id) {
  return ifetch(baseUrl(unitId, projectId) + `/building-element/${id}/alignWithSpec`, { method: 'POST' })
}

function removeBuildingDocumentation(unitId, projectId, id) {
  return ifetch(baseUrl(unitId, projectId) + `/building-element/${id}`, { method: 'DELETE' })
}

function newProject(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/structure-copy`, { method: 'POST' })
}

function documentationIndexTfm(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/documentation/index/tfm`)
}

function documentationTfm(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/documentation/tfm`)
}

export {
  elementIds,
  documentation,
  availableBuildingElements,
  addGeneralDocumentation,
  removeGeneralDocumentation,
  addBuildingDocumentation,
  alignWithSpecification,
  removeBuildingDocumentation,
  newProject,
  documentationIndex,
  documentationIndexTfm,
  documentationTfm
}
