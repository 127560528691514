import { ContainerStructure } from '@integrator/api'

/**
 * Represents a files available in template procedure
 */
export class TemplateProcedureFiles {
  /**
   * Structure from template procedure
   * @type {ContainerStructure}
   */
  templateContainerStructure

  /**
   * If of template procedure filesystem.
   * @type {number}
   */
  templateProcedureFileSystemId

  /**
   * Id of template procedure
   * @type {number}
   */
  templateProcedureId

  constructor(props) {
    if (props) {
      this.templateContainerStructure = new ContainerStructure(props.templateContainerStructure)
      this.templateProcedureFileSystemId = props.templateProcedureFileSystemId
      this.templateProcedureId = props.templateProcedureId
    }
  }

  /**
   * Records
   * @returns {Map<Number, RecordHit>}
   */
  recordMap() {
    return this.templateContainerStructure.records
  }
}
