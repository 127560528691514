import { Enum } from '../enum'

class Mode extends Enum {
  static NONE = new Mode('NONE')
  static DAY = new Mode('DAY')
  static WEEK = new Mode('WEEK')
  static MONTH = new Mode('MONTH')
  static YEAR = new Mode('YEAR')
  static WEEKDAY = new Mode('WEEKDAY')
  static CUSTOM = new Mode('CUSTOM')

  constructor(name = Mode.NONE.name) {
    super(name)
  }
}

export { Mode }
