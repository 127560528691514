/**
 * @typedef {Object} DeviationSearchCriteria
 * @property {String} searchText
 * @property {Boolean} archived
 */

export class DeviationSearchCriteria {
  searchText
  archived

  constructor(props) {
    Object.assign(this, props)
  }
}
