import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/users/settings/notification-profiles`
}

function allProfilesInCompany(unitId) {
  return ifetch(url(unitId))
}

export { allProfilesInCompany }
