import { ifetch } from '@integrator/api'

function baseUrl(checklistId) {
  return `/api/checklists/${checklistId}/checkpoints`
}

function add(checklistId, definition) {
  return ifetch(baseUrl(checklistId), { method: 'POST', body: JSON.stringify(definition) })
}

function addMultiple(checklistId, definitions) {
  return ifetch(baseUrl(checklistId) + `/add-multiple`, { method: 'POST', body: JSON.stringify(definitions) })
}

// no response value
function reorder(checklistId, order) {
  return ifetch(baseUrl(checklistId) + `/reorder`, { method: 'POST', body: JSON.stringify(order) })
}

function searchByMode(checklistId, modeName) {
  const mode = modeName.toString()
  return ifetch(baseUrl(checklistId) + `/search/${mode}`, { method: 'GET' })
}

export { add, addMultiple, reorder, searchByMode }
