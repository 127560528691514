class Address {
  fullName
  line1
  line2
  city
  postalCode
  region
  constructor(props) {
    if (props) {
      this.fullName = props.fullName
      this.line1 = props.line1
      this.line2 = props.line2
      this.city = props.city
      this.postalCode = props.postalCode
      this.region = props.region
    }
  }
}

export { Address }
