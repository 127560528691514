import { TagGroup } from './tag-group'
import { Tag } from './tag'

class Tags {
  tags
  groups
  constructor(props) {
    if (props?.tags) {
      this.tags = new Map()
      for (const [key, value] of Object.entries(props.tags)) {
        value.forEach(tag => {
          this.tags.set(Number(key), new Tag(tag))
        })
      }
    }
    this.groups = props?.groups?.map(group => new TagGroup(group))
  }
}

export { Tags }
