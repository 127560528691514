import { Checklists, ChecklistTemplates, ChecklistType } from '@integrator/api'

class ChecklistMap extends Map {
  withChecklists(checklists) {
    this.set(ChecklistType.CHECKLISTS, new Checklists(checklists))
    return this
  }

  withUnitTemplates(checklists) {
    this.set(ChecklistType.UNIT_TEMPLATES, new ChecklistTemplates(checklists))
    return this
  }

  withCorporateTemplates(checklists) {
    this.set(ChecklistType.CORPORATE_TEMPLATES, new ChecklistTemplates(checklists))
    return this
  }

  withGlobalTemplates(checklists) {
    this.set(ChecklistType.GLOBAL_TEMPLATES, new ChecklistTemplates(checklists))
    return this
  }

  getChecklists() {
    return this.get(ChecklistType.CHECKLISTS)?.list || []
  }

  getUnitTemplates() {
    return this.get(ChecklistType.UNIT_TEMPLATES)?.list || []
  }

  getCorporateTemplates() {
    return this.get(ChecklistType.CORPORATE_TEMPLATES)?.list || []
  }

  getGlobalTemplates() {
    return this.get(ChecklistType.GLOBAL_TEMPLATES)?.list || []
  }

  /**
   * @param {ChecklistTemplate} checklistTemplate
   */
  addUnitTemplate(checklistTemplate) {
    const unitTemplates = this.get(ChecklistType.UNIT_TEMPLATES)
    if (unitTemplates) {
      unitTemplates.list.push(checklistTemplate)
      unitTemplates.sortByDefinitionName()
    }
  }
}

export { ChecklistMap }
