import { ifetch } from '@integrator/api'

function url() {
  return `/api/links`
}

function preview(urlToCheck) {
  return ifetch(url() + '/preview', {
    method: 'POST',
    body: urlToCheck
  })
}

function cleanHtml(unsafeHtml) {
  return ifetch(url() + '/clean-html', {
    method: 'POST',
    body: unsafeHtml
  })
}

export { preview, cleanHtml }
