import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedures/collections`
}

function forUser(unitId) {
  return ifetch(url(unitId))
}

function available(unitId, level) {
  return ifetch(url(unitId) + `/available/${level}`)
}

function add(unitId, definition) {
  return ifetch(url(unitId), {
    method: 'POST',
    body: JSON.stringify(definition)
  })
}

function addFromProject(unitId, projectId) {
  return ifetch(url(unitId) + `/${projectId}`, {
    method: 'POST'
  })
}

export { forUser, available, add, addFromProject }
