'use strict'

import angular from 'angular'
import router from 'angular-ui-router'
import NotificationService from '../../services/service.notifications.js'
import AuthService from '../../services/auth/auth.service.js'

/**
 * Directive for showing status if
 * there is not connection to the API.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
const moduleName = 'ConnectionsDirective'
import './connection.directive.scss'

class ConnectionController {
  constructor($log, $rootScope, Notification, EVENTS, authService, connectionManager) {
    this.$log = $log
    this.$rootScope = $rootScope
    this.Notification = Notification
    this.authService = authService
    this.connectionManager = connectionManager
  }

  showError() {
    return this.connectionManager.getError()
  }
}

ConnectionController.$inject = ['$log', '$rootScope', 'Notification', 'EVENTS', 'authService', 'connectionManager']

/**
 *
 * Interceptor for no-connection events.
 * Only broadcasts events if nessecary.
 *
 */
const ConnectionInterceptor = function ($log, $q, Notification, EVENTS, connectionManager) {
  return {
    responseError: response => {
      $log.debug('Response error: ', response)
      if (response.status === 404 || response.status === 0 || (response.status > 500 && response.status < 600)) {
        if (!connectionManager.getError()) {
          Notification.broadcast(EVENTS.NO_CONNECTION_EVENT)
        }
      } else if (response.status === 401) {
        if (connectionManager.getError()) {
          Notification.broadcast(EVENTS.CONNECTION_ESTABLISHED_EVENT)
        }
      }
      return $q.reject(response)
    },
    requestError: request => {
      return $q.reject(request)
    },

    response: response => {
      if (connectionManager.getError()) {
        Notification.broadcast(EVENTS.CONNECTION_ESTABLISHED_EVENT)
      }
      return response || $q.when(response)
    }
  }
}

ConnectionInterceptor.$inject = ['$log', '$q', 'Notification', 'EVENTS', 'connectionManager']

/**
 * Shared connection manager.
 *
 * Keeps state on if we are currently showing connection error.
 */
class ConnectionManager {
  constructor($rootScope, Notification, EVENTS, $log) {
    this.showError = false
    this.$rootScope = $rootScope
    this.Notification = Notification
    this.$log = $log

    // Subscribe to relevant events
    this.Notification.subscribe(this.$rootScope, EVENTS.NO_CONNECTION_EVENT, this.noConnectionEvent())
    this.Notification.subscribe(this.$rootScope, EVENTS.CONNECTION_ESTABLISHED_EVENT, this.connectionEstablishedEvent())
  }

  /**
   * Event callback for LOGIN_EVENT
   */
  noConnectionEvent() {
    return function () {
      this.$log.debug(moduleName + ': NO_CONNECTION_EVENT RECEIVED')
      this.setError(true)
    }
  }

  /**
   * Event callback for LOGOUT_EVENT
   */
  connectionEstablishedEvent() {
    return function () {
      this.$log.debug(moduleName + ': CONNECTION_ESTABLISHED_EVENT RECEIVED')
      this.setError(false)
    }
  }

  getError() {
    return this.showError
  }

  setError(status) {
    this.showError = status
  }
}
ConnectionManager.$inject = ['$rootScope', 'Notification', 'EVENTS', '$log']

/**
 * Config for the module.
 * @param $httpProvider
 */
const config = function ($httpProvider) {
  $httpProvider.interceptors.push('connectionInterceptor')
}
config.$inject = ['$httpProvider']

/**
 * Run configuration that pings server if we are showing connection error.
 *
 * @param authService
 * @param $interval
 * @param $log
 * @param connectionManager
 */
const run = function (authService, $interval, $log, connectionManager) {
  var TIME = 10000

  function ping() {
    // Only ping server if we are currently showing connection error.
    if (connectionManager.getError()) {
      $log.info('PING SERVER')
      authService.sendAuthCall()
    }
  }

  $interval(ping, TIME)
}
run.$inject = ['authService', '$interval', '$log', 'connectionManager']

/**
 * Export angular module.
 */
export default angular
  .module('ps.directives.connection', [router, NotificationService, AuthService])
  .controller('connectionCtrl', ConnectionController)
  .service('connectionInterceptor', ConnectionInterceptor)
  .service('connectionManager', ConnectionManager)
  .config(config)
  .run(run)
  .directive('connection', function () {
    return {
      restrict: 'E',
      template: require('./connection.directive.tpl.html'),
      scope: {},
      controller: 'connectionCtrl',
      controllerAs: 'vc'
    }
  }).name
