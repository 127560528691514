import { Procedure } from './procedure'
import { ProcedureCategoryTree } from '../category'

export class ProcedureStructure {
  /**
   * The category tree of procedures.
   * @type {ProcedureCategoryTree}
   */
  categoryTree

  /**
   * The template procedures within the collection.
   * @type {Map<number, Procedure>}
   */
  templateProcedures

  /**
   * The ids of template procedures of the collection.
   * @type {Array<number>}
   */
  templateProceduresIds

  /**
   * The used template procedures ids.
   * @type {Map<number, number>}
   */
  usedTemplateProcedures

  /**
   * The presentation procedures within the collection.
   * @type {Map<number, Array<Procedure>>}
   */
  presentationProcedures

  /**
   * The business procedures within the collection.
   * @type {Map<number, Array<Procedure>>}
   */
  businessProcedures

  /**
   * The ids of business procedures of the collection.
   * @type {Array<number>}
   */
  businessProcedureIds

  /**
   * The project procedures within the collection.
   * @type {Map<number, Array<Procedure>>}
   */
  projectProcedures

  /**
   * The ids of project procedures of the collection.
   * @type {Array<number>}
   */
  projectProcedureIds

  /**
   * Returns all procedures as flat array
   * @type {Array<Procedure>}
   * @private
   */
  _allProjectProcedures = []

  constructor(props) {
    if (props) {
      this.categoryTree = new ProcedureCategoryTree(props?.categoryTree)
      if (props?.businessProcedureIds) {
        this.businessProcedureIds = props.businessProcedureIds
      }
      this.businessProcedures = new Map()
      if (props?.businessProcedures) {
        Object.entries(props?.businessProcedures)?.forEach(([key, val]) => {
          const procedureList = val?.map(entity => new Procedure(entity))
          procedureList.sort(this._procedureByNameComparator)
          this.businessProcedures.set(Number(key), procedureList)
        })
      }
      if (props?.projectProcedureIds) {
        this.projectProcedureIds = props.projectProcedureIds
      }
      this.projectProcedures = new Map()
      if (props?.projectProcedures) {
        Object.entries(props?.projectProcedures)?.forEach(([key, val]) => {
          const procedureList = val?.map(entity => new Procedure(entity))
          procedureList.sort(this._procedureByNameComparator)
          this.projectProcedures.set(Number(key), procedureList)
        })
        this._allProjectProcedures =
          Array.from(this.projectProcedures.values()).flatMap(categoryProcedures => categoryProcedures) || []
        this._allProjectProcedures.sort(this._procedureByNameComparator)
      }

      this.presentationProcedures = new Map()
      if (props?.presentationProcedures) {
        Object.entries(props?.presentationProcedures)?.forEach(([key, val]) => {
          const procedureList =
            Object.entries(val)?.map(([_procedureId, _procedure]) => new Procedure(_procedure)) || []
          procedureList.sort(this._procedureByNameComparator)
          this.presentationProcedures.set(Number(key), procedureList)
        })
      }
      if (props?.usedTemplateProcedures) {
        this.usedTemplateProcedures = props.usedTemplateProcedures
      }
      this.templateProcedures = new Map()
      if (props?.templateProcedures) {
        Object.entries(props?.templateProcedures)?.forEach(([key, val]) => {
          const procedure = new Procedure(val)
          this.templateProcedures.set(Number(key), procedure)
        })
      }
      if (props?.templateProceduresIds) {
        this.templateProceduresIds = props.templateProceduresIds
      }
    }
  }

  /**
   * Set project procedures on ProcedureStructure
   * @param procedures list of procedures
   */
  setProjectProcedures(procedures) {
    this.projectProcedures = new Map()
    procedures?.forEach(entity => {
      const procedure = new Procedure(entity)
      const categoryId = procedure.procedureCategoryId
      const procedures = this.projectProcedures.get(categoryId)
      if (procedures) {
        procedures.push(procedure)
      } else {
        this.projectProcedures.set(categoryId, [procedure])
      }
    })
  }

  _getProceduresByCategoryIds(categoryIds, procedures) {
    return Array.from(procedures.entries()).reduce((acc, [key, value]) => {
      if (categoryIds.includes(key)) {
        acc.push(...value)
      }
      return acc
    }, [])
  }

  _getProceduresByCategoryId(categoryId, procedures) {
    return this._getProceduresByCategoryIds(this.categoryTree.getCategoryAndSubCategoryIds(categoryId), procedures)
  }

  getBusinessProceduresByCategoryId(categoryId) {
    return this._getProceduresByCategoryId(categoryId, this.businessProcedures)
  }

  getProjectProceduresByCategoryId(categoryId) {
    return this._getProceduresByCategoryId(categoryId, this.projectProcedures)
  }

  getProjectProcedureById(procedureId) {
    let result = []
    this.projectProcedures.forEach(procedures => {
      procedures.forEach(procedure => {
        if (procedure.id === Number(procedureId)) {
          result.push(procedure)
          return
        }
      })
    })
    return result
  }

  set allProjectProcedures(projectProcedures) {
    const procedures = Array.from(projectProcedures.values()).flatMap(categoryProcedures => categoryProcedures)
    procedures.sort(this._procedureByNameComparator)
    this._allProjectProcedures = procedures || []
  }

  get allProjectProcedures() {
    return this._allProjectProcedures
  }

  allProjectProcedureIds() {
    return (
      Array.from(this.projectProcedures.values())
        .flatMap(categoryProcedures => categoryProcedures)
        .map(({ id }) => id) || []
    )
  }

  _procedureByNameComparator(procedure1, procedure2) {
    return procedure1.name.localeCompare(procedure2.name)
  }
}
