import { ifetch } from '@integrator/api'

function url(businessUnitId, competencyId, iterationId, participantId) {
  // /api/business/{businessUnitId}/competences/{competencyId}/iterations/{iterationId}/participants/{participantId}
  return `api/business/${businessUnitId}/competences/${competencyId}/iterations/${iterationId}/participants/${participantId}`
}

function deleteParticipant(businessUnitId, competencyId, iterationId, participantId) {
  return ifetch(url(businessUnitId, competencyId, iterationId, participantId), { method: 'DELETE' })
}

// return FieldsJson
function getFields(businessUnitId, competencyId, iterationId, participantId) {
  return ifetch(url(businessUnitId, competencyId, iterationId, participantId) + '/fields', { method: 'GET' })
}

// recordFieldsValues  { dateValues : { string: date }, stringValues : { string: string } }
function updateFields(businessUnitId, competencyId, iterationId, participantId, recordFieldsValues) {
  return ifetch(url(businessUnitId, competencyId, iterationId, participantId) + '/fields', {
    method: 'POST',
    body: JSON.stringify({ stringValues: Object.fromEntries(recordFieldsValues) })
  })
}

export { deleteParticipant, getFields, updateFields }
