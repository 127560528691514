import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/tasks`
}

function tasks(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId), { method: 'GET' })
}

function configurationForNew(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/new-config`, { method: 'GET' })
}

function add(unitId, projectId, task) {
  return ifetch(baseUrl(unitId, projectId), { method: 'POST', body: JSON.stringify(task) })
}

function plannerTest(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/planner/test`, { method: 'GET' })
}

function planner(unitId, projectId, taskCriteria) {
  return ifetch(baseUrl(unitId, projectId) + `/planner`, { method: 'POST', body: JSON.stringify(taskCriteria) })
}

function history(unitId, projectId, taskCriteria) {
  return ifetch(baseUrl(unitId, projectId) + `/history`, { method: 'POST', body: JSON.stringify(taskCriteria) })
}

function taskCriteria(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId) + `/task-criteria`, { method: 'GET' })
}

export { tasks, add, configurationForNew, planner, history, plannerTest, taskCriteria }
