import { ifetch } from '@integrator/api'

function url(deviationId) {
  return `/api/deviations/${deviationId}/context`
}

function attach(deviationId, relationName, relationId) {
  return ifetch(url(deviationId) + `/${relationName}/${relationId}`, { method: 'POST' })
}

function detach(deviationId, relationName) {
  return ifetch(url(deviationId) + `/${relationName}`, { method: 'DELETE' })
}

function options(deviationId) {
  return ifetch(url(deviationId) + `/options`)
}

export { attach, detach, options }
