class FdvDetailCtrl {
  constructor($state, $stateParams, psFdvService, psDownloadService, pdfGenerateService, project) {
    this.project = project.data.project
    this.unitId = $stateParams.unitId
    this.$state = $state
    this.$stateParams = $stateParams
    this.service = psFdvService
    this.downloadService = psDownloadService
    this.pdfService = pdfGenerateService
    this.$state.go('fdv.detail.administration', { unitId: $stateParams.unitId })
    this.getAdministrativeDocuments(this.project.id)
  }

  getAdministrativeDocuments(projectId) {
    this.service
      .fdvAdministrationDocument(projectId)
      .then(response => {
        this.adminDocuments = response.data.documents
      })
      .catch(error => {})
  }

  documentIconClass(type) {
    switch (type) {
      case 'PDF':
        return 'fa-file-pdf-o'
      case 'IMAGE':
        return 'fa-file-image-o'
      case 'MS_WORD':
        return 'fa-file-word-o'
      case 'MS_EXCEL':
        return 'fa-file-excel-o'
      case 'MS_POWERPOINT':
        return 'fa-file-powerpoint-o'
      case 'GENERIC':
        return 'fa-file'
      default:
        return 'fa-file'
    }
  }

  getDownloadUrl(id, fileName) {
    return this.downloadService.getOpenDownloadUrl(id, fileName)
  }

  download(id, fileName) {
    this.downloadService.getDocument(id, fileName).catch(error => {
      console.log(error)
    })
  }

  generateAdministrationPdf() {
    var pdfUrl = this.pdfService.getFdvAdministrationPdf(this.project.id)
    window.open(pdfUrl)
  }
}
FdvDetailCtrl.$inject = [
  '$state',
  '$stateParams',
  'psFdvService',
  'psDownloadService',
  'psPdfGenerateService',
  'project'
]

export default FdvDetailCtrl
