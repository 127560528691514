import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `api/business/${businessUnitId}/competences/groups`
}

function groups(businessUnitId) {
  return ifetch(url(businessUnitId), { method: 'GET' })
}

function newGroup(businessUnitId, groupDefinition) {
  return ifetch(url(businessUnitId), { method: 'POST', body: JSON.stringify(groupDefinition) })
}

function reorderGroups(businessUnitId, order) {
  return ifetch(url(businessUnitId) + '/reorder', { method: 'POST', body: JSON.stringify(order) })
}

export { groups, newGroup, reorderGroups }
