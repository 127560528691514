import { ifetch } from '@integrator/api'

function url(unitId, procedureId) {
  return `/api/business/${unitId}/procedures/${procedureId}/manual/paragraphs`
}

function addTemplateText(unitId, procedureId, definitionId, editorText) {
  return ifetch(url(unitId, procedureId) + '/add-template-text/' + definitionId, {
    method: 'POST',
    body: JSON.stringify(editorText)
  })
}

function addBusinessText(unitId, procedureId, definitionId, editorText) {
  return ifetch(url(unitId, procedureId) + '/add-business-text/' + definitionId, {
    method: 'POST',
    body: JSON.stringify(editorText)
  })
}

function reorder(unitId, procedureId, orderMap) {
  return ifetch(url(unitId, procedureId) + '/reorder', {
    method: 'POST',
    body: JSON.stringify(orderMap)
  })
}

export { addTemplateText, addBusinessText, reorder }
