import { ifetch } from '@integrator/api'

function url(projectId, procedureId, paragraphId) {
  return `/api/projects/${projectId}/procedures/${procedureId}/manual/paragraphs/${paragraphId}`
}

function saveText(projectId, procedureId, paragraphId, level, editorText) {
  return ifetch(url(projectId, procedureId, paragraphId) + `/save-text/${level}`, {
    method: 'POST',
    body: JSON.stringify(editorText)
  })
}

/**
 *
 * @param projectId
 * @param procedureId
 * @param paragraphId
 * @param level
 * @returns {(function(*): Promise<*>)|*}
 */
function deleteText(projectId, procedureId, paragraphId, level) {
  return ifetch(url(projectId, procedureId, paragraphId) + `/delete-text/${level}`, {
    method: 'DELETE'
  })
}

function remove(projectId, procedureId, paragraphId) {
  return ifetch(url(projectId, procedureId, paragraphId), {
    method: 'DELETE'
  })
}

function equalizeToBusiness(projectId, procedureId, paragraphId) {
  return ifetch(url(projectId, procedureId, paragraphId) + '/equalize-to-business', {
    method: 'POST'
  })
}

function boostTextVersion(projectId, procedureId, paragraphId) {
  return ifetch(url(projectId, procedureId, paragraphId) + '/boost-text-version', {
    method: 'POST'
  })
}

export { saveText, deleteText, remove, equalizeToBusiness, boostTextVersion }
