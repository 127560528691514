/**
 * Represents a procedure delete permission.
 */
export class ProcedureDeletePermission {
  /**
   * The name of procedure permission to delete.
   * @type {string}
   */
  name
  /**
   * The flag of procedure permission to delete.
   * @type {boolean}
   */
  allowed

  constructor(name, allowed) {
    if (name) {
      this.name = name
      this.allowed = allowed
    }
  }
}
