import { Enum } from '../../universal'

class ProjectContractLabelType extends Enum {
  static NO_CONTRACT = new ProjectContractLabelType('NO_CONTRACT')
  static CUSTOM = new ProjectContractLabelType('CUSTOM')
  static NS_8405_8415 = new ProjectContractLabelType('NS_8405_8415')
  static NS_8406_8416 = new ProjectContractLabelType('NS_8406_8416')
  static NS_8407_8417 = new ProjectContractLabelType('NS_8407_8417')
}

export { ProjectContractLabelType }
