import { EventRecurrence } from '../../universal'
import { Order } from '../../order'
import { ControlType } from './control-type'
import { ControlDutyUser } from './control-duty-user'

class ControlSetup {
  copyFromControlId
  order
  controlTypes = []
  description
  responsibleUsers = []
  recurrence
  startDate
  constructor(props) {
    if (props) {
      this.copyFromControlId = props.copyFromControlId
      this.order = new Order(props.order)
      this.controlTypes = props.controlTypes?.map(type => new ControlType(type))
      this.description = props.description
      this.responsibleUsers = props.responsibleUsers?.map(user => new ControlDutyUser(user))
      this.recurrence = new EventRecurrence(props.recurrence)
      this.startDate = props.startDate
    }
  }
}

export { ControlSetup }
