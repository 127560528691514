import { Enum } from '@integrator/api'

class InternalDeviationSeverity extends Enum {
  static LOW = new InternalDeviationSeverity('LOW')
  static MEDIUM = new InternalDeviationSeverity('MEDIUM')
  static IMPROVEMENT = new InternalDeviationSeverity('IMPROVEMENT')
  static HIGH = new InternalDeviationSeverity('HIGH')

  constructor(name = InternalDeviationSeverity.LOW.name) {
    super(name)
  }

  static list() {
    return [
      InternalDeviationSeverity.IMPROVEMENT,
      InternalDeviationSeverity.LOW,
      InternalDeviationSeverity.MEDIUM,
      InternalDeviationSeverity.HIGH
    ]
  }

  color() {
    switch (this.name) {
      case 'IMPROVEMENT':
        return 'success'
      case 'LOW':
        return 'primary'
      case 'MEDIUM':
        return 'warning'
      case 'HIGH':
        return 'danger'
      default:
        return 'neutral'
    }
  }
}

export { InternalDeviationSeverity }
