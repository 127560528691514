class Cadastre {
  municipalityNumber
  municipalityName
  cadastralUnitNumber
  propertyUnitNumber
  leaseNumber
  condominiumUnitNumber
  buildingNumber
  constructor(props) {
    this.municipalityNumber = props.municipalityNumber
    this.municipalityName = props.municipalityName
    this.cadastralUnitNumber = props.cadastralUnitNumber
    this.propertyUnitNumber = props.propertyUnitNumber
    this.leaseNumber = props.leaseNumber
    this.condominiumUnitNumber = props.condominiumUnitNumber
    this.buildingNumber = props.buildingNumber
  }
}

export { Cadastre }
