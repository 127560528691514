import { FileSystem } from './file-system'
/**
 * @typedef { object } RecordNetwork
 * @property { FileSystem } from
 * @property { Array<FileSystem> } linkedIn
 */
class RecordNetwork {
  from
  linkedIn

  constructor(props) {
    this.from = new FileSystem(props.from)
    this.linkedIn = []
    if (props.linkedIn) {
      props.linkedIn.forEach(linkFs => {
        this.linkedIn.push(new FileSystem(linkFs))
      })
    }
  }
}

export { RecordNetwork }
