// From EmployeeLeaveEndpoint.java
import { ifetch } from '@integrator/api'

function url(unitId, employeeId) {
  return `/api/business/${unitId}/employees/${employeeId}/leave`
}

function overview(unitId, employeeId, body) {
  return ifetch(url(unitId, employeeId) + `/overview/`, {
    method: 'POST',
    body: JSON.stringify(body)
  })
}

function init(unitId, employeeId) {
  return ifetch(url(unitId, employeeId) + `/init`)
}

function validate(unitId, employeeId, newLeave) {
  return ifetch(url(unitId, employeeId) + `/validate`, {
    method: 'POST',
    body: JSON.stringify(newLeave)
  })
}

function preUpdateValidation(unitId, employeeId, leaveId, changedLeave) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}/validate`, {
    method: 'POST',
    body: JSON.stringify(changedLeave)
  })
}

function newLeave(unitId, employeeId, newLeave) {
  return ifetch(url(unitId, employeeId), {
    method: 'POST',
    body: JSON.stringify(newLeave)
  })
}

function approve(unitId, employeeId, leaveId) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}/approve`, {
    method: 'POST'
  })
}

function reject(unitId, employeeId, leaveId) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}/reject`, {
    method: 'POST'
  })
}

function remove(unitId, employeeId, leaveId) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}/reject`, {
    method: 'DELETE'
  })
}

function messages(unitId, employeeId, leaveId) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}/messages`)
}

function addMessages(unitId, employeeId, leaveId, message) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}/messages`, { method: 'POST', body: message })
}

function daysToDates(unitId, employeeId, leaveId) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}/days-to-dates`)
}

function datesToDays(unitId, employeeId, leaveId) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}/dates-to-days`)
}

function update(unitId, employeeId, leaveId, changedLeave) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}`, {
    method: 'POST',
    body: JSON.stringify(changedLeave)
  })
}

function details(unitId, employeeId, leaveId) {
  return ifetch(url(unitId, employeeId) + `/absences/${leaveId}`)
}

export {
  init,
  newLeave,
  update,
  validate,
  overview,
  approve,
  reject,
  remove,
  messages,
  daysToDates,
  datesToDays,
  preUpdateValidation,
  details,
  addMessages
}
