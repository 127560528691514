import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/settings/sick-leave`
}

function getSickLeaveRules(unitId) {
  return ifetch(url(unitId), { method: 'GET' })
}

function updateSelfDeclaration(unitId, sickLeaveRules) {
  return ifetch(url(unitId) + `/self-declaration`, { method: 'POST', body: JSON.stringify(sickLeaveRules) })
}

function updateChild(unitId, childRule) {
  return ifetch(url(unitId) + `/child`, { method: 'POST', body: JSON.stringify(childRule) })
}

function updateWorkingWeek(unitId, workingWeek) {
  return ifetch(url(unitId) + `/work-week`, { method: 'POST', body: JSON.stringify(workingWeek) })
}

export { getSickLeaveRules, updateSelfDeclaration, updateChild, updateWorkingWeek }
