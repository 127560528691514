class BusinessUserSettings {
  profilePictureUrl
  language
  constructor(props) {
    this.profilePictureUrl = props.profilePictureUrl
    this.language = props.language
  }
}

export { BusinessUserSettings }
