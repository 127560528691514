import { ifetch } from '@integrator/api'

function url(unitId, procedureId) {
  return `/api/business/${unitId}/procedures/${procedureId}`
}

function remove(unitId, procedureId) {
  return ifetch(url(unitId, procedureId), {
    method: 'DELETE'
  })
}

function rename(unitId, procedureId, newName) {
  return ifetch(url(unitId, procedureId) + '/rename', {
    method: 'POST',
    body: newName
  })
}

function move(unitId, procedureId, newCategoryId) {
  return ifetch(url(unitId, procedureId) + '/move-to-category/' + newCategoryId, {
    method: 'POST'
  })
}

function makeCopy(unitId, procedureId) {
  return ifetch(url(unitId, procedureId) + '/make-copy', {
    method: 'POST'
  })
}

/**
 * Recognition of the procedure is a user read log.
 * It returns map where key is user id and value is a date list when user read procedure.
 *
 * @param unitId
 * @param procedureId
 */
function recognition(unitId, procedureId) {
  return ifetch(url(unitId, procedureId) + '/recognition/')
}

export { remove, rename, move, makeCopy, recognition }
