/**
 * @typedef { object } FsPath
 * @property { string } full
 * @property { string } clean
 */
class FsPath {
  full
  clean
  constructor(props) {
    Object.assign(this, props)
  }
}
export { FsPath }
