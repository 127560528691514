import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/mom/building-locations`
}

function list(unitId, projectId) {
  return ifetch(baseUrl(unitId, projectId), { method: 'GET' })
}

function add(unitId, projectId, code) {
  return ifetch(baseUrl(unitId, projectId), { method: 'POST', body: code })
}

export { list, add }
