/**
 * @typedef {Object} DeviationReportText
 * @property {String} description
 * @property {String} value
 * @property {Boolean} sendNotification
 * @property {Number} costEstimate
 */
class DeviationReportText {
  description
  value
  sendNotification
  costEstimate

  constructor(props) {
    Object.assign(this, props)
  }
}

export { DeviationReportText }
