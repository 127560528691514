'use strict'

import angular from 'angular'
import angularMaterial from 'angular-material'
import router from 'angular-ui-router'

//Directives
import dialog from '../../../services/dialogs/tables/dialog.table.js'

//Styles
import './list.tables.directive.scss'

class ListTableCtrl {
  constructor($state, $log, $scope, $mdDialog) {
    this.$scope = $scope
    this.$log = $log
    this.$state = $state
    this.$mdDialog = $mdDialog
  }

  openTable(table) {
    console.log('OPEN TABLE')
    this.$mdDialog.show(dialog(table))
  }
}
ListTableCtrl.$inject = ['$state', '$log', '$scope', '$mdDialog']

export default angular
  .module('ps.directives.list.tables', [router, angularMaterial])
  .controller('ListTableCtrl', ListTableCtrl)
  .directive('listtables', function () {
    return {
      restrict: 'E',
      template: require('./list.tables.directive.tpl.html'),
      scope: {
        tables: '=',
        tableTemplates: '=',
        showTemplates: '='
      },
      controller: 'ListTableCtrl',
      controllerAs: 'vc'
    }
  }).name
