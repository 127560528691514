import { ifetch } from '@integrator/api'

function url(unitId, procedureId, paragraphId) {
  return `/api/business/${unitId}/procedures/${procedureId}/manual/paragraph/${paragraphId}`
}

function remove(unitId, procedureId, paragraphId) {
  return ifetch(url(unitId, procedureId, paragraphId), {
    method: 'DELETE'
  })
}

function saveTemplate(unitId, procedureId, paragraphId, editorText) {
  return ifetch(url(unitId, procedureId, paragraphId) + '/save-template-text', {
    method: 'POST',
    body: JSON.stringify(editorText)
  })
}

function saveBusiness(unitId, procedureId, paragraphId, editorText) {
  return ifetch(url(unitId, procedureId, paragraphId) + '/save-business-text', {
    method: 'POST',
    body: JSON.stringify(editorText)
  })
}

function rename(unitId, procedureId, paragraphId, name) {
  return ifetch(url(unitId, procedureId, paragraphId) + '/name', {
    method: 'POST',
    body: name
  })
}

export { remove, saveTemplate, saveBusiness, rename }
