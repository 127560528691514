import { html, LitElement } from 'lit'
import { contextFileSystemApi, containerStructureApi } from '@integrator/api'
import '@shoelace-style/shoelace/dist/components/tree/tree.js'
import '@shoelace-style/shoelace/dist/components/tree-item/tree-item.js'
import './container-line'
import './record-line'
import './record-line-header'

/**
 * Tree view of container structure.
 * Used as generic component for view files in tree.
 */
export class ContainerTree extends LitElement {
  static properties = {
    relation: { type: Number },
    context: { type: String },
    filesystem: { type: Object, state: true }
  }

  firstUpdated(_changeProperties) {
    super.firstUpdated(_changeProperties)
    this._fetchRootStructure()
  }

  _fetchRootStructure() {
    contextFileSystemApi
      .structure(this.context, this.relation, 'READ_ACCESS')()
      .then(resp => {
        this.filesystem = resp
        const rootItem = this.renderRoot.getElementById('tree')
        this._populateTreeItems(this.filesystem, rootItem)
      })
      .catch(error => {
        console.log(error)
      })
  }

  _fetchContainerStructure(containerId) {
    return containerStructureApi.containerStructure(this.context, this.relation, containerId, 'READ_ACCESS')()
  }

  render() {
    return html`
      <sl-tree id="tree" class="tree-with-lines">
        <sl-tree-item>
          <i-record-line-header></i-record-line-header>
        </sl-tree-item>
      </sl-tree>
    `
  }

  _populateTreeItems(containerStructure, parentItem) {
    if (containerStructure) {
      parentItem.lazy = false
      if (containerStructure.elements && containerStructure.elements.length > 0) {
        for (const element of containerStructure.elements) {
          const treeItem = document.createElement('sl-tree-item')
          this._populateContainer(containerStructure, element, treeItem)
          this._populateRecord(containerStructure, element, treeItem)
          parentItem.append(treeItem)
        }
      } else {
        parentItem.loading = false
      }
    }
  }

  _populateContainer(containerStructure, element, treeItem) {
    const containers = containerStructure.containers
    if (containers[element.id]) {
      const folder = document.createElement('i-container-line')
      folder.container = containers[element.id]
      treeItem.append(folder)
      treeItem.lazy = true
      const lazyLoad = () => {
        treeItem.removeEventListener('sl-lazy-load', lazyLoad)
        this._fetchContainerStructure(element.id).then(innerStructure => {
          this._populateTreeItems(innerStructure, treeItem)
        })
      }
      treeItem.addEventListener('sl-lazy-load', lazyLoad)
    }
  }

  _populateRecord(containerStructure, element, treeItem) {
    const records = containerStructure.records
    if (records[element.id]) {
      const record = document.createElement('i-record-line')
      record.record = records[element.id]
      treeItem.append(record)
    }
  }
}

customElements.define('i-container-tree', ContainerTree)
