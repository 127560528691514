'use strict'
/**
 * Controller for project detail view.
 *
 * @author Rannveig A.Skjerve (rannveig.a.skjerve@gmail.com)
 */

import _ from 'lodash'

class ProjectAlternativesCtrl {
  constructor($state, $stateParams, $scope, modules, projects) {
    this.$state = $state
    this.modules = this.sortModules($scope.$parent.vc.features, modules)

    // Get the current project from the list of projects
    this.project = _.find(projects.data, project => {
      return project.id === parseInt($stateParams.id)
    })
  }

  sortModules(features, modules) {
    return _.pick(modules, features)
  }

  noEnabledFeatures() {
    return _.isEmpty(this.modules)
  }

  navigateToPortal(module) {
    let url = module.location()
    console.log(url)
    window.open(url, '_blank')
  }
}

ProjectAlternativesCtrl.$inject = ['$state', '$stateParams', '$scope', 'modules', 'projects']

export default ProjectAlternativesCtrl
