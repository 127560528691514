import { Credentials } from './credentials'

class Integration {
  exists
  name
  externalId
  credentials
  attributes
  constructor(props) {
    this.attributes = new Map()
    if (props) {
      this.exists = props.exists
      this.name = props.name
      this.externalId = props.externalId
      this.credentials = new Credentials(props.credentials)
      Object.entries(props.attributes)?.forEach(([key, val]) => this.attributes.set(key, val))
    }
  }
}

export { Integration }
