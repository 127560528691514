import { Definition } from './definition'

class TagGroup {
  id
  definition
  sortOrder
  constructor(props) {
    this.id = props?.id
    this.definition = new Definition(props?.definition)
    this.sortOrder = props?.sortOrder
  }
}

export { TagGroup }
