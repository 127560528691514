/**
 * @typedef {Object} CountedData
 * @property {Object.<string|number|symbol|boolean, number>} countMap
 * @property {number} total
 */

/**
 * @class
 */
class CountedData {
  /** @type {Object.<string|number|symbol|boolean, number>} */
  countMap
  /** @type {number} */
  total
  /** @type {Object.<string|number|symbol|boolean, number>} */
  percentageMap

  /**
   * @param {Object.<string|number|symbol|boolean>} props
   */
  constructor(props) {
    this.countMap = props?.countMap || {}
    this.percentageMap = props?.percentageMap || {}
    this.total = props?.total || 0
  }

  addNew(key) {
    this.total++
    this.increment(key)
  }

  remove(key) {
    this.total--
    this.decrement(key)
  }

  increment(key, step = 1) {
    if (key) {
      if (this.countMap[key] === undefined) {
        this.countMap[key] = 0
      }
      this.countMap[key] = this.countMap[key] + step
    }
  }

  decrement(key, step = 1) {
    if (key && this.countMap[key] !== undefined) {
      this.countMap[key] = this.countMap[key] - step
    }
  }

  change(from, to) {
    this.decrement(from)
    this.increment(to)
  }
}

export { CountedData }
