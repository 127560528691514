import { Definition } from './definition'

export class Definitions {
  /**
   * @type {Definition[]}
   */
  list

  constructor(props) {
    this.list = props?.list?.map(definition => new Definition(definition))
  }
}
