import { Checkpoint } from './checkpoint'
import { CheckpointModeName } from './checkpoint-mode-name'

class Checkpoints {
  /** @type {Array<CheckpointTemplate>} */
  list

  constructor(props) {
    this.list = []
    if (props.list) {
      this.list = props.list.map(ct => new Checkpoint(ct))
    }
  }

  static fromText(data) {
    const lines = data.split(/\n/gi)
    const isHeader = item => ['*', '='].includes(item.charAt(0))

    const toCheckpoint = item => {
      const checkpoint = new Checkpoint()
      const header = isHeader(item)
      checkpoint.definition.name = header ? item.substring(1) : item
      checkpoint.modeName = header ? CheckpointModeName.GROUP.name : CheckpointModeName.CHECK.name
      return checkpoint
    }

    const checkpoints = lines.filter(item => item.trim().length > 0).map(toCheckpoint)
    return checkpoints
  }
}

export { Checkpoints }
