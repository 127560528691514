import { BoligmappaProjectIntegration } from './boligmappa-project-integration'

class ProjectIntegration {
  boligmappa
  constructor(props) {
    this.boligmappa = new BoligmappaProjectIntegration(props?.boligmappa)
  }
}

export { ProjectIntegration }
