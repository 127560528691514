/**
 * Controller for lists of issues.
 *
 * @author Audun Follegg (audunfo@gmail.com).
 */

class IssuesListCtrl {
  constructor() {
    this.data = [{ name: 'issue' }]
  }
}

IssuesListCtrl.$inject = []

export default IssuesListCtrl
