import { ifetch } from '@integrator/api'

function url(deviationId) {
  return `/api/deviations/${deviationId}`
}

function deviationDetails(deviationId) {
  return ifetch(url(deviationId) + `/details`)
}

function config(deviationId) {
  return ifetch(url(deviationId) + `/config`)
}

function management(deviationId, management) {
  return ifetch(url(deviationId) + `/management`, { method: 'PATCH', body: JSON.stringify(management) })
}

function metaData(deviationId, metaData) {
  return ifetch(url(deviationId) + `/meta-data`, { method: 'POST', body: JSON.stringify(metaData) })
}

// no response
function archive(deviationId) {
  return ifetch(url(deviationId) + `/archive`, { method: 'POST' })
}

// no response
function activate(deviationId) {
  return ifetch(url(deviationId) + `/activate`, { method: 'POST' })
}

function deviation(deviationId) {
  return ifetch(url(deviationId) + `/fresh`)
}

export { deviation, deviationDetails, config, management, metaData, archive, activate }
