import { ifetch } from '@integrator/api'

function url(businessUnitId, competencyId) {
  return `api/business/${businessUnitId}/competences/${competencyId}`
}

function getCompetency(businessUnitId, competencyId) {
  return ifetch(url(businessUnitId, competencyId), { method: 'GET' })
}

function deleteCompetency(businessUnitId, competencyId) {
  return ifetch(url(businessUnitId, competencyId), { method: 'DELETE' })
}

function updateCompetency(businessUnitId, competency) {
  return ifetch(url(businessUnitId, competency?.id), { method: 'POST', body: JSON.stringify(competency?.definition) })
}

function users(businessUnitId, competencyId) {
  return ifetch(url(businessUnitId, competencyId) + '/users', { method: 'GET' })
}

export { getCompetency, deleteCompetency, updateCompetency, users }
