import { FsElement } from './fs-element'

/**
 * @typedef { object } ContainerHit
 */
class ContainerHit extends FsElement {
  constructor(props) {
    super(props)
  }
}

export { ContainerHit }
