import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/employees`
}

function getVacationRequests(unitId, year) {
  return ifetch(url(unitId) + `/vacation/planner/${year}`)
}

function acceptRequest(unitId, userId, requestId, message) {
  return ifetch(url(unitId) + `/${userId}/vacation/requests/${requestId}/approve`, { method: 'POST', body: message })
}

function rejectRequest(unitId, userId, requestId, message) {
  return ifetch(url(unitId) + `/${userId}/vacation/requests/${requestId}/reject`, { method: 'POST', body: message })
}

// body = {range: {from:,to:}, message: ''}
function updateRequestRange(unitId, userId, requestId, body) {
  return ifetch(url(unitId) + `/${userId}/vacation/requests/${requestId}/range`, {
    method: 'POST',
    body: JSON.stringify(body)
  })
}

function getMessages(unitId, userId, requestId) {
  return ifetch(url(unitId) + `/${userId}/vacation/requests/${requestId}/messages`)
}

function addMessage(unitId, userId, requestId, message) {
  return ifetch(url(unitId) + `/${userId}/vacation/requests/${requestId}/messages`, { method: 'POST', body: message })
}

// range = {from:, to:}
function validateBeforeCreateRequest(unitId, userId, year, range) {
  return ifetch(url(unitId) + `/${userId}/vacation/requests/${year}/validate`, {
    method: 'POST',
    body: JSON.stringify(range)
  })
}

function validateBeforeChangingRequest(unitId, userId, requestId, range) {
  return ifetch(url(unitId) + `/${userId}/vacation/requests/${requestId}/validate-change`, {
    method: 'POST',
    body: JSON.stringify(range)
  })
}

// range = {from:, to:}
function createRequest(unitId, userId, year, range) {
  return ifetch(url(unitId) + `/${userId}/vacation/requests/${year}`, {
    method: 'POST',
    body: JSON.stringify(range)
  })
}

export {
  getVacationRequests,
  acceptRequest,
  rejectRequest,
  updateRequestRange,
  getMessages,
  createRequest,
  validateBeforeCreateRequest,
  validateBeforeChangingRequest,
  addMessage
}
