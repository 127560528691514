class OrganizationNumber {
  value
  subsidiary
  constructor(props) {
    this.value = props?.value
    this.subsidiary = props?.subsidiary
  }
}

export { OrganizationNumber }
