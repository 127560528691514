import { ProcedureCategory } from './procedure-category'

/**
 * Represents a tree structure of procedure categories.
 */
export class ProcedureCategoryTree {
  /**
   * The categories within the procedure category tree.
   * @type {Array<ProcedureCategory>}
   */
  categories

  /**
   * List of identifiers for all categories and subcategories in the category tree
   * @type {Array<Number>}
   */
  categoryIds

  constructor(props) {
    this.categories = props?.categories?.map(category => new ProcedureCategory(category))
    this.categoryIds = this._toCategoryIds()
  }

  /**
   * Retrieves the identifiers of categories and subcategories for a given category identifier.
   * @param {Number} id - Identifier of the category.
   * @returns {Array<Number>} List of identifiers of categories and subcategories.
   */
  getCategoryAndSubCategoryIds(id) {
    const result = []

    const searchCategories = list => {
      for (let category of list) {
        if (category.id === id) {
          result.push(category.id)
          getSubCategoryIds(category)
          return true
        }
        if (searchCategories(category.categories)) {
          return true
        }
      }
      return false
    }

    const getSubCategoryIds = category => {
      for (let subCategory of category.categories) {
        result.push(subCategory.id)
        getSubCategoryIds(subCategory)
      }
    }

    searchCategories(this.categories)
    return result
  }

  _toCategoryIds() {
    const ids = []

    const recurse = categories => {
      for (const category of categories) {
        ids.push(category.id)
        if (category.categories && category.categories.length > 0) {
          recurse(category.categories)
        }
      }
    }

    recurse(this.categories)
    return ids
  }

  getAllCategories() {
    const allCategories = []
    const recurse = categories => {
      for (const category of categories) {
        allCategories.push(category)
        if (category.categories && category.categories.length > 0) {
          recurse(category.categories)
        }
      }
    }

    recurse(this.categories)
    return allCategories
  }
}
