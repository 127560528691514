import { Enum } from '../../universal'

class IntegratorBusinessModule extends Enum {
  static PERIODIC_CONTROL = new IntegratorBusinessModule('PERIODIC_CONTROL')
  static PERIODIC_CONTROL_MOBILE = new IntegratorBusinessModule('PERIODIC_CONTROL_MOBILE')
  static PERIODIC_CONTROL_PORTAL = new IntegratorBusinessModule('PERIODIC_CONTROL_PORTAL')
  static SICK_LEAVE = new IntegratorBusinessModule('SICK_LEAVE')
  static INTERNAL_CONTROL_MOBILE = new IntegratorBusinessModule('INTERNAL_CONTROL_MOBILE')
  static PLANING_AND_BUILDING_ACT = new IntegratorBusinessModule('PLANING_AND_BUILDING_ACT')
  static INTERNAL_CONTROL = new IntegratorBusinessModule('INTERNAL_CONTROL')
  static END_CONTROL = new IntegratorBusinessModule('END_CONTROL')
  static VACATION = new IntegratorBusinessModule('VACATION')
  static SICK_LEAVE_MOBILE = new IntegratorBusinessModule('SICK_LEAVE_MOBILE')
  static QUALITY_ASSURANCE = new IntegratorBusinessModule('QUALITY_ASSURANCE')
  static VACATION_MOBILE = new IntegratorBusinessModule('VACATION_MOBILE')
  constructor(name) {
    super(name.name ? name.name : name)
  }
}

export { IntegratorBusinessModule }
