'use strict'
/**
 * Authentication Module.
 * Login, Signup and Reset password.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */

import angular from 'angular'
import material from 'angular-material'
import routes from './auth.routes'
import LoginCtrl from './login/login.controller'
import ResetCtrl from './reset/reset.controller'
import ResetChangeCtrl from './reset/reset.change.controller.js'

import authService from '../../common/services/auth/auth.service.js'
import ApiConfig from '../../config/config.constants.js'

export default angular
  .module('module.auth', [authService, ApiConfig, material])
  .config(routes)
  .controller('LoginCtrl', LoginCtrl)
  .controller('ResetCtrl', ResetCtrl)
  .controller('ResetChangeCtrl', ResetChangeCtrl).name
