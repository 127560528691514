import { ifetch } from '@integrator/api'

function baseUrl(unitId, processId) {
  return `/api/business/${unitId}/processes/${processId}`
}

// no json response
function remove(unitId, processId) {
  return ifetch(baseUrl(unitId, processId), { method: 'DELETE' })
}

function repeat(unitId, processId) {
  return ifetch(baseUrl(unitId, processId) + `/repeat`, { method: 'POST' })
}

export { remove, repeat }
