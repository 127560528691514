import { Enum } from '@integrator/api'

class CheckpointModeName extends Enum {
  static CHECK = new CheckpointModeName('CHECK')
  static GROUP = new CheckpointModeName('GROUP')
  static CHECK_GROUP = new CheckpointModeName('CHECK_GROUP')

  constructor(name = CheckpointModeName.CHECK.name) {
    super(name)
  }
}

export { CheckpointModeName }
