import { ifetch } from '@integrator/api'

function url(contextName, relationId) {
  return `/api/filesystems/${contextName}/${relationId}`
}

/**
 * Fetch root structure for file system.
 *
 * @param contextName name of filesystem context (ie: project)
 * @param relationId id of filesystem relation (ie: project id)
 * @param externalClient optional. If provided elements are filtered for specified external client.
 *
 * @returns {(function(*): Promise<string|any>)|*} when resolved we get root structure of filesystem.
 */
function structure(contextName, relationId, externalClient) {
  let externalClientPath = ''
  if (externalClient) {
    externalClientPath += '/' + externalClient
  }
  return ifetch(url(contextName, relationId) + `/structure${externalClientPath}`)
}

export { structure }
