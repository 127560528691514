import { EmployeeHmsCard } from './employee-hms-card'

class EmployeeAttributes {
  birthDate
  employeeNumber
  workPercentage
  workingHours
  workType
  startDate
  endDate
  hmsCard
  otherInfo
  constructor(props) {
    if (props) {
      this.birthDate = props.birthDate
      this.employeeNumber = props.employeeNumber
      this.workPercentage = props.workPercentage
      this.workingHours = props.workingHours
      this.workType = props.workType
      this.startDate = props.startDate
      this.endDate = props.endDate
      this.hmsCard = new EmployeeHmsCard(props.hmsCard)
      this.otherInfo = props.otherInfo
    }
  }
}

export { EmployeeAttributes }
