import './table.dialog.scss'

class Controller {
  constructor(data, $mdDialog) {
    this.data = data
    console.log(this.data)
    this.dialog = $mdDialog
  }

  cancel() {
    this.dialog.hide()
  }
}
Controller.$inject = ['data', '$mdDialog']

export default table => {
  return {
    controller: Controller,
    controllerAs: 'vc',
    template: require('./dialogs.table.tpl.html'),
    autoWrap: true,
    fullscreen: true,
    locals: {
      data: table
    }
  }
}
