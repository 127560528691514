import { Enum } from '../../universal'

class OriginLevel extends Enum {
  static PROJECT = new OriginLevel('PROJECT')
  static BUSINESS = new OriginLevel('BUSINESS')
  static CORPORATE = new OriginLevel('CORPORATE')
  static TEMPLATE = new OriginLevel('TEMPLATE')

  constructor(name) {
    super(name)
  }
}

export { OriginLevel }
