import { ContainerHit } from './container-hit'
import { RecordHit } from './record-hit'
import { FsElement } from './fs-element'

/**
 * @typedef { object } ContainerContent
 * @property { Map<Number, ContainerHit> } container
 * @property { Map<Number, RecordHit> } records
 * @property { List<FsElement> } elements
 */
class ContainerContent {
  id
  containers
  records
  elements

  constructor(props) {
    this.containers = new Map()
    this.records = new Map()
    this.elements = []
    if (props) {
      this.id = props.id
      if (props.containers) {
        for (const [id, containerHit] of Object.entries(props.containers)) {
          this.containers.set(Number(id), new ContainerHit(containerHit))
        }
      }
      if (props.records) {
        for (const [id, recordHit] of Object.entries(props.records)) {
          this.records.set(Number(id), new RecordHit(recordHit))
        }
      }
      if (props.elements) {
        props.elements.forEach(el => {
          let added = false
          const recordHit = this.records.get(el.id)
          if (recordHit) {
            this.elements.push(recordHit)
            added = true
          }
          const containerHit = this.containers.get(el.id)
          if (containerHit) {
            this.elements.push(containerHit)
            added = true
          }
          if (!added) {
            this.elements.push(new FsElement(el))
          }
        })
      }
    }
  }

  isEmpty() {
    return this.elements.length <= 0
  }

  recordsArray() {
    const recordValues = this.records.values()
    return Array.from(recordValues)
  }

  /**
   * Add new record to container.
   *
   * @property {RecordHit} record
   */
  addRecord(record) {
    this.records.set(record.id, record)
    this.elements.push(record)
  }

  removeRecord(record) {
    this.records.delete(record.id)
    this.elements.removeById(record.id)
  }

  updateRecord(record) {
    const index = this.elements.indexById(record.id)
    if (index !== -1) {
      this.elements[index] = record
    }
    this.records.set(record.id, record)
  }
}

export { ContainerContent }
