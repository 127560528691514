import { TaskStatusName } from './task-status-name'
import { Progress } from '../../process'

class TaskStatus {
  name
  progress
  doneDate

  constructor(props) {
    this.name = new TaskStatusName(props?.name)
    this.progress = new Progress(props?.progress)
    this.doneDate = props?.doneDate
  }
}

export { TaskStatus }
