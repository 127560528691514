import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/employees`
}

function employeesStatusList(unitId, year) {
  return ifetch(url(unitId) + `/vacation/status/${year}`)
}

function employeeStatus(unitId, userId, year) {
  return ifetch(url(unitId) + `/${userId}/vacation/status/${year}`)
}

// body = EmployeeVacationSettingsJson
function updateEmployeeStatus(unitId, userId, body) {
  return ifetch(url(unitId) + `/${userId}/vacation/status`, { method: 'POST', body: body })
}

export { employeeStatus, updateEmployeeStatus, employeesStatusList }
