import { ifetch } from '@integrator/api'

function baseUrl(unitId, projectId, taskId) {
  return `/api/business/${unitId}/projects/${projectId}/tasks/${taskId}`
}

function getTask(unitId, projectId, taskId) {
  return ifetch(baseUrl(unitId, projectId, taskId))
}

function preTaskStatusDone(unitId, projectId, taskId) {
  return ifetch(baseUrl(unitId, projectId, taskId) + `/status/done/metadata`, { method: 'GET' })
}

function statusDone(unitId, projectId, taskId, completion) {
  return ifetch(baseUrl(unitId, projectId, taskId) + `/status/done`, {
    method: 'POST',
    body: JSON.stringify(completion)
  })
}

function statusOpen(unitId, projectId, taskId) {
  return ifetch(baseUrl(unitId, projectId, taskId) + `/status/open`, {
    method: 'POST'
  })
}

function deleteTask(unitId, projectId, taskId) {
  return ifetch(baseUrl(unitId, projectId, taskId), { method: 'DELETE' })
}

function deleteTaskWithDeviations(unitId, projectId, taskId) {
  return ifetch(baseUrl(unitId, projectId, taskId) + '/with-deviations', { method: 'DELETE' })
}

function update(unitId, projectId, task) {
  return ifetch(baseUrl(unitId, projectId, task.id), {
    method: 'PATCH',
    body: JSON.stringify(task)
  })
}

function canBeDeleted(unitId, projectId, taskId) {
  return ifetch(baseUrl(unitId, projectId, taskId) + '/canBeDeleted', { method: 'GET' })
}

export {
  preTaskStatusDone,
  statusDone,
  statusOpen,
  getTask,
  update,
  deleteTask,
  canBeDeleted,
  deleteTaskWithDeviations
}
