import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/settings`
}

/**
 * Get unit logo url
 * Remember that it is optional and may be null.
 *
 * @param unitId id of unit
 */
function brandLogo(unitId) {
  return ifetch(url(unitId) + '/brand-logo')
}

export { brandLogo }
