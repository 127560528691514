/**
 * Represents a procedure category.
 */
export class ProcedureCategory {
  /**
   * The ID of the procedure category.
   * @type {number}
   */
  id

  /**
   * The main category ID.
   * @type {number}
   */
  mainCategoryId

  /**
   * The parent category ID.
   * @type {number}
   */
  parentCategoryId

  /**
   * The name of the procedure category.
   * @type {string}
   */
  name

  /**
   * The level of the procedure category.
   * @type {number}
   */
  level

  /**
   * The module of the procedure category.
   * @type {string}
   */
  module

  /**
   * The sort order of the procedure category.
   * @type {number}
   */
  sortOrder

  /**
   * The sub-categories of the procedure category.
   * @type {Array<ProcedureCategory>}
   */
  categories

  /**
   * Flat list of all categories, including child categories
   * @type {Array<ProcedureCategory>}
   */
  subcategories

  /**
   * The tree level of the procedure category.
   * @type {number}
   */
  treeLevel

  /**
   * Indicates whether the procedure category is active.
   * @type {boolean}
   */
  active

  constructor(props) {
    this.id = props?.id
    this.mainCategoryId = props?.mainCategoryId
    this.parentCategoryId = props?.parentCategoryId
    this.name = props?.name
    this.level = props?.level
    this.module = props?.module
    this.sortOrder = props?.sortOrder
    this.categories = props?.categories?.map(category => new ProcedureCategory(category))
    this.subcategories = this._getSubcategories()
    this.treeLevel = props?.treeLevel
    this.active = props?.active
  }

  get isMain() {
    return this.mainCategoryId === 0
  }

  _getSubcategories() {
    const subcategories = []

    const collectSubcategories = category => {
      category?.categories?.forEach(subcategory => {
        subcategories.push(subcategory)
        collectSubcategories(subcategory)
      })
    }

    collectSubcategories(this)
    return subcategories
  }
}
