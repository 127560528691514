import { Enum } from '@integrator/api'

class NekDeviationSeverity extends Enum {
  static TG0 = new NekDeviationSeverity('TG0')
  static TG1 = new NekDeviationSeverity('TG1')
  static TG2 = new NekDeviationSeverity('TG2')
  static TG3 = new NekDeviationSeverity('TG3')

  constructor(name = NekDeviationSeverity.TG1.name) {
    super(name)
  }

  static list() {
    return [NekDeviationSeverity.TG0, NekDeviationSeverity.TG1, NekDeviationSeverity.TG2, NekDeviationSeverity.TG3]
  }

  color() {
    switch (this.name) {
      case 'TG0':
        return 'success'
      case 'TG1':
        return 'primary'
      case 'TG2':
        return 'warning'
      case 'TG3':
        return 'danger'
      default:
        return 'neutral'
    }
  }
}

export { NekDeviationSeverity }
