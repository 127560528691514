import { FieldTemplate } from './field-template'
import { Field } from './field'
import { FieldValues } from './fields-values'
import { FieldType } from './field-type'

/**
 * @typedef { object } Fields
 * @property { Array<FieldTemplate> } templates
 * @property { Array<Field> } fields
 * @property { Map<String, Field> } fieldMap
 */
class Fields {
  templates
  fields
  fieldMap

  constructor(props) {
    this.templates = []
    this.fields = []
    this.fieldMap = new Map()
    if (props.templates) {
      props.templates.forEach(template => {
        this.templates.push(new FieldTemplate(template))
      })
    }
    if (props.fieldMap) {
      for (const [name, field] of Object.entries(props.fieldMap)) {
        this.fieldMap.set(name, new Field(field))
      }
    }
    if (props.fields) {
      props.fields.forEach(field => {
        let f = this.fieldMap.get(field.name)
        if (f) {
          this.fields.push(f)
        } else {
          this.fields.push(new Field(field))
        }
      })
    }
  }

  /**
   * Gets field by template
   *
   * @param {FieldTemplate} fieldTemplate
   * @return { Field } field
   */
  defaultFieldByTemplate(fieldTemplate) {
    let f = this.fieldByName(fieldTemplate.name)
    if (!f) {
      f = new Field({ name: fieldTemplate.name, type: fieldTemplate.type })
      this.fields.push(f)
      this.fieldMap.set(f.name, f)
    }
    return f
  }

  /**
   * Gets field by template
   *
   * @param {FieldTemplate} fieldTemplate
   * @return { Field } field
   */
  fieldByTemplate(fieldTemplate) {
    return this.fieldByName(fieldTemplate.name)
  }

  /**
   * Gets field by name.
   * @param {string} name
   * @return { Field } field
   */
  fieldByName(name) {
    return this.fieldMap.get(name)
  }

  /**
   * @return { FieldValues } construct only values from this fields
   */
  fieldValues() {
    const fieldValues = new FieldValues({})
    this.templates.forEach(fieldTemplate => {
      const field = this.fieldMap.get(fieldTemplate.name)
      if (field) {
        switch (fieldTemplate.type.name) {
          case FieldType.STRING.name: {
            fieldValues.stringValues.set(fieldTemplate.name, field.value)
            break
          }
          case FieldType.DATE.name: {
            fieldValues.dateValues.set(fieldTemplate.name, field.value)
            break
          }
        }
      }
    })
    return fieldValues
  }
}

export { Fields }
