import { InstallationTypeDefinition } from './installation-type-definition'

class InstallationType {
  id
  definition
  active
  description
  constructor(props) {
    if (props) {
      this.id = props.id
      this.definition = new InstallationTypeDefinition(props.definition)
      this.active = props.active
      this.description = props.description
    }
  }
}

export { InstallationType }
