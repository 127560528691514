import { DeviationReport } from './deviation-report'
import { DeviationReportStep } from './deviation-report-step'

class DeviationReports {
  list
  steps
  map
  ids
  constructor(props) {
    this.map = new Map()
    if (props && Object.keys(props).length > 0) {
      this.list = props.list.map(report => new DeviationReport(report))
      this.steps = props.steps?.map(step => new DeviationReportStep(step))
      Object.entries(props.map)?.forEach(([key, val]) =>
        this.map.set(new DeviationReportStep(key), new DeviationReport(val))
      )
      this.ids = props.ids
    }
  }

  add(deviationReport) {
    this.steps.push(deviationReport.step)
    this.list.push(deviationReport)
    this.ids.push(deviationReport.id)
    this.map.set(deviationReport.step, deviationReport)
  }
}

export { DeviationReports }
