import * as deviationApi from './deviation-api'
import * as deviationContextApi from './deviation-context-api'
import * as deviationDeleteApi from './deviation-delete-api'
import * as deviationMetaApi from './deviation-meta-api'
import * as deviationPdfApi from './deviation-pdf-api'
import * as deviationReportApi from './deviation-report-api'
import * as deviationReportsApi from './deviation-reports-api'
import * as deviationsApi from './deviations-api'
import * as deviationsPdfApi from './deviations-pdf-api'
import * as deviationsSearchApi from './deviations-search-api'
import * as deviationsStatisticApi from './deviations-statistic-api'

export * from './model'

export {
  deviationApi,
  deviationContextApi,
  deviationDeleteApi,
  deviationMetaApi,
  deviationPdfApi,
  deviationReportApi,
  deviationReportsApi,
  deviationsApi,
  deviationsPdfApi,
  deviationsSearchApi,
  deviationsStatisticApi
}
