/** @typedef {object} Progress */
export class Progress {
  /** @type {number} */
  goal

  /** @type {number} */
  count

  /** @type {number} */
  current

  /** @type {number} */
  percent

  /** @type {boolean} */
  indeterminate

  constructor(props) {
    if (props) {
      this.goal = props.goal
      this.count = props.count
      this.percent = props.percent
      this.current = props.current
      this.indeterminate = props.indeterminate
    }
  }

  increment() {
    this.current += 1
    let i = this.goal > 0 ? (100 * this.current / this.goal) : 0
    if (this.percent < i) {
      this.percent = i
    }
  }
}
