/**
 * @typedef { object } ElementAttributes
 * @property { number } id
 * @property { string } description
 * @property { string } imagePreviewUrl
 */
class ElementAttributes {
  id
  description
  imagePreviewUrl
  constructor(props) {
    Object.assign(this, props)
  }
}

export { ElementAttributes }
