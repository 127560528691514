import { Action } from '@integrator/api'
/**
 * @typedef { object } Operation
 */
class Operation {
  /** @type {Action} */
  action
  /** @type {String} */
  transaction
  /** @type {String} */
  description
  /** @type {Number} */
  dataId
  /** @type {Object} */
  data

  constructor(props) {
    this.action = new Action(props.action)
    this.transaction = props.transaction
    this.description = props.description
    this.dataId = props.dataId
    this.data = props.data
  }
}

export { Operation }
