'use strict'

import angular from 'angular'
import router from 'angular-ui-router'

import routes from './controls.routes.js'

import ControlsListCtrl from './list/controls.list.controller.js'
import ControlsDetailCtrl from './detail/controls.detail.controller.js'
import ControlsParentCtrl from './controls.parent.controller.js'
import PdfReportService from '../../common/services/data/pdf.report.dataservice.js'

// Directives
import ComponentListDirective from '../../common/directives/lists/components/list.components.directive.js'

import ControlsDataService from '../../common/services/data/controls.dataservice.js'
import './list/controls.list.scss'
import './controls.parent.scss'

export default angular
  .module('ps.module.controls', [router, ControlsDataService, ComponentListDirective, PdfReportService])
  .controller('ControlsListCtrl', ControlsListCtrl)
  .controller('ControlsDetailCtrl', ControlsDetailCtrl)
  .controller('ControlsParentCtrl', ControlsParentCtrl)
  .config(routes).name
