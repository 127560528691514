import { ifetch } from '@integrator/api'

function url(unitId, controlTypeId) {
  return `/api/business/${unitId}/settings/periodic-control/control-types/${controlTypeId}`
}

function details(unitId, controlTypeId) {
  return ifetch(url(unitId, controlTypeId) + '/details')
}

function updateName(unitId, controlTypeId, name) {
  return ifetch(url(unitId, controlTypeId), {
    method: 'PATCH',
    body: name
  })
}

function changeDescription(unitId, controlTypeId, description) {
  return ifetch(url(unitId, controlTypeId) + '/description', {
    method: 'PATCH',
    body: description
  })
}

function activate(unitId, controlTypeId) {
  return ifetch(url(unitId, controlTypeId) + '/activate', {
    method: 'PATCH'
  })
}

function deactivate(unitId, controlTypeId) {
  return ifetch(url(unitId, controlTypeId) + '/deactivate', {
    method: 'PATCH'
  })
}

function deleteControlType(unitId, controlTypeId) {
  return ifetch(url(unitId, controlTypeId), {
    method: 'delete'
  })
}

function canBeDeleted(unitId, controlTypeId) {
  return ifetch(url(unitId, controlTypeId) + '/can-be-deleted')
}

export { activate, changeDescription, deactivate, updateName, deleteControlType, details, canBeDeleted }
