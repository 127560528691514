import { ifetch } from '@integrator/api'

function baseUrl(checklistId) {
  return `/api/checklists/template/${checklistId}/checkpoints`
}

/**
 * @param {number} checklistId
 * @returns {Promise<CheckpointTemplates>}
 */
function get(checklistId) {
  return ifetch(baseUrl(checklistId), { method: 'GET' })
}

function reorder(checklistId, orderMap) {
  return ifetch(baseUrl(checklistId) + '/reorder', { method: 'POST', body: JSON.stringify(orderMap) })
}

function add(checklistId, checkpointTemplate) {
  return ifetch(baseUrl(checklistId), {
    method: 'POST',
    body: JSON.stringify(checkpointTemplate)
  })
}

function addMultiple(checklistId, checkpoints) {
  return ifetch(baseUrl(checklistId) + `/add-multiple`, {
    method: 'POST',
    body: JSON.stringify(checkpoints)
  })
}

export { get, reorder, add, addMultiple }
