import { ifetch } from '@integrator/api'

function url(unitId, projectId, controlId) {
  return `/api/business/${unitId}/projects/${projectId}/periodic-control/controls/${controlId}`
}

function getControl(unitId, projectId, controlId) {
  return ifetch(url(unitId, projectId, controlId), {
    method: 'GET'
  })
}

// measurementProtocols method on the backend
function saveControlInstallation(unitId, projectId, controlId, controlInstallation) {
  return ifetch(url(unitId, projectId, controlId) + '/installation', {
    method: 'POST',
    body: JSON.stringify(controlInstallation)
  })
}

function getControlInstallation(unitId, projectId, controlId) {
  return ifetch(url(unitId, projectId, controlId) + '/installation', {
    method: 'GET'
  })
}

function updateControl(unitId, projectId, controlId, controlSetup) {
  return ifetch(url(unitId, projectId, controlId), {
    method: 'PATCH',
    body: JSON.stringify(controlSetup)
  })
}

function statusDone(unitId, projectId, controlId, completion) {
  return ifetch(url(unitId, projectId, controlId) + '/status/done', {
    method: 'POST',
    body: JSON.stringify(completion)
  })
}

function statusOpen(unitId, projectId, controlId, completion) {
  return ifetch(url(unitId, projectId, controlId) + '/status/open', {
    method: 'POST'
  })
}

function deleteControl(unitId, projectId, controlId) {
  return ifetch(url(unitId, projectId, controlId), {
    method: 'DELETE'
  })
}

function preControlStatusDone(unitId, projectId, controlId) {
  return ifetch(url(unitId, projectId, controlId) + '/status/done/metadata', {
    method: 'GET'
  })
}

export {
  getControl,
  saveControlInstallation,
  getControlInstallation,
  updateControl,
  statusDone,
  statusOpen,
  deleteControl,
  preControlStatusDone
}
