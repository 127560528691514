import { Address, NameIdentity, Person } from '../../universal'
import { Contact } from './contact'

class ContactPerson {
  id
  userId
  person
  address
  contact
  title
  mainContact
  activeAccount
  constructor(props) {
    if (props) {
      this.id = props.id
      this.userId = props.userId
      this.person = new Person(props.person)
      this.address = new Address(props.address)
      this.contact = new Contact(props.contact)
      this.title = new NameIdentity(props.title)
      this.mainContact = props.mainContact
      this.activeAccount = props.activeAccount
    }
  }
}

export { ContactPerson }
