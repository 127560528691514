import { ifetch } from '@integrator/api'

function url(contextName) {
  return `/api/filesystems/${contextName}`
}

function findInContext(contextName, relationId) {
  return ifetch(url(contextName) + `/${relationId}`)
}

function multiStructure(contextName, ids) {
  return ifetch(`${url(contextName)}/multi-structure`, { method: 'POST', body: JSON.stringify(ids) })
}

export { findInContext, multiStructure }
