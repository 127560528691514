export class Definition {
  /** @type {number} */
  id

  /** @type {string} */
  name

  /** @type {string} */
  description

  constructor(props) {
    if (props) {
      this.id = props.id
      this.name = props.name
      this.description = props.description
    }
  }
}
