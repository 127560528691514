'use strict'

import angular from 'angular'
import ApiConstants from '../../../config/config.constants.js'
import '@integrator/js/src/date'

class DeviationsDataService {
  constructor($http, $log, $q, $cacheFactory, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.cache = $cacheFactory.get('$http')
    this.endpoint = new DeviationsEndpoint(apiConfig)
  }

  /**
   * Get deviations.
   * @param relationName
   * @param relationId|
   */
  fetch(relationName, relationId) {
    let toDate = new Date().toArray()
    toDate[2] = toDate[2] + 1
    let asyncResult = this.$q.defer()
    let criteria = {
      discoveryDateRange: {
        from: 0,
        to: toDate
      },
      newDeviation: true,
      open: true,
      closed: true,
      archived: false,
      limit: 1000,
      offset: 0,
      ordered: true
    }
    this.$http
      .post(this.endpoint.deviations(relationName, relationId), criteria)
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for POST ' +
            this.endpoint.deviations(relationName, relationId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  details(deviationId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.details(deviationId))
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.details(deviationId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }
}

DeviationsDataService.$inject = ['$http', '$log', '$q', '$cacheFactory', 'apiConfig']

class DeviationsEndpoint {
  constructor(apiConfig) {
    this.path = apiConfig.url() + 'deviations/'
  }

  deviations(relationName, relationId) {
    return this.path + 'search/' + relationName + '/' + relationId
  }

  details(deviationId) {
    return this.path + deviationId
  }
}

/**
 * Export the service as a separate angular module.
 */
export default angular
  .module('service.data.deviations', [ApiConstants])
  .service('psDeviationsService', DeviationsDataService).name
