'use strict'

/**
 * Data service for getting document templates.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import ApiConstants from '../../../config/config.constants.js'

class TemplatesDataService {
  constructor($http, $log, $q, $cacheFactory, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.cache = $cacheFactory.get('$http')
    this.endpoint = new TemplateDocumentsEndpoint(apiConfig)
  }

  /**
   * Get document templates for project.
   * @param projectId
   */
  getProjectTemplates(projectId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.templates(projectId), { cache: true })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.templates(projectId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }
}

TemplatesDataService.$inject = ['$http', '$log', '$q', '$cacheFactory', 'apiConfig']

// Definition for the templates endpoint.
class TemplateDocumentsEndpoint {
  constructor(apiConfig) {
    this.path = apiConfig.completeUrl() + '/templates'
  }

  templates(projectId) {
    return this.path + '/' + projectId
  }
}

/**
 * Export the service as a seperate angular module.
 */
export default angular
  .module('ps.service.data.templates', [ApiConstants])
  .service('psTemplateService', TemplatesDataService).name
