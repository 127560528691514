import { ifetch } from '@integrator/api'

function url(contextName, relationId, containerId) {
  return `/api/filesystems/${contextName}/${relationId}/container-structure/${containerId}`
}

//ContainerStructureEndpoint.java
function uploadFile(contextName, relationId, containerId, formData) {
  const options = { method: 'POST', body: formData, headers: {} } // given empty headers: {} forces browser to create correct Content-Type to send a file
  return ifetch(url(contextName, relationId, containerId) + '/add-record', options)
}

//ContextFileSystemsEndpoint.java
function initFileSystem(contextName, relationId) {
  return ifetch(`/api/filesystems/${contextName}/${relationId}/init-fs`, { method: 'POST' })
}

export { initFileSystem, uploadFile }
