import { Frequency } from './frequency'
import { Mode } from './mode'
import { Day } from './day'

class EventRecurrence {
  frequency
  factor
  mode
  days
  constructor(props) {
    this.frequency = new Frequency(props?.frequency)
    this.factor = props?.factor
    this.mode = new Mode(props?.mode)
    this.days = props?.days?.map(dayName => new Day(dayName)) || []
  }
}

export { EventRecurrence }
