import { LocalDateTime } from './local-date-time'
import { NameIdentity } from './name-identity'
/**
 * @typedef {Object} Action
 * @property {string} name
 * @property {LocalDateTime} at
 * @property {NameIdentity} by
 * @property {number} date
 */
class Action {
  name
  at
  by
  date
  constructor(props) {
    this.name = props.name
    if (props.at) {
      this.at = new LocalDateTime(props.at)
    }
    this.by = new NameIdentity(props.by)
    this.date = props.date
  }

  atDate() {
    return this.at?.toDate()
  }
}

export { Action }
