import { ifetch } from '@integrator/api'

function url(unitId, userId, action) {
  return `/business/${unitId}/employees/${userId}/${action}`
}

function getWorkTitle(unitId, userId) {
  return ifetch(url(unitId, userId, 'work-title'), { method: 'GET' })
}

function updateWorkTitle(unitId, userId, workTitleId) {
  return ifetch(url(unitId, userId, 'work-title/' + workTitleId), { method: 'POST' })
}

export { getWorkTitle, updateWorkTitle }
