import { ifetch } from '@integrator/api'

function baseUrl(deviationId) {
  return `/api/deviations/${deviationId}`
}

// no response value
function remove(deviationId) {
  return ifetch(baseUrl(deviationId), { method: 'DELETE' })
}

// no response value
function restore(deviationId, deleteId) {
  return ifetch(baseUrl(deviationId) + `/${deleteId}`, { method: 'GET' })
}

export { remove, restore }
