import { ControlTypes } from './control-types'

class InstallationTypeDefinition {
  controlTypes
  name
  constructor(props) {
    this.controlTypes = new ControlTypes(props?.controlTypes)
    this.name = props?.name
  }
}

export { InstallationTypeDefinition }
