import { ifetch } from '@integrator/api'

function baseUrl() {
  return `/api/mom/building-elements`
}

function list() {
  return ifetch(baseUrl(), { method: 'GET' })
}

function informationTypes() {
  return ifetch(baseUrl() + `/information-types`, { method: 'GET' })
}

// no response value
function refresh() {
  return ifetch(baseUrl() + `/refresh`, { method: 'POST' })
}

export { list, informationTypes, refresh }
