import { ifetch } from '@integrator/api'

function url(businessUnitId, businessUserId, deviceId) {
  return '/api/business/' + businessUnitId + '/users/' + businessUserId + '/devices/' + deviceId
}

function deactivate(unitId, userId, deviceId) {
  return ifetch(url(unitId, userId, deviceId), { method: 'POST' })
}

export { deactivate }
