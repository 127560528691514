import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedures`
}

function pushDownAll(unitId, revision, childUnitIds) {
  switch (revision) {
    case 'INCREMENT_REVISION':
    case 'KEEP_AS_DRAFT':
      return ifetch(url(unitId) + `/push-down-all/${revision}`, {
        method: 'POST',
        body: childUnitIds ? JSON.stringify(childUnitIds) : null
      })
  }
}

function pushDown(unitId, procedureId, revision, childUnitIds) {
  switch (revision) {
    case 'INCREMENT_REVISION':
    case 'KEEP_AS_DRAFT':
      return ifetch(url(unitId) + `/${procedureId}/push-down/${revision}`, {
        method: 'POST',
        body: childUnitIds ? JSON.stringify(childUnitIds) : null
      })
  }
}

export { pushDown, pushDownAll }
