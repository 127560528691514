import { Enum } from '../../universal'

class DeviationModuleLevel extends Enum {
  static UNIT = new DeviationModuleLevel('UNIT')
  static PROJECT = new DeviationModuleLevel('PROJECT')
  static CONTRACT = new DeviationModuleLevel('CONTRACT')
  static CUSTOMER = new DeviationModuleLevel('CUSTOMER')
}

export { DeviationModuleLevel }
