import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/projects/user-time-registration`
}

function activeProject(unitId) {
  return ifetch(url(unitId) + `/active`)
}

function activeProjectForUser(unitId, userId) {
  return ifetch(url(unitId, projectId) + `/active/${userId}`)
}

export { activeProject, activeProjectForUser }
