import { Directive, directive } from 'lit/directive.js'
import session from './session'

/**
 * Directive depends on session
 * In order to use it please call: session.create(session)
 */
class TranslateDirective extends Directive {
  render(key, locale) {
    return session ? session.doTranslation(key, locale) : key
  }
}

const translate = directive(TranslateDirective)

export default translate
