import { BusinessAccountRoles } from './business-account-roles'

class Account {
  status
  created
  expirationDate
  passwordProtected
  accountRoles
  lastSuccessLogin
  constructor(props) {
    if (props) {
      this.status = props.status
      this.created = props.created
      this.expirationDate = props.expirationDate
      this.passwordProtected = props.passwordProtected
      this.accountRoles = new BusinessAccountRoles(props.accountRoles)
      this.lastSuccessLogin = props.lastSuccessLogin
    }
  }
}

export { Account }
