'use strict'
/**
 * Module for Projects (listing and viewing details).
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
/**  Dependencies  **/
import angular from 'angular'
import WebComponentController from '../../common/web.component.controller.js'
import routes from './projects.routes'
import ProjectsCtrl from './projects.controller.js'
import ProjectDetailCtrl from './projects.detail.controller.js'
import ProjectAlternativesCtrl from './projects.alternatives.controller.js'
import ProjectDeviationCtrl from './project.deviation.controller.js'

// Services
import AuthService from '../../common/services/auth/auth.service.js'
import ProjectsService from './../../common/services/data/projects.dataservice.js'
import PsDownloadService from '../../common/services/data/download.dataservice.js'
import NotificationService from '../../common/services/service.notifications.js'

// Modules
import DocumentsModule from '../documents/documents.module.js'

// Directives
import ProjectsSidebarDirective from '../../common/directives/lists/list.projects.directive.js'

// Styles
import './projects.scss'
import './project.details.scss'

export default angular
  .module('ps.module.projects', [
    AuthService,
    ProjectsService,
    PsDownloadService,
    DocumentsModule,
    ProjectsSidebarDirective,
    NotificationService
  ])
  .controller('ProjectsCtrl', ProjectsCtrl)
  .controller('ProjectDetailCtrl', ProjectDetailCtrl)
  .controller('ProjectAlternativesCtrl', ProjectAlternativesCtrl)
  .controller('ProjectDeviationCtrl', ProjectDeviationCtrl)
  .controller('WebComponentController', WebComponentController)
  .config(routes).name
