import { ifetch } from '@integrator/api'

function url(projectId) {
  return `/api/projects/${projectId}/orders`
}

function ordersInProject(projectId) {
  return ifetch(url(projectId))
}

function search(projectId, query) {
  return ifetch(url(projectId) + '/search/by-files-full-text-search', { body: query, method: 'POST' })
}

function orderNumbersSearch(projectId, query) {
  return ifetch(url(projectId) + '/search/numbers', { body: query, method: 'POST' })
}

export { ordersInProject, search, orderNumbersSearch }
