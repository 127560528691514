import { ifetch } from '@integrator/api'

function baseUrl(businessContent, procedureId) {
  return `/api/${businessContent}/procedures/${procedureId}/checklists`
}

function list(businessContent, procedureId) {
  return ifetch(baseUrl(businessContent, procedureId), { method: 'GET' })
}

function add(businessContent, procedureId, definition, subjectAreaIds) {
  let url = baseUrl(businessContent, procedureId)
  if (subjectAreaIds) {
    url = baseUrl(businessContent, procedureId) + `/subjectAreaIds?${subjectAreaIds}`
  }

  return ifetch(url, {
    method: 'POST',
    body: JSON.stringify(definition)
  })
}

// no response value
function copyTo(businessContent, procedureId, sourceContentName, sourceProcedureId) {
  return ifetch(baseUrl(businessContent, procedureId) + `/copy-to/${sourceContentName}/${sourceProcedureId}`, {
    method: 'POST'
  })
}

export { list, add, copyTo }
