import { html, css, LitElement } from 'lit'
import '@shoelace-style/shoelace/dist/components/format-date/format-date.js'
import '@iwc/user/src/shared/action-user'
import { recordLineStyles } from './record-line-styles'

/**
 */
export class RecordLine extends LitElement {
  static styles = [recordLineStyles]

  static properties = {
    record: { type: Object }
  }

  firstUpdated(_changeProperties) {}

  render() {
    return html`
      <span class="name">
        <a href=${this.record.url} target="_blank" style="color: var(--sl-color-teal-500)">${this.record.name}</a>
        <i style="margin-left: 1em">${this.record.description}</i>
      </span>
      <span class="createdBy">
        <i-action-user .actions=${this.record.actions} .name=${'created'}></i-action-user>
      </span>
      <span class="createdAt">
        <sl-format-date date=${new Date(this.record.actions.map.created.date)}></sl-format-date>
      </span>
      <span class="size">${this.record.size}kb</span>
    `
  }
}

customElements.define('i-record-line', RecordLine)
