import { ifetch } from '@integrator/api'

function url(unitId, projectId, controlId, componentId) {
  return `/api/business/${unitId}/project/${projectId}/periodic-control/controls/${controlId}/components/${componentId}/pdf`
}

function componentInfoPdf(unitId, projectId, controlId, componentId) {
  return ifetch(url(unitId, projectId, controlId, componentId) + '/component-info')
}

function componentInfoData(unitId, projectId, controlId, componentId) {
  return ifetch(url(unitId, projectId, controlId, componentId) + '/component-info-data')
}

function measurementProtocolPdf(unitId, projectId, controlId, componentId) {
  return ifetch(url(unitId, projectId, controlId, componentId) + '/measurement-protocol')
}

function measurementProtocolData(unitId, projectId, controlId, componentId) {
  return ifetch(url(unitId, projectId, controlId, componentId) + '/measurement-protocol-data')
}

export { componentInfoPdf, componentInfoData, measurementProtocolPdf, measurementProtocolData }
