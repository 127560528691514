import { ifetch } from '@integrator/api'

function url(unitId, projectId, userId) {
  return `/api/business/${unitId}/projects/${projectId}/customer-portal/users/${userId}`
}

function removeUser(unitId, projectId, userId) {
  return ifetch(url(unitId, projectId, userId), { method: 'DELETE' })
}

function resendEmail(unitId, projectId, userId) {
  return ifetch(url(unitId, projectId, userId) + '/resend-email', { method: 'POST' })
}

export { removeUser, resendEmail }
