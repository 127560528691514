import { ifetch } from '@integrator/api'

function url(unitId, mainCategoryId) {
  return `/api/business/${unitId}/procedures/main-categories/${mainCategoryId}`
}

function remove(unitId, mainCategoryId) {
  return ifetch(url(unitId, mainCategoryId), {
    method: 'DELETE'
  })
}

function update(unitId, mainCategoryId, mainCategory) {
  return ifetch(url(unitId, mainCategoryId), {
    method: 'POST',
    body: JSON.stringify(mainCategory)
  })
}

function activate(unitId, mainCategoryId) {
  return ifetch(url(unitId, mainCategoryId) + `/activate`, {
    method: 'POST'
  })
}

function deactivate(unitId, mainCategoryId) {
  return ifetch(url(unitId, mainCategoryId) + `/deactivate`, {
    method: 'POST'
  })
}

export { activate, deactivate, update, remove }
