'use strict'

class ProcedureParentCtrl {
  constructor($stateParams, $state, projects) {
    this.$state = $state
    this.$stateParams = $stateParams
    this.projects = projects
  }

  isDetailState() {
    return this.$state.current.name !== 'procedures.parent'
  }
}

ProcedureParentCtrl.$inject = ['$stateParams', '$state', 'projects']

export default ProcedureParentCtrl
