import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/employees/sick-leave`
}

function sickLeaveStatus(businessUnitId) {
  return ifetch(url(businessUnitId) + `/status`, { method: 'GET' })
}

function absence(businessUnitId, year) {
  return ifetch(url(businessUnitId) + `/random-absence/${year}`, { method: 'GET' })
}

function overview(businessUnitId, year) {
  return ifetch(url(businessUnitId) + `/overview/${year}`, { method: 'GET' })
}

export { sickLeaveStatus, overview, absence }
