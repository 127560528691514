/**
 * @typedef { object } ExternalAccess
 * @property { number } id
 * @property { string } name
 */
class ExternalAccess {
  clientPermissions

  constructor(props) {
    if (props) {
      this.clientPermissions = new Map()
      for (const [client, permission] of Object.entries(props.clientPermissions)) {
        this.clientPermissions.set(client, permission)
      }
    }
  }

  toJSON() {
    return this.clientPermissions ? { map: Object.fromEntries(this.clientPermissions) } : undefined
  }
}

export { ExternalAccess }
