import { Action } from './action'
/**
 * @typedef { object } Actions
 * @property { Map<String, Action> } map
 */
class Actions {
  map
  constructor(props) {
    if (props?.map) {
      const actionMap = props.map
      if (actionMap instanceof Map) {
        this.map = new Map(actionMap)
      } else {
        this.map = new Map()
        for (const [actionName, action] of Object.entries(actionMap)) {
          this.map.set(actionName, new Action(action))
        }
      }
    }
  }

  byName(name) {
    return this.map.get(name)
  }
}

export { Actions }
