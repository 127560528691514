import { Actions, Tags } from '../../universal'
import { BusinessModule } from '../../business'
import { DeviationContext } from './deviation-context'
import { DeviationErrorCode } from './deviation-error-code'
import { DeviationField } from './deviation-field'
import { DeviationId } from './deviation-id'
import { DeviationManagement } from './deviation-management'
import { DeviationReport } from './deviation-report'
import { DeviationStatus } from './deviation-status'
import { IntegratorBusinessModule } from '../../module'

class Deviation {
  /**
   * Unique identifier for the deviation.
   * @type {DeviationId}
   */
  id

  /**
   * The date when the deviation was discovered.
   * @type {number}
   */
  discoveryDate

  /**
   * Numerical order for sorting deviations by discovery date.
   * @type {number}
   */
  discoveryDateOrder

  /**
   * Error code associated with the deviation.
   * @type {DeviationErrorCode}
   */
  errorCode

  /**
   * The field or area in which the deviation occurred.
   * @type {DeviationField}
   */
  field

  /**
   * Actions taken or required for the deviation.
   * @type {Actions}
   */
  actions

  /**
   * Severity level of the deviation.
   * Possible values: 'UNKNOWN', 'HIGH', 'LOW', 'MEDIUM', 'IMPROVEMENT'
   * @type {string}
   */
  severity

  /**
   * Numerical order for sorting deviations by severity.
   * @type {number}
   */
  severityOrder

  /**
   * Location where the deviation was identified.
   * @type {string}
   */
  location

  /**
   * The business module associated with the deviation.
   * @type {BusinessModule}
   */
  module

  /**
   * Current status of the deviation.
   * @type {DeviationStatus}
   */
  status

  /**
   * Context or additional metadata related to the deviation.
   * @type {DeviationContext}
   */
  context

  /**
   * Report associated with the deviation, if open.
   * @type {DeviationReport}
   */
  openReport

  /**
   * Tags or labels associated with the deviation.
   * @type {Tags}
   */
  tags

  /**
   * Information about the management of the deviation.
   * @type {DeviationManagement}
   */
  management

  constructor(props) {
    this.id = new DeviationId(props?.id)
    this.discoveryDate = props?.discoveryDate
    this.discoveryDateOrder = props?.discoveryDateOrder
    this.errorCode = new DeviationErrorCode(props?.errorCode)
    this.field = new DeviationField(props?.field)
    this.actions = new Actions(props?.actions)
    this.severity = props?.severity
    this.severityOrder = props?.severityOrder
    this.location = props?.location
    if (props?.module) {
      this.module = new BusinessModule(props.module)
    }
    this.status = new DeviationStatus(props?.status)
    this.context = new DeviationContext(props?.context)
    this.openReport = new DeviationReport(props?.openReport)
    this.tags = new Tags(props?.tags)
    this.management = new DeviationManagement(props?.management)
  }

  isErrorCodeVisible() {
    return [IntegratorBusinessModule.PERIODIC_CONTROL, IntegratorBusinessModule.PERIODIC_CONTROL_MOBILE].includes(
      this.module
    )
  }
}

export { Deviation }
