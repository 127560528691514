import { Enum } from '@integrator/api'

class CheckpointStatusName extends Enum {
  static UNCHECKED = new CheckpointStatusName('UNCHECKED')
  static CHECKED = new CheckpointStatusName('CHECKED')
  static NOT_RELEVANT = new CheckpointStatusName('NOT_RELEVANT')

  constructor(name = CheckpointStatusName.UNCHECKED) {
    super(name)
  }
}

export { CheckpointStatusName }
