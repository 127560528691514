import { Enum } from './enum'

class IntegratorExternalClient extends Enum {
  static READ_ACCESS = new IntegratorExternalClient('READ_ACCESS')
  static MOBILE_APP = new IntegratorExternalClient('MOBILE_APP')

  constructor(name) {
    super(name)
  }
}

export { IntegratorExternalClient }
