import { FsPath } from './fs-path'
/**
 * @typedef { object } PsPaths
 * @property { FsPath } name
 * @property { FsPath } createdAt
 */
class FsPaths {
  name
  createdAt
  constructor(props) {
    this.name = new FsPath(props.name ? props.name : {})
    this.createdAt = new FsPath(props.createdAt ? props.createdAt : {})
  }
}

export { FsPaths }
