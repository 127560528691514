import { ifetch } from '@integrator/api'

function url(unitId) {
  return `/api/business/${unitId}/procedures`
}

function add(unitId, categoryId, procedureName) {
  return ifetch(url(unitId) + '/add/' + categoryId, {
    method: 'POST',
    body: procedureName
  })
}

function assign(unitId, categoryId, templateProcedureId) {
  return ifetch(url(unitId) + '/' + categoryId + '/assign/' + templateProcedureId, {
    method: 'POST'
  })
}

function assignWithOrder(unitId, categoryId, templateProcedureId, orderMap) {
  return ifetch(url(unitId) + '/' + categoryId + '/assign-with-order/' + templateProcedureId, {
    method: 'POST',
    body: JSON.stringify(orderMap)
  })
}

function reorder(unitId, categoryId, order) {
  return ifetch(url(unitId) + '/reorder/' + categoryId, {
    method: 'POST',
    body: JSON.stringify(order)
  })
}

export { add, assign, assignWithOrder, reorder }
