import { css, html, LitElement } from 'lit'
import '@shoelace-style/shoelace/dist/components/skeleton/skeleton.js'
import './session-user'
import { Actions } from '@integrator/api/src/universal'
import translate from '@iwc/common/src/translate'

class ActionUser extends LitElement {
  static styles = [
    css`
      :host {
        display: block;
      }
    `
  ]

  static properties = {
    actions: { type: Actions },
    name: { type: String, reflect: true },
    showEmail: { type: Boolean },
    _userId: { type: Number }
  }

  render() {
    let action
    if (this.actions.byName) {
      action = this.actions.byName(this.name)
    } else {
      action = this.actions.map[this.name]
    }
    if (action) {
      this._userId = action.by.id
    }
    if (this._userId) {
      return html`
        <i-session-user .userId=${this._userId} ?showEmail=${this.showEmail}></i-session-user>
      `
    }
    return translate('NONE')
  }
}

customElements.define('i-action-user', ActionUser)
