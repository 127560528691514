import { Checklist } from './checklist'
import { BaseChecklist } from './base-checklist'

class Checklists extends BaseChecklist {
  /**
   * @param {Array<Checklist>} list
   * @param {{ [key: number]: Checklist }} localMap
   */
  constructor(props) {
    super()
    this.list = props?.list?.map(checklist => new Checklist(checklist))
    if (props) {
      this.sortByDefinitionName()
    }
  }
}

export { Checklists }
