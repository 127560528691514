import { Definition, Enumeration } from '../../universal'
import { DistributionBoard } from './distribution-board'
import { ComponentStatusName } from './component-status-name'
import { ComponentSettings } from './component-settings'

class Component {
  id
  parentId
  type
  definition
  location
  distributionBoard
  status
  settings
  constructor(props) {
    if (props) {
      this.id = props.id
      this.parentId = props.parentId
      this.type = new Enumeration(props.type)
      this.definition = new Definition(props.definition)
      this.location = props.location
      this.distributionBoard = new DistributionBoard(props.distributionBoard)
      this.status = new ComponentStatusName(props.status)
      this.settings = new ComponentSettings(props.settings)
    }
  }
}

export { Component }
