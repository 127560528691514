import { Enum } from '../../universal'

class DeviationRelationName extends Enum {
  static UNIT_PROCEDURE = new DeviationRelationName('UNIT_PROCEDURE')
  static PROCEDURE = new DeviationRelationName('PROCEDURE')
  static CHECKPOINT = new DeviationRelationName('CHECKPOINT')
  static CHECKLIST = new DeviationRelationName('CHECKLIST')
  static CONTROL = new DeviationRelationName('CONTROL')
  static COMPONENT = new DeviationRelationName('COMPONENT')
  static TASK = new DeviationRelationName('TASK')
  static PROJECT = new DeviationRelationName('PROJECT')
  static BUSINESS_UNIT = new DeviationRelationName('BUSINESS_UNIT')
  constructor(name) {
    super(name)
  }
}

export { DeviationRelationName }
