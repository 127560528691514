'use strict'

/**
 * Data service for getting documents.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import ApiConstants from '../../../config/config.constants.js'

class DocumentsDataService {
  constructor($http, $log, $q, $cacheFactory, apiConfig) {
    this.$http = $http
    this.$log = $log
    this.$q = $q
    this.cache = $cacheFactory.get('$http')
    this.endpoint = new DocumentsEndpoint(apiConfig)
  }

  /**
   * Get documents.
   * @param contextName
   * @param relationId
   * @param containerId optional!
   */
  getDocuments(contextName, relationId, containerId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.documents(contextName, relationId, containerId), { cache: false })
      .then(response => {
        asyncResult.resolve(response)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.documents(contextName, relationId, containerId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })

    return asyncResult.promise
  }

  /**
   * Get documents.
   * @param contextName
   * @param relationId
   */
  getFlattenDocuments(contextName, relationId) {
    let asyncResult = this.$q.defer()
    this.$http
      .get(this.endpoint.flatDocuments(contextName, relationId), { cache: false })
      .then(response => {
        asyncResult.resolve(response.data)
      })
      .catch(error => {
        this.$log.error(
          'XHR failed for GET ' +
            this.endpoint.flatDocuments(contextName, relationId) +
            ' with error: ' +
            error.status +
            ' ' +
            error.statusText
        )
        asyncResult.reject(error)
      })
    return asyncResult.promise
  }
}

DocumentsDataService.$inject = ['$http', '$log', '$q', '$cacheFactory', 'apiConfig']

class DocumentsEndpoint {
  constructor(apiConfig) {
    this.path = apiConfig.completeFsUrl()
  }

  //
  // Path for fs documents
  // containerId is optional!
  //
  documents(contextName, relationId, containerId) {
    var rootPath = this.path + '/' + contextName + '/' + relationId
    if (containerId) {
      rootPath += '/container-structure/' + containerId
    }
    rootPath += '/structure'
    return rootPath
  }

  //
  // Path for fs documents which are flatten
  //
  flatDocuments(contextName, relationId) {
    return this.path + '/' + contextName + '/' + relationId + '/flat-structure'
  }
}

/**
 * Export the service as a separate angular module.
 */
export default angular
  .module('ps.service.data.documents', [ApiConstants])
  .service('psDocumentsService', DocumentsDataService).name
