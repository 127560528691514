import { Actions, Definition, Progress } from '@integrator/api'

/** @typedef {object} Process */
export class Process {
  /** @type {Definition} */
  definition

  /** @type {Progress} */
  progress

  /** @type {string} */
  status

  /** @type {Actions} */
  actions

  constructor(props) {
    if (props) {
      this.definition = new Definition(props.definition)
      this.actions = new Actions(props.actions)
      this.progress = new Progress(props.progress)
      this.status = props.status
    }
  }

  createdAt() {
    let dateTimeAsArray = this.actions?.byName('created').at.array
    dateTimeAsArray[1]-- // js months start from 0, java from 1
    return new Date(...dateTimeAsArray.slice(0, 6))
  }
}
