class ComponentsListCtrl {
  constructor(components, $stateParams, $state) {
    this.count = components.data.count
    this.components = components.data.data
    this.data = ['components']
    this.projectId = $stateParams.project
    this.controldId = $stateParams.control
    this.unitId = $stateParams.unitId
    this.$state = $state
  }

  goToComponent(id) {
    this.$state.go('components.detail', {
      unitId: this.unitId,
      project: this.projectId,
      control: this.controldId,
      component: id
    })
  }
}

ComponentsListCtrl.$inject = ['components', '$stateParams', '$state']
export default ComponentsListCtrl
