import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `api/business/${businessUnitId}/competences`
}

function newCompetency(businessUnitId, competency) {
  return ifetch(url(businessUnitId), {
    method: 'POST',
    body: JSON.stringify(competency)
  })
}

function getCompetencies(businessUnitId) {
  return ifetch(url(businessUnitId), { method: 'GET' })
}

function getCompetencyGroups(businessUnitId) {
  return ifetch(url(businessUnitId) + '/groups', { method: 'GET' })
}

function getCompetencyTypes(businessUnitId) {
  return ifetch(url(businessUnitId) + '/types', { method: 'GET' })
}

function matrix(businessUnitId) {
  return ifetch(url(businessUnitId), { method: 'GET' })
}

export { getCompetencies, getCompetencyGroups, getCompetencyTypes, newCompetency, matrix }
