'use strict'

/**
 * Controller for projects.
 */
class ProjectsCtrl {
  constructor($log, psProjectService, psDownloadService, $state) {
    this.$log = $log
    this.service = psProjectService
    this.projects = null
    this.downloadService = psDownloadService
    this.$state = $state
    this.getData()
    this.showCardsList = true
  }

  groupProjects(projects) {
    let groupProjects = {}
    for (let i in projects) {
      let project = projects[i]
      let groupPath = project.groupPath
      groupPath = groupPath == undefined ? '' : groupPath
      let innerProjects = groupProjects[groupPath]
      if (!innerProjects) {
        innerProjects = []
      }
      innerProjects.push(project)
      groupProjects[groupPath] = innerProjects
    }
    return groupProjects
  }

  getData() {
    this.service.list().then(response => {
      this.projects = response.data
      this.projectsGroupped = this.groupProjects(this.projects)
    })
  }

  getDocument(documentId, fileName) {
    this.$log.info('Download document with id: ' + documentId + ' and fileName: ' + fileName)
    this.downloadService.getDocument(documentId, fileName)
  }

  shouldShowList() {
    return this.$state.current.name === 'projects.list'
  }

  toggleListView(showCards) {
    this.showCardsList = showCards
  }
}

ProjectsCtrl.$inject = ['$log', 'psProjectService', 'psDownloadService', '$state']

export default ProjectsCtrl
