import { ContainerStructure, RecordHitOrigin } from '@integrator/api'
import { TemplateProcedureFiles } from './template-procedure-files'

/**
 * Represents a files available in project procedure
 */
export class BusinessProcedureFiles extends TemplateProcedureFiles {
  businessContainerStructure

  /**
   * Structure which is template provided by unit in unit for reuse
   */
  businessTemplateContainerStructure

  /**
   * Structure which is template provided by corporate parent unit.
   */
  corporateTemplateContainerStructure

  /**
   * File system for company files.
   * This is used when create runtime files.
   */
  businessProcedureFileSystemId

  businessProcedureId

  /**
   * File system for company runtime template files.
   * This is used when creating templates inside unit.
   */
  businessTemplateProcedureFileSystemId

  constructor(props) {
    super(props)
    if (props) {
      this.businessContainerStructure = new ContainerStructure(props.businessContainerStructure)
      this.businessTemplateContainerStructure = new ContainerStructure(props.businessTemplateContainerStructure)
      this.corporateTemplateContainerStructure = new ContainerStructure(props.corporateTemplateContainerStructure)
      this.businessProcedureFileSystemId = props.businessProcedureFileSystemId
      this.businessProcedureId = props.businessProcedureId
      this.businessTemplateProcedureFileSystemId = props.businessTemplateProcedureFileSystemId
    }
  }

  /**
   * Map of business records
   * @returns {Map<Number, RecordHit>}
   */
  recordMap() {
    const documents = new Map()
    const businessRecords = this.businessContainerStructure.records
    const businessTemplateRecords = this.businessTemplateContainerStructure.records
    const corporateRecords = this.corporateTemplateContainerStructure.records
    const templateRecords = super.recordMap()
    templateRecords.forEach((value, key) => {
      value.origin = RecordHitOrigin.TEMPLATE_RECORDS
      documents.set(key, value)
    })
    businessRecords.forEach((value, key) => {
      value.origin = RecordHitOrigin.BUSINESS_RECORDS
      documents.set(key, value)
    })
    businessTemplateRecords.forEach((value, key) => {
      value.origin = RecordHitOrigin.BUSINESS_TEMPLATE_RECORDS
      documents.set(key, value)
    })
    corporateRecords.forEach((value, key) => {
      value.origin = RecordHitOrigin.CORPORATE_RECORDS
      documents.set(key, value)
    })
    return documents
  }
}
