import { ifetch } from '@integrator/api'

function url() {
  return '/api/portal/authenticate'
}

// credentials = {username, password}
function authenticate(credentials) {
  return ifetch(url() + '/', {
    method: 'POST',
    body: JSON.stringify(credentials)
  })
}

function authenticateWithinUnit(unitId, credentials) {
  return ifetch(url() + '/' + unitId, {
    method: 'POST',
    body: JSON.stringify(credentials)
  })
}

function units() {
  return ifetch(url() + '/units', {
    method: 'GET'
  })
}

function switchToUnit(unitId) {
  return ifetch(url() + '/switch-to/' + unitId, {
    method: 'POST'
  })
}

export { authenticate, authenticateWithinUnit, units, switchToUnit }
