import { ifetch } from '@integrator/api'

function baseUrl(checklistId) {
  return `/api/checklists/${checklistId}`
}

/**
 * @param {number} checklistId
 * @returns {(function(*): Promise<*>)|*}
 */
function get(checklistId) {
  return ifetch(baseUrl(checklistId), { method: 'GET' })
}

/**
 * @param {number} checklistId
 * @returns {(function(*): Promise<*>)|*}
 */
function getEventLog(checklistId) {
  return ifetch(baseUrl(checklistId) + `/event-log`, { method: 'GET' })
}

function switchStatus(checklistId, currentStatus) {
  return ifetch(baseUrl(checklistId) + `/status`, { method: 'POST', body: currentStatus })
}

function definition(checklistId, definition) {
  return ifetch(baseUrl(checklistId) + `/definition`, { method: 'POST', body: JSON.stringify(definition) })
}

function changeExternalAccessPermissionForClient(checklistId, client, permission) {
  return ifetch(baseUrl(checklistId) + `/external-access/${client}`, {
    method: 'POST',
    body: permission
  })
}

function assignedBuildingElement(checklistId, id) {
  return ifetch(baseUrl(checklistId) + `/building-element/${id}`, { method: 'POST' })
}

function deleteAssignedBuildingElement(checklistId) {
  return ifetch(baseUrl(checklistId) + `/building-element`, { method: 'DELETE' })
}

function remove(checklistId) {
  return ifetch(baseUrl(checklistId), { method: 'DELETE' })
}

function removeWithDeviations(checklistId) {
  return ifetch(baseUrl(checklistId) + '/with-deviations', { method: 'DELETE' })
}

export {
  get,
  getEventLog,
  switchStatus,
  definition,
  changeExternalAccessPermissionForClient,
  assignedBuildingElement,
  deleteAssignedBuildingElement,
  remove,
  removeWithDeviations
}
