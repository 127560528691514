/**
 * Self contained module that lists projects.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import router from 'angular-ui-router'

import './list.components.directive.scss'
import _ from 'lodash'

class ListComponentsDirCtrl {
  constructor($stateParams, psProjectService, $state, $log, $scope) {
    this.$scope = $scope
    this.$log = $log
    this.$state = $state
  }

  openComponent(id) {
    this.$log.debug('Open component with id: ' + id)
    this.$state.go('components.detail', {
      unitId: this.$state.params.unitId,
      project: this.$state.params.project,
      control: this.$state.params.control,
      component: id
    })
  }
}
ListComponentsDirCtrl.$inject = ['$stateParams', 'psProjectService', '$state', '$log', '$scope']

export default angular
  .module('ps.directives.list.components', [router])
  .controller('ListComponentsDirCtrl', ListComponentsDirCtrl)
  .directive('listComponents', function () {
    return {
      restrict: 'E',
      template: require('./list.components.directive.tpl.html'),
      scope: {
        components: '='
      },
      controller: 'ListComponentsDirCtrl',
      controllerAs: 'vc'
    }
  }).name
