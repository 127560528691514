class Criterion {
  key
  value
  constructor({ key, value } = {}) {
    this.key = key
    this.value = value
  }
}

export { Criterion }
