import { ProcedureCollectionDefinition, ProcedureCategoryTree, Procedure } from '@integrator/api'

/**
 * Represents the structure of a procedure collection.
 */
export class ProcedureCollectionStructure {
  /**
   * The definition of the procedure collection.
   * @type {ProcedureCollectionDefinition}
   */
  definition

  /**
   * The category tree of procedures.
   * @type {ProcedureCategoryTree}
   */
  categoryTree

  /**
   * The procedures within the collection.
   * @type {Map<number, Array<Procedure>>}
   */
  procedures

  /**
   * The selection status of the collection.
   * @type {Map<number, boolean>}
   */
  selection

  /**
   * The selected procedureIds of the collection.
   * @type {Array<number>}
   */
  selectedProcedureIds

  constructor(props) {
    if (props) {
      this.definition = new ProcedureCollectionDefinition(props?.definition)
      this.categoryTree = new ProcedureCategoryTree(props?.categoryTree)
      this.procedures = new Map()
      if (props?.procedures) {
        Object.entries(props?.procedures)?.forEach(([key, val]) => {
          const procedureList = val?.map(entity => new Procedure(entity))
          procedureList.sort(this._procedureByNameComparator)
          this.procedures.set(Number(key), procedureList)
        })
      }
      this.selection = new Map()
      if (props?.selection) {
        Object.entries(props?.selection?.map)?.forEach(([key, val]) => this.selection.set(Number(key), !!val))
        this.selectedProcedureIds = [...this.selection].filter(([, val]) => val).map(([key]) => Number(key)) || []
      }
    }
  }

  _procedureByNameComparator(procedure1, procedure2) {
    return procedure1.name.localeCompare(procedure2.name)
  }
}
