import { Actions } from '../../universal'

class Order {
  id
  number
  actions
  constructor(props) {
    if (props) {
      this.id = props.id
      this.number = props.number
      this.actions = new Actions(props.actions)
    }
  }
}

export { Order }
