class DistributionBoard {
  earthingSystem
  rating
  nominalValue
  protection
  constructor(props) {
    if (props) {
      this.earthingSystem = props.earthingSystem
      this.rating = props.rating
      this.nominalValue = props.nominalValue
      this.protection = props.protection
    }
  }
}

export { DistributionBoard }
