'use strict'

/**
 * Application sidebar menu.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import router from 'angular-ui-router'
import angularTranslate from 'angular-translate'
import './sidenav.menu.directive.scss'

class SideNavMenuCtrl {
  constructor($log, $mdSidenav, $state) {
    this.$log = $log
    this.$state = $state
    $mdSidenav('menu').then(instance => {
      this.instance = instance
    })
    this.getFeatures()
  }

  getFeatures() {
    this.features = [
      {
        name: 'PROJECTS',
        state: 'projects.list',
        icon: 'location_city'
      },
      {
        name: 'PERIODIC_CONTROLS',
        state: 'periodic.parent',
        icon: 'assignment_turned_in',
        submodules: [
          {
            name: 'CONTROLS',
            state: 'controls.parent',
            icon: 'done_all'
          },
          {
            name: 'COMPONENTS',
            state: 'components.parent',
            icon: 'tune'
          }
        ]
      },
      {
        name: 'PROCEDURES',
        state: 'procedures.parent',
        icon: 'assignment'
      },
      {
        name: 'FDV',
        state: 'fdv.parent',
        icon: 'book'
      }
    ]
  }

  close() {
    this.instance.close()
  }

  /**
   * Transition to state.
   * @param state
   */
  goToState(state) {
    try {
      let unitId = this.$state.params.unitId
      this.$state.go(state, { unitId: unitId }, { reload: true })
    } catch (e) {
      this.$log.error(e)
    }
    this.instance.close()
  }
}
SideNavMenuCtrl.$inject = ['$log', '$mdSidenav', '$state']

export default angular
  .module('ps.directives.sidenav.menu', [router, angularTranslate])
  .controller('SideNavMenuCtrl', SideNavMenuCtrl)
  .directive('sidenavMenu', function () {
    return {
      restrict: 'E',
      template: require('./sidenav.menu.directive.tpl.html'),
      scope: {},
      controller: 'SideNavMenuCtrl',
      controllerAs: 'vc'
    }
  }).name
