import { DeviationRelation } from './deviation-relation'

class DeviationRelations {
  list
  map
  constructor(props) {
    this.list = props?.list?.map(relation => new DeviationRelation(relation))
    this.map = new Map()
    if (props?.map) {
      for (const [key, value] of Object.entries(props.map)) {
        this.map.set(key, new DeviationRelation(value))
      }
    }
  }
}

export { DeviationRelations }
