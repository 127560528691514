/**
 * @typedef {Object} Cost
 * @property {number} value
 * @property {Boolean} includeTax
 */
class Cost {
  value
  includeTax

  constructor(props) {
    this.value = props.value
    this.includeTax = props.includeTax
  }
}

export { Cost }
