import { ifetch } from '@integrator/api'
function url(unitId, projectId) {
  return `/api/business/${unitId}/projects/${projectId}/periodic-control/controls`
}

function getNewControlConfig(unitId, projectId) {
  return ifetch(url(unitId, projectId) + '/new-config')
}

// endpoint method 'controls'
function getControls(unitId, projectId) {
  return ifetch(url(unitId, projectId))
}

function upcomingActive(unitId, projectId, count) {
  if (!count) {
    count = 1
  }
  return ifetch(url(unitId, projectId) + '/upcoming/' + count)
}

function addNew(unitId, projectId, controlSetup) {
  return ifetch(url(unitId, projectId), {
    method: 'POST',
    body: JSON.stringify(controlSetup)
  })
}

export { getNewControlConfig, getControls, addNew, upcomingActive }
