import { DeviationReportText } from './deviation-report-text'
import { DeviationReportStep } from './deviation-report-step'
import { Cost } from '@integrator/api/src/universal/model/cost'
import { Actions } from '@integrator/api/src/universal/model/actions'

/**
 * @typedef {Object} DeviationReport
 * @property {number} id
 * @property {DeviationReportStep} step
 * @property {string} module
 * @property {DeviationReportText} text
 * @property {Cost} cost
 * @property {Actions} actions
 */
class DeviationReport {
  id
  step
  module
  text
  cost
  actions

  constructor(props) {
    this.id = props?.id ? props.id : 0
    if (props?.step) {
      this.step = new DeviationReportStep(props.step)
    }
    this.module = props?.module
    this.text = props?.text ? new DeviationReportText(props.text) : new DeviationReportText({})
    this.costEstimate = props?.costEstimate ? new Cost(props.costEstimate) : new Cost({})
    this.actions = props?.actions ? new Actions(props.actions) : new Actions({})
  }

  formUpdate(data) {
    this.text.description = data.description
    this.text.value = data.value
    this.text.sendNotification = data.sendNotification === 'on'
    if (this.step.name === DeviationReportStep.COST_ESTIMATE.name) {
      this.costEstimate.value = data.costEstimate
      this.costEstimate.includeTax = data.includeTax === 'on'
    }
  }

  static fromForm(step, data) {
    const report = new DeviationReport({ step: step })
    report.formUpdate(data)
    return report
  }
}

export { DeviationReport }
