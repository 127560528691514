import { css } from 'lit'
export const linkPreviewStyles = css`
  .link-card {
    padding: 0.8em;
    background: #fff;
    border: 1px solid rgba(201, 201, 204, 0.48);
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    border-radius: 2px;
    margin: 2px 0;
  }
  .link-title {
    padding: 0.3em 0;
    font-weight: bold;
  }
  .link {
    color: inherit;
    text-decoration: inherit;
  }
`
