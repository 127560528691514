import { ifetch } from '@integrator/api'

function url() {
  return `/api/global/users/search`
}

function search(query) {
  return ifetch(url(), {
    method: 'POST',
    body: query
  })
}

function personById(id) {
  return ifetch(url() + `/person/${id}`, {
    method: 'GET'
  })
}

export { search, personById }
