/**
 *
 * Controller for lists of periodic controls.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 *
 */
import dialog from '../../../common/services/dialogs/controls/dialogs.checklist.js'

class ControlsListCtrl {
  constructor(psControlsService, $stateParams, $mdDialog, $log, $state) {
    this.service = psControlsService
    this.projectId = $stateParams.id
    this.unitId = $stateParams.unitId
    this.$mdDialog = $mdDialog
    this.$log = $log
    this.$state = $state
    this.error = false

    // Load periodic controls
    this.getProjectControls()
  }

  /**
   * Get the periodic controls for this projectId.
   */
  getProjectControls() {
    this.service
      .projectControls(this.projectId)
      .then(response => (this.data = response.data))
      .catch(error => (this.error = true))
  }

  openControl(control) {
    this.$state.go('controls.detail', { unitId: this.unitId, project: this.projectId, control: control.id })
  }
}

ControlsListCtrl.$inject = ['psControlsService', '$stateParams', '$mdDialog', '$log', '$state']

export default ControlsListCtrl
