'use strict'
/**
 * Directive for listing checklists.
 *
 * @author Audun Follegg (audunfo@gmail.com)
 */
import angular from 'angular'
import angularMaterial from 'angular-material'
import router from 'angular-ui-router'
import dialog from '../../../services/dialogs/controls/dialogs.checklist.js'
import ChecklistDataService from '../../../services/data/checklist.dataservice.js'

import './list.checklist.directive.scss'
import _ from 'lodash'

class ListChecklistsDirCtrl {
  constructor($state, $log, $scope, $mdDialog, psChecklistService) {
    this.$scope = $scope
    this.$log = $log
    this.$state = $state
    this.$mdDialog = $mdDialog
    this.psChecklistService = psChecklistService
  }

  openChecklist(checklist) {
    this.$log.debug('Open checklist with id: ' + checklist.id)
    let mdDialog = this.$mdDialog
    this.psChecklistService.checklist(checklist.id).then(function (checklist) {
      mdDialog.show(dialog(checklist))
    })
  }
}
ListChecklistsDirCtrl.$inject = ['$state', '$log', '$scope', '$mdDialog', 'psChecklistService']

export default angular
  .module('ps.directives.list.checklists', [router, angularMaterial, ChecklistDataService])
  .controller('ListChecklistsDirCtrl', ListChecklistsDirCtrl)
  .directive('listChecklists', function () {
    return {
      restrict: 'E',
      template: require('./list.checklists.directive.tpl.html'),
      scope: {
        templates: '=',
        checklists: '=',
        showTemplates: '=',
        companyTemplates: '='
      },
      controller: 'ListChecklistsDirCtrl',
      controllerAs: 'vc'
    }
  }).name
