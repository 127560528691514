import { ifetch } from '@integrator/api'

function url(businessUnitId) {
  return `/api/business/${businessUnitId}/settings/leave`
}

function get(unitId) {
  return ifetch(url(unitId))
}
function newReason(unitId, reason) {
  return ifetch(url(unitId) + `/reason`, { method: 'POST', body: JSON.stringify(reason) })
}

function update(unitId, reason) {
  return ifetch(url(unitId) + `/reason/${reason.id}`, { method: 'POST', body: JSON.stringify(reason) })
}

function deleteReason(unitId, reason) {
  return ifetch(url(unitId) + `/reason/${reason.id}`, { method: 'DELETE' })
}

function changePayedDuringLunch(unitId, update) {
  return ifetch(url(unitId) + `/paid-during-lunch`, { method: 'POST', body: JSON.stringify(update) })
}

export { get, newReason, update, deleteReason, changePayedDuringLunch }
