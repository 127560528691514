/**
 * @typedef {Object} ForgotPasswordRequest
 * @property {string} email
 * @property {string} handleRequestUrl
 */
class ForgotPasswordRequest {
  email
  handleRequestUrl

  constructor(props) {
    const { email, handleRequestUrl } = props
    Object.assign(this, props)
  }
}

export { ForgotPasswordRequest }
