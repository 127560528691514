import { ifetch } from '@integrator/api'

function url(unitId, projectId, procedureId) {
  return `/api/business/${unitId}/projects/${projectId}/procedures/${procedureId}/manual`
}

function manual(unitId, projectId, procedureId) {
  return ifetch(url(unitId, projectId, procedureId))
}

export { manual }
