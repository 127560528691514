import { Enum } from '../../universal'

/**
 * @typedef {Object} DeviationReportStep
 */
class DeviationReportStep extends Enum {
  static OPEN = new DeviationReportStep('OPEN')
  static REOPEN = new DeviationReportStep('REOPEN')
  static CLOSE = new DeviationReportStep('CLOSE')
  static RECOMMENDATION = new DeviationReportStep('RECOMMENDATION')
  static COST_ESTIMATE = new DeviationReportStep('COST_ESTIMATE')
  static UNDERLYING_CAUSE = new DeviationReportStep('UNDERLYING_CAUSE')
  static STATUS = new DeviationReportStep('STATUS')

  constructor(name) {
    super(name)
  }
}

export { DeviationReportStep }
