import { Checklists, ChecklistTemplates } from '@integrator/api'
import { OriginLevel } from '../../content/model/origin-level'

/**
@extends {Map<OriginLevel, Checklists|ChecklistTemplates>}
 */

class TaskChecklistsMap extends Map {
  constructor(props) {
    super()
    this.set(OriginLevel.PROJECT, new Checklists(props[OriginLevel.PROJECT.name]))
    this.set(OriginLevel.BUSINESS, new ChecklistTemplates(props[OriginLevel.BUSINESS.name]))
    this.set(OriginLevel.CORPORATE, new ChecklistTemplates(props[OriginLevel.CORPORATE.name]))
    this.set(OriginLevel.TEMPLATE, new ChecklistTemplates(props[OriginLevel.TEMPLATE.name]))
  }

  getChecklists() {
    return this.get(OriginLevel.PROJECT)?.list
  }

  getUnitTemplates() {
    return this.get(OriginLevel.BUSINESS)?.list
  }

  getCorporateTemplates() {
    return this.get(OriginLevel.CORPORATE)?.list
  }

  getGlobalTemplates() {
    return this.get(OriginLevel.TEMPLATE)?.list
  }
}

export { TaskChecklistsMap }
